import React, { useState } from 'react';
import { Collapse, Media } from 'react-bootstrap';
import { Constant } from '../../../shared';
import './guest-profile-sidebar.style.scss';

const GuestProfileSideBar = props => {
    const [open, collapseOpen] = useState(false);
    const [roomDetails, setRoomDetails] = useState(props.data);
    return (
        <div className="br__guest-login-meta">
            <img
                id="room-image"
                className="guest-login__img-hero"
                src={roomDetails.image}
                alt=""
                data-test="room-image"
            />
            <p id="room-time-stamp" className="guest-login__time" data-test="room-timestamp">
                {roomDetails.timeStamp}
            </p>
            <div className="guest-login__info">
                <div className="d-flex align-items-start">
                    <img
                        id="org-logo"
                        className="guest-login__img-org"
                        src={roomDetails.organization.logo}
                        alt=""
                        data-test="org-logo"
                    />
                    <div>
                        <h2 id="room-name" className="guest-login__name" data-test="room-name-heading">
                            {roomDetails.name}
                        </h2>
                        <h5 id="board-name" className="guest-login__board-name" data-test="board-name-heading">
                            {roomDetails.board.name}
                        </h5>
                    </div>
                </div>

                {!open && (
                    <p
                        id="room-description"
                        className="guest-login__desc guest-login__desc--truncate "
                        data-test="room-description"
                    >
                        {roomDetails.description.slice(0, Constant.GUEST_LOGIN_DESCRIPTION_LIMIT)}
                    </p>
                )}
                {open && (
                    <Collapse in={open}>
                        <div id="readmore-collapse-text" className="guest-login__desc">
                            {roomDetails.description}
                        </div>
                    </Collapse>
                )}
                {roomDetails.description.length > Constant.GUEST_LOGIN_DESCRIPTION_LIMIT && (
                    <label
                        id="read-more-label"
                        className="guest-login__read-more"
                        onClick={() => collapseOpen(!open)}
                        aria-controls="readmore-collapse-text"
                        aria-expanded={open}
                        data-test="readmore-label"
                    >
                        {!open ? 'Read More' : 'Collapse'}
                    </label>
                )}
            </div>
            <div className="guest-login__speakers">
                <h4 id="speaker-heading" className="guest-login__speaker-heading" data-test="speaker-heading">
                    Speakers | {roomDetails.speakers.length}
                </h4>
                <ul id="speaker-list" className="guest-login__speakers-list" data-test="speaker-list">
                    {roomDetails.speakers.map((curr, ind) => (
                        <li
                            id={`guest-login-speaker-item-${ind}`}
                            key={ind}
                            className="guest-login__speakers-item"
                            data-test="guest-login-speaker-item"
                        >
                            <Media className="guest-login__speaker-info">
                                <img
                                    id={`guest-login-speaker-image-${ind}`}
                                    width={64}
                                    height={64}
                                    className="mr-3"
                                    src={curr.image}
                                    alt=""
                                    data-test="guest-login-speaker-info"
                                />
                                <Media.Body>
                                    <div className="guest-login__speaker-head">
                                        <h5
                                            id={`guest-login-speaker-name-${ind}`}
                                            className="guest-login__speaker-name"
                                            data-test="guest-login-speaker-name"
                                        >
                                            {curr.name}
                                        </h5>
                                    </div>
                                    <p
                                        id={`guest-login-speaker-blurb-${ind}`}
                                        className="guest-login__speaker-blurb"
                                        data-test="guest-login-speaker-blurb"
                                    >
                                        {curr.blurb}
                                    </p>
                                </Media.Body>
                            </Media>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default GuestProfileSideBar;
