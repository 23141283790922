import React from 'react';
import { connect } from 'react-redux';
import './header-component.style.scss';
import { ButtonComponent } from '../../../../shared/components';

const headerComponent = props => {
    const { click } = { ...props };
    return (
        <div id="organization__heading_container" className="organization__header">
            <h2 id="organization__heading_name" className="organization__heading">
                Organizations
            </h2>
            {props.user.isRoot ? (
                <ButtonComponent
                    id={'organization-header-component-button'}
                    className={'br__btn-theme-filled br__btn-theme-filled--width-231'}
                    name="Create New Organization"
                    onclick={click}
                />
            ) : (
                ''
            )}
        </div>
    );
};

const mapStateToProp = state => {
    /**
     * This will return all the state declare inside the app directory in store
     */
    return {
        ...state.app,
    };
};

export default connect(mapStateToProp)(headerComponent);
