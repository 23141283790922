import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './core';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure({
    aws_project_region: awsconfig.aws_project_region,
    aws_cognito_region: awsconfig.aws_cognito_region,
    aws_user_pools_id: awsconfig.aws_user_pools_id,
    aws_user_pools_web_client_id: awsconfig.aws_user_pools_web_client_id,
    oauth: {},
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: awsconfig.aws_cognito_region,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: awsconfig.aws_user_pools_id,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    Storage: {
        bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        region: 'us-east-1',
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
});

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
