/**
 * Temporarily not in use but need to exist
 */
import React from 'react';
import { startLoading } from '../..';
import { AuthService, AWSStorageService, showHideErrorToaster, CloudinaryService } from '../..';
import { Button, Form, Row, Col } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png';
import './guest-profile.style.scss';
import { Constant, ErrorMessageComponent, FileSelectComponent, Utility } from '../../../shared';
import { GuestProfileSideBar } from '../../components';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { CreateRoomDashboardService } from '../../../content/create-room/services';
import { CommonService } from '../../services/common/common';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export class GuestProfileContainer extends React.Component {
    _authService = new AuthService();
    _createRoomService = new CreateRoomDashboardService();
    _commonService = new CommonService();
    _awsStorageService = new AWSStorageService();
    _utilityService = new Utility();
    _cloudinaryService = new CloudinaryService();

    constructor(props) {
        super(props);
        this.queryParams = new URLSearchParams(this.props.location.search);
        this.token = this.queryParams.get('token');
        this.decoded = jwt_decode(this.token);
        this.roomId = this.queryParams.get('roomId');
        this.state = {
            formDetails: {
                name: null,
                email: null,
                blurb: null,
                fullAvatarUrl: null,
                localImagePath: null,
                existingLogoPath: null,
            },
            buttonDisabled: false,
            roomDetails: null,
            existingLogoPath: null,
            userImage: null,
            localImagePath: null,
            validationErrorMessage: {
                logoValidation: '',
                memberNameValidation: '',
            },
        };
    }

    setLogo = async imageId => {
        if (imageId !== '') {
            const logo = await this.getImageSrc(imageId);
            return logo;
        }
        return null;
    };

    getImageSrc = async imageId => {
        const image = await this._awsStorageService.downloadFile(imageId);

        if (image && image.Body) {
            return URL.createObjectURL(image.Body);
        }
        return null;
    };

    getZoneAbbr = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
        });
        return zone[0].abbr;
    };

    setTimeStamp = (startTime, endTime) => {
        let abbr = 'EST';
        const start = DateTime.fromISO(startTime, { zone: 'utc' })
            .toLocal()
            .toFormat('DD | ha');
        const end = DateTime.fromISO(endTime, { zone: 'utc' })
            .toLocal()
            .toFormat('ha');
        abbr = this.getZoneAbbr() && this.getZoneAbbr();
        return [start, ' - ', end, '\t', abbr].join('');
    };

    setBoardDetails = async board => {
        const boardId = board.boardId;
        const name = board.name;
        const logo = await this.setLogo(board.logo);
        return { boardId, name, logo };
    };

    setOrgDetails = async org => {
        const orgId = org.orgId;
        const name = org.name;
        const logo = await this.setLogo(org.logo);
        return { orgId, name, logo };
    };

    handleMemberObj = async member => {
        const id = member.id;
        const name = member.name && member.name.slice(0, 155);
        const role = member.role;
        const createdAt = member.createdAt;
        const image = await this.setLogo(member.image);
        const inviteStatus = member.inviteStatus;
        const blurb = member.blurb;
        const email = member.email;
        const state = member.state;
        return { id, name, role, createdAt, image, inviteStatus, blurb, email, state };
    };

    getMemberArrayBySpecifiedRole = async (membersArray, role) => {
        const totalMembers = [];
        for (const member of membersArray) {
            if (member.role === role) {
                const memberObj = await this.handleMemberObj(member);
                totalMembers.push(memberObj);
            }
        }
        return totalMembers;
    };

    setRoomDetails = async roomDetails => {
        const board = await this.setBoardDetails(roomDetails.board);
        const description = roomDetails.description;
        const endTime = roomDetails.endTime;
        const image = await this.setLogo(roomDetails.image);
        const name = roomDetails.name;
        const organization = await this.setOrgDetails(roomDetails.organization);
        const speakers = await this.getMemberArrayBySpecifiedRole(
            roomDetails.participants,
            Constant.ROOM_MEMBERS.SPEAKER,
        );
        const roomId = roomDetails.roomId;
        const startTime = roomDetails.startTime;
        const status = roomDetails.status;
        const timeStamp = this.setTimeStamp(roomDetails.startTime, roomDetails.endTime);
        const timezone = roomDetails.timezone;
        return {
            board,
            description,
            endTime,
            image,
            name,
            organization,
            speakers,
            roomId,
            startTime,
            status,
            timeStamp,
            timezone,
        };
    };

    handleToken = async () => {
        try {
            await this._authService.doPasswordLessLogin(this.decoded.email, this.token);
        } catch (error) {
            this.props.showHideErrorToaster({
                show: true,
                message: error.message,
                bgColor: '#ff3547',
            });
        }
    };

    handleRoomDetails = async () => {
        const roomData = await this._createRoomService.getRoomDetail(this.roomId);
        const roomInfo = await this.setRoomDetails(roomData);
        this.setState(prevState => ({
            ...prevState,
            roomDetails: roomInfo,
        }));
    };

    fetchCompleteDetails = async () => {
        this.props.startLoading(true);
        await this.handleToken();
        await this.handleRoomDetails();
        this.props.startLoading(false);
    };

    componentDidMount() {
        this.fetchCompleteDetails();
    }

    componentDidUpdate() {
        if (
            this.props.user &&
            this.props.user.name &&
            this.props.user.image &&
            this.props.user.email === this.decoded.email
        ) {
            this.props.history.replace(`/room-join/${this.roomId}`);
        }
    }

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    validateForm = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        if (!this.state.formDetails.name || !this.state.formDetails.name.trim()) {
            validationErrorMessage.memberNameValidation = 'Name is required';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        return true;
    };

    handleInputChange(e) {
        const { name, value } = e.target;

        this.setState({
            ...this.state,
            formDetails: {
                ...this.state.formDetails,
                [name]: value,
            },
        });
    }

    async signIn(email) {
        return await Auth.signIn(email);
    }

    setSelectedLogo = async image => {
        const imageValidation = this._utilityService.validateImageFile(image, Constant.FILE_UPLOAD_LIMIT);
        if (imageValidation.isValid) {
            this.props.startLoading(true);
            await this.setState({
                ...this.state,
                userImage: image,
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            const storageMetaData = await this.getStorageMetaData(this.decoded.userId);
            const cloudinaryImageUrl = await this._cloudinaryService.getCloudinaryURL(storageMetaData);
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    localImagePath: cloudinaryImageUrl,
                    existingLogoPath: storageMetaData,
                    cloudinaryImageKey: this._utilityService.removeImgExtension(storageMetaData),
                },
            });
            this.props.startLoading(false);
            return;
        }

        this.setState({
            ...this.state,
            userImage: null,
            localImagePath: '',
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: imageValidation.message,
            },
        });
    };

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    uploadFileToStorage = async () => {
        const credentials = await this._authService.getAuthCredentials();
        const cognitoId = credentials.identityId;
        const storageMetaData = await this._awsStorageService.uploadFile(
            `${cognitoId}/${uuidv4()}`,
            this.state.userImage,
            this.state.userImage.type,
        );
        return storageMetaData;
    };

    getStorageMetaData = async userId => {
        let storageMetaData = '';
        if (userId) {
            if (this.state.userImage) {
                storageMetaData = await this.uploadFileToStorage();
            } else {
                storageMetaData = this.state.existingLogoPath;
            }
        } else {
            storageMetaData = await this.uploadFileToStorage();
        }
        return storageMetaData.key || storageMetaData;
    };

    setCloudinaryImageUrl = src => {
        this.setState({
            ...this.state,
            formDetails: {
                ...this.state.formDetails,
                localImagePath: this._utilityService.removeImgExtension(src),
            },
        });
    };

    async joinRoom(e, roomId) {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        this.props.startLoading(true);

        const requestPayload = {
            name: this.state.formDetails.name,
            email: this.decoded.email,
            blurb: this.state.formDetails.blurb,
            image: this.state.formDetails.existingLogoPath,
            fullAvatarUrl: this.state.formDetails.localImagePath,
        };
        await this._commonService.updateUserProfile(requestPayload);

        this.props.startLoading(false);
        this.props.history.replace(`/room-join/${this.roomId}`);
    }

    render() {
        return (
            <div className="br__guest-login-wrapper">
                <img
                    id="b1-logo"
                    src={logo}
                    alt="logo"
                    className="br__guestlogin-logo img-fluid"
                    data-test="boardroom-logo"
                />
                <Row>
                    <Col xs="12" md="6" className="br__guest-login-left-col">
                        {this.state.roomDetails && <GuestProfileSideBar data={this.state.roomDetails} />}
                    </Col>
                    <Col xs="12" md="6" className="br__guest-login-right-col">
                        <Form onSubmit={e => this.joinRoom(e, this.roomId)}>
                            <div className="br__guest-login-block">
                                <FileSelectComponent
                                    id="file-upload-create-room"
                                    label="Profile Picture*"
                                    isProfilePicture={true}
                                    fileHandling={{
                                        setSelectedLogo: this.setSelectedLogo,
                                        localImagePath: this.state.formDetails.localImagePath,
                                        cloudinaryImageKey: this.state.formDetails.cloudinaryImageKey,
                                        setCloudinaryImageUrl: this.setCloudinaryImageUrl,
                                    }}
                                    validationErrorMessage={this.state.validationErrorMessage}
                                    validateImageFileMessage={this.validateImageFileMessage}
                                />
                                <p className="optional-tag">*Optional</p>

                                <Form.Group id="form-full-name" className="br__form-group" controlId="formFullName">
                                    <Form.Label className="br__form-label">Name</Form.Label>
                                    <Form.Control
                                        name="name"
                                        className="br__form-control"
                                        placeholder="Name"
                                        onChange={e => this.handleInputChange(e)}
                                        maxLength={155}
                                    />
                                    <ErrorMessageComponent
                                        message={this.state.validationErrorMessage.memberNameValidation}
                                    />
                                </Form.Group>

                                <Form.Group id="form-email-address" className="br__form-group" controlId="formFullName">
                                    <Form.Label className="br__form-label">Email</Form.Label>
                                    <Form.Control
                                        name="email"
                                        className="br__form-control"
                                        placeholder="Email"
                                        value={this.decoded.email}
                                        disabled
                                    />
                                </Form.Group>

                                <Form.Group id="form-blurb" className="br__form-group" controlId="formFullName">
                                    <Form.Label className="br__form-label">Blurb</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="blurb"
                                        rows={2}
                                        className="br__form-control"
                                        placeholder="Blurb (Optional)"
                                        maxLength={75}
                                        onChange={e => this.handleInputChange(e)}
                                    />
                                </Form.Group>

                                <Button id="join-room-btn" className="btn__themefilled br__btn-join-room" type="submit">
                                    Join Room
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestProfileContainer);
