import { Http } from '../../../core';

export class UpdateProfileService {
    /**
     * @name getUserById
     * @param {string} param User and board Ids required for getting users board
     * @desc Will fetch user boards.
     * @return {Promise}
     */
    getUserById = (userId, boardId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/profile/${userId}/${boardId}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };

    /**
     * @name updateProfile
     * @param {object} requestPayload request payload for updating user profile
     * @param {string} userId userId whose profile is to be updated
     * @param {string} boardId boardId required for updating users board profile
     * @desc Will update specific user profile
     * @return {Promise}
     */
    updateProfile = (requestPayload, userId, boardId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`user/profile/${userId}/${boardId}`, requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
