import React from 'react';
import { AuthService } from '../../services';

export class LogoutContainer extends React.Component {
    _authService = new AuthService();

    componentDidMount = async () => {
        console.log('loggingout');
        await this._authService.logout();
        this.props.history.push('/');
    };

    render() {
        return <h5>Logout</h5>;
    }
}

export default LogoutContainer;
