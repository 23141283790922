import React, { useEffect, useLayoutEffect, useState } from 'react';
import arrowRight from '../../../assets/images/arrow-right.png';
import arrowLeft from '../../../assets/images/arrow-left.png';
import './carousel.style.scss';

const Carousel = props => {
    const { children, activeParticipantid, speakers } = props;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(children.length);
    const [touchPosition, setTouchPosition] = useState(null);
    const [itemToShow, setItemToShow] = useState(7);

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length);
        const totalElements = children.length;
        if (totalElements > itemToShow) {
            const activeSpeakersIdx = findActiveSpeakerIndex();
            activeSpeakersIdx &&
                activeSpeakersIdx.forEach(activeSpeakerIdx => {
                    const highlightActiveSpeaker = () => {
                        const half = Math.floor(itemToShow / 2);
                        if (activeSpeakerIdx + half >= totalElements - 1) {
                            setCurrentIndex(totalElements - itemToShow);
                            return;
                        }
                        if (activeSpeakerIdx < half + 1) {
                            setCurrentIndex(0);
                            return;
                        }
                        if (activeSpeakerIdx) {
                            setCurrentIndex(activeSpeakerIdx - half);
                            return;
                        }
                    };
                    setTimeout(highlightActiveSpeaker);
                });
        }
    }, [children, props.activeParticipantId]);

    const findActiveSpeakerIndex = () => {
        const activeSpeakerIdx = speakers.map((speaker, index) => {
            if (activeParticipantid.includes(speaker.id)) {
                return index;
            }
        });
        return activeSpeakerIdx;
    };

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth < 600) {
                setItemToShow(3);
                setCurrentIndex(0);
                return;
            }
            setItemToShow(7);
            setCurrentIndex(0);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const next = () => {
        if (currentIndex < length - itemToShow) {
            setCurrentIndex(prevState => prevState + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1);
        }
    };

    const handleTouchStart = e => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleTouchMove = e => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
            next();
        }

        if (diff < -5) {
            prev();
        }

        setTouchPosition(null);
    };

    return (
        <div className="guestroom__carousel" data-test="carousel_component">
            <div
                className="carousel-content-wrapper"
                data-test="carousel_content"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
            >
                <div
                    className={`carousel-content show-${itemToShow}`}
                    style={{ transform: `translateX(-${currentIndex * (100 / itemToShow)}%)` }}
                >
                    {children}
                </div>
            </div>
            <div className="carousel__nav" data-test="carousel_nav">
                <button
                    data-test="left_arrow"
                    onClick={prev}
                    style={{ background: 'transparent', border: 'none' }}
                    className="carousel__arrow carousel__arrow--left"
                >
                    {speakers.length > itemToShow && <img src={arrowLeft} alt="" />}
                </button>
                <button
                    data-test="right_arrow"
                    onClick={next}
                    style={{ background: 'transparent', border: 'none' }}
                    className="carousel__arrow carousel__arrow--right"
                >
                    {speakers.length > itemToShow && <img src={arrowRight} alt="" />}
                </button>
            </div>
        </div>
    );
};

export default Carousel;
