import React from 'react';
import { ThreeDotsDropDownComponent } from '../../../../shared';
import './member-table-list-component.style.scss';
import { v4 as uuidv4 } from 'uuid';

const membertableListComponent = props => {
    const { id, ...info } = props.memberInfo;
    const { index } = props;
    let nameDataClass = undefined;
    return (
        <React.Fragment>
            {Object.keys(info).map((key, i) => {
                key === 'name'
                    ? (nameDataClass = 'board-listing__cel manage-member-table__cal board-listing__cel--width-30')
                    : key === 'blurb'
                    ? (nameDataClass =
                          'board-listing__cel manage-member-table__cal manage-member-table__cal--blurb board-listing__cel--blurb')
                    : key === 'role'
                    ? (nameDataClass =
                          'board-listing__cel manage-member-table__cal manage-member-table__cal--role board-listing__cel--role')
                    : key === 'email'
                    ? (nameDataClass =
                          'board-listing__cel manage-member-table__cal manage-member-table__cal--email board-listing__cel--email')
                    : (nameDataClass = 'board-listing__cel manage-member-table__cal');
                return (
                    <td
                        key={uuidv4()}
                        id={`member-table-body-row-data-${index + 1}-${i}`}
                        className={nameDataClass}
                        onClick={() => props.onClick(id)}
                        data-test="member-table-body-row-data-render"
                    >
                        {Array.isArray(props.memberInfo[key])
                            ? props.memberInfo[key].map(value => <tr>{value}</tr>)
                            : props.memberInfo[key]}
                    </td>
                );
            })}
            <td
                id="table-meatball-menu"
                data-test="member-table-body-row-data-render"
                className="board-listing__cel board-listing__cel--dropdown manage-member-table__cal--dropdown"
            >
                <ThreeDotsDropDownComponent
                    id={`organization-detail-table-${index}`}
                    menuItems={props.menuItems}
                    onSelect={item => {
                        props.onSelectMenu(item, id);
                    }}
                />
            </td>
        </React.Fragment>
    );
};

export default membertableListComponent;
