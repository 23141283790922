import React from 'react';
import { Table } from 'react-bootstrap';
import { TableHeaderComponent, TableListComponent } from '..';
import './data-table-component.style.scss';

const dataTableComponent = props => {
    const headings = props.data[0] && getHeadings();
    const { isBoardScreen, isUserExecutiveProducer, isRoomScreen } = { ...props };
    function getHeadings() {
        const { id, ...showData } = props.data[0];
        const headers = Object.keys(showData);
        let cvtdHeading;
        if (props.getHeadings) {
            cvtdHeading = props.getHeadings(headers);
        }
        return cvtdHeading;
    }

    return (
        <div
            className="board-label"
            style={{
                color: 'black',
            }}
        >
            <Table id="data-table" className="organization__board-listing-table" data-test="table_render">
                <thead id="table-header" className="board-listing__head" data-test="table_header_render">
                    <tr id="table-header-row" className="board-listing__row--head" data-test="table_header_row_render">
                        {props.data[0] &&
                            headings &&
                            headings.map((heading, index) => (
                                <TableHeaderComponent key={index} heading={heading} index={index} />
                            ))}
                    </tr>
                </thead>
                <tbody id="table-body" className="board-listing__body" data-test="table_body_render">
                    {props.data &&
                        props.data.map((singleBoard, index) => {
                            return (
                                <tr
                                    key={singleBoard.id}
                                    id={`table-body-row-${index + 1}`}
                                    className="board-listing__row"
                                    data-test="table_body_row_render"
                                >
                                    <TableListComponent
                                        isBoardScreen={isBoardScreen || false}
                                        isRoomScreen={isRoomScreen || false}
                                        isUserExecutiveProducer={isUserExecutiveProducer}
                                        boardInfo={singleBoard}
                                        menuItems={props.menuItems}
                                        onSelectMenu={props.onSelectMenu}
                                        onClick={props.onClick}
                                        index={index}
                                    />
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};

export default dataTableComponent;
