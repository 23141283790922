import React from 'react';
import { RoomJoinService } from '../../services';
import { Col, Row, Button, Modal, Media, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import moderatorTag from '../../../../assets/images/group-2.png';
import micOff from '../../../../assets/images/ic-mic-off.svg';
import micOn from '../../../../assets/images/ic-mic-on.svg';
import iconShare from '../../../../assets/images/ic-share.svg';
import btnMuted from '../../../../assets/images/btn-muted.png';
import btnUnmuted from '../../../../assets/images/btn-unmuted.png';
import './room-join-container.style.scss';
import roomActionBtn from '../../../../assets/images/room-action-btn.png';
import handRaised from '../../../../assets/images/group-8.svg';
import cameraOn from '../../../../assets/images/start-video.png';
import cameraOff from '../../../../assets/images/stop-video.png';
import tagLive from '../../../../assets/images/tag-live-transparent.png';
import iconLounge from '../../../../assets/images/group.png';
import tagGreenRoom from '../../../../assets/images/green-room-ic.png';
import maximiseIcon from '../../../../assets/images/maximise-screen.png';
import shareScreen from '../../../../assets/images/share-screen.png';
import { LoungeTimer, LoungeHoldScreen, NoLoungeExist } from '../../components/room-lounge-modals';

import {
    AuthService,
    UserService,
    startLoading,
    AWSStorageService,
    CloudinaryService,
    WebRTCService,
    PubnubService,
    showHideErrorToaster,
    selectUser,
    VoxeetService,
    CommonService,
} from '../../../../core';
import { Carousel, Constant, ImageComponent, IconButton, BtnDropDownComponent, Utility } from '../../../../shared';
import Emitter from '../../../../core/services/emitter/emitter';
import attendee_placeHolder from '../../../../assets/images/attendee-placeholder.png';
import icon_placeHolder from '../../../../assets/images/icon-placeholder.png';
import { RoomJoinModal } from '../../components';
import { GuestRoomDetailParticipantProfile } from '../../../guest-room-detail/components';
import { GuestRoomDetailService } from '../../../guest-room-detail/services';
import { RoomDetailsDashboardService } from '../../../room-details/services';
import ScreenReshareConfirmationModal from '../../components/screen-reshare-confirmation-modal/screen-reshare-confirmation-modal';
import ScreenShareFullScreenModal from '../../components/screen-share-full-screen-modal/screen-share-full-screen-modal';
export class RoomJoinContainer extends React.Component {
    _roomJoinService = new RoomJoinService();
    _roomDetailService = new RoomDetailsDashboardService();
    _authService = new AuthService();
    _userService = new UserService();
    _awsStorageService = new AWSStorageService();
    _pubnubService = new PubnubService();
    _cloudinaryService = new CloudinaryService();
    _voxeetService = new VoxeetService();
    _webRTCService = new WebRTCService();
    _guestRoomDetailService = new GuestRoomDetailService();
    _commonService = new CommonService();
    _utilityService = new Utility();
    connectionFailedCount = 0;
    audio = document.getElementById('remoteVideo');
    user = null;
    promotionReqSenderId = null;
    voxeetParticipantInfo = null;
    joinRoomResponse = null;
    isAntMediaEnabled = false;
    promotionSpeakerRole = Constant.ROOM_MEMBERS.SPEAKER;
    isPolling = false;
    pollingId = null;
    actionMenuItems = {
        moderator: {
            moderator: [
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.MUTE, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.MOVE_TO_AUDIENCE, hideOnGreenRoom: true, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.EJECT, isDanger: true },
            ],
            speaker: [
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.PROMOTE_TO_MODERATOR, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.MUTE, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.MOVE_TO_AUDIENCE, hideOnGreenRoom: true, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.EJECT, isDanger: true },
            ],
            guest: [
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE, isBlue: true },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.EJECT, isDanger: true },
            ],
            attendee: [
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE, isBlue: true },
                {
                    name: Constant.ROOM_USER_PROFILE_ACTIONS.INVITE_TO_STAGE,
                    hideOnGreenRoom: true,
                    isDanger: false,
                    isBlue: true,
                },
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.EJECT, isDanger: true },
            ],
        },
        speaker: {
            moderator: [],
            speaker: [],
            guest: [],
            attendee: [],
        },
        guest: {
            moderator: [],
            speaker: [],
            guest: [],
            attendee: [],
        },
        attendee: {
            moderator: [],
            speaker: [],
            guest: [],
            attendee: [],
        },
    };
    profileMenuItems = [{ name: Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE, isBlue: true }];
    constructor(props) {
        super(props);
        this.state = {
            showLoungeTimer: false,
            showNoLoungeMessage: false,
            showHoldScreen: false,
            showLeaveRoom: false,
            showMeetingEnded: false,
            showEjectByModerator: false,
            showEndRoom: false,
            showRoomEndedByModerator: false,
            showReshareModal: false,
            room: { board: {}, description: '' },
            roomStarted: false,
            speakers: [],
            attendees: [],
            onlineSpeakers: [],
            onlineAttendees: [],
            activeParticipantid: [],
            toggleClass: 'roomdetail__desc roomdetail__desc--truncate',
            accordionText: 'Read More',
            roomJoinModal: false,
            modalInfo: {
                modalHeader: '',
                modalParagraph: '',
            },
            streamId: '',
            streamToken: '',
            pubnubAuthKey: '',
            reconnect: false,
            selectedParticipant: null,
            classForTruncate: false,
            collapseOpenProfile: false,
            copyMessage: Constant.ROOM_DETAILS_COPY_MESSAGE,
            showRecordedModal: false,
            showEjectModal: false,
            ejectParticipantId: null,
            isRecording: false,
            showPromotionDeclinedModal: false,
            showSpeakingTimeOverModal: false,
            moderatorActionMenuItems: [
                { name: Constant.MODERATOR_ACTIONS.MUTE_ALL },
                { name: Constant.MODERATOR_ACTIONS.LOWER_ALL_HANDS, hideOnGreenRoom: true },
                {
                    name: Constant.MODERATOR_ACTIONS.END_ROOM,
                    hideOnGreenRoom: true,
                    isDanger: true,
                },
            ],
            showPromotionModal: false,
            isVideoStarted: false,
            speakerVideoStream: null,
            isScreenShareStarted: false,
            screenShareStreaming: null,
            isFullScreenSharing: false,
            avatarUrl: '',
            shareUserName: '',
            promotionModalMsgs: {
                title: '',
                body: '',
                btnAcceptText: '',
                bntRejectText: '',
            },
        };
    }

    getUser = async () => {
        if (this.props.user && this.props.user.id) {
            return this.props.user;
        }
        return await this._userService.getUser();
    };

    isAntEnable = () => {
        return this.isAntMediaEnabled;
    };

    sendMessage = async message => {
        try {
            await this._pubnubService.publish(message, [this.state.room.roomId]);
        } catch (e) {
            console.error({ e });
        }
    };

    setSelfRole = role => {
        this.user.roomRole = role;
        this.user.roomRoleClassification = [role, 's'].join('');
    };

    sortAttendees = onlineAttendees => {
        if (this.user) {
            onlineAttendees &&
                onlineAttendees.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                });
            if (this.user.roomRole === Constant.ROOM_MEMBERS.MODERATOR) {
                onlineAttendees.sort((a, b) => {
                    return (a.state && a.state.handRaised) === (b.state && b.state.handRaised)
                        ? 0
                        : a.state && a.state.handRaised
                        ? -1
                        : 1;
                });
            }
        }
        return onlineAttendees;
    };

    generateSelfMuteMessage = () => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.MUTE,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [this.user.id],
        };
    };

    generateSelfUnMuteMessage = () => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.UNMUTE,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [this.user.id],
        };
    };

    generateInviteToStageMessage = userId => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTION_INITIATED,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.PARTICIPANT,
            senderId: this.user.id,
            subjectIds: [userId],
        };
    };

    generatePromoteToModeratorMessage = userId => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTION_INITIATED,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.PARTICIPANT,
            senderId: this.user.id,
            subjectIds: [userId],
        };
    };

    generatePromotionDeclinedMessage = () => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTION_DECLINED,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.PARTICIPANT,
            senderId: this.user.id,
            subjectIds: [this.promotionReqSenderId],
        };
    };

    generatePromotionAcceptedMessage = () => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTION_ACCEPTED,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.PARTICIPANT,
            senderId: this.user.id,
            subjectIds: [this.promotionReqSenderId],
        };
    };

    generatePromotedMessage = id => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTED,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [id],
        };
    };

    generateDemotedMessage = id => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.DEMOTED,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [id],
        };
    };

    generateMuteParticipantMessage = participantId => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.MUTE,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.PARTICIPANT,
            senderId: this.user.id,
            subjectIds: [participantId],
        };
    };

    generateKickParticipantMessage = participantId => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.KICK,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.PARTICIPANT,
            senderId: this.user.id,
            subjectIds: [participantId],
        };
    };

    generateMuteAllMessage = () => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.MUTE,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [],
        };
    };

    generateEndRoomMessage = withLounge => {
        if (withLounge) {
            return {
                action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.INITIATE_LOUNGE,
                recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
                senderId: this.user.id,
                subjectIds: [],
                roomLoungeId: null,
                roomId: null,
            };
        }
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.END_ROOM,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [],
        };
    };

    generateHandRaisedMessage = () => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.RAISE_HAND,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.MODERATORS,
            senderId: this.user.id,
            subjectIds: [this.user.id],
        };
    };

    generateHandLoweredMessage = subjectIds => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.LOWER_HAND,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.MODERATORS,
            senderId: this.user.id,
            subjectIds: [subjectIds],
        };
    };

    generateParticipantHandLoweredMessage = subjectIds => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.LOWER_HAND,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.PARTICIPANT,
            senderId: this.user.id,
            subjectIds: [subjectIds],
        };
    };

    generateLowerAllHandMessage = () => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.LOWER_HAND,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [],
        };
    };

    generateScreenReshareMessage = participantId => {
        return {
            action: Constant.ROOM_JOIN_MESSAGE_ACTIONS.SCREEN_RESHARE,
            recipient: Constant.PUBNUB_RECIPIENT_ARRAY.ALL,
            senderId: this.user.id,
            subjectIds: [participantId],
        };
    };

    updateSelfMuteState = async () => {
        const payload = {
            selfMuted: true,
            handRaised: this.user.handRaised || false,
        };
        await this._roomJoinService.updateUserState(this.state.room.roomId, this.user.id, payload);
    };

    updateSelfUnMuteState = async () => {
        const payload = {
            selfMuted: false,
        };
        await this._roomJoinService.updateUserState(this.state.room.roomId, this.user.id, payload);
    };

    updateHandRaiseState = async () => {
        const payload = {
            handRaised: true,
        };
        await this._roomJoinService.updateUserState(this.state.room.roomId, this.user.id, payload);
    };

    updateHandLowerState = async id => {
        const payload = {
            handRaised: false,
        };
        await this._roomJoinService.updateUserState(this.state.room.roomId, id, payload);
    };

    muteUser = async () => {
        this.user.isMute = true;
        this._voxeetService.muteParticipant(this.voxeetParticipantInfo);
    };

    muteSelfWithBroadcast = async () => {
        if (this.user && this.voxeetParticipantInfo) {
            await this.muteUser();
            await this.updateSelfMuteState();
            const message = this.generateSelfMuteMessage();
            this.sendMessage(message);
            return;
        }
        return;
    };

    muteSelf = async (updateState = true) => {
        if (this.user && this.voxeetParticipantInfo) {
            await this.muteUser();
            updateState && (await this.updateSelfMuteState());
            const message = this.generateSelfMuteMessage();
            this.sendMessage(message);
            return;
        }
        return;
    };

    unMuteSelf = async () => {
        if (this.user) {
            this.user.isMute = false;
            const message = this.generateSelfUnMuteMessage();
            this._voxeetService.unMuteParticipant(this.voxeetParticipantInfo);
            await this.updateSelfUnMuteState();
            this.sendMessage(message);
            return;
        }
        return;
    };

    onMuteButtonClick = async () => {
        this.user.isMute ? await this.unMuteSelf() : await this.muteSelf();
    };

    onCameraButtonClick = () => {
        !this.state.isVideoStarted
            ? this._voxeetService.startVideo(this.voxeetParticipantInfo)
            : this._voxeetService.stopVideo(this.voxeetParticipantInfo);
        this.setState({
            isVideoStarted: !this.state.isVideoStarted,
        });
    };

    getUserWhoSharedTheScreen = userId => {
        const speaker = this.state.onlineSpeakers.find(user => user.id === userId);
        return speaker;
    };

    onScreenShareClick = async () => {
        if (this.state.isScreenShareStarted) {
            await this.stopScreenShare();
            return;
        }
        if (this.state.screenShareStreaming) {
            const screenSharedPersonName = this.getUserWhoSharedTheScreen(this.state.screenShareStreaming.userId);
            this.setState({
                showReshareModal: true,
                shareUserName: screenSharedPersonName ? screenSharedPersonName.name : '',
            });
            return;
        }
        await this.startScreenShare();
    };

    setScreenShareStatus = isScreenShareStarted => {
        this.setState({
            isScreenShareStarted,
        });
    };

    startScreenShare = async () => {
        await this._voxeetService.startScreenShare();
    };

    stopScreenShare = async () => {
        await this._voxeetService.stopScreenShare(this.voxeetParticipantInfo);
    };

    stopScreenShareWhenSomeoneReshare = async message => {
        const { subjectIds } = message;
        if (subjectIds.includes(this.user.id)) {
            await this.stopScreenShare();
            this.setScreenShareStatus(false);
        }
    };

    reshareScreen = () => {
        this.props.startLoading(true);
        const message = this.generateScreenReshareMessage(this.state.screenShareStreaming.userId);
        this.sendMessage(message);
        setTimeout(async () => {
            this.closeReshareModal();
            this.props.startLoading(false);
            await this.startScreenShare();
        }, 3000);
    };

    showFullScreen = () => {
        this.setState(
            {
                isFullScreenSharing: true,
            },
            () => {
                if (this.state.screenShareStreaming) {
                    this.setupAndDisplayVideo('full-screen-share-video', this.state.screenShareStreaming.stream);
                }
            },
        );
    };

    closeFullScreen = () => {
        this.setState({
            isFullScreenSharing: false,
        });
    };

    closeReshareModal = () => {
        this.setState({
            showReshareModal: false,
        });
    };

    keyPress = e => {
        switch (e.key) {
            case Constant.KEYS.ESCAPE:
                this.closeFullScreen();
                break;
        }
    };

    getParticipantDetails = participantId => {
        if (this.state.room) {
            return this.state.room.participants.find(participant => participant.id === participantId);
        }
        return;
    };

    getParticipantRole = participantId => {
        if (this.state.room) {
            const participant =
                this.state.onlineSpeakers.find(participant => participant.id === participantId) ||
                this.state.onlineAttendees.find(participant => participant.id === participantId);
            return participant.role;
        }
        return;
    };

    isUserAnonymous = participantId => {
        if (this.state.room) {
            const participant =
                this.state.onlineSpeakers.find(participant => participant.id === participantId) ||
                this.state.onlineAttendees.find(participant => participant.id === participantId);
            return participant.isAnonymous;
        }
        return;
    };

    canUserSpeak = () => {
        if (!this.user) {
            return false;
        }
        return (
            this.user.roomRole === Constant.ROOM_MEMBERS.MODERATOR ||
            this.user.roomRole === Constant.ROOM_MEMBERS.SPEAKER
        );
    };

    isTarget = ({ recipient, subjectIds }) => {
        return (
            (recipient.includes('participant') && subjectIds.include(this.user.id)) ||
            recipient.includes(this.user.roomRoleClassification)
        );
    };

    getJoinRoomResponse = async () => {
        const joinRoomResponse = await this._roomJoinService.getJoinRoom(this.state.room.roomId);
        return joinRoomResponse;
    };

    disconnect = async () => {
        this.unsubscribePubnub();
        await this.disconnectConference();
        this.props.history.replace(`/thankyou-for-joining-room/${this.state.room.roomId}/${'internet-disrupted'}`);
    };

    listenConferenceEvents = () => {
        Emitter.on('message_received', ({ m }) => {
            const { message } = m;
            if (message) {
                switch (message.action) {
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.RAISE_HAND:
                        this.raiseHand(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.LOWER_HAND:
                        this.lowerHand(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.MUTE:
                        this.mute(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.UNMUTE:
                        this.unmute(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.KICK:
                        this.kick(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.END_ROOM:
                        this.roomEndedByModerator(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTION_INITIATED:
                        this.promotionInitiated(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTION_ACCEPTED:
                        this.promotionAccepted(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTION_DECLINED:
                        this.promotionDeclined(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.PROMOTED:
                        this.promoted(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.DEMOTED:
                        this.demoted(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.IS_SPEAKING:
                        this.displayActiveSpeakerVideo(message);
                        this.highlightSpeakingIndicator(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.INITIATE_LOUNGE:
                        this.initiateLounge();
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.LOUNGE_INVITE:
                        this.processLounge(message);
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.START_RECORDING:
                        this.setState({
                            ...this.state,
                            showRecordedModal: true,
                            isRecording: true,
                        });
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.STOP_RECORDING:
                        this.setState({
                            ...this.state,
                            isRecording: false,
                        });
                        break;
                    case Constant.ROOM_JOIN_MESSAGE_ACTIONS.SCREEN_RESHARE:
                        this.stopScreenShareWhenSomeoneReshare(message);
                        break;
                    default:
                        break;
                }
            }
        });

        Emitter.on('status_received', ({ s }) => {
            const { category } = s;
            if (category) {
                console.log(`status received ${category} - time : ${new Date()}`);
                switch (category) {
                    case 'PNConnectedCategory':
                        this.connect();
                        break;
                    case 'PNReconnectedCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Reconnected',
                            bgColor: '#ff3547',
                        });
                        this.connect();
                        break;
                    case 'PNUnexpectedDisconnectCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Network Unexpectedly Disconnected',
                            bgColor: '#ff3547',
                        });
                        this.disconnect();
                        break;
                    case 'PNNetworkDownCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Network Server Down',
                            bgColor: '#ff3547',
                        });
                        this.disconnect();
                        break;
                    case 'PNNetworkIssuesCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Network Issue Detected',
                            bgColor: '#ff3547',
                        });
                        this.disconnect();
                        break;
                    case 'PNNetworkUpCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Network Is Up',
                            bgColor: '#ff3547',
                        });
                        break;
                    case 'PNAccessDeniedCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Access Denied',
                            bgColor: '#ff3547',
                        });
                        break;
                    case 'PNMalformedResponseCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Parsing Crashed',
                            bgColor: '#ff3547',
                        });
                        break;
                    case 'PNDecryptionErrorCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Decryption Error',
                            bgColor: '#ff3547',
                        });
                        break;
                    case 'PNRequestMessageCountExceedCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Message Count Exceeded',
                            bgColor: '#ff3547',
                        });
                        break;
                    case 'PNUnknownCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Unknown Error',
                            bgColor: '#ff3547',
                        });
                        break;
                    case 'PNTimeoutCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Slow Internet Connection',
                            bgColor: '#ff3547',
                        });
                        break;
                    case 'PNBadRequestCategory':
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'Bad Request',
                            bgColor: '#ff3547',
                        });
                        break;
                    default:
                        break;
                }
            }
        });

        Emitter.on('presence_received', async ({ p }) => {
            const { action, channel, state, uuid } = p;
            if (action && channel === this.state.room.roomId) {
                console.log(`presence - ${action} - ${uuid} - time - ${new Date()}`);
                switch (action) {
                    case 'join':
                        setTimeout(() => {
                            this.join(uuid);
                        }, 3000);
                        break;
                    case 'leave':
                    case 'timeout':
                        this.leaveOrTimeOut(uuid);
                        this.unselectParticipantWhenLeave(uuid);
                        break;
                    case 'stateChange':
                        console.log({ message: 'State Changed', state });
                        break;
                    default:
                        break;
                }
            }
        });

        Emitter.on('reconnection', async reconnect => {
            this.connectionFailedCount += 1;
            if (this.connectionFailedCount > 0) {
                const user = await this.getUser();
                const { streamId, streamToken } = await this._roomJoinService.getStreamToken(
                    user.id,
                    this.state.room.roomId,
                );
                this.props.startLoading(false, false, '');
                this._webRTCService.play(streamId, streamToken);
                this.connectionFailedCount = 0;
                setTimeout(() => {
                    this.audio.play();
                }, 2000);
            }
        });

        Emitter.on('connection_closed', async closed => {
            this.connectionFailedCount += 1;
            if (this.connectionFailedCount === 3) {
                this.connectionFailedCount = 0;
                await this._pubnubService.unsubscribe([this.state.room.roomId]);
                this.handleClose();
                this.props.startLoading(false, false, '');
                this.props.history.replace(
                    `/thankyou-for-joining-room/${this.state.room.roomId}/${'internet-disrupted'}`,
                );
            } else {
                this.props.startLoading(true, true, 'Connecting audio...');
                setTimeout(() => {
                    this._webRTCService = new WebRTCService();
                    this._webRTCService.initialize();
                }, 10000);
            }
        });

        Emitter.on('active_speaker', message => {
            this.displayActiveSpeakerVideo(message);
            this.highlightSpeakingIndicator(message);
        });

        Emitter.on('voxeet_disconnected', async error => {});

        Emitter.on('addVideoNode', event => {
            const { participant, stream } = { ...event };
            this.setVideoStreamForSpeakers(participant, stream);
        });

        Emitter.on('removeVideoNode', event => {
            const { participant } = { ...event };
            this.setVideoStreamForSpeakers(participant);
        });

        Emitter.on('addScreenShare', event => {
            const { participant, stream } = { ...event };
            this.addScreenShareStreaming(participant, stream);
        });

        Emitter.on('removeScreenShare', async event => {
            await this.setScreenShareStatus(false);
            this.closeFullScreen();
            this.setState({
                screenShareStreaming: null,
            });
        });
    };

    displayActiveSpeakerVideo = message => {
        const { subjectIds, avatarUrl } = message;
        if (this.state.speakerVideoStream && this.state.speakerVideoStream.id === subjectIds[subjectIds.length - 1]) {
            return;
        }
        let speakerVideoStream = null;
        if (subjectIds && subjectIds.length) {
            const activeSpeaker = this.state.onlineSpeakers.find(
                activeSpeaker => activeSpeaker.id === subjectIds[subjectIds.length - 1],
            );
            speakerVideoStream =
                activeSpeaker && activeSpeaker.stream ? { stream: activeSpeaker.stream, id: activeSpeaker.id } : null;
        }
        this.setState(
            {
                speakerVideoStream,
                avatarUrl: avatarUrl && avatarUrl.length ? avatarUrl[avatarUrl.length - 1] : this.state.avatarUrl,
            },
            () => {
                if (this.state.screenShareStreaming) {
                    return;
                }
                if (speakerVideoStream) {
                    const videoNode = document.getElementById('active-speaker-video');
                    if (!videoNode) {
                        return;
                    }
                    navigator.attachMediaStream(videoNode, speakerVideoStream.stream);
                    videoNode.onenterpictureinpicture = () => {
                        this.props.showHideErrorToaster({
                            show: true,
                            message: 'This is a browser feature and you may experience loss of functionality',
                            bgColor: 'green',
                        });
                    };
                }
            },
        );
    };

    setVideoStreamForSpeakers = (participant, stream = null) => {
        if (participant) {
            this.setState(
                {
                    onlineSpeakers: this.state.onlineSpeakers.map(speaker => {
                        if (speaker.id === participant.info.externalId) {
                            speaker.stream = stream;
                            return speaker;
                        }
                        return speaker;
                    }),
                },
                () => {
                    if (stream) {
                        const videoNode = document.getElementById('participant-' + participant.info.externalId);
                        if (!videoNode) {
                            return;
                        }
                        navigator.attachMediaStream(videoNode, stream);
                        videoNode.onenterpictureinpicture = () => {
                            this.props.showHideErrorToaster({
                                show: true,
                                message: 'This is a browser feature and you may experience loss of functionality',
                                bgColor: 'green',
                            });
                        };
                    }
                },
            );
        }
    };

    addScreenShareStreaming = (participant, stream = null) => {
        if (participant.info.externalId === this.user.id) {
            this.setScreenShareStatus(true);
            return;
        }
        if (participant) {
            this.setState(
                {
                    screenShareStreaming: {
                        userId: participant.info.externalId,
                        stream: stream,
                    },
                },
                () => {
                    if (stream) {
                        if (this.state.isFullScreenSharing) {
                            this.setupAndDisplayVideo('full-screen-share-video', stream);
                            return;
                        }
                        this.setupAndDisplayVideo('active-speaker-video', stream);
                    }
                },
            );
        }
    };

    setupAndDisplayVideo = (domId, stream) => {
        const videoNode = document.getElementById(domId);
        if (!videoNode) {
            return;
        }
        navigator.attachMediaStream(videoNode, stream);
        videoNode.onenterpictureinpicture = () => {
            this.props.showHideErrorToaster({
                show: true,
                message: 'This is a browser feature and you may experience loss of functionality',
                bgColor: 'green',
            });
        };
    };

    userAlreadyInRoom = id => {
        return (
            this.state.onlineSpeakers.some(speaker => speaker.id === id) ||
            this.state.onlineAttendees.some(attendee => attendee.id === id)
        );
    };

    join = async uuid => {
        console.log(`join function called for userid -  ${uuid}`);
        if (this.userAlreadyInRoom(uuid) || this.user.id === uuid) {
            console.log(`skip join for userId -  ${uuid}`);
            return;
        }
        const room = await this._roomJoinService.getRoomDetail(this.props.match.params.id);
        this.setTimeStamp(room);
        room.isGreenRoom = this.isGreenRoom(room);
        const speakers = room.participants.filter(participant => {
            return ['speaker', 'moderator'].includes(participant.role);
        });
        const attendees = room.participants.filter(participant => {
            return ['attendee', 'guest'].includes(participant.role);
        });
        const newSpeaker = speakers.find(speaker => {
            return speaker.id === uuid;
        });
        if (!newSpeaker) {
            const newAttendee = attendees.find(attendee => {
                return attendee.id === uuid;
            });
            if (newAttendee) {
                this.setState({
                    ...this.state,
                    room,
                    speakers,
                    attendees,
                    roomStarted: true,
                    onlineAttendees: this.sortAttendees([...this.state.onlineAttendees, newAttendee]),
                });
                return;
            }
            return;
        }
        this.setState(
            {
                ...this.state,
                room,
                speakers,
                attendees,
                roomStarted: true,
                onlineSpeakers: [...this.state.onlineSpeakers, newSpeaker],
            },
            this.refreshVideoStream,
        );
    };

    refreshVideoStream = () => {
        this.state.onlineSpeakers.forEach(speaker => {
            if (speaker.stream) {
                const participant = {
                    info: {
                        externalId: speaker.id,
                    },
                };
                this.setVideoStreamForSpeakers(participant, speaker.stream);
            }
        });
    };

    leaveOrTimeOut = uuid => {
        const onlineSpeakers = this.state.onlineSpeakers.filter(speaker => {
            return uuid !== speaker.id;
        });
        const onlineAttendees = this.state.onlineAttendees.filter(attendee => {
            return uuid !== attendee.id;
        });
        this.setState(
            {
                ...this.state,
                onlineSpeakers,
                onlineAttendees,
            },
            this.refreshVideoStream,
        );
    };

    connect = async () => {
        const room = await this._roomJoinService.getRoomDetail(this.props.match.params.id);
        const speakers = room.participants.filter(participant => {
            return ['speaker', 'moderator'].includes(participant.role);
        });
        const attendees = room.participants.filter(participant => {
            return ['attendee', 'guest'].includes(participant.role);
        });
        this._pubnubService
            .hereNow([this.state.room.roomId])
            .then(async response => {
                const { occupants } = response.channels[this.state.room.roomId];
                console.log('connect called', { occupants });
                let userUuids = [...new Set(occupants.map(occupant => occupant.uuid))];
                const uuid = userUuids.map(userUuid => {
                    if (!this.userAlreadyInRoom(userUuid)) {
                        return userUuid;
                    }
                    return;
                });
                if (!uuid.includes(this.user.id)) {
                    uuid.push(this.user.id);
                }
                const onlineSpeakers = speakers.filter(speaker => {
                    return uuid.includes(speaker.id);
                });
                const onlineAttendees = attendees.filter(attendee => {
                    if (attendee.id === this.user.id) {
                        this.user.handRaised = attendee.state ? attendee.state.handRaised : false;
                    }
                    return uuid.includes(attendee.id);
                });
                this.setState(
                    {
                        ...this.state,
                        onlineSpeakers: [...this.state.onlineSpeakers, ...onlineSpeakers],
                        onlineAttendees: this.sortAttendees([...this.state.onlineAttendees, ...onlineAttendees]),
                    },
                    async () => await this.handleHandLowered(),
                );
            })
            .catch(e => {
                console.error({ e });
            });
        this.props.startLoading(false);
    };

    mute = async message => {
        const { senderId, subjectIds } = message;
        const speakers = [...this.state.onlineSpeakers];
        if (!subjectIds || !subjectIds.length) {
            // mute all recipients except initiator
            if (senderId !== this.user.id) {
                return this.muteSelfWithBroadcast();
            }
        }
        if (subjectIds.includes(this.user.id) && !(senderId === this.user.id)) {
            return this.muteSelf();
        }
        speakers.forEach(speaker => {
            if (subjectIds.includes(speaker.id)) {
                speaker.state
                    ? (speaker.state.selfMuted = true)
                    : (speaker.state = { ...speaker.state, selfMuted: true });
                return;
            }
        });
        this.setState({
            ...this.state,
            onlineSpeakers: [...speakers],
        });
    };
    unmute = message => {
        const { subjectIds } = message;
        if (this.state.onlineSpeakers) {
            const speakers = [...this.state.onlineSpeakers];
            speakers.forEach(speaker => {
                if (subjectIds.includes(speaker.id)) {
                    speaker.state.selfMuted = false;
                }
            });
            this.setState({
                ...this.state,
                onlineSpeakers: [...speakers],
            });
        }
    };

    endRoom = () => {
        this.setState({
            showMeetingEnded: true,
        });
    };

    roomEndedByModerator = message => {
        const { senderId } = message;
        if (senderId === this.user.id) {
            return;
        }
        this.disconnectConference();
        this.setState({
            ...this.state,
            showRoomEndedByModerator: true,
        });
    };
    initiateLounge = () => {
        this.disconnectConference();
        this.setState({
            showLoungeTimer: true,
        });
    };

    processLounge = message => {
        const { subjectIds, roomLoungeId, error } = message;
        if (!subjectIds.includes(this.user.id)) {
            return;
        }
        if (!roomLoungeId && error) {
            this.setState({
                showNoLoungeMessage: true,
            });
            return;
        }
        this.setState({
            showHoldScreen: false,
        });
        this.props.history.push(`/lounge-join/${roomLoungeId}`);
    };

    kick = async message => {
        const { subjectIds } = message;
        const user = await this.getUser();
        if (subjectIds.includes(user.id)) {
            const isUserKicked = true;
            await this.leaveRoom(isUserKicked);
            return;
        }
    };

    promotionInitiated = message => {
        const { senderId, subjectIds } = message;
        this.promotionReqSenderId = senderId;
        if (subjectIds.includes(this.user.id)) {
            if (this.user.roomRole === Constant.ROOM_MEMBERS.ATTENDEE) {
                this.setState({
                    ...this.state,
                    showPromotionModal: true,
                    promotionModalMsgs: {
                        title: "You've been invited up to the stage to speak.",
                        body: "Click okay to speak to the room, or decline if you've changed your mind.",
                        btnAcceptText: 'Okay',
                        bntRejectText: 'Decline',
                    },
                });
            } else if (this.user.roomRole === Constant.ROOM_MEMBERS.SPEAKER) {
                this.setState({
                    ...this.state,
                    showPromotionModal: true,
                    promotionModalMsgs: {
                        title: 'You have been selected to become a Moderator.',
                        body: 'Would you like to accept the invite?',
                        btnAcceptText: 'Accept',
                        bntRejectText: 'Reject',
                    },
                });
            }
        }
    };
    handlePromotionAccepted = () => {
        this.handlePromotion(true);
    };

    handlePromotionDeclined = () => {
        this.handlePromotion(false);
    };

    handlePromotion = confirmed => {
        this.setState({ ...this.state, showPromotionModal: false });
        const message = confirmed ? this.generatePromotionAcceptedMessage() : this.generatePromotionDeclinedMessage();
        this.sendMessage(message);
    };

    updateUserRole = async param => {
        try {
            await this._roomDetailService.updateRoomMember(param);
        } catch (e) {
            if (e.responseCode && e.responseCode === Constant.STATUS_CODE.NOT_ACCEPTABLE) {
                this.props.showHideErrorToaster({
                    show: true,
                    message: e.message,
                    bgColor: '#ff3547',
                });
                this.props.startLoading(false);
            }
        }
    };

    promotionAccepted = async message => {
        const { senderId, subjectIds } = message;
        const senderRole = this.getParticipantRole(senderId);
        let role;
        if (senderRole === Constant.ROOM_MEMBERS.ATTENDEE) {
            role = Constant.ROOM_MEMBERS.SPEAKER;
        } else if (senderRole === Constant.ROOM_MEMBERS.SPEAKER) {
            role = Constant.ROOM_MEMBERS.MODERATOR;
        } else {
            role = Constant.ROOM_MEMBERS.SPEAKER;
        }
        if (subjectIds.includes(this.user.id)) {
            this.props.startLoading(true);
            const param = {
                roomId: this.state.room.roomId,
                memberId: senderId,
                role: role,
            };
            await this.updateUserRole(param);
            const message = this.generatePromotedMessage(senderId);
            this.sendMessage(message);
            this.props.startLoading(false);
        }
        return;
    };

    promotionDeclined = message => {
        const { senderId, subjectIds } = message;
        const role = this.getParticipantRole(senderId);
        if (subjectIds.includes(this.user.id)) {
            this.setState({
                ...this.state,
                showPromotionDeclinedModal: true,
                promotionModalMsgs: {
                    title: role === Constant.ROOM_MEMBERS.SPEAKER ? 'Reject' : 'Decline',
                    body:
                        role === Constant.ROOM_MEMBERS.SPEAKER
                            ? "Speaker refused to accept moderator's invite."
                            : "Attendee refused to accept speaker's invite.",
                    btnAcceptText: 'Okay',
                },
            });
        }
    };

    handleClosePromotionDeclinedModal = () => {
        this.setState({ ...this.state, showPromotionDeclinedModal: false });
    };

    highlightSpeakingIndicator = message => {
        const { subjectIds } = message;
        this.setState({
            ...this.state,
            activeParticipantid: [...subjectIds],
        });
    };

    rejoinConference = async () => {
        try {
            this.props.startLoading(true);
            this.user.antMediaStatus
                ? this._webRTCService.stop(this.state.streamId)
                : await this._voxeetService.leaveConference();
            this.joinRoomResponse = await this.getJoinRoomResponse();
            await this.initializeAndSetupConference();
            this.props.startLoading(false);
        } catch (e) {
            console.error({ e });
        }
    };

    promoted = async message => {
        this.props.startLoading(true);
        const { subjectIds } = message;
        const subjectRole = this.getParticipantRole(subjectIds[0]);
        let role =
            subjectRole === Constant.ROOM_MEMBERS.ATTENDEE
                ? Constant.ROOM_MEMBERS.SPEAKER
                : Constant.ROOM_MEMBERS.MODERATOR;
        const speakers = this.state.onlineSpeakers;
        const attendees = this.state.onlineAttendees;
        let selectedParticipant = null;
        if (subjectIds.includes(this.user.id)) {
            await this.handleHandLowered();
            this.setSelfRole(role);
            await this.rejoinConference();
        }
        speakers.forEach(speaker => {
            if (subjectIds.includes(speaker.id)) {
                speaker.role = role;
            }
        });
        attendees.forEach((attendee, index) => {
            if (subjectIds.includes(attendee.id)) {
                attendee.role = role;
                speakers.push(attendee);
                attendees.splice(index, 1);
                return;
            }
        });

        if (this.state.selectedParticipant && subjectIds.includes(this.state.selectedParticipant.id)) {
            selectedParticipant = { ...this.state.selectedParticipant };
            selectedParticipant.role = role;
        }
        this.setState(
            {
                ...this.state,
                onlineSpeakers: speakers,
                onlineAttendees: this.sortAttendees(attendees),
                selectedParticipant,
            },
            this.refreshVideoStream,
        );
        this.props.startLoading(false);
    };

    speakingTimeOver = async id => {
        this.props.startLoading(true);
        const param = {
            roomId: this.state.room.roomId,
            memberId: id,
            role: Constant.ROOM_MEMBERS.ATTENDEE,
        };
        await this.updateUserRole(param);
        const message = this.generateDemotedMessage(id);
        this.sendMessage(message);
        this.props.startLoading(false);
    };

    muteParticipant = async id => {
        this.props.startLoading(true);
        const message = this.generateMuteParticipantMessage(id);
        this.sendMessage(message);
        this.props.startLoading(false);
    };

    ejectParticipant = async () => {
        const id = this.state.ejectParticipantId;
        this.props.startLoading(true);
        const message = this.generateKickParticipantMessage(id);
        this.sendMessage(message);
        // add delay for mob bug fix
        setTimeout(() => {
            this._voxeetService.kickParticipant(id);
        }, 2000);

        this.handleCloseEjectParticipant();
        this.props.startLoading(false);
    };

    handleCloseSpeakingTimeOverModal = async () => {
        await this.listenViaVoxeet();
        this.setState({ ...this.state, showSpeakingTimeOverModal: false });
    };

    demoted = async message => {
        const { subjectIds } = message;
        const speakers = [...this.state.onlineSpeakers];
        const attendees = [...this.state.onlineAttendees];
        let selectedParticipant = null;

        if (subjectIds.includes(this.user.id)) {
            this.setSelfRole(Constant.ROOM_MEMBERS.ATTENDEE);
            await this.rejoinConference();
            this.setState({ ...this.state, showSpeakingTimeOverModal: true });
            await this.handleHandLowered();
        }
        speakers.forEach((speaker, index) => {
            if (subjectIds.includes(speaker.id)) {
                speaker.role = Constant.ROOM_MEMBERS.ATTENDEE;
                speaker.stream = null;
                attendees.push(speaker);
                speakers.splice(index, 1);
                return;
            }
        });
        if (this.state.selectedParticipant) {
            selectedParticipant = { ...this.state.selectedParticipant };
            selectedParticipant.role = Constant.ROOM_MEMBERS.ATTENDEE;
        }
        this.setState(
            {
                ...this.state,
                onlineSpeakers: [...speakers],
                onlineAttendees: [...this.sortAttendees(attendees)],
                selectedParticipant,
                isVideoStarted: subjectIds.includes(this.user.id) ? false : this.state.isVideoStarted,
            },
            this.refreshVideoStream,
        );
    };

    commenceEvents = async () => {
        try {
            this.joinRoomResponse = await this.getJoinRoomResponse();
            this.initializePubnub();
            await this.initializeAndSetupConference();
        } catch (e) {
            console.error({ e });
            if (e.response && e.response.status === 404) {
                this.props.history.replace(`/room-wait/${this.state.room.roomId}`);
            }
        }
    };

    initializeAndSetupConference = async () => {
        this.user.antMediaStatus = false;
        await this._voxeetService.initialize(this.user);
        this.voxeetParticipantInfo = this._voxeetService.getCurrentParticipant();
        this.user.canSpeak = this.canUserSpeak();
        if (this.user.canSpeak) {
            await this._voxeetService.joinConference(this.joinRoomResponse);
            const shouldUpdateState = false;
            await this.muteSelf(shouldUpdateState);
            return;
        }
        if (this.isAntMediaEnabled) {
            this.user.antMediaStatus = true;
            await this.initializeAndSetupAnt();
            return;
        }
    };

    initializeAndSetupAnt = async () => {
        const { streamId, streamToken } = await this._roomJoinService.getStreamToken(
            this.user.id,
            this.state.room.roomId,
        );
        this.setState({ ...this.state, streamId });
        this._webRTCService.initialize();
        this._webRTCService.play(streamId, streamToken);
    };

    initializePubnub = () => {
        this._pubnubService.initialize(this.joinRoomResponse.pubnubAuthKey, this.user.id);
        this._pubnubService.addEventListener();
        this._pubnubService.subscribe([this.state.room.roomId], true);
        this.listenConferenceEvents();
    };

    unsubscribePubnub = async () => {
        this.state.room &&
            this._pubnubService.getPubNub() &&
            (await this._pubnubService.unsubscribe([this.state.room.roomId]));
    };

    navigateToPrivateRoomMessage = roomId => {
        this.props.history.replace({
            pathname: `/private-room-experience`,
        });
    };

    isGreenRoom = room => {
        if (!room.greenRoomStartTime) {
            return false;
        }
        const currentTime = this.getCurrentTime();
        const startTime = this.getLocalTimeFromUtcTime(room.startTime);
        return currentTime < startTime;
    };

    getCurrentTime = () => {
        return DateTime.local();
    };

    getLocalTimeFromUtcTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' }).toLocal();
    };

    handleGreenRoom = room => {
        try {
            if (room.isGreenRoom && this.canUserSpeak()) {
                const currentTime = this.getCurrentTime();
                const startTime = this.getLocalTimeFromUtcTime(room.startTime);
                const greenRoomStartTime = this.getLocalTimeFromUtcTime(room.greenRoomStartTime);
                if (currentTime >= greenRoomStartTime && currentTime < startTime) {
                    this.greenRoomTimeDiff = startTime.diff(currentTime).toFormat('mm:ss');
                    return room;
                }
                this.greenRoomTimeDiff = null;
                room.isGreenRoom = false;
            }
            return room;
        } catch (e) {
            console.error({ e });
        }
    };

    startPolling = () => {
        this.pollingId = setInterval(() => {
            if (!this.state.room.isGreenRoom || this.isPolling) {
                return;
            }
            this.isPolling = true;
            const room = this.handleGreenRoom(this.state.room);
            this.setState({
                ...this.state,
                room,
            });
            this.isPolling = false;
        }, 1000);
    };

    async componentDidMount() {
        try {
            this.props.startLoading(true);
            const roomId = this.props.match.params.id;
            const room = await this._roomJoinService.getRoomDetail(roomId, true);
            room.description = room.description
                ? this._utilityService.replaceHyperLinkWithAnchor(room.description)
                : '';
            if (room.ended) {
                this.setState({
                    room,
                    showMeetingEnded: true,
                });
                this.props.startLoading(false);
                return;
            }
            this.setTimeStamp(room);
            this.user = await this.getUser();
            room.isGreenRoom = this.isGreenRoom(room);
            this.setState(
                {
                    ...this.state,
                    room: room,
                    avatarUrl: this.user.fullAvatarUrl,
                },
                () => {
                    room.isGreenRoom && this.startPolling();
                },
            );
            const userIsAuthorized = room.participants.some(participant => {
                return participant.id === this.user.id;
            });
            if (userIsAuthorized) {
                const participantDetails = this.getParticipantDetails(this.user.id);
                this.setSelfRole(participantDetails.role);
                this.user.handRaised = participantDetails.state && participantDetails.state.handRaised;
                await this.updateSelfMuteState();
                const { isAntMediaEnabled } = await this._commonService.getSettings();
                this.isAntMediaEnabled = isAntMediaEnabled || false;
                await this.commenceEvents();
                this.props.startLoading(false);
                let showRecordedModal = false;
                let isRecording = false;
                if (room.recordingStatus && room.recordingStatus === Constant.RECORDING_STATUS.STARTED) {
                    showRecordedModal = true;
                    isRecording = true;
                }
                this.setState({
                    ...this.state,
                    modalInfo: {
                        modalHeader: `Welcome To ${room.name}`,
                        modalParagraph: '',
                    },
                    roomJoinModal: true,
                    showRecordedModal,
                    isRecording,
                });
            } else {
                this.props.startLoading(false);
                this.props.history.replace('/unauthorized-error?fromGuest=true');
            }
        } catch (error) {
            if (error.response && error.response.status === 406) {
                this.setState({
                    showMeetingEnded: true,
                });
            }
            this.props.startLoading(false);
        }
    }

    async componentDidUpdate() {
        if (this.props.isUserSelected) {
            const user = await this.getUser();
            this.fetchGuestRoomDetailParticipant(user.id);
            this.props.selectUser(false);
        }
        if (!this.state.room.isGreenRoom) {
            this.stopPolling();
        }
    }

    async componentWillUnmount() {
        this.closeAllTheConnection();
    }

    disconnectConference = async () => {
        if (this.user) {
            this.user.antMediaStatus
                ? this._webRTCService.stop(this.state.streamId)
                : await this._voxeetService.disconnectVoxeet();
        }
    };

    stopPolling = () => {
        if (this.pollingId) {
            clearInterval(this.pollingId);
            this.pollingId = null;
        }
    };

    closeAllTheConnection = async () => {
        this.state.isVideoStarted && (await this._voxeetService.stopVideo(this.voxeetParticipantInfo));
        this.stopPolling();
        await this.unsubscribePubnub();
        await this.disconnectConference();
        Emitter.off('message_received');
        Emitter.off('status_received');
        Emitter.off('presence_received');
        Emitter.off('reconnection');
        Emitter.off('connection_closed');
        Emitter.off('active_speaker');
        Emitter.off('voxeet_disconnected');
        Emitter.off('addVideoNode');
        Emitter.off('removeVideoNode');
        Emitter.off('addScreenShare');
        Emitter.off('removeScreenShare');
        this.state.roomStarted && this._pubnubService.removeEventListener();
    };

    closeModal = async () => {
        await this.listenViaVoxeet();
        this.audio.play();
        this.setState({ ...this.state, roomJoinModal: false });
    };

    getZoneAbbr = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
            return;
        });
        return zone[0] ? zone[0].abbr : 'EST';
    };

    getFormattedDay = date => {
        const roomStartTime = DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toFormat('yyyy-MM-dd');
        const localTime = DateTime.local();
        const today = localTime.toISODate();
        const tomorrow = localTime.plus({ days: 1 }).toISODate();
        const yesterday = localTime.minus({ days: 1 }).toISODate();
        if (today === roomStartTime) {
            return Constant.DATE_OBJ.TODAY;
        }
        if (tomorrow === roomStartTime) {
            return Constant.DATE_OBJ.TOMORROW;
        }
        if (yesterday === roomStartTime) {
            return Constant.DATE_OBJ.YESTERDAY;
        }
        return;
    };

    getFormattedDate = date => {
        return (
            this.getFormattedDay(date) ||
            DateTime.fromISO(date, { zone: 'utc' })
                .toLocal()
                .toFormat('LLL dd, yyyy')
        );
    };

    getFormattedTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' })
            .toLocal()
            .toFormat('h:mma');
    };

    setTimeStamp = room => {
        const date = this.getFormattedDate(room.startTime);
        const start = this.getFormattedTime(room.startTime);
        const end = this.getFormattedTime(room.endTime);
        const abbr = this.getZoneAbbr();
        room.timestamp = [date, ' | ', start, ' - ', end, ' ', abbr].join('');
    };

    leaveRoom = async (isKicked = false) => {
        await this.unsubscribePubnub();
        this.handleClose();
        await this.disconnectConference();
        if (isKicked) {
            this.setState({
                ...this.state,
                showEjectByModerator: true,
            });
            return;
        }
        this.displayToasterMessage(
            'Thank you for joining the room, complete web experience coming soon, get your hands on the experience with our iOS app',
        );
        setTimeout(() => {
            this.redirectUserWhereComesFrom();
        }, 1000);
    };

    listenViaVoxeet = async () => {
        if (!this.isAntMediaEnabled && !this.user.canSpeak) {
            await this._voxeetService.listenConference(this.joinRoomResponse);
        }
    };

    redirectUserWhereComesFrom = () => {
        const isAnonymous = this._userService.isAnonymous();
        if (isAnonymous) {
            this.props.history.replace(`/guest-room-detail/${this.state.room.roomId}`);
            return;
        }
        const locationState = this.props.location.state;
        if (locationState && locationState.prevPath) {
            if (locationState.prevPath.includes('guest-dashboard')) {
                this.props.history.replace('/guest-dashboard');
                return;
            }
            if (locationState.prevPath.includes('guest-room-detail')) {
                this.props.history.replace(`/guest-room-detail/${this.state.room.roomId}`);
                return;
            }
        }
        this.props.history.replace('/guest-dashboard');
    };

    displayToasterMessage = (message, isError) => {
        this.props.showHideErrorToaster({
            show: true,
            message: message,
            bgColor: isError ? '#ff3547' : 'green',
        });
    };

    handleShowLeaveRoom = () => {
        this.setState({
            ...this.state,
            showLeaveRoom: true,
        });
    };
    handleClose = () => {
        this.setState({
            ...this.state,
            showLeaveRoom: false,
        });
    };

    handleCloseEnded = () => {
        this.setState({
            ...this.state,
            showMeetingEnded: false,
        });
    };
    handleCloseEjectByModerator = () => {
        this.setState({
            ...this.state,
            showEjectByModerator: false,
        });
    };
    handleCloseEndRoom = () => {
        this.setState({
            ...this.state,
            showEndRoom: false,
        });
    };

    handleCloseRoomEndedByModerator = () => {
        this.setState(
            {
                ...this.state,
                showRoomEndedByModerator: false,
            },
            () => {
                this.redirectUserWhereComesFrom();
            },
        );
    };

    handleCloseRoomRecorded = () => {
        this.setState({
            ...this.state,
            showRecordedModal: false,
        });
    };

    handleCloseEjectParticipant = () => {
        this.setState({
            ...this.state,
            showEjectModal: false,
            ejectParticipantId: null,
        });
    };

    endTheRoom = () => {
        this.handleCloseEnded();
        this.redirectUserWhereComesFrom();
    };

    handleEjectByModerator = () => {
        this.handleCloseEjectByModerator();
        this.redirectUserWhereComesFrom();
    };

    onSelect() {
        let className = 'roomdetail__desc roomdetail__desc--truncate';
        let text = 'Read More';
        if (this.state.toggleClass.includes('truncate')) {
            className = 'roomdetail__desc';
            text = 'Collapse';
        }
        this.setState({
            ...this.state,
            accordionText: text,
            toggleClass: className,
        });
    }

    speakers = () => {
        return (
            this.state.onlineSpeakers &&
            (this.state.onlineSpeakers || []).map((item, index) => {
                return this.state.activeParticipantid.includes(item.id) ? (
                    <div
                        onClick={() => {
                            this.fetchGuestRoomDetailParticipant(item.id);
                        }}
                        key={item.id + index}
                        className="guestroom__carousel__item guestroom__carousel__item--active"
                    >
                        {item.stream ? (
                            <video
                                id={'participant-' + item.id}
                                className="img-fluid item__img video"
                                width="80"
                                height="80"
                                playsInline={true}
                                autoPlay="autoplay"
                                muted={true}
                            ></video>
                        ) : item.fullAvatarUrl ? (
                            <img
                                src={item.fullAvatarUrl}
                                alt=""
                                className="img-fluid item__img"
                                width="80"
                                height="80"
                            />
                        ) : (
                            <ImageComponent
                                isAvatar={true}
                                src={item.image}
                                placeholder={icon_placeHolder}
                                alt=""
                                className="img-fluid item__img"
                                width="80"
                                height="80"
                            />
                        )}
                        <div className="item__description">
                            <p className="item__name">{item.name}</p>
                            {item.role === 'moderator' && (
                                <img className="moderator" alt="moderator-tag" src={moderatorTag} />
                            )}
                            {item.state && <img src={item.state.selfMuted ? micOff : micOn} alt="mic-icon" />}
                            <p className="item__blurb">{item.blurb}</p>
                        </div>
                    </div>
                ) : (
                    <div
                        onClick={() => {
                            this.fetchGuestRoomDetailParticipant(item.id);
                        }}
                        key={item.id + index}
                        className="guestroom__carousel__item"
                    >
                        {item.stream ? (
                            <video
                                id={'participant-' + item.id}
                                className="img-fluid item__img video"
                                width="80"
                                height="80"
                                playsInline={true}
                                autoPlay="autoplay"
                                muted={true}
                            ></video>
                        ) : item.fullAvatarUrl ? (
                            <img
                                src={item.fullAvatarUrl}
                                alt=""
                                className="img-fluid item__img"
                                width="80"
                                height="80"
                            />
                        ) : (
                            <ImageComponent
                                isAvatar={true}
                                src={item.image}
                                placeholder={icon_placeHolder}
                                alt=""
                                className="img-fluid item__img"
                                width="80"
                                height="80"
                            />
                        )}
                        <div className="item__description">
                            <p className="item__name">{item.name}</p>
                            {item.role === 'moderator' && <img className="moderator" src={moderatorTag} />}
                            {item.state && <img src={item.state.selfMuted ? micOff : micOn} alt="" />}
                            <p className="item-blurb-hidden">{item.blurb}</p>
                        </div>
                    </div>
                );
            })
        );
    };

    attendees = () => {
        return (
            <>
                <div className="attendees_wrapper">
                    <h3 className="attendees_head">
                        Attendees |{' '}
                        {this.state.onlineAttendees && this.state.onlineAttendees.length > 0
                            ? this.state.onlineAttendees.length
                            : 0}
                    </h3>
                    {this.state.onlineAttendees.length > 0 && (
                        <ul className="attendees__list">
                            {(this.state.onlineAttendees || []).map((item, index) => {
                                return (
                                    <li
                                        onClick={() => {
                                            this.fetchGuestRoomDetailParticipant(item.id);
                                        }}
                                        key={index}
                                        className="attendees__item"
                                    >
                                        <Media className="guest-room-detail__users-info cursor-pointer">
                                            <div className="position-relative">
                                                {item.fullAvatarUrl ? (
                                                    <img src={item.fullAvatarUrl} alt="" className="attendee__img" />
                                                ) : (
                                                    <ImageComponent
                                                        isAvatar={true}
                                                        src={item.image}
                                                        alt=""
                                                        className="attendee__img"
                                                        placeholder={attendee_placeHolder}
                                                    />
                                                )}
                                                {item.state && this.user && (
                                                    <img
                                                        src={
                                                            item.state &&
                                                            (this.user.roomRole === Constant.ROOM_MEMBERS.MODERATOR ||
                                                                item.id === this.user.id) &&
                                                            item.state.handRaised
                                                                ? handRaised
                                                                : ''
                                                        }
                                                        className={
                                                            'attendee__handraise ' +
                                                            (item.state.handRaised ? '' : 'attendee__handraise-noshow')
                                                        }
                                                        alt=""
                                                    />
                                                )}
                                            </div>
                                            <Media.Body className="attendee__description">
                                                <h4 className="attendee__name" title={item.name}>
                                                    {item.name}
                                                </h4>
                                                <p className="attendee__blurb" title={item.blurb}>
                                                    {item.blurb}
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </>
        );
    };

    handleHandGestureOperation = () => {
        this.user.handRaised ? this.handleHandLowered() : this.handleHandRaised();
    };

    handleHandRaised = async () => {
        if (this.user) {
            const message = this.generateHandRaisedMessage();
            this.sendMessage(message);
        }
    };

    handleHandLowered = async () => {
        if (this.user) {
            const message = this.generateHandLoweredMessage(this.user.id);
            this.sendMessage(message);
        }
    };

    handleHandLoweredWithoutBroadCast = async () => {
        this.props.startLoading(true);
        if (this.user) {
            this.user.handRaised = false;
            await this.updateHandLowerState(this.user.id);
        }
        this.props.startLoading(false);
    };

    handleLowerAllHands = () => {
        this.props.startLoading(true);
        const message = this.generateLowerAllHandMessage();
        this.sendMessage(message);
        this.props.startLoading(false);
    };

    handleMuteAll = () => {
        this.props.startLoading(true);
        const message = this.generateMuteAllMessage();
        this.sendMessage(message);
        this.props.startLoading(false);
    };

    handleCloseLoungeTimer = () => {
        this.setState({
            showLoungeTimer: false,
        });
        this.redirectUserWhereComesFrom();
    };

    handleCloseNoLougeMessage = () => {
        this.setState({
            showNoLoungeMessage: false,
        });
        this.redirectUserWhereComesFrom();
    };

    onJoinLounge = () => {
        this.setState(
            {
                showLoungeTimer: false,
            },
            () => {
                this.setState({
                    showHoldScreen: true,
                });
            },
        );
        this._roomJoinService.joinLounge(this.state.room.roomId);
    };
    handleEndRoomWithLounge = async () => {
        const response = await this._roomJoinService.startLounge(this.state.room.roomId);
        if (response && response.isSuccess) {
            this.handleEndRoom(true);
        } else {
            this.props.showHideErrorToaster({
                show: true,
                message: 'Something went wrong',
                bgColor: '#ff3547',
            });
        }
    };
    handleEndRoom = async (withLounge = false) => {
        this.props.startLoading(true);
        this.handleCloseEndRoom();
        const message = this.generateEndRoomMessage(withLounge);
        await this._voxeetService.disconnectVoxeet();
        await this._roomJoinService.deleteConference(this.state.room.roomId);
        this.sendMessage(message);
        !withLounge && this.redirectUserWhereComesFrom();
        this.props.startLoading(false);
    };
    raiseHand = async message => {
        const { subjectIds } = message;
        if (subjectIds.includes(this.user.id)) {
            this.user.handRaised = true;
            await this.updateHandRaiseState();
        }
        this.handleHandAction(true, message);
    };

    lowerHand = async message => {
        const { senderId, subjectIds, recipient } = message;
        if (subjectIds.length) {
            if (
                !subjectIds.includes(senderId) &&
                recipient.includes(Constant.PUBNUB_RECIPIENT_PARTICIPANT_STRING) &&
                subjectIds.includes(this.user.id)
            ) {
                await this.handleHandLowered();
                return;
            }
            if (!recipient.includes(Constant.PUBNUB_RECIPIENT_PARTICIPANT_STRING) && subjectIds.includes(senderId)) {
                if (subjectIds.includes(this.user.id)) {
                    await this.handleHandLoweredWithoutBroadCast();
                }
                this.handleHandAction(false, message);
                return;
            }
            return;
        }
        if (this.user.roomRole === Constant.ROOM_MEMBERS.ATTENDEE) {
            await this.handleHandLoweredWithoutBroadCast();
        }
        const onlineAttendees = [...this.state.onlineAttendees];
        onlineAttendees.forEach(attendee => {
            if (attendee.role === Constant.ROOM_MEMBERS.ATTENDEE) {
                attendee.state
                    ? (attendee.state.handRaised = false)
                    : (attendee.state = {
                          ...attendee.state,
                          handRaised: false,
                      });
            }
        });
        this.setState({
            ...this.state,
            onlineAttendees: this.sortAttendees(onlineAttendees),
        });
    };

    handleHandAction = (isHandRaised, message) => {
        const { subjectIds, recipient } = message;
        if (recipient.includes(this.user.roomRoleClassification) || subjectIds.includes(this.user.id)) {
            const onlineAttendees = [...this.state.onlineAttendees];
            onlineAttendees.forEach(attendee => {
                if (subjectIds.includes(attendee.id)) {
                    attendee.state
                        ? (attendee.state.handRaised = isHandRaised)
                        : (attendee.state = {
                              ...attendee.state,
                              handRaised: isHandRaised,
                          });
                    return;
                }
            });
            this.setState({
                ...this.state,
                onlineAttendees: this.sortAttendees(onlineAttendees),
            });
        }
    };

    fetchGuestRoomDetailParticipant = async participantId => {
        this.props.startLoading(true);
        this.setState({ selectedParticipant: null });
        const selectedParticipant = await this._guestRoomDetailService.getGuestRoomDetailParticipantProfile(
            participantId,
        );

        selectedParticipant.memberSince &&
            (selectedParticipant.timeStamp = this.setProfileTimeStamp(selectedParticipant.memberSince));
        selectedParticipant.role = this.getParticipantRole(participantId);
        selectedParticipant.profileMenuItems = [];
        selectedParticipant.isAnonymous = this.isUserAnonymous(participantId);
        if (!selectedParticipant.isAnonymous) {
            selectedParticipant.profileMenuItems = this.profileMenuItems;
        }
        if (selectedParticipant.id === this.user.id && !selectedParticipant.isAnonymous) {
            selectedParticipant.profileMenuItems = [
                ...this.profileMenuItems,
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.EDIT_PROFILE, isBlue: true },
            ];
        }
        this.setState({ selectedParticipant });
        this.props.startLoading(false);
    };

    unselectParticipantWhenLeave = uuid => {
        if (!this.state.selectedParticipant) {
            return;
        }
        if (uuid === this.state.selectedParticipant.id) {
            this.setState({ selectedParticipant: null });
        }
    };

    setProfileTimeStamp = date => {
        return DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toFormat('LLLL d, yyyy');
    };

    onProfileCollapseLabelClick = () => {
        this.setState({
            ...this.state,
            collapseOpenProfile: !this.state.collapseOpenProfile,
            classForTruncate: !this.state.classForTruncate,
        });
    };

    hideProfile = () => {
        this.setState({ ...this.state, selectedParticipant: null });
    };

    copyToClipBoard = async e => {
        e.persist();
        this.setState({ ...this.state, copyMessage: Constant.ROOM_DETAILS_COPIED_MESSAGE });
        await navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}/room/invite?roomId=${this.state.room.roomId}`,
        );
    };

    inviteToStage = async id => {
        if (this.user.roomRole === Constant.ROOM_MEMBERS.MODERATOR) {
            const lowerHandMessage = this.generateParticipantHandLoweredMessage(id);
            this.sendMessage(lowerHandMessage);
            const message = this.generateInviteToStageMessage(id);
            this.sendMessage(message);
            return;
        }
        return;
    };

    promoteToModerator = async id => {
        if (this.user.roomRole === Constant.ROOM_MEMBERS.MODERATOR) {
            const message = this.generatePromoteToModeratorMessage(id);
            this.sendMessage(message);
            return;
        }
        return;
    };

    onSelectActionMenu = (item, participantId) => {
        switch (item) {
            case Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE: {
                const url =
                    participantId === this.user.id
                        ? `/guest-profile-view`
                        : `/guest-profile-view?profileId=${participantId}`;
                this.props.history.push(url);
                break;
            }
            case Constant.ROOM_USER_PROFILE_ACTIONS.EDIT_PROFILE:
                this.props.history.push('/guest-profile');
                break;
            case Constant.ROOM_USER_PROFILE_ACTIONS.INVITE_TO_STAGE:
                this.inviteToStage(participantId);
                break;
            case Constant.ROOM_USER_PROFILE_ACTIONS.PROMOTE_TO_MODERATOR:
                this.promoteToModerator(participantId);
                break;
            case Constant.ROOM_USER_PROFILE_ACTIONS.MOVE_TO_AUDIENCE:
                this.speakingTimeOver(participantId);
                break;
            case Constant.ROOM_USER_PROFILE_ACTIONS.MUTE:
                this.muteParticipant(participantId);
                break;
            case Constant.ROOM_USER_PROFILE_ACTIONS.EJECT:
                this.setState({
                    ...this.state,
                    showEjectModal: true,
                    ejectParticipantId: participantId,
                });
                break;
            default:
                break;
        }
    };

    onSelectModeratorActionMenu = item => {
        switch (item) {
            case Constant.MODERATOR_ACTIONS.LOWER_ALL_HANDS:
                this.handleLowerAllHands();
                break;
            case Constant.MODERATOR_ACTIONS.MUTE_ALL:
                this.handleMuteAll();
                break;
            case Constant.MODERATOR_ACTIONS.END_ROOM:
                this.setState({
                    ...this.state,
                    showEndRoom: true,
                });
                break;

            default:
                break;
        }
    };

    followUnfollowUser = async (userId, payload) => {
        try {
            const response = await this._guestRoomDetailService.followUnFollowUser(userId, payload);
            if (response.isSuccess) {
                this.fetchGuestRoomDetailParticipant(userId);
            } else {
                this.props.showHideErrorToaster({
                    show: true,
                    message: `Failed to ${payload.follow ? 'follow' : 'unfollow'} user`,
                    bgColor: '#ff3547',
                });
            }
        } catch (error) {
            this.props.showHideErrorToaster({
                show: true,
                message: `Failed to ${payload.follow ? 'follow' : 'unfollow'} user`,
                bgColor: '#ff3547',
            });
        }
    };

    updateInnerCircle = async (userId, payload) => {
        try {
            const response = await this._guestRoomDetailService.innerCircle(userId, payload);
            if (response.isSuccess) {
                this.fetchGuestRoomDetailParticipant(userId);
            } else {
                this.props.showHideErrorToaster({
                    show: true,
                    message: 'Something went wrong updating inner circle',
                    bgColor: '#ff3547',
                });
            }
        } catch (error) {
            this.props.showHideErrorToaster({
                show: true,
                message: 'Something went wrong while updating inner circle',
                bgColor: '#ff3547',
            });
        }
    };

    navigateToRoomDetail = () => {
        this.props.history.push({
            pathname: `/guest-room-detail/${this.state.room.roomId}`,
        });
    };

    render() {
        const isRoomPrivate = this.state.room.roomType === Constant.ROOM_TYPE.PRIVATE;
        const isLounge = this.state.room.roomType === Constant.ROOM_TYPE.LOUNGE;
        let createdBy = isRoomPrivate
            ? this.state.room.participants.filter(participant => participant.id === this.state.room.createdBy)
            : null;
        createdBy = createdBy && createdBy.length ? createdBy[0] : null;
        return (
            <>
                <div className="guestroom_wrapper">
                    <RoomJoinModal
                        onClose={this.closeModal}
                        showModal={this.state.roomJoinModal}
                        modalInfo={this.state.modalInfo}
                    />
                    <Row>
                        <Col xs={{ size: 12, order: 2 }} sm="12" className="guestroom__information">
                            {
                                <Carousel
                                    speakers={this.state.onlineSpeakers}
                                    activeParticipantid={this.state.activeParticipantid}
                                >
                                    {this.speakers()}
                                </Carousel>
                            }
                            {this.state.isRecording ? (
                                <div className="text-right mt-3">
                                    <p className="recording-text">Recording...</p>
                                </div>
                            ) : (
                                ''
                            )}
                        </Col>

                        <Col
                            className="guestroom-detail__col-border"
                            xs={{ size: 12, order: 1 }}
                            sm={{ size: 6, order: 2 }}
                        >
                            <div className="guestroom__detail_wrapper">
                                {!isRoomPrivate ? (
                                    <div className="guestroom-hero-wrap">
                                        {this.state.screenShareStreaming &&
                                        this.user &&
                                        this.user.id !== this.state.screenShareStreaming.userId ? (
                                            <img
                                                src={maximiseIcon}
                                                className={'guestroom-video-icon cursor-pointer'}
                                                onClick={this.showFullScreen}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        <video
                                            id={'active-speaker-video'}
                                            className={`img-fluid hero__cover guestroom-video ${
                                                this.state.speakerVideoStream || this.state.screenShareStreaming
                                                    ? 'guestroom-video-show'
                                                    : ''
                                            } ${this.state.screenShareStreaming ? '' : 'video'}`}
                                            playsInline={true}
                                            autoPlay="autoplay"
                                            muted={true}
                                        ></video>

                                        <ImageComponent
                                            src={this.state.room.image}
                                            placeholder={null}
                                            alt="hero cover"
                                            className={
                                                'img-fluid hero__cover cursor-pointer guestroom-hero-img ' +
                                                (this.state.speakerVideoStream ? 'opacity-0' : '')
                                            }
                                            onClick={() => {
                                                this.navigateToRoomDetail();
                                            }}
                                        />
                                    </div>
                                ) : this.state.speakerVideoStream ? (
                                    <video
                                        id={'active-speaker-video'}
                                        className={`img-fluid hero__cover ${
                                            this.state.speakerVideoStream ? 'video' : ''
                                        }`}
                                        playsInline={true}
                                        autoPlay="autoplay"
                                        muted={true}
                                    ></video>
                                ) : (
                                    ''
                                )}
                                <div className="roomdetail__action-wrap">
                                    <img
                                        className="roomdetail__status-tag"
                                        src={
                                            this.greenRoomTimeDiff
                                                ? tagGreenRoom
                                                : this.state.room.roomType === Constant.ROOM_TYPE.LOUNGE
                                                ? iconLounge
                                                : tagLive
                                        }
                                        alt=""
                                        data-test=""
                                    />
                                    <p className="roomdetail__time">{this.state.room.timestamp}</p>
                                </div>
                                <div className="roomdetail__description_wrapper">
                                    <div className="d-flex align-items-start">
                                        <ImageComponent
                                            src={
                                                !isRoomPrivate
                                                    ? this.state.room.organization && this.state.room.organization.logo
                                                    : createdBy && createdBy.image
                                            }
                                            placeholder={null}
                                            alt="hero profile"
                                            className="img-fluid hero__profile"
                                            isAvatar={isRoomPrivate}
                                        />
                                        <div>
                                            <h2
                                                className="roomdetail__roomname cursor-pointer"
                                                data-test="room-name"
                                                onClick={() => {
                                                    this.navigateToRoomDetail();
                                                }}
                                            >
                                                {this.state.room.name}
                                            </h2>
                                            {!isRoomPrivate ? (
                                                <h5 className="roomdetail__board" data-test="board-name">
                                                    {this.state.room.board.name}
                                                </h5>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    {this.state.room.isShareAble ? (
                                        <div className="room-detail__link-wrapper">
                                            <label className="room-detail__link-label" data-test="room-detail-label">
                                                Link
                                            </label>
                                            <div className="room-detail__link-inner">
                                                <Form.Control
                                                    className="room-detail__link-input"
                                                    type="text"
                                                    value={`${window.location.protocol}//${window.location.host}/room/invite?roomId=${this.state.room.roomId}`}
                                                    placeholder="Some link here"
                                                    readOnly
                                                    disabled
                                                />
                                                <IconButton
                                                    image={iconShare}
                                                    text="Copy Link"
                                                    title={this.state.copyMessage}
                                                    click={this.copyToClipBoard}
                                                    cssClass="room-detail__icon-btn room-detail__copyBtn room-join-copyBtn"
                                                />
                                            </div>
                                            <p className="room-detail__link-tagline" data-test="room-detail-tagline">
                                                {Constant.ROOM_DETAILS_LINK_COPIED_MESSAGE}
                                            </p>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <p
                                        className="roomdetail__desc"
                                        data-test="room-detail-desc"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.room.description,
                                        }}
                                    ></p>
                                </div>
                            </div>
                        </Col>
                        <Col
                            className="guestroom-detail__col-border"
                            xs={{ size: 12, order: 3 }}
                            sm={{ size: 6, order: 3 }}
                        >
                            {this.greenRoomTimeDiff ? (
                                <div className="guestroom-detail__countdown">
                                    <p className="guestroom-detail__countdown-text">
                                        {Constant.GREEN_ROOM_COUNTDOWN_MSG}
                                    </p>
                                    <p className="guestroom-detail__countdown-time">{this.greenRoomTimeDiff}</p>
                                </div>
                            ) : !isRoomPrivate && !isLounge ? (
                                this.attendees()
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col xs={{ size: 12, order: 3 }} sm={{ size: 6, order: 3 }}>
                            <GuestRoomDetailParticipantProfile
                                user={this.user}
                                isGreenRoom={this.state.room.isGreenRoom}
                                participant={this.state.selectedParticipant}
                                actionMenuItems={this.actionMenuItems}
                                collapseOpen={this.state.collapseOpenProfile}
                                classForTruncate={this.state.classForTruncate}
                                onLabelClick={this.onProfileCollapseLabelClick}
                                hideProfile={this.hideProfile}
                                profileActionMenuItems={this.state.profileActionMenuItems}
                                onSelectActionMenu={this.onSelectActionMenu}
                                followUnfollowUser={this.followUnfollowUser}
                                updateInnerCircle={this.updateInnerCircle}
                                isAnonymous={this._userService.isAnonymous()}
                            />
                        </Col>
                    </Row>

                    {!this.state.isFullScreenSharing && (
                        <div className="guestroom__leavewrapper">
                            <div className="d-flex align-items-center">
                                <Button
                                    variant="light"
                                    className="btn__themeoutline mr-19"
                                    onClick={this.handleShowLeaveRoom}
                                >
                                    Leave Room
                                </Button>
                                {this.user && this.user.roomRole === Constant.ROOM_MEMBERS.ATTENDEE && (
                                    <Button
                                        variant="light"
                                        className="btn__themeoutline btn-hand"
                                        onClick={this.handleHandGestureOperation}
                                    >
                                        {this.user.handRaised
                                            ? Constant.USER_HAND_ACTION_STATUS.LOWER_HAND
                                            : Constant.USER_HAND_ACTION_STATUS.RAISE_HAND}
                                    </Button>
                                )}
                                {this.user && this.user.roomRole === Constant.ROOM_MEMBERS.MODERATOR && (
                                    <BtnDropDownComponent
                                        src={roomActionBtn}
                                        hoverClass=".btn__themeoutline"
                                        isGreenRoom={this.state.room.isGreenRoom}
                                        menuItems={this.state.moderatorActionMenuItems}
                                        onSelect={item => {
                                            this.onSelectModeratorActionMenu(item);
                                        }}
                                    />
                                )}
                            </div>
                            <div className="guestroom__disclaimer-wrapper">
                                {this.canUserSpeak() && !isRoomPrivate ? (
                                    <IconButton
                                        image={shareScreen}
                                        text={this.state.isScreenShareStarted ? 'Stop Screen' : 'Share Screen'}
                                        click={this.onScreenShareClick}
                                        cssClass="guestroom-video-btn"
                                        iconClass="guestroom-video-btn-icon"
                                        id="start-screenshare-btn"
                                    />
                                ) : (
                                    ''
                                )}
                                {this.user && this.user.canSpeak && (
                                    <IconButton
                                        image={this.state.isVideoStarted ? cameraOff : cameraOn}
                                        text={this.state.isVideoStarted ? 'Stop Video' : 'Start Video'}
                                        click={this.onCameraButtonClick}
                                        cssClass="guestroom-video-btn"
                                        iconClass="guestroom-video-btn-icon"
                                    />
                                )}
                                {this.user && this.user.canSpeak && (
                                    <img
                                        onClick={this.onMuteButtonClick}
                                        src={this.user.isMute ? btnMuted : btnUnmuted}
                                        alt="btn"
                                        className="guestroom__mute-btn cursor-pointer"
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    <Modal
                        className="leaveguestroom__modal"
                        centered
                        show={this.state.showMeetingEnded}
                        onHide={this.handleCloseEnded}
                        backdrop="static"
                    >
                        <Modal.Body>
                            <h4 className="modal__heading">This session has ended.</h4>
                            <p className="modal__desc">Download the Boardroom One app for the complete experience.</p>
                        </Modal.Body>
                        <Modal.Footer className="modal__footer">
                            <Button variant="primary" className="btn__themefilled" onClick={this.endTheRoom}>
                                Leave Room
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <LoungeTimer
                        roomId={this.state.room.roomId}
                        show={this.state.showLoungeTimer}
                        onClose={this.handleCloseLoungeTimer}
                        onJoinLounge={this.onJoinLounge}
                    ></LoungeTimer>
                    <NoLoungeExist
                        show={this.state.showNoLoungeMessage}
                        onClose={this.handleCloseNoLougeMessage}
                    ></NoLoungeExist>
                    {this.state.showHoldScreen && (
                        <LoungeHoldScreen
                            show={this.state.showHoldScreen}
                            roomId={this.state.room.roomId}
                        ></LoungeHoldScreen>
                    )}
                    <Modal
                        className="leaveguestroom__modal"
                        centered
                        show={this.state.showEjectByModerator}
                        onHide={this.handleCloseEjectByModerator}
                        backdrop="static"
                    >
                        <Modal.Body>
                            <h4 className="modal__heading">You have been removed from the room!</h4>
                            <p className="modal__desc">You have been removed from this room by the moderator.</p>
                        </Modal.Body>
                        <Modal.Footer className="modal__footer">
                            <Button
                                variant="primary"
                                className="btn__themefilled"
                                onClick={this.handleEjectByModerator}
                            >
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        className="leaveguestroom__modal"
                        centered
                        show={this.state.showEndRoom}
                        onHide={this.handleCloseEndRoom}
                        backdrop="static"
                    >
                        <Modal.Body>
                            <h4 className="modal__heading">End Room</h4>
                            <p className="modal__desc">
                                Select whether you would like to end the Room completely, or end the Room and prompt the
                                audience members to join a lounge.
                            </p>
                        </Modal.Body>
                        <Modal.Footer className="modal__footer end-modal-footer">
                            <Button variant="light" className="btn__themeoutline" onClick={this.handleCloseEndRoom}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="btn__themefilled"
                                onClick={this.handleEndRoom.bind(null, false)}
                            >
                                End Room
                            </Button>
                            {this.state.room.isLoungeEnabled && (
                                <Button
                                    variant="primary"
                                    className="btn__themefilled endroom-btn-long"
                                    onClick={this.handleEndRoomWithLounge}
                                >
                                    End Room and Start Lounge
                                </Button>
                            )}
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        className="leaveguestroom__modal"
                        centered
                        show={this.state.showRoomEndedByModerator}
                        onHide={this.handleCloseRoomEndedByModerator}
                        backdrop="static"
                    >
                        <Modal.Body>
                            <h4 className="modal__heading">Room has been ended by moderator</h4>
                        </Modal.Body>
                        <Modal.Footer className="modal__footer">
                            <Button
                                variant="light"
                                className="btn__themeoutline"
                                onClick={this.handleCloseRoomEndedByModerator}
                            >
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        className="leaveguestroom__modal"
                        centered
                        show={this.state.showLeaveRoom}
                        onHide={this.handleClose}
                    >
                        <Modal.Body>
                            <h4 className="modal__heading">Are you sure you wish to leave the room?</h4>
                        </Modal.Body>
                        <Modal.Footer className="modal__footer">
                            <Button variant="light" className="btn__themeoutline" onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="btn__themefilled h-40"
                                onClick={async () => {
                                    await this.leaveRoom();
                                }}
                            >
                                Leave Room
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* eject participant modal */}
                    <Modal
                        className="theme-modal recording-modal"
                        centered
                        show={this.state.showEjectModal}
                        onHide={this.handleCloseEjectParticipant}
                        backdrop="static"
                    >
                        <Modal.Body className="text-center">
                            <h4 className="recording-modal__heading">
                                Are you sure you want to eject this{' '}
                                {this.state.selectedParticipant && this.state.selectedParticipant.role}?
                            </h4>
                            <p className="recording-modal__text">
                                This {this.state.selectedParticipant && this.state.selectedParticipant.role} will be
                                removed from the room.
                            </p>
                        </Modal.Body>
                        <Button className="recording-modal__btn-okay" onClick={this.handleCloseEjectParticipant}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="btn__themefilled" onClick={this.ejectParticipant}>
                            Eject
                        </Button>
                    </Modal>

                    {/* recording modal */}
                    <Modal
                        className="theme-modal recording-modal"
                        centered
                        show={this.state.showRecordedModal}
                        onHide={this.handleCloseRoomRecorded}
                        backdrop="static"
                    >
                        <Modal.Body className="text-center">
                            <h4 className="recording-modal__heading">This room is being recorded</h4>
                            <p className="recording-modal__text">
                                By continuing to be in the room, you are consenting to be recorded.
                            </p>
                        </Modal.Body>
                        <Button className="recording-modal__btn-okay" onClick={this.handleCloseRoomRecorded}>
                            Okay
                        </Button>
                        <Button
                            variant="primary"
                            className="btn__themefilled"
                            onClick={async () => {
                                await this.leaveRoom();
                            }}
                        >
                            Leave Room
                        </Button>
                    </Modal>

                    {/* promotion initiated for attendee to speaker modal */}
                    <Modal
                        className="theme-modal invite-modal"
                        centered
                        show={this.state.showPromotionModal}
                        onHide={this.handlePromotionDeclined}
                        backdrop="static"
                    >
                        <Modal.Body className="text-center">
                            <h4 className="invite-modal__heading">{this.state.promotionModalMsgs.title}</h4>
                            <p className="invite-modal__text">{this.state.promotionModalMsgs.body}</p>
                        </Modal.Body>
                        <Modal.Footer className="invite-modal__footer">
                            <Button className="invite-modal__footer__btn-okay" onClick={this.handlePromotionAccepted}>
                                {this.state.promotionModalMsgs.btnAcceptText}
                            </Button>
                            <Button
                                variant="primary"
                                className="btn__themefilled"
                                onClick={this.handlePromotionDeclined}
                            >
                                {this.state.promotionModalMsgs.bntRejectText}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* promotion declined modal */}
                    <Modal
                        className="decline-promotion__modal"
                        centered
                        show={this.state.showPromotionDeclinedModal}
                        onHide={this.handleClosePromotionDeclinedModal}
                        backdrop="static"
                    >
                        <Modal.Body>
                            <h4 className="decline-modal__heading">{this.state.promotionModalMsgs.title}</h4>
                            <p className="decline-modal__desc">{this.state.promotionModalMsgs.body}</p>
                        </Modal.Body>
                        <Modal.Footer className="decline-modal__footer">
                            <Button
                                variant="primary"
                                className="btn__themefilled"
                                onClick={this.handleClosePromotionDeclinedModal}
                            >
                                {this.state.promotionModalMsgs.btnAcceptText}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* speaking time over modal */}
                    <Modal
                        className="decline-promotion__modal"
                        centered
                        show={this.state.showSpeakingTimeOverModal}
                        onHide={this.handleCloseSpeakingTimeOverModal}
                        backdrop="static"
                    >
                        <Modal.Body>
                            <h4 className="decline-modal__heading">Your speaking time is up!</h4>
                            <p className="decline-modal__desc">
                                The moderator has ended your speaking time and you have moved to the audience. If you
                                wish to speak again, raise your hand.
                            </p>
                        </Modal.Body>
                        <Modal.Footer className="decline-modal__footer">
                            <Button
                                variant="primary"
                                className="btn__themefilled"
                                onClick={this.handleCloseSpeakingTimeOverModal}
                            >
                                Okay
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <ScreenReshareConfirmationModal
                        name={this.state.shareUserName}
                        show={this.state.showReshareModal}
                        handleClose={this.closeReshareModal}
                        reshareScreen={this.reshareScreen}
                    />
                    <ScreenShareFullScreenModal
                        speakerVideoStream={this.state.speakerVideoStream}
                        avatarUrl={this.state.avatarUrl}
                        show={this.state.isFullScreenSharing}
                        handleClose={this.closeFullScreen}
                        onKeyUp={this.keyPress}
                        canSpeak={this.user && this.user.canSpeak}
                        onCameraButtonClick={this.onCameraButtonClick}
                        isVideoStarted={this.state.isVideoStarted}
                        onMuteButtonClick={this.onMuteButtonClick}
                        isMute={this.user && this.user.isMute}
                        isModerator={this.user && this.user.roomRole === Constant.ROOM_MEMBERS.MODERATOR}
                        isGreenRoom={this.state.room.isGreenRoom}
                        moderatorActionMenuItems={this.state.moderatorActionMenuItems}
                        onSelectModeratorActionMenu={this.onSelectModeratorActionMenu}
                        isAttendee={this.user && this.user.roomRole === Constant.ROOM_MEMBERS.ATTENDEE}
                        handleHandGestureOperation={this.handleHandGestureOperation}
                        handRaised={this.user && this.user.handRaised}
                        handleShowLeaveRoom={this.handleShowLeaveRoom}
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: (isLoading, isMessage, message) => dispatch(startLoading(isLoading, isMessage, message)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
        selectUser: isUserSelected => dispatch(selectUser(isUserSelected)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomJoinContainer);
