import React from 'react';
import './guest-room-detail-participant-profile.style.scss';
import iconPlaceholder from '../../../../assets/images/icon-placeholder.png';
import iconLinkedin from '../../../../assets/images/ic-linkedin.svg';
import iconMail from '../../../../assets/images/ic-mail.svg';
import iconMsg from '../../../../assets/images/ic-message.svg';
import iconLocation from '../../../../assets/images/ic-location.svg';
import iconCheckmark from '../../../../assets/images/ic-checkmark.png';
import { Constant, ImageComponent, ThreeDotsDropDownComponent, ButtonComponent, IconButton } from '../../../../shared';
import { Collapse } from 'react-bootstrap';

function GuestRoomDetailParticipantProfile(props) {
    const participant = props.participant;
    let actionsMenu = [];

    if (props.user && props.participant && props.user.id !== props.participant.id && props.actionMenuItems) {
        actionsMenu = props.actionMenuItems[props.user.roomRole][props.participant.role];

        if (props.participant.handRaised && props.user.role === Constant.ROOM_MEMBERS.MODERATOR) {
            actionsMenu.splice(1, 0, { name: 'Lower Hand', isDanger: false });
        }
        if (props.isGreenRoom) {
            actionsMenu = actionsMenu.filter(item => !item.hideOnGreenRoom);
        }
    }
    if (props.participant && props.participant.isAnonymous) {
        let index = actionsMenu.findIndex(x => x.name === Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE);
        if (index > -1) {
            actionsMenu.splice(index, 1);
        }
    }
    return (
        <div className="guest-room-detail__user-profile">
            {!participant ? (
                <div className="guest-room-detail__user-profile-empty">
                    <div className="text-center">
                        <img
                            className="guest-room-detail__user-profile-empty-placeholder"
                            src={iconPlaceholder}
                            alt=""
                        />{' '}
                        <p className="guest-room-detail__user-profile-empty-text">
                            {' '}
                            Click on a profile to view details.
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <div className="guest-room-detail__kabob-menu">
                        {(actionsMenu.length > 0 || participant.profileMenuItems.length > 0) && !props.isAnonymous && (
                            <ThreeDotsDropDownComponent
                                dotClass="guest-room-detail__rotate-kabob"
                                hoverClass="guest-room-detail__menu-item"
                                itemClass="pl-3"
                                menuItems={actionsMenu.length > 0 ? actionsMenu : participant.profileMenuItems}
                                onSelect={item => {
                                    props.onSelectActionMenu(item, participant.id);
                                }}
                            />
                        )}
                    </div>
                    <div className="guest-room-detail__user-profile-meta">
                        {participant.fullAvatarUrl ? (
                            <img
                                id="participant-image"
                                className="guest-room-detail__user-profile-img"
                                src={participant.fullAvatarUrl}
                                alt=""
                                data-test="fullAvatarUrl"
                            />
                        ) : (
                            <ImageComponent
                                className="guest-room-detail__user-profile-img"
                                data-test="hero_img"
                                src={participant.image}
                                isAvatar={true}
                                placeholder={iconPlaceholder}
                                alt=""
                            />
                        )}
                        <h4 className="guest-room-detail__user-profile-name" data-test="user-name">
                            {participant.name}
                        </h4>
                        <p className="guest-room-detail__user-profile-blurb" data-test="user-blurb">
                            {participant.blurb}
                        </p>
                        {participant.timeStamp && (
                            <p className="guest-room-detail__user-profile-timestamp" data-test="user-profile-timestamp">
                                Member since {participant.timeStamp}
                            </p>
                        )}
                        {participant.email && participant.email !== props?.user?.email && !props.isAnonymous && (
                            <div className="d-flex align-items-center flex-wrap">
                                {!participant.relationship.isFollowing && (
                                    <ButtonComponent
                                        className={'guest-room-detail__btn-follow'}
                                        name="Follow"
                                        onclick={() => props.followUnfollowUser(participant.id, { follow: true })}
                                    />
                                )}
                                {participant.relationship.isFollowing && (
                                    <IconButton
                                        data-test="past-room-home-btn"
                                        image={iconCheckmark}
                                        text="Following"
                                        cssClass="guest-room-detail__icon-btn-profile"
                                        iconClass="guest-room-detail__icon-btn-profile-icon"
                                        click={() => props.followUnfollowUser(participant.id, { follow: false })}
                                    />
                                )}
                                {participant.relationship.isFollower && !participant.relationship.isInnerCircle && (
                                    <ButtonComponent
                                        className={'guest-room-detail__btn-follow ml-3'}
                                        name="Add to Inner Circle"
                                        onclick={() => props.updateInnerCircle(participant.id, { innerCircle: true })}
                                    />
                                )}
                                {participant.relationship.isInnerCircle && (
                                    <IconButton
                                        data-test="past-room-home-btn"
                                        image={iconCheckmark}
                                        text="Inner Circle"
                                        cssClass="guest-room-detail__icon-btn-profile ml-3"
                                        iconClass="guest-room-detail__icon-btn-profile-icon"
                                        click={() => props.updateInnerCircle(participant.id, { innerCircle: false })}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="guest-room-detail__user-profile-social">
                        {participant.linkedin && (
                            <p
                                className="guest-room-detail__user-profile-social-text"
                                data-test="user-linkedin-profile"
                            >
                                <img
                                    src={iconLinkedin}
                                    alt=""
                                    className="guest-room-detail__user-profile-social-img"
                                    data-test="linkedin-icon"
                                />
                                {participant.linkedin}
                            </p>
                        )}
                        {participant.email && (
                            <p className="guest-room-detail__user-profile-social-text" data-test="user-email-id">
                                <img
                                    src={iconMail}
                                    alt=""
                                    className="guest-room-detail__user-profile-social-img"
                                    data-test="email-icon"
                                />
                                {participant.email}
                            </p>
                        )}
                        {participant.phone && (
                            <p className="guest-room-detail__user-profile-social-text" data-test="user-phone-no">
                                <img
                                    src={iconMsg}
                                    alt=""
                                    className="guest-room-detail__user-profile-social-img"
                                    data-test="phone-icon"
                                />
                                {participant.phone}
                            </p>
                        )}
                        {participant.location && (
                            <p className="guest-room-detail__user-profile-social-text" data-test="user-location">
                                <img
                                    src={iconLocation}
                                    alt=""
                                    className="guest-room-detail__user-profile-social-img"
                                    data-test="location-icon"
                                />
                                {participant.location}
                            </p>
                        )}
                    </div>
                    {participant.bio && (
                        <div>
                            {!props.collapseOpen && (
                                <p
                                    data-test="description_slice"
                                    className={
                                        'room-detail__desc room-detail__truncate-4 ' +
                                        (props.classForTruncate ? 'room-detail__negate-truncate' : '')
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: participant.bio.slice(0, Constant.DESCRIPTION_LIMIT),
                                    }}
                                ></p>
                            )}
                            {props.collapseOpen && (
                                <Collapse in={props.collapseOpen}>
                                    <div
                                        id="readmore-collapse-text"
                                        className="room-detail__desc"
                                        data-test="description_slice"
                                        dangerouslySetInnerHTML={{ __html: participant.bio }}
                                    ></div>
                                </Collapse>
                            )}
                            {participant.bio.length > Constant.DESCRIPTION_LIMIT && (
                                <label
                                    data-test="description_label"
                                    className="room-detail__read-more"
                                    onClick={props.onLabelClick}
                                    aria-controls="readmore-collapse-text"
                                    aria-expanded={props.collapseOpen}
                                >
                                    {!props.collapseOpen
                                        ? Constant.ROOM_DETAILS_DESCRIPTION_CLICK_MESSAGE.READ_MORE
                                        : Constant.ROOM_DETAILS_DESCRIPTION_CLICK_MESSAGE.COLLAPSE}
                                </label>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default GuestRoomDetailParticipantProfile;
