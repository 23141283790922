import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { showHideErrorToaster, startLoading, UserRegister } from '../../';
import './guest-confirm-email.style.scss';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Constant, InputComponent, Utility } from '../../../shared';
import logoDolby from '../../../assets/images/logo-dolby.png';
import betaLogo from '../../../assets/images/beta-logo.png';
import { DateTime } from 'luxon';

export class GuestConfirmEmail extends Component {
    _userRegisterService = new UserRegister();
    _utilityService = new Utility();

    emailSchema = Yup.object().shape({
        email: Yup.string().email(),
    });

    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            validationErrorMessage: {
                emailValidation: '',
            },
            error: null,
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
    }

    setUserEmail = userEmail => {
        this.setState({ ...this.state, userEmail });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    sendRegistrationEmail = async () => {
        if (this.validateEmail()) {
            try {
                this.props.startLoading(true);
                const resp = await this._userRegisterService.sendRegistrationEmail({ email: this.state.userEmail });
                if (resp) {
                    this.props.history.replace({
                        pathname: `/verify-email-status/${this._utilityService.encryptObjectToBase64({
                            email: this.state.userEmail,
                        })}`,
                    });
                }
            } catch (error) {
                if (error.response.status === 409) {
                    const validationErrorMessage = { ...this.state.validationErrorMessage };
                    validationErrorMessage.userEmailValidation = `This email has already been registered in our system, Please use magic link option to login.`;
                    this.setErrorMessages(validationErrorMessage);
                } else {
                    this.props.showHideErrorToaster({
                        show: true,
                        message: 'Failed to send email.',
                        bgColor: '#ff3547',
                    });
                }
            } finally {
                this.props.startLoading(false);
            }
        }
    };

    validateEmail = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        const isEmailValid = this.emailSchema.isValidSync({ email: this.state.userEmail });
        if (!isEmailValid || !this.state.userEmail) {
            validationErrorMessage.userEmailValidation = `Please enter a valid email address`;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        return true;
    };

    onKeyDown = async event => {
        const keyPressed = event.key;
        if (keyPressed === Constant.KEYS.ENTER) {
            await this.sendRegistrationEmail();
        }
    };

    render() {
        return (
            <>
                <div className="guest-confirm-email">
                    <div className="guest-confirm-email__block">
                        <div className="magic-link-sender__logos">
                            <img
                                src={betaLogo}
                                alt="betalogo"
                                className="guest-confirm-email__logos__beta-logo img-fluid"
                                data-test="logo_render"
                            />
                            <img
                                src={logoDolby}
                                alt="logoDolby"
                                className="guest-confirm-email__logos__logo img-fluid"
                                data-test="logo_render"
                            />
                        </div>
                        {/* <img src={logoDolby} alt="logo" className="guest-confirm-email__logo img-fluid" /> */}
                        <div className="guest-confirm-email__inner">
                            <Form.Group className="br__form-group" controlId="formEmail">
                                <Form.Label className="br__form-label">Email Address</Form.Label>
                                <InputComponent
                                    classes={'br__form-control'}
                                    textId={'magic-link-sender-container-email'}
                                    onChange={this.setUserEmail}
                                    type="email"
                                    value={this.state.userEmail}
                                    errorMessage={this.state.validationErrorMessage.userEmailValidation}
                                    onKeyDown={this.onKeyDown}
                                />
                            </Form.Group>
                            <Button className="guest-confirm-email__btn-send" onClick={this.sendRegistrationEmail}>
                                Confirm Email
                            </Button>
                        </div>
                        <div className="guest-confirm-email__disclaimer" data-test="disclaimer">
                            <div className="text-center">
                                <Link
                                    to="/guest-login"
                                    className="loginform__link"
                                    id="si_need_help"
                                    data-test="need_help_link"
                                    onMouseDown={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    Log in with Password
                                </Link>
                            </div>
                            <p className="guest-confirm-email__disclaimer-text">
                                &copy; {this.state.yearNow} Boardroom One. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(GuestConfirmEmail));
