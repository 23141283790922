import React, { Component } from 'react';
import { AuthService, showHideErrorToaster } from '../../';
import './forgot-password.style.scss';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { InputComponent, Utility } from '../../../shared';
import logo from '../../../assets/images/logo.png';
import { DateTime } from 'luxon';

export class ForgotPasswordContainer extends Component {
    fromGuest = false;
    _authService = new AuthService();
    _utilityService = new Utility();
    emailSchema = Yup.object().shape({
        email: Yup.string().email(),
    });
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            validationErrorMessage: {
                emailValidation: '',
            },
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
        const search = this.props.location.search;
        this.fromGuest = new URLSearchParams(search).get('fromGuest') || false;
        this.fromGuest = this.fromGuest && this.fromGuest === 'true' ? true : false;
    }

    setUserEmail = userEmail => {
        this.setState({ ...this.state, userEmail });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    sendUseremailCode = async () => {
        if (this.validateEmail()) {
            try {
                const resp = await this._authService.forgotPassword(this.state.userEmail);
                this.props.showHideErrorToaster({
                    show: true,
                    message: resp ? resp.message : 'Verification code is sent to your email.',
                    bgColor: resp ? '#ff3547' : 'green',
                });
                if (!resp) {
                    this.props.history.push(
                        `/reset-password?email=${this.state.userEmail}&fromGuest=${this.fromGuest}`,
                    );
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    validateEmail = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        const isEmailValid = this.emailSchema.isValidSync({ email: this.state.userEmail });
        if (!isEmailValid || !this.state.userEmail) {
            validationErrorMessage.userEmailValidation = `Please provide a valid email`;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        return true;
    };

    render() {
        return (
            <>
                <Form>
                    <div className="forgot-password">
                        <div className="forgot-password__block">
                            <img src={logo} alt="logo" className="forgot-password__logo img-fluid" />
                            <div className="forgot-password__inner">
                                <p className="forgot-password__text">
                                    Please enter your email address to receive further instructions
                                </p>
                                <Form.Group className="br__form-group" controlId="formEmail">
                                    <Form.Label className="br__form-label">Email</Form.Label>
                                    <InputComponent
                                        classes={'br__form-control'}
                                        textId={'forgot-password-container-email'}
                                        onChange={this.setUserEmail}
                                        type="email"
                                        value={this.state.userEmail}
                                        errorMessage={this.state.validationErrorMessage.userEmailValidation}
                                    />
                                </Form.Group>
                                <Button className="forgot-password__btn-send" onClick={this.sendUseremailCode}>
                                    Send
                                </Button>
                            </div>
                            <p className="disclaimer" data-test="disclaimer">
                                &copy; {this.state.yearNow} Boardroom One. All rights reserved.
                            </p>
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(null, mapDispatchToProps)(ForgotPasswordContainer);
