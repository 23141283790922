import { Http } from '../../../../core';

export class GuestDashboardService {
    /**
     *
     * @name getRoomInvites
     * @desc this will be return the list of rooms
     * @return {Promise}
     */
    getRoomInvites = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get('room/invites')
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(null);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getRelationSummary
     * @desc will fetch relation summary
     * @return {Promise}
     */
    getRelationSummary = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`relation/summary`)
                .then(async _successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };

    /**
     * @name getUserBoards
     * @desc will fetch user boards
     * @return {Promise}
     */
    getUserBoards = userId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/boards/${userId}`)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getStatusPubsubKey
     * @desc will fetch relation summary
     * @return {Promise}
     */
    getStatusPubsubKey = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`room/status/getPubnubKey`)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
