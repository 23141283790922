import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useHistory } from 'react-router';
import { LoungeTimerExpired } from './room-lounge-timer-expired';
import './room-lounges-modal.style.scss';
const LoungeTimer = props => {
    const [showExpiredTimer, setShowExpiredTimer] = useState(false);
    const history = useHistory();

    const onCloseTimerExpired = () => {
        setShowExpiredTimer(false);
        history.push('/guest-dashboard');
    };
    const onTimerComplate = () => {
        setShowExpiredTimer(true);
    };
    return (
        <>
            <Modal
                className="leaveguestroom__modal room-lounge-timer"
                centered
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
            >
                <Modal.Body>
                    <h4 className="">
                        <Countdown
                            onComplete={onTimerComplate}
                            date={Date.now() + 30000}
                            renderer={props => <p className="join-lounge-modal-timer">00:{zeroPad(props.seconds)}</p>}
                        />
                    </h4>
                    <h4 className="modal__heading">This room has ended</h4>

                    <p className="modal__desc">
                        Join the Lounge to continue the conversation with attendees we've matched with you.
                    </p>
                </Modal.Body>
                <Modal.Footer className="modal__footer">
                    <Button variant="primary" className="btn__themefilled" onClick={props.onJoinLounge}>
                        Join the Lounge
                    </Button>
                    <Button variant="light" className="btn-link" onClick={props.onClose}>
                        Leave Room
                    </Button>
                </Modal.Footer>
            </Modal>
            <LoungeTimerExpired show={showExpiredTimer} onClose={onCloseTimerExpired}></LoungeTimerExpired>
        </>
    );
};
export { LoungeTimer };
