import React from 'react';
import { connect } from 'react-redux';

import { AuthService, UserRegister, AWSStorageService, startLoading, CloudinaryService } from '../../../core';
import { Button, Form } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png';

import './update-profile.style.scss';
import { FileSelectComponent, InputComponent, Utility } from '../../../shared';

import { SuccessfulComponent } from '../../../core/components';
import { UpdateProfileService } from '../services';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

export class UpdateProfileContainer extends React.Component {
    _updateProfileService = new UpdateProfileService();
    _authService = new AuthService();
    _utilityService = new Utility();
    _userRegister = new UserRegister();
    _awsStorageService = new AWSStorageService();
    _cloudinaryService = new CloudinaryService();
    constructor(props) {
        super(props);

        this.state = {
            formDetails: {
                profilePicture: null,
                localImagePath: '',
                fullName: '',
                email: '',
                blurb: '',
                existingProfilePicturePath: '',
            },
            validationErrorMessage: {
                logoValidation: '',
                nameValidation: '',
                emailValidation: '',
                blurbValidation: '',
            },
            buttonDisabled: false,
            isSuccessfullySubmitted: false,
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
    }

    showHideLoader = show => {
        this.props.startLoading(show);
    };

    componentDidMount = async () => {
        this.showHideLoader(true);
        const userId = this.props.match.params.userId;
        const boardId = this.props.match.params.boardId;
        try {
            const { name, image, fullAvatarUrl, email, blurb } = await this._updateProfileService.getUserById(
                userId,
                boardId,
            );

            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    fullName: name || '',
                    localImagePath: fullAvatarUrl || (image && (await this._cloudinaryService.getCloudinaryURL(image))),
                    blurb: blurb || '',
                    email: email,
                    image,
                },
            });
            this.showHideLoader(false);
        } catch (error) {
            this.showHideLoader(false);
        }
    };

    removeFileFromStorage = async () => {
        if (this.state.formDetails.existingProfilePicturePath) {
            await this._awsStorageService.removeFile(this.state.formDetails.existingProfilePicturePath);
        }
    };

    setSelectedLogo = async profilePicture => {
        if (this._utilityService.validateImageFile(profilePicture.type)) {
            this.props.startLoading(true);
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    profilePicture,
                },
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            await this.removeFileFromStorage();
            const storageMetaData = await this.getStorageMetaData(this.props.match.params.userId);
            const cloudinaryImageUrl = await this._cloudinaryService.getCloudinaryURL(storageMetaData);
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    localImagePath: cloudinaryImageUrl,
                    existingProfilePicturePath: storageMetaData,
                    cloudinaryImageKey: this._utilityService.removeImgExtension(storageMetaData),
                },
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            return;
        }

        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, profilePicture: null, localImagePath: '' },
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: 'Selected File is not an image',
            },
        });
    };

    setCloudinaryImageUrl = src => {
        this.setState({
            ...this.state,
            formDetails: {
                ...this.state.formDetails,
                localImagePath: this._utilityService.removeImgExtension(src),
            },
        });
    };

    setFullName = fullName => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, fullName },
            validationErrorMessage: { ...this.state.validationErrorMessage, nameValidation: '' },
        });
    };

    setBlurb = blurb => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, blurb },
            validationErrorMessage: { ...this.state.validationErrorMessage, blurbValidation: '' },
        });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    validateForm = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };

        if (!this.state.formDetails.profilePicture && !this.state.formDetails.localImagePath) {
            validationErrorMessage.logoValidation = 'Please select the profile picture';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.fullName.trim()) {
            validationErrorMessage.nameValidation = 'Please write your full name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        // eslint-disable-next-line no-useless-escape
        const re = /[`!@#$%^&*()_=\[\]{};\\|<>\/?~]/;

        if (re.test(this.state.formDetails.fullName.trim())) {
            validationErrorMessage.nameValidation = 'Special characters are not allowed in Full Name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.email) {
            return false;
        }

        return true;
    };

    submit = async () => {
        if (!this.validateForm()) {
            return;
        }
        this.showHideLoader(true);
        try {
            const payload = {
                name: this.state.formDetails.fullName,
                blurb: this.state.formDetails.blurb,
                image: this.state.formDetails.existingProfilePicturePath || this.state.formDetails.image,
                fullAvatarUrl: this.state.formDetails.localImagePath,
                role: '',
            };

            const userId = this.props.match.params.userId;
            const boardId = this.props.match.params.boardId;
            await this._updateProfileService.updateProfile(payload, userId, boardId);
            this.showHideLoader(false);
            this.props.history.goBack();
        } catch (error) {
            this.showHideLoader(false);
        }
    };

    uploadFileToStorage = async () => {
        const credentials = await this._authService.getAuthCredentials();
        const cognitoId = credentials.identityId;
        const storageMetaData = await this._awsStorageService.uploadFile(
            `${cognitoId}/${uuidv4()}`,
            this.state.formDetails.profilePicture,
            this.state.formDetails.profilePicture.type,
        );
        return storageMetaData;
    };

    getStorageMetaData = async () => {
        const userId = this.props.match.params.userId;
        let storageMetaData = '';
        if (userId) {
            if (this.state.formDetails.profilePicture) {
                storageMetaData = await this.uploadFileToStorage();
            } else {
                storageMetaData = this.state.formDetails.existingProfilePicturePath;
            }
        } else {
            storageMetaData = await this.uploadFileToStorage();
        }
        return storageMetaData.key || storageMetaData;
    };

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    render() {
        return (
            <Form>
                <div className="br__register-wrapper">
                    <div className="br__register-block">
                        <img src={logo} alt="profilePicture" className="br__register-logo img-fluid" />
                        {this.state.isSuccessfullySubmitted ? (
                            <SuccessfulComponent />
                        ) : (
                            <div className="br__register-form-wrapper">
                                <FileSelectComponent
                                    id={'user-register'}
                                    label="Upload Profile Picture"
                                    ratio="1:1"
                                    isProfilePicture={true}
                                    fileHandling={{
                                        setSelectedLogo: this.setSelectedLogo,
                                        localImagePath: this.state.formDetails.localImagePath,
                                        cloudinaryImageKey: this.state.formDetails.cloudinaryImageKey,
                                        setCloudinaryImageUrl: this.setCloudinaryImageUrl,
                                    }}
                                    validationErrorMessage={{ ...this.state.validationErrorMessage }}
                                    validateImageFileMessage={this.validateImageFileMessage}
                                />

                                <Form.Group className="br__form-group" controlId="formFullName">
                                    <Form.Label className="br__form-label">Full Name</Form.Label>
                                    <InputComponent
                                        onChange={this.setFullName}
                                        charLimit={155}
                                        value={this.state.formDetails.fullName}
                                        errorMessage={this.state.validationErrorMessage.nameValidation}
                                        classes={'br__form-control'}
                                    />
                                </Form.Group>

                                <Form.Group className="br__form-group" controlId="formFullName">
                                    <Form.Label className="br__form-label">Email</Form.Label>
                                    <InputComponent
                                        disable={true}
                                        value={this.state.formDetails.email}
                                        classes={'br__form-control'}
                                        readonly={true}
                                    />
                                </Form.Group>

                                <Form.Group className="br__form-group" controlId="formFullName">
                                    <Form.Label className="br__form-label">Blurb</Form.Label>
                                    <InputComponent
                                        type="description_box"
                                        value={this.state.formDetails.blurb}
                                        charLimit={100}
                                        onChange={this.setBlurb}
                                        errorMessage={this.state.validationErrorMessage.blurbValidation}
                                        classes={'br__form-control'}
                                    />
                                </Form.Group>

                                <Button
                                    className="br__btn-theme-filled br__btn-register"
                                    onClick={e => {
                                        this.submit();
                                    }}
                                >
                                    Update
                                </Button>
                                <div className="text-center">
                                    <div className="br__register-need-help">
                                        {' '}
                                        <a
                                            href="https://boardroomone.notion.site/boardroomone/Organization-Management-2bca263b20f34f16b66d5ec6d9d2a186"
                                            target="_blank"
                                        >
                                            Need help?
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <p className="br__register-disclaimer" data-test="disclaimer">
                        &copy; {this.state.yearNow} Boardroom One. All rights reserved.
                    </p>
                </div>
            </Form>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(null, mapDispatchToProps)(UpdateProfileContainer);
