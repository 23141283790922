import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Constant } from '../..';
import './loader.style.scss';
class Loader extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.isLoading ? (
                    <div data-test="loader_wrapper" className="loader-wrapper">
                        <div className="loader-inner">
                            <Spinner animation="border" role="status"></Spinner>
                            <p className="loader-text">{this.props.isMessage && this.props.message}</p>
                        </div>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.app,
    };
};

export default connect(mapStateToProps)(Loader);
