export class Constant {
    /**
     * constant variable should be declare as
     *
     * static CONSTANT_VARIABLE = <some value>;
     *
     * and related constants like object should be declare as
     *
     * static CONSTANT_OBJECT = {
     *      <KEY_NAME1>: <value>,
     *      <KEY_NAME2>: <value>
     * }
     */

    static AUTH_EXCEPTION = {
        TYPE: 'AUTH_EXCEPTION',
        MESSAGE: 'Sorry, the email address or password provided isn’t right.',
    };

    static KEYS = {
        ENTER: 'Enter',
        TAB: 'Tab',
        BACKSPACE: 'Backspace',
        ESCAPE: 'Escape',
    };

    /**
     * @constant ROLES
     * @desc ROLE OF MEMBERS IN ORGANIZATIONS, BOARDS, AND ROOMS
     */

    static ROOT_USER = 'isRoot';

    static ORGANIZATION_MEMBERS = {
        EXECUTIVE_PRODUCER: 'executive.producer',
    };

    static BOARD_MEMBERS = {
        PRODUCER: 'producer',
        DIRECTOR: 'director',
        MEMBER: 'member',
    };

    static ROOM_MEMBERS = {
        GUEST: 'guest',
        ATTENDEE: 'attendee',
        SPEAKER: 'speaker',
        MODERATOR: 'moderator',
    };

    /**
     * @constant ORGANIZATION
     * @desc CONSTANTS IN ORGANIZATION's MODULE
     */

    static ORGANIZATION_MENU_ITEMS = [
        { name: 'Edit', isDanger: false },
        // { name: 'Delete', isDanger: true },
    ];
    static CREATE_ORGANIZATION_VALIDATION = {
        logoFileValidation: 'Selected File is not an image',
        logoValidation: 'Please select the logo',
        orgNameValidation: 'Please fill the organization name',
        emailsValidation: 'Please provide email address(es)',
        invalidEmailsValidation: 'Provided email address(es) are not valid',
        logoSizeLimitValidation: 'Please select image with size upto 20mb',
    };

    static NO_ORGANIZATION_ASSOCIATED = 'No organization associated';

    static ORGANIZATION_MODAL_LOGO = {
        LABEL: 'Upload Logo',
        RATIO: '1:1',
    };

    static ORGANIZATION_CARDS_ROLE_FILTER_CONSTANT = 'executive.producer';

    /**
     * @constant ORGANIZATION_DETAILS
     * @desc Constants in Organization Details module
     */

    static ORGANIZATION_DETAILS_MENU_ITEMS = [
        { name: 'Edit Board', isDanger: false },
        // { name: 'Invite Members', isDanger: false },
        { name: 'Manage Members', isDanger: false },
        // { name: 'Delete Board', isDanger: true },
    ];

    static ORGANIZATION_DETAILS_CHECK_BOARD_PARAM = 5;

    static ORGANIZATION_TABLE_UPCOMING_ROOM_STATUS = 'upcoming';

    static ORGANIZATION_TABLE_UPCOMING_ROOM_MENU_ITEMS = [
        { name: 'Edit Room', isDanger: false },
        { name: 'Duplicate Room', isDanger: false },
        { name: 'Delete Room', isDanger: true },
    ];

    /**
     * @constant BOARD_DETAILS
     * @desc Constants in Board Detail module
     */

    static BOARD_DETAILS_SUBTITLE_CONSTANT = 'description';

    /**
     * @constant ROOM_DETAILS
     * @desc Constants in Room Detail module
     */

    static ROOM_DETAILS_COPY_MESSAGE = 'Copy to clipboard';
    static ROOM_DETAILS_COPIED_MESSAGE = 'Copied to clipboard';
    static ROOM_DETAILS_ATTENDEES_MENU_ITEMS = [
        { name: 'Promote to Moderator', isDanger: false },
        { name: 'Promote to Speaker', isDanger: false },
        { name: 'Edit Profile', isDanger: false },
        { name: 'Remove from Room', isDanger: true },
    ];
    static ROOM_DETAILS_SPEAKERS_MENU_ITEMS = [
        { name: 'Move to Audience', isDanger: false },
        { name: 'Edit Profile', isDanger: false },
        { name: 'Remove from Room', isDanger: true },
    ];
    static ROOM_DETAILS_LINK_COPIED_MESSAGE = 'This room is available to everyone with the link.';
    static ROOM_DETAILS_DESCRIPTION_CLICK_MESSAGE = {
        READ_MORE: 'Read More',
        COLLAPSE: 'Collapse',
    };

    /**
     * @constant MEMBER_DETAILS
     * @desc Constants in Member Detail module
     */

    static MEMBER_DETAILS_MENU_ITEMS = [
        { name: 'Edit Profile', isDanger: false },
        { name: 'Remove Member', isDanger: true },
    ];
    static MEMBER_DETAILS_MODAL_LOGO = {
        LABEL: 'Upload Logo',
        RATIO: '1:1',
    };
    static MEMBER_DETAILS_MODAL_DELETION_MESSAGE = 'Member successfully removed.';
    static EDIT_MEMBER_MODAL_VALIDATION = {
        logoFileValidation: 'Selected File is not an image',
        logoValidation: 'Please select the profile picture',
        nameExistValidation: 'Please write your full name',
        nameSpecialCharacterValidation: 'Special characters are not allowed in Full Name',
        emailsValidation: 'Please provide email address(es)',
        invalidEmailsValidation: 'Provided email address(es) are not valid',
    };
    static MEMBER_DETAILS_BUTTON_NAME = 'Invite Member';
    static MEMBER_DETAILS_DELETE_MODAL_INFO = {
        MODAL_HEADER: 'Remove Member',
        MODAL_PARAGRAPH: null,
    };
    static MEMBER_DETAILS_EP_ROOT_ACCESSIBLE_ROLES = {
        producer: 'PRODUCER',
        director: 'DIRECTOR',
        member: 'MEMBER',
    };
    static MEMBER_DETAILS_PRODUCER_ACCESSIBLE_ROLES = {
        director: 'DIRECTOR',
        member: 'MEMBER',
    };

    /**
     * @constant ROOM_INVITE
     * @desc Constants in room invite module
     */

    static ROOM_INVITE_ROLES = {
        attendee: 'Attendee',
        speaker: 'Speaker',
        moderator: 'Moderator',
    };

    static ROOM_INVITE_DEFAULT_ROLE = 'attendee';

    static BOARD_HEADER_DESCRIPTION_SUBTITLE = 'description';
    static BOARD_HEADER_DESCRIPTION_SUBTITLE_POPOVER_LIMIT = 80;
    static BOARD_HEADER_DESCRIPTION_SUBTITLE_POPOVER_LABEL = 'read more';
    static BOARD_HEADER = {
        producers: 'PRODUCERS',
        directors: 'DIRECTORS',
        description: 'BOARD DESCRIPTION',
    };

    static BOARDS_CONSTANTS = {
        name: 'BOARD NAME',
        producers: 'PRODUCERS',
        directors: 'DIRECTORS',
        totalRooms: 'ROOMS',
        totalMembers: 'MEMBERS',
    };

    static MEMBER_CONSTANTS = {
        memberColumn: 'MEMBERS',
        role: 'ROLE',
        blurb: '',
        email: 'EMAIL ADDRESS',
    };

    static ROOM_CONSTANTS = {
        roomName: 'ROOM NAME',
        moderators: 'MODERATORS',
        speakers: 'SPEAKERS',
        roomStatusObj: 'STATUS',
        attendees: 'ATTENDEES',
    };

    static MEMBER_ROLES = {
        producer: 'PRODUCER',
        director: 'DIRECTOR',
        member: 'MEMBER',
    };

    static RolesMapping = {
        'executive.producer': 'Executive Producer',
        producers: 'Producer',
        directors: 'Director',
        moderator: 'Moderator',
    };

    static RESPONSE_ERROR = {
        500: 'Something went wrong.',
        403: 'Unauthorized.',
        404: 'Not Found.',
        401: 'Unauthenticated, logging you out.',
        400: 'Bad Request.',
    };

    static STATUS_CODE = {
        NOT_ACCEPTABLE: 406,
    };

    static MODULE = {
        ORGANIZATION: 'organization',
        BOARD: 'board',
    };

    static ROOM_CONFERENCE_STATUS = {
        CONFERENCE_CREATED: 'conferenceCreated',
        CONFERENCE_ENDED: 'conferenceEnded',
    };

    static ROOM_JOIN_MESSAGE_ACTIONS = {
        RAISE_HAND: 'raiseHand',
        LOWER_HAND: 'lowerHand',
        MUTE: 'mute',
        UNMUTE: 'unmute',
        KICK: 'kick',
        END_ROOM: 'endRoom',
        PROMOTION_INITIATED: 'promotionInitiated',
        PROMOTION_ACCEPTED: 'promotionAccepted',
        PROMOTION_DECLINED: 'promotionDeclined',
        PROMOTED: 'promoted',
        DEMOTED: 'demoted',
        UNKNOWN_USER_JOINED: 'UnknownUserJoined',
        IS_SPEAKING: 'speaking',
        INITIATE_LOUNGE: 'initiateLounge',
        START_RECORDING: 'startRecording',
        STOP_RECORDING: 'stopRecording',
        LOUNGE_INVITE: 'loungeInvite',
        SCREEN_RESHARE: 'screenReshare',
    };

    static VOXEET_ERROR_EVENTS = {
        SCREEN_SHARE_IN_PROGRESS: 'screen_share_in_progress',
    };

    static RECORDING_STATUS = {
        STARTED: 'started',
        STOPPED: 'stopped',
    };

    static USER_HAND_ACTION_STATUS = {
        RAISE_HAND: 'Raise Hand',
        LOWER_HAND: 'Lower Hand',
    };

    static CLOUDINARY_LOADER_MESSAGE = 'Please wait for few seconds while your profile picture is processing';
    static PAGINATED_ITEM = 6;
    static GUEST_PAGINATED_ITEM = 20;
    static INNER_CIRCLE_PAGINATED_ITEM = 20;
    static PAGINATED_BOARDS = 15;
    static PAGINATED_ROOMS = 15;
    static PAGINATED_BOARD_MEMBERS = 15;
    static DESCRIPTION_LIMIT = 208;
    static GUEST_LOGIN_DESCRIPTION_LIMIT = 127;
    static UNKNOWN_USER = 'Unknown';
    static ROOT_USER = 'isRoot';
    static IS_PRODUCER = 'producer';
    static NOT_ALLOWED_MESSAGE = 'Not Allowed';
    static USER_ALREADY_REGISTERED = 'User is already registered';
    static USER_REGISTER_SUCCESS = 'You have successfully registered with  Boardroom One.';
    static MEMBER_FALLBACK = {
        name: 'Unregistered',
        blurb: '-------',
    };
    static UNAUTHORIZED_MESSAGE = 'Unauthorized: You are not allowed to access this page.';
    static MEMBER_NAME_LIMIT = 154;
    static USER_FULL_NAME_LIMIT = 50;
    static FILE_UPLOAD_LIMIT = 20971520;
    static DATE_OBJ = {
        TODAY: 'TODAY',
        TOMORROW: 'TOMORROW',
        YESTERDAY: 'YESTERDAY',
    };

    static PUBNUB_RECIPIENT_ARRAY = {
        ALL: ['all', 'speakerPlus', 'moderatorPlus', 'moderators', 'speakers', 'attendees', 'guests'],
        MODERATOR_PLUS: ['moderators', 'moderatorPlus'],
        SPEAKER_PLUS: ['speakerPlus', 'moderators', 'speakers', 'moderatorPlus'],
        MODERATORS: ['moderators', 'moderatorPlus'],
        SPEAKERS: ['speakers'],
        ATTENDEES: ['attendees'],
        GUESTS: ['guests'],
        PARTICIPANT: ['participant'],
    };

    static PUBNUB_RECIPIENT_PARTICIPANT_STRING = 'participant';

    static ROOM_TYPE = {
        PRIVATE: 'private',
        LOUNGE: 'lounge',
    };

    static ROOM_USER_PROFILE_ACTIONS = {
        VIEW_FULL_PROFILE: 'View Complete Profile',
        EDIT_PROFILE: 'Edit Profile',
        INVITE_TO_STAGE: 'Invite To Stage',
        MOVE_TO_AUDIENCE: 'Move To Audience',
        MUTE: 'Mute',
        EJECT: 'Eject',
        PROMOTE_TO_MODERATOR: 'Promote To Moderator',
    };

    static MODERATOR_ACTIONS = {
        LOWER_ALL_HANDS: 'Lower All Hands',
        MUTE_ALL: 'Mute All',
        END_ROOM: 'End Room',
    };

    static PRIVATE_ROOM_EXP_MESSAGE = 'Please download the iOS app to experience Private Room';
    static NO_ROOM_AVAILABLE_MESSAGE = 'This is your dashboard. Any room invite you get will be shown here.';
    static GREEN_ROOM_MSG = 'GREEN ROOM BEGINS AT';
    static GREEN_ROOM_COUNTDOWN_MSG = 'Countdown to Live';
    static GREEN_ROOM_STATUS = {
        UPCOMING: 'greenRoomUpcoming',
        STARTED: 'greenRoomStarted',
        LIVE: 'live',
    };

    static ROOM_STATUS = {
        EXPIRED: 'expired',
        LIVE: 'live',
    };

    static FALLBACK_CONST = {
        abbr: 'EDT',
        id: '887a9952-23db-4817-b2fb-e4bc963fa4d2',
        isdst: true,
        offset: -4,
        text: '(UTC-05:00) Eastern Time (US Canada)',
        utc: [
            'America/Detroit',
            'America/Havana',
            'America/Indiana/Petersburg',
            'America/Indiana/Vincennes',
            'America/Indiana/Winamac',
            'America/Iqaluit',
            'America/Kentucky/Monticello',
            'America/Louisville',
            'America/Montreal',
            'America/Nassau',
            'America/New_York',
            'America/Nipigon',
            'America/Pangnirtung',
            'America/Port-au-Prince',
            'America/Thunder_Bay',
            'America/Toronto',
            'EST5EDT',
        ],
        value: 'Eastern Standard Time',
    };

    static TIME_ZONES = [
        {
            abbr: 'DST',
            id: '19ff7196-c72d-415d-8fa9-a39f6dc3afde',
            isdst: false,
            offset: -12,
            text: '(UTC-12:00) International Date Line West',
            utc: ['Etc/GMT+12'],
            value: 'Dateline Standard Time',
        },
        {
            abbr: 'U',
            id: '2e79ec24-9127-4c45-b666-841956aae7c1',
            isdst: false,
            offset: -11,
            text: '(UTC-11:00) Coordinated Universal Time-11',
            utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago'],
            value: 'UTC-11',
        },
        {
            abbr: 'HST',
            id: '21cfd583-2ca4-4ec9-adff-532e4cd6b612',
            isdst: false,
            offset: -10,
            text: '(UTC-10:00) Hawaii',
            utc: ['Etc/GMT+10', 'Pacific/Honolulu', 'Pacific/Johnston', 'Pacific/Rarotonga', 'Pacific/Tahiti'],
            value: 'Hawaiian Standard Time',
        },
        {
            abbr: 'AKDT',
            id: 'ee820d65-cc6a-4314-8807-8634c3a8bc87',
            isdst: true,
            offset: -8,
            text: '(UTC-09:00) Alaska',
            utc: ['America/Anchorage', 'America/Juneau', 'America/Nome', 'America/Sitka', 'America/Yakutat'],
            value: 'Alaskan Standard Time',
        },
        {
            abbr: 'PDT',
            id: '3cf5a6fc-50ec-42e4-b501-da4ce8b51323',
            isdst: true,
            offset: -7,
            text: '(UTC-08:00) Baja California',
            utc: ['America/Santa_Isabel'],
            value: 'Pacific Standard Time (Mexico)',
        },
        {
            abbr: 'PDT',
            id: '30ca2569-4326-438c-ac3a-72cb8e2d734e',
            isdst: true,
            offset: -7,
            text: '(UTC-07:00) Pacific Time (US & Canada)',
            utc: [
                'America/Los_Angeles',
                'America/Tijuana',
                'America/Vancouver',
                'America/Whitehorse',
                'America/Dawson',
            ],
            value: 'Pacific Daylight Time',
        },
        {
            abbr: 'PST',
            id: 'efcfe798-e41f-478c-ab89-4090c1156989',
            isdst: false,
            offset: -8,
            text: '(UTC-08:00) Pacific Time (US & Canada)',
            utc: [
                'America/Los_Angeles',
                'America/Tijuana',
                'America/Vancouver',
                'America/Whitehorse',
                'PST8PDT',
                'America/Dawson',
            ],
            value: 'Pacific Standard Time',
        },
        {
            abbr: 'UMST',
            id: '99ee98f0-fadc-45d8-8afc-05c64c7a5c84',
            isdst: false,
            offset: -7,
            text: '(UTC-07:00) Arizona',
            utc: ['America/Creston', 'America/Dawson_Creek', 'America/Hermosillo', 'America/Phoenix', 'Etc/GMT+7'],
            value: 'US Mountain Standard Time',
        },
        {
            abbr: 'MDT',
            id: 'a8ec6a69-8db8-493e-9e54-b7861f8d3914',
            isdst: true,
            offset: -6,
            text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
            utc: ['America/Chihuahua', 'America/Mazatlan'],
            value: 'Mountain Standard Time (Mexico)',
        },
        {
            abbr: 'MDT',
            id: '0f8c463e-2815-4601-9ae7-2bc67031d1b6',
            isdst: true,
            offset: -6,
            text: '(UTC-07:00) Mountain Time (US & Canada)',
            utc: [
                'America/Boise',
                'America/Cambridge_Bay',
                'America/Denver',
                'America/Edmonton',
                'America/Inuvik',
                'America/Ojinaga',
                'America/Yellowknife',
                'MST7MDT',
            ],
            value: 'Mountain Standard Time',
        },
        {
            abbr: 'CAST',
            id: 'd4af00b2-cacc-425a-beeb-8a272d6d2afe',
            isdst: false,
            offset: -6,
            text: '(UTC-06:00) Central America',
            utc: [
                'America/Belize',
                'America/Costa_Rica',
                'America/El_Salvador',
                'America/Guatemala',
                'America/Managua',
                'America/Tegucigalpa',
                'Etc/GMT+6',
                'Pacific/Galapagos',
            ],
            value: 'Central America Standard Time',
        },
        {
            abbr: 'CDT',
            id: 'd0263ad4-e788-40a8-850d-82bff24708fd',
            isdst: true,
            offset: -5,
            text: '(UTC-06:00) Central Time (US & Canada)',
            utc: [
                'America/Chicago',
                'America/Indiana/Knox',
                'America/Indiana/Tell_City',
                'America/Matamoros',
                'America/Menominee',
                'America/North_Dakota/Beulah',
                'America/North_Dakota/Center',
                'America/North_Dakota/New_Salem',
                'America/Rainy_River',
                'America/Rankin_Inlet',
                'America/Resolute',
                'America/Winnipeg',
                'CST6CDT',
            ],
            value: 'Central Standard Time',
        },
        {
            abbr: 'CDT',
            id: 'ad0f7af8-f724-4960-9434-a01d9f08654a',
            isdst: true,
            offset: -5,
            text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
            utc: [
                'America/Bahia_Banderas',
                'America/Cancun',
                'America/Merida',
                'America/Mexico_City',
                'America/Monterrey',
            ],
            value: 'Central Standard Time (Mexico)',
        },
        {
            abbr: 'CCST',
            id: '13f08274-9f70-43a8-a3ae-80aaae461f34',
            isdst: false,
            offset: -6,
            text: '(UTC-06:00) Saskatchewan',
            utc: ['America/Regina', 'America/Swift_Current'],
            value: 'Canada Central Standard Time',
        },
        {
            abbr: 'SPST',
            id: '7afb749d-a3fe-4b2b-877b-08974ea735b6',
            isdst: false,
            offset: -5,
            text: '(UTC-05:00) Bogota, Lima, Quito',
            utc: [
                'America/Bogota',
                'America/Cayman',
                'America/Coral_Harbour',
                'America/Eirunepe',
                'America/Guayaquil',
                'America/Jamaica',
                'America/Lima',
                'America/Panama',
                'America/Rio_Branco',
                'Etc/GMT+5',
            ],
            value: 'SA Pacific Standard Time',
        },
        {
            abbr: 'EDT',
            id: '887a9952-23db-4817-b2fb-e4bc963fa4d2',
            isdst: true,
            offset: -4,
            text: '(UTC-04:00) Eastern Daylight Time (US Canada)',
            utc: [
                'America/Detroit',
                'America/Havana',
                'America/Indiana/Petersburg',
                'America/Indiana/Vincennes',
                'America/Indiana/Winamac',
                'America/Iqaluit',
                'America/Kentucky/Monticello',
                'America/Louisville',
                'America/Montreal',
                'America/Nassau',
                'America/New_York',
                'America/Nipigon',
                'America/Pangnirtung',
                'America/Port-au-Prince',
                'America/Thunder_Bay',
                'America/Toronto',
                'EST5EDT',
            ],
            value: 'Eastern Standard Time',
        },
        {
            abbr: 'EST',
            id: 'c014ea4c-37b3-4d78-b234-4a029212e41f',
            isdst: false,
            offset: -5,
            text: '(UTC-05:00) Eastern Standard Time (US Canada)',
            utc: [
                'America/Detroit',
                'America/Havana',
                'America/Indiana/Petersburg',
                'America/Indiana/Vincennes',
                'America/Indiana/Winamac',
                'America/Iqaluit',
                'America/Kentucky/Monticello',
                'America/Louisville',
                'America/Montreal',
                'America/Nassau',
                'America/New_York',
                'America/Nipigon',
                'America/Pangnirtung',
                'America/Port-au-Prince',
                'America/Thunder_Bay',
                'America/Toronto',
                'EST5EDT',
            ],
            value: 'Eastern Standard Time',
        },
        {
            abbr: 'UEDT',
            id: '66c554c5-d9b0-4ec9-8586-3ca7bc6454ad',
            isdst: true,
            offset: -4,
            text: '(UTC-05:00) Indiana (East)',
            utc: ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis'],
            value: 'US Eastern Standard Time',
        },
        {
            abbr: 'VST',
            id: 'f0c6a087-01ad-4a08-b687-98d6ea06f2f6',
            isdst: false,
            offset: -4.5,
            text: '(UTC-04:30) Caracas',
            utc: ['America/Caracas'],
            value: 'Venezuela Standard Time',
        },
        {
            abbr: 'PYT',
            id: '1eca2ad4-6391-4735-97fd-bf401341c344',
            isdst: false,
            offset: -4,
            text: '(UTC-04:00) Asuncion',
            utc: ['America/Asuncion'],
            value: 'Paraguay Standard Time',
        },
        {
            abbr: 'ADT',
            id: '7d8e8f8b-16b8-4931-afce-7c8cb354e45d',
            isdst: true,
            offset: -3,
            text: '(UTC-04:00) Atlantic Time (Canada)',
            utc: [
                'America/Glace_Bay',
                'America/Goose_Bay',
                'America/Halifax',
                'America/Moncton',
                'America/Thule',
                'Atlantic/Bermuda',
            ],
            value: 'Atlantic Standard Time',
        },
        {
            abbr: 'CBST',
            id: 'eb96e0cb-53f4-4123-9e03-0167e929c6e2',
            isdst: false,
            offset: -4,
            text: '(UTC-04:00) Cuiaba',
            utc: ['America/Campo_Grande', 'America/Cuiaba'],
            value: 'Central Brazilian Standard Time',
        },
        {
            abbr: 'SWST',
            id: '20818cc8-14c4-4221-8a7e-5e9214e4b459',
            isdst: false,
            offset: -4,
            text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
            utc: [
                'America/Anguilla',
                'America/Antigua',
                'America/Aruba',
                'America/Barbados',
                'America/Blanc-Sablon',
                'America/Boa_Vista',
                'America/Curacao',
                'America/Dominica',
                'America/Grand_Turk',
                'America/Grenada',
                'America/Guadeloupe',
                'America/Guyana',
                'America/Kralendijk',
                'America/La_Paz',
                'America/Lower_Princes',
                'America/Manaus',
                'America/Marigot',
                'America/Martinique',
                'America/Montserrat',
                'America/Port_of_Spain',
                'America/Porto_Velho',
                'America/Puerto_Rico',
                'America/Santo_Domingo',
                'America/St_Barthelemy',
                'America/St_Kitts',
                'America/St_Lucia',
                'America/St_Thomas',
                'America/St_Vincent',
                'America/Tortola',
                'Etc/GMT+4',
            ],
            value: 'SA Western Standard Time',
        },
        {
            abbr: 'PSST',
            id: '392268b5-4e40-4ccc-a007-a13ca1b3369d',
            isdst: false,
            offset: -4,
            text: '(UTC-04:00) Santiago',
            utc: ['America/Santiago', 'Antarctica/Palmer'],
            value: 'Pacific SA Standard Time',
        },
        {
            abbr: 'NDT',
            id: '042804cc-6cb6-46c4-9ef7-b79db150d4c5',
            isdst: true,
            offset: -2.5,
            text: '(UTC-03:30) Newfoundland',
            utc: ['America/St_Johns'],
            value: 'Newfoundland Standard Time',
        },
        {
            abbr: 'ESAST',
            id: '714707c1-7412-480e-84bb-8ce3c2f7688f',
            isdst: false,
            offset: -3,
            text: '(UTC-03:00) Brasilia',
            utc: ['America/Sao_Paulo'],
            value: 'E. South America Standard Time',
        },
        {
            abbr: 'AST',
            id: '7093dcdb-216b-4346-8ece-b404a7255a60',
            isdst: false,
            offset: -3,
            text: '(UTC-03:00) Buenos Aires',
            utc: [
                'America/Argentina/La_Rioja',
                'America/Argentina/Rio_Gallegos',
                'America/Argentina/Salta',
                'America/Argentina/San_Juan',
                'America/Argentina/San_Luis',
                'America/Argentina/Tucuman',
                'America/Argentina/Ushuaia',
                'America/Buenos_Aires',
                'America/Catamarca',
                'America/Cordoba',
                'America/Jujuy',
                'America/Mendoza',
            ],
            value: 'Argentina Standard Time',
        },
        {
            abbr: 'SEST',
            id: '6209fdec-7e7d-4378-b933-d700a2b5a140',
            isdst: false,
            offset: -3,
            text: '(UTC-03:00) Cayenne, Fortaleza',
            utc: [
                'America/Araguaina',
                'America/Belem',
                'America/Cayenne',
                'America/Fortaleza',
                'America/Maceio',
                'America/Paramaribo',
                'America/Recife',
                'America/Santarem',
                'Antarctica/Rothera',
                'Atlantic/Stanley',
                'Etc/GMT+3',
            ],
            value: 'SA Eastern Standard Time',
        },
        {
            abbr: 'GDT',
            id: '1224accb-7186-40cb-853b-8c3d95f029f6',
            isdst: true,
            offset: -3,
            text: '(UTC-03:00) Greenland',
            utc: ['America/Godthab'],
            value: 'Greenland Standard Time',
        },
        {
            abbr: 'MST',
            id: 'db9ca537-7427-4315-8a6d-afaa671d23db',
            isdst: false,
            offset: -3,
            text: '(UTC-03:00) Montevideo',
            utc: ['America/Montevideo'],
            value: 'Montevideo Standard Time',
        },
        {
            abbr: 'BST',
            id: '01df692d-ed35-4e99-aec8-d4792d04a993',
            isdst: false,
            offset: -3,
            text: '(UTC-03:00) Salvador',
            utc: ['America/Bahia'],
            value: 'Bahia Standard Time',
        },
        {
            abbr: 'U',
            id: '659a1087-e8e6-49db-9aeb-31857918cfca',
            isdst: false,
            offset: -2,
            text: '(UTC-02:00) Coordinated Universal Time-02',
            utc: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
            value: 'UTC-02',
        },
        {
            abbr: 'MDT',
            id: '8a649207-eae3-4692-8d65-f78a344cb857',
            isdst: true,
            offset: -1,
            text: '(UTC-02:00) Mid-Atlantic - Old',
            utc: [],
            value: 'Mid-Atlantic Standard Time',
        },
        {
            abbr: 'ADT',
            id: '41fe2309-0abf-4024-b989-6d7ab5b10b42',
            isdst: true,
            offset: 0,
            text: '(UTC-01:00) Azores',
            utc: ['America/Scoresbysund', 'Atlantic/Azores'],
            value: 'Azores Standard Time',
        },
        {
            abbr: 'CVST',
            id: 'fad7baea-5891-4eea-beab-205d555e579f',
            isdst: false,
            offset: -1,
            text: '(UTC-01:00) Cape Verde Is.',
            utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
            value: 'Cape Verde Standard Time',
        },
        {
            abbr: 'MDT',
            id: '8e819f83-2518-41b3-bfc7-8af28740896b',
            isdst: true,
            offset: 1,
            text: '(UTC) Casablanca',
            utc: ['Africa/Casablanca', 'Africa/El_Aaiun'],
            value: 'Morocco Standard Time',
        },
        {
            abbr: 'UTC',
            id: '69e626bd-af6a-48d0-98a8-77fd40896f85',
            isdst: false,
            offset: 0,
            text: '(UTC) Coordinated Universal Time',
            utc: ['America/Danmarkshavn', 'Etc/GMT'],
            value: 'UTC',
        },
        {
            abbr: 'GMT',
            id: '079897d3-54ba-4004-9250-1a443d326316',
            isdst: false,
            offset: 0,
            text: '(UTC) Edinburgh, London',
            utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
            value: 'GMT Standard Time',
        },
        {
            abbr: 'BST',
            id: 'a3d01fd9-db85-470f-90ce-e14b66c9336f',
            isdst: true,
            offset: 1,
            text: '(UTC+01:00) Edinburgh, London',
            utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
            value: 'British Summer Time',
        },
        {
            abbr: 'GDT',
            id: '8e558ec2-d3a0-4f54-ad4b-053627b5d353',
            isdst: true,
            offset: 1,
            text: '(UTC) Dublin, Lisbon',
            utc: ['Atlantic/Canary', 'Atlantic/Faeroe', 'Atlantic/Madeira', 'Europe/Dublin', 'Europe/Lisbon'],
            value: 'GMT Standard Time',
        },
        {
            abbr: 'GST',
            id: '8893bb63-9dfa-4325-adcf-622d588721a6',
            isdst: false,
            offset: 0,
            text: '(UTC) Monrovia, Reykjavik',
            utc: [
                'Africa/Abidjan',
                'Africa/Accra',
                'Africa/Bamako',
                'Africa/Banjul',
                'Africa/Bissau',
                'Africa/Conakry',
                'Africa/Dakar',
                'Africa/Freetown',
                'Africa/Lome',
                'Africa/Monrovia',
                'Africa/Nouakchott',
                'Africa/Ouagadougou',
                'Africa/Sao_Tome',
                'Atlantic/Reykjavik',
                'Atlantic/St_Helena',
            ],
            value: 'Greenwich Standard Time',
        },
        {
            abbr: 'WEDT',
            id: '560494ad-6a69-4386-8619-3214d5a8c541',
            isdst: true,
            offset: 2,
            text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
            utc: [
                'Arctic/Longyearbyen',
                'Europe/Amsterdam',
                'Europe/Andorra',
                'Europe/Berlin',
                'Europe/Busingen',
                'Europe/Gibraltar',
                'Europe/Luxembourg',
                'Europe/Malta',
                'Europe/Monaco',
                'Europe/Oslo',
                'Europe/Rome',
                'Europe/San_Marino',
                'Europe/Stockholm',
                'Europe/Vaduz',
                'Europe/Vatican',
                'Europe/Vienna',
                'Europe/Zurich',
            ],
            value: 'W. Europe Standard Time',
        },
        {
            abbr: 'CEDT',
            id: 'c3c6b2ee-e715-42ad-89ea-c0b6ecfb76cb',
            isdst: true,
            offset: 2,
            text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
            utc: [
                'Europe/Belgrade',
                'Europe/Bratislava',
                'Europe/Budapest',
                'Europe/Ljubljana',
                'Europe/Podgorica',
                'Europe/Prague',
                'Europe/Tirane',
            ],
            value: 'Central Europe Standard Time',
        },
        {
            abbr: 'RDT',
            id: '99056926-0862-4b2b-8137-dccf403e9980',
            isdst: true,
            offset: 2,
            text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
            utc: ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Europe/Paris'],
            value: 'Romance Standard Time',
        },
        {
            abbr: 'CEDT',
            id: 'ff8b9739-5e09-481f-afad-14bf2a65352b',
            isdst: true,
            offset: 2,
            text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
            utc: ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb'],
            value: 'Central European Standard Time',
        },
        {
            abbr: 'WCAST',
            id: '1e59b5e4-63a2-4d4e-b916-40fa3e3f66a6',
            isdst: false,
            offset: 1,
            text: '(UTC+01:00) West Central Africa',
            utc: [
                'Africa/Algiers',
                'Africa/Bangui',
                'Africa/Brazzaville',
                'Africa/Douala',
                'Africa/Kinshasa',
                'Africa/Lagos',
                'Africa/Libreville',
                'Africa/Luanda',
                'Africa/Malabo',
                'Africa/Ndjamena',
                'Africa/Niamey',
                'Africa/Porto-Novo',
                'Africa/Tunis',
                'Etc/GMT-1',
            ],
            value: 'W. Central Africa Standard Time',
        },
        {
            abbr: 'NST',
            id: '1a08eb58-8e50-452d-bcd4-19ea4093e7b7',
            isdst: false,
            offset: 1,
            text: '(UTC+01:00) Windhoek',
            utc: ['Africa/Windhoek'],
            value: 'Namibia Standard Time',
        },
        {
            abbr: 'GDT',
            id: 'fbcdc98c-9a8a-4ea5-9bde-b55f671ce2ec',
            isdst: true,
            offset: 3,
            text: '(UTC+02:00) Athens, Bucharest',
            utc: ['Asia/Nicosia', 'Europe/Athens', 'Europe/Bucharest', 'Europe/Chisinau'],
            value: 'GTB Standard Time',
        },
        {
            abbr: 'MEDT',
            id: '5181ec18-f9ba-4fd3-99c0-b660101bae11',
            isdst: true,
            offset: 3,
            text: '(UTC+02:00) Beirut',
            utc: ['Asia/Beirut'],
            value: 'Middle East Standard Time',
        },
        {
            abbr: 'EST',
            id: '73e24662-8e5b-4581-8086-a71780dc782c',
            isdst: false,
            offset: 2,
            text: '(UTC+02:00) Cairo',
            utc: ['Africa/Cairo'],
            value: 'Egypt Standard Time',
        },
        {
            abbr: 'SDT',
            id: 'f9e821d0-82e0-4369-95c2-8ba65becbf92',
            isdst: true,
            offset: 3,
            text: '(UTC+02:00) Damascus',
            utc: ['Asia/Damascus'],
            value: 'Syria Standard Time',
        },
        {
            abbr: 'EEDT',
            id: '9cef3646-94e3-40ca-a70b-eca8e0cb0a6f',
            isdst: true,
            offset: 3,
            text: '(UTC+02:00) E. Europe',
            utc: [
                'Asia/Nicosia',
                'Europe/Athens',
                'Europe/Bucharest',
                'Europe/Chisinau',
                'Europe/Helsinki',
                'Europe/Kiev',
                'Europe/Mariehamn',
                'Europe/Nicosia',
                'Europe/Riga',
                'Europe/Sofia',
                'Europe/Tallinn',
                'Europe/Uzhgorod',
                'Europe/Vilnius',
                'Europe/Zaporozhye',
            ],
            value: 'E. Europe Standard Time',
        },
        {
            abbr: 'SAST',
            id: '9560e26b-e68b-4530-bed9-66a55e16eb9f',
            isdst: false,
            offset: 2,
            text: '(UTC+02:00) Harare, Pretoria',
            utc: [
                'Africa/Blantyre',
                'Africa/Bujumbura',
                'Africa/Gaborone',
                'Africa/Harare',
                'Africa/Johannesburg',
                'Africa/Kigali',
                'Africa/Lubumbashi',
                'Africa/Lusaka',
                'Africa/Maputo',
                'Africa/Maseru',
                'Africa/Mbabane',
                'Etc/GMT-2',
            ],
            value: 'South Africa Standard Time',
        },
        {
            abbr: 'FDT',
            id: '23b7aa31-43a0-439d-8bfd-f39b9383bd10',
            isdst: true,
            offset: 3,
            text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
            utc: [
                'Europe/Helsinki',
                'Europe/Kiev',
                'Europe/Mariehamn',
                'Europe/Riga',
                'Europe/Sofia',
                'Europe/Tallinn',
                'Europe/Uzhgorod',
                'Europe/Vilnius',
                'Europe/Zaporozhye',
            ],
            value: 'FLE Standard Time',
        },
        {
            abbr: 'TDT',
            id: '3a0452e6-7faf-4766-a6bf-8f0e61ff78dd',
            isdst: false,
            offset: 3,
            text: '(UTC+03:00) Istanbul',
            utc: ['Europe/Istanbul'],
            value: 'Turkey Standard Time',
        },
        {
            abbr: 'JDT',
            id: '9b76eaa7-1228-43fd-97d9-abd130a5b55d',
            isdst: true,
            offset: 3,
            text: '(UTC+02:00) Jerusalem',
            utc: ['Asia/Jerusalem'],
            value: 'Israel Standard Time',
        },
        {
            abbr: 'LST',
            id: 'eb1c3755-f09d-492a-91f7-c531577bcab7',
            isdst: false,
            offset: 2,
            text: '(UTC+02:00) Tripoli',
            utc: ['Africa/Tripoli'],
            value: 'Libya Standard Time',
        },
        {
            abbr: 'JST',
            id: '1c418f47-6a17-42dd-b3e5-51c0e3586fca',
            isdst: false,
            offset: 3,
            text: '(UTC+03:00) Amman',
            utc: ['Asia/Amman'],
            value: 'Jordan Standard Time',
        },
        {
            abbr: 'AST',
            id: 'dee47802-b17c-45e2-af09-49c8fd7b043e',
            isdst: false,
            offset: 3,
            text: '(UTC+03:00) Baghdad',
            utc: ['Asia/Baghdad'],
            value: 'Arabic Standard Time',
        },
        {
            abbr: 'KST',
            id: '441e1bc6-0790-4918-9b2e-7d5862f3fe11',
            isdst: false,
            offset: 3,
            text: '(UTC+02:00) Kaliningrad',
            utc: ['Europe/Kaliningrad'],
            value: 'Kaliningrad Standard Time',
        },
        {
            abbr: 'AST',
            id: 'a433ce41-bb16-44ce-9698-609a3309288d',
            isdst: false,
            offset: 3,
            text: '(UTC+03:00) Kuwait, Riyadh',
            utc: ['Asia/Aden', 'Asia/Bahrain', 'Asia/Kuwait', 'Asia/Qatar', 'Asia/Riyadh'],
            value: 'Arab Standard Time',
        },
        {
            abbr: 'EAST',
            id: '0d519b1b-bbc1-47d0-8f7b-282b11f22749',
            isdst: false,
            offset: 3,
            text: '(UTC+03:00) Nairobi',
            utc: [
                'Africa/Addis_Ababa',
                'Africa/Asmera',
                'Africa/Dar_es_Salaam',
                'Africa/Djibouti',
                'Africa/Juba',
                'Africa/Kampala',
                'Africa/Khartoum',
                'Africa/Mogadishu',
                'Africa/Nairobi',
                'Antarctica/Syowa',
                'Etc/GMT-3',
                'Indian/Antananarivo',
                'Indian/Comoro',
                'Indian/Mayotte',
            ],
            value: 'E. Africa Standard Time',
        },
        {
            abbr: 'MSK',
            id: 'da1f42be-b4f7-4ef0-b26f-4a78ac1d09de',
            isdst: false,
            offset: 3,
            text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
            utc: ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd', 'Europe/Minsk'],
            value: 'Moscow Standard Time',
        },
        {
            abbr: 'SAMT',
            id: 'ba60735a-1287-445c-98dc-4e7287b9af2f',
            isdst: false,
            offset: 4,
            text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
            utc: ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk'],
            value: 'Samara Time',
        },
        {
            abbr: 'IDT',
            id: '3be94ed0-23c7-4b6d-ad4c-8834839a6027',
            isdst: true,
            offset: 4.5,
            text: '(UTC+03:30) Tehran',
            utc: ['Asia/Tehran'],
            value: 'Iran Standard Time',
        },
        {
            abbr: 'AST',
            id: 'a1e434ab-d3cb-45a2-ae58-ca1a96b2ed32',
            isdst: false,
            offset: 4,
            text: '(UTC+04:00) Abu Dhabi, Muscat',
            utc: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
            value: 'Arabian Standard Time',
        },
        {
            abbr: 'ADT',
            id: '9cc4b417-d96a-48e7-933f-d0fae2448860',
            isdst: true,
            offset: 5,
            text: '(UTC+04:00) Baku',
            utc: ['Asia/Baku'],
            value: 'Azerbaijan Standard Time',
        },
        {
            abbr: 'MST',
            id: '36ac5be4-5835-426c-b27b-a171847e353a',
            isdst: false,
            offset: 4,
            text: '(UTC+04:00) Port Louis',
            utc: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
            value: 'Mauritius Standard Time',
        },
        {
            abbr: 'GET',
            id: '7435aab6-d786-4f86-838a-dfbf5e8f4204',
            isdst: false,
            offset: 4,
            text: '(UTC+04:00) Tbilisi',
            utc: ['Asia/Tbilisi'],
            value: 'Georgian Standard Time',
        },
        {
            abbr: 'CST',
            id: 'df11f84b-56a3-4cd7-80da-fe742650ba5f',
            isdst: false,
            offset: 4,
            text: '(UTC+04:00) Yerevan',
            utc: ['Asia/Yerevan'],
            value: 'Caucasus Standard Time',
        },
        {
            abbr: 'AST',
            id: '633dead6-6939-4b73-b3ca-ee00c818e0ff',
            isdst: false,
            offset: 4.5,
            text: '(UTC+04:30) Kabul',
            utc: ['Asia/Kabul'],
            value: 'Afghanistan Standard Time',
        },
        {
            abbr: 'WAST',
            id: 'bee8fc66-f4ed-4d74-91c0-6dc79f03c691',
            isdst: false,
            offset: 5,
            text: '(UTC+05:00) Ashgabat, Tashkent',
            utc: [
                'Antarctica/Mawson',
                'Asia/Aqtau',
                'Asia/Aqtobe',
                'Asia/Ashgabat',
                'Asia/Dushanbe',
                'Asia/Oral',
                'Asia/Samarkand',
                'Asia/Tashkent',
                'Etc/GMT-5',
                'Indian/Kerguelen',
                'Indian/Maldives',
            ],
            value: 'West Asia Standard Time',
        },
        {
            abbr: 'YEKT',
            id: '9e76c94c-a7a7-45cd-ba7b-1f7faf6981b6',
            isdst: false,
            offset: 5,
            text: '(UTC+05:00) Yekaterinburg',
            utc: ['Asia/Yekaterinburg'],
            value: 'Yekaterinburg Time',
        },
        {
            abbr: 'PKT',
            id: '73f2ef68-ec50-480f-bd3a-b9d3b77466b9',
            isdst: false,
            offset: 5,
            text: '(UTC+05:00) Islamabad, Karachi',
            utc: ['Asia/Karachi'],
            value: 'Pakistan Standard Time',
        },
        {
            abbr: 'IST',
            id: 'f2ca3a8c-54d8-4174-910d-20588c349f4b',
            isdst: false,
            offset: 5.5,
            text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
            utc: ['Asia/Kolkata'],
            value: 'India Standard Time',
        },
        {
            abbr: 'SLST',
            id: 'c97344f8-5bca-4a31-b6a0-140c1af2f346',
            isdst: false,
            offset: 5.5,
            text: '(UTC+05:30) Sri Jayawardenepura',
            utc: ['Asia/Colombo'],
            value: 'Sri Lanka Standard Time',
        },
        {
            abbr: 'NST',
            id: '7d321fde-2a56-473e-ac79-5df23ce71e91',
            isdst: false,
            offset: 5.75,
            text: '(UTC+05:45) Kathmandu',
            utc: ['Asia/Kathmandu'],
            value: 'Nepal Standard Time',
        },
        {
            abbr: 'CAST',
            id: '4713cf98-e8e7-42fe-8d33-6495c7a9190d',
            isdst: false,
            offset: 6,
            text: '(UTC+06:00) Nur-Sultan (Astana)',
            utc: [
                'Antarctica/Vostok',
                'Asia/Almaty',
                'Asia/Bishkek',
                'Asia/Qyzylorda',
                'Asia/Urumqi',
                'Etc/GMT-6',
                'Indian/Chagos',
            ],
            value: 'Central Asia Standard Time',
        },
        {
            abbr: 'BST',
            id: 'a4f5f0b6-997d-432f-8cf0-58d8ce8b5379',
            isdst: false,
            offset: 6,
            text: '(UTC+06:00) Dhaka',
            utc: ['Asia/Dhaka', 'Asia/Thimphu'],
            value: 'Bangladesh Standard Time',
        },
        {
            abbr: 'MST',
            id: '98f3a0f6-9a4e-41fc-a3fd-7f0ea3871c82',
            isdst: false,
            offset: 6.5,
            text: '(UTC+06:30) Yangon (Rangoon)',
            utc: ['Asia/Rangoon', 'Indian/Cocos'],
            value: 'Myanmar Standard Time',
        },
        {
            abbr: 'SAST',
            id: 'c190fb8c-0e84-4550-9ee7-ac1d879e227d',
            isdst: false,
            offset: 7,
            text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
            utc: [
                'Antarctica/Davis',
                'Asia/Bangkok',
                'Asia/Hovd',
                'Asia/Jakarta',
                'Asia/Phnom_Penh',
                'Asia/Pontianak',
                'Asia/Saigon',
                'Asia/Vientiane',
                'Etc/GMT-7',
                'Indian/Christmas',
            ],
            value: 'SE Asia Standard Time',
        },
        {
            abbr: 'NCAST',
            id: 'df6a662e-6c59-4c3a-a599-b3b2b0dd475a',
            isdst: false,
            offset: 7,
            text: '(UTC+07:00) Novosibirsk',
            utc: ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk'],
            value: 'N. Central Asia Standard Time',
        },
        {
            abbr: 'CST',
            id: '7486065f-4c15-4b0a-b66c-a5b16314a3ef',
            isdst: false,
            offset: 8,
            text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
            utc: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
            value: 'China Standard Time',
        },
        {
            abbr: 'NAST',
            id: '0baa5852-5483-404c-a39e-f5a043f38455',
            isdst: false,
            offset: 8,
            text: '(UTC+08:00) Krasnoyarsk',
            utc: ['Asia/Krasnoyarsk'],
            value: 'North Asia Standard Time',
        },
        {
            abbr: 'MPST',
            id: 'b55e5d6f-bf4e-44f4-9fe8-829fbb51f04b',
            isdst: false,
            offset: 8,
            text: '(UTC+08:00) Kuala Lumpur, Singapore',
            utc: [
                'Asia/Brunei',
                'Asia/Kuala_Lumpur',
                'Asia/Kuching',
                'Asia/Makassar',
                'Asia/Manila',
                'Asia/Singapore',
                'Etc/GMT-8',
            ],
            value: 'Singapore Standard Time',
        },
        {
            abbr: 'WAST',
            id: '07cecf72-042a-4854-abad-eb0a5185c11a',
            isdst: false,
            offset: 8,
            text: '(UTC+08:00) Perth',
            utc: ['Antarctica/Casey', 'Australia/Perth'],
            value: 'W. Australia Standard Time',
        },
        {
            abbr: 'TST',
            id: '08b40775-23e1-4e1f-b8c0-f05f7edaf103',
            isdst: false,
            offset: 8,
            text: '(UTC+08:00) Taipei',
            utc: ['Asia/Taipei'],
            value: 'Taipei Standard Time',
        },
        {
            abbr: 'UST',
            id: '6b6f8e86-0c29-47ec-8525-361eab0ea3a7',
            isdst: false,
            offset: 8,
            text: '(UTC+08:00) Ulaanbaatar',
            utc: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
            value: 'Ulaanbaatar Standard Time',
        },
        {
            abbr: 'NAEST',
            id: 'af66c456-e81d-4e93-95c3-38dbab328a54',
            isdst: false,
            offset: 8,
            text: '(UTC+08:00) Irkutsk',
            utc: ['Asia/Irkutsk'],
            value: 'North Asia East Standard Time',
        },
        {
            abbr: 'JST',
            id: 'c7cc45bb-7b03-4dd7-afd7-7a7861886852',
            isdst: false,
            offset: 9,
            text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
            utc: ['Asia/Dili', 'Asia/Jayapura', 'Asia/Tokyo', 'Etc/GMT-9', 'Pacific/Palau'],
            value: 'Japan Standard Time',
        },
        {
            abbr: 'KST',
            id: 'f59041f7-061c-4b85-9400-6883e1d283bc',
            isdst: false,
            offset: 9,
            text: '(UTC+09:00) Seoul',
            utc: ['Asia/Pyongyang', 'Asia/Seoul'],
            value: 'Korea Standard Time',
        },
        {
            abbr: 'CAST',
            id: '63b9f004-5736-4a1f-adb8-12fe798a2f3b',
            isdst: false,
            offset: 9.5,
            text: '(UTC+09:30) Adelaide',
            utc: ['Australia/Adelaide', 'Australia/Broken_Hill'],
            value: 'Cen. Australia Standard Time',
        },
        {
            abbr: 'ACST',
            id: 'b084fd87-151d-4f30-8052-ba08ce1cc2e5',
            isdst: false,
            offset: 9.5,
            text: '(UTC+09:30) Darwin',
            utc: ['Australia/Darwin'],
            value: 'AUS Central Standard Time',
        },
        {
            abbr: 'EAST',
            id: '0fe07add-9c04-4afa-9c34-3449065f3086',
            isdst: false,
            offset: 10,
            text: '(UTC+10:00) Brisbane',
            utc: ['Australia/Brisbane', 'Australia/Lindeman'],
            value: 'E. Australia Standard Time',
        },
        {
            abbr: 'AEST',
            id: 'e11d588f-5f58-48c2-beda-7ce980de169a',
            isdst: false,
            offset: 10,
            text: '(UTC+10:00) Canberra, Melbourne, Sydney',
            utc: ['Australia/Melbourne', 'Australia/Sydney'],
            value: 'AUS Eastern Standard Time',
        },
        {
            abbr: 'WPST',
            id: '98020c79-b76a-4cc4-b297-3d240eb74632',
            isdst: false,
            offset: 10,
            text: '(UTC+10:00) Guam, Port Moresby',
            utc: [
                'Antarctica/DumontDUrville',
                'Etc/GMT-10',
                'Pacific/Guam',
                'Pacific/Port_Moresby',
                'Pacific/Saipan',
                'Pacific/Truk',
            ],
            value: 'West Pacific Standard Time',
        },
        {
            abbr: 'TST',
            id: 'e2d40d23-c8ac-448d-a0af-54a27028520c',
            isdst: false,
            offset: 10,
            text: '(UTC+10:00) Hobart',
            utc: ['Australia/Currie', 'Australia/Hobart'],
            value: 'Tasmania Standard Time',
        },
        {
            abbr: 'YST',
            id: '9ccac929-3a13-4678-a813-fd86633e8217',
            isdst: false,
            offset: 9,
            text: '(UTC+09:00) Yakutsk',
            utc: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
            value: 'Yakutsk Standard Time',
        },
        {
            abbr: 'CPST',
            id: 'df688142-e0dd-4e92-9c62-22b75dc1fa2a',
            isdst: false,
            offset: 11,
            text: '(UTC+11:00) Solomon Is., New Caledonia',
            utc: [
                'Antarctica/Macquarie',
                'Etc/GMT-11',
                'Pacific/Efate',
                'Pacific/Guadalcanal',
                'Pacific/Kosrae',
                'Pacific/Noumea',
                'Pacific/Ponape',
            ],
            value: 'Central Pacific Standard Time',
        },
        {
            abbr: 'VST',
            id: 'ed6858d0-03e4-440a-b9eb-e14600809406',
            isdst: false,
            offset: 11,
            text: '(UTC+11:00) Vladivostok',
            utc: ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok'],
            value: 'Vladivostok Standard Time',
        },
        {
            abbr: 'NZST',
            id: '9c6d7ddf-cd08-4be7-b8a7-41b4fcb83dec',
            isdst: false,
            offset: 12,
            text: '(UTC+12:00) Auckland, Wellington',
            utc: ['Antarctica/McMurdo', 'Pacific/Auckland'],
            value: 'New Zealand Standard Time',
        },
        {
            abbr: 'U',
            id: '2320ab82-e290-4188-8226-631ec7827990',
            isdst: false,
            offset: 12,
            text: '(UTC+12:00) Coordinated Universal Time+12',
            utc: [
                'Etc/GMT-12',
                'Pacific/Funafuti',
                'Pacific/Kwajalein',
                'Pacific/Majuro',
                'Pacific/Nauru',
                'Pacific/Tarawa',
                'Pacific/Wake',
                'Pacific/Wallis',
            ],
            value: 'UTC+12',
        },
        {
            abbr: 'FST',
            id: 'dc33c5a5-9ade-4233-ac83-a14e696a0f2d',
            isdst: false,
            offset: 12,
            text: '(UTC+12:00) Fiji',
            utc: ['Pacific/Fiji'],
            value: 'Fiji Standard Time',
        },
        {
            abbr: 'MST',
            id: 'b1131750-85d0-4aa5-bb4c-2cd5c431fd2e',
            isdst: false,
            offset: 12,
            text: '(UTC+12:00) Magadan',
            utc: ['Asia/Anadyr', 'Asia/Kamchatka', 'Asia/Magadan', 'Asia/Srednekolymsk'],
            value: 'Magadan Standard Time',
        },
        {
            abbr: 'KDT',
            id: '70cd666f-0d6b-45d9-9b66-d36772f32771',
            isdst: true,
            offset: 13,
            text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
            utc: ['Asia/Kamchatka'],
            value: 'Kamchatka Standard Time',
        },
        {
            abbr: 'TST',
            id: '38c0ed0d-b8d3-425e-9f37-b6e158b856fa',
            isdst: false,
            offset: 13,
            text: "(UTC+13:00) Nuku'alofa",
            utc: ['Etc/GMT-13', 'Pacific/Enderbury', 'Pacific/Fakaofo', 'Pacific/Tongatapu'],
            value: 'Tonga Standard Time',
        },
        {
            abbr: 'SST',
            id: '8d602c50-1854-4935-a1b9-79291b7ba336',
            isdst: false,
            offset: 13,
            text: '(UTC+13:00) Samoa',
            utc: ['Pacific/Apia'],
            value: 'Samoa Standard Time',
        },
    ];

    static MODERATORS_DETAIL_ROOM = {
        items: ['Moderators facilitate conversations in Rooms & designate Speakers.'],
    };

    static IMAGE_SELECTION_OPTIONS = {
        IMAGE_LIBRARY: 'Unsplash',
        BROWSE_MY_FILES: 'Browse My Files',
    };

    static LIBRARY_OPTIONS = {
        LIBRARY: 'lib',
        RECENT: 'recently',
    };

    static WEBRTC_CONSTANTS = {
        SDP_CONTRAINTS: {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true,
        },
        PC_CONFIG: null,
        MEDIA_CONSTRAINTS: {
            video: false,
            audio: false,
        },
        REMOTE_VIDEO_ID: 'remoteVideo',
        IS_PLAY_MODE: true,
        DEBUG: true,
        CALLBACK_CONSTANTS: {
            INITIALIZED: 'initialized',
            PLAY_STARTED: 'play_started',
            PLAY_FINISHED: 'play_finished',
            CLOSED: 'closed',
        },
    };
    static AUTHENTICATION_CONSTANT = {
        AUTHENTICATION_FLOW_TYPE: {
            USER_SRP_AUTH: 'USER_SRP_AUTH',
            CUSTOM_AUTH: 'CUSTOM_AUTH',
        },
    };

    static ERROR_MESSAGES = {
        LINK_EXPIRE: `Your Magic Link has expired. Just click "Send Magic Link" again and we'll send you a new one.`,
        NO_LIVE_ROOM: `No live room exists`,
        AUTHENTICATION_FAILED: 'Authentication Failed',
        ROOM_ALREADY_ENDED: 'The room has been ended.',
        EMAIL_FAILED: 'Email sending failed.',
        USER_IS_NOT_INVITED: 'You are not invited to the room',
    };

    static ROOM_INVITE_STATUS = {
        LIVE: 'live',
        INVITE: 'invite',
        PAST_ROOM: 'past_room',
        PENDING: 'pending',
        ACCEPTED: 'accepted',
        REJECTED: 'rejected',
    };

    static ROOM_TYPES = {
        PRIVATE: 'private',
        REGULAR: 'regular',
    };

    static SESSION_STORAGE_KEYS = {
        ACCESS_TOKEN: 'accessToken',
        ROOM_ID: 'roomId',
        IS_ANONYMOUS: 'isAnonymous',
    };

    static IOS_PLATFORMS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

    static NAVLIST = [
        {
            id: 1,
            icon: 'icon-icon-light-outline-home',
            name: 'Home',
            link: '/guest-dashboard',
        },
        {
            id: 2,
            icon: 'icon-icon-past-rooms',
            name: 'Past Rooms',
            link: '/guest-past-rooms',
        },
        {
            id: 3,
            icon: 'icon-icon-bcast',
            name: 'Bcast',
            link: '/bcast',
        },
    ];
}
