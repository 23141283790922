import React from 'react';
import './createBoard-container.style.scss';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import { AuthService, AWSStorageService, showHideErrorToaster, startLoading, CommonService } from '../../../../core';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import iconInfo from '../../../../assets/images/icon-info.svg';
import {
    ChipInputComponent,
    FileSelectComponent,
    InputComponent,
    Utility,
    ErrorMessageComponent,
    ImageLibraryComponent,
    ImageCropComponent,
    Constant,
} from '../../../../shared';
import { OrganizationDetailsDashboardService } from '../../services';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
export class CreateBoardContainer extends React.Component {
    _authService = new AuthService();
    _utilityService = new Utility();
    _awsStorageService = new AWSStorageService();
    _createBoardService = new OrganizationDetailsDashboardService();
    _commonService = new CommonService();
    debounceTimeout = null;
    constructor(props) {
        super(props);
        this.state = {
            orgDetails: null,
            epEmails: null,
            boardName: '',
            boardDescription: '',
            subscriptionFee: '',
            organizationLogo: null,
            localImagePath: '',
            directorsChips: [],
            producersChips: [],
            membersChips: [],
            existingLogoPath: '',
            validationErrorMessage: {
                boardNameValidation: '',
                descriptionBoxValidation: '',
                directorEmailsValidation: '',
                subscriptionFeeValidation: '',
                producerEmailValidation: '',
                memberEmailValidation: '',
                logoValidation: '',
            },
            userIsAllowed: false,
            isSearching: false,
            searchedImage: '',
            libraryPagination: {
                totalPages: 0,
                currentPage: 1,
            },
            libraryImages: [],
            recentImages: [],
            isLibraryModalOpen: false,
            isCropModalOpen: false,
            selectedImage: null,
            defaultCropState: {
                unit: 'px',
                aspect: {
                    numerator: 16,
                    denominator: 9,
                },
                width: 294.8,
                height: 165.825,
            },
            croperHeading: 'Image',
        };
    }

    userIsRoot = async () => {
        const userInfo = await this._createBoardService.getUser();
        return userInfo.isRoot;
    };

    setParams = () => {
        return {
            id: this.props.match.params.id,
            boardId: this.props.match.params.boardId,
        };
    };

    isUserAllowed = async () => {
        if (await this.userIsRoot()) {
            return true;
        }
        const param = this.setParams();
        const boardMemberDetails = param.boardId && (await this._createBoardService.getBoardDetails(param));
        return this._createBoardService.getUser().then(_successLog => {
            let memberFound = this.state.orgDetails.member.find(member => member.id === _successLog.id);
            memberFound =
                memberFound ||
                (boardMemberDetails &&
                    boardMemberDetails.member.find(
                        member => member.id === _successLog.id && member.role === Constant.IS_PRODUCER,
                    ));
            return memberFound ? true : false;
        });
    };

    setUserAuthorization = async () => {
        const userIsAllowed = await this.isUserAllowed();
        this.setState({ userIsAllowed });
    };

    redirectBack = () => {
        this.props.showHideErrorToaster({
            show: true,
            message: Constant.NOT_ALLOWED_MESSAGE,
            bgColor: '#ff3547',
        });
        this.props.history.replace({
            pathname: '/',
        });
    };

    getOrganizationDetails = async () => {
        const param = this.setParams();
        const orgDetails = await this._createBoardService.getOrganizationDetails(param);
        return orgDetails;
    };

    setOrganizationDetails = async () => {
        const orgDetails = await this.getOrganizationDetails();
        await this.setState({ orgDetails });
    };

    getOrgMembers = () => {
        return this.state.orgDetails.member;
    };

    getExecutiveProducersEmail = () => {
        const epEmails = [];
        const executiveProducers = this.getOrgMembers();
        executiveProducers.forEach(info => {
            epEmails.push(info.email);
        });
        return epEmails;
    };

    setExecutiveProducerEmails = () => {
        const epEmails = this.getExecutiveProducersEmail();
        this.setState({ epEmails });
    };

    fetchCompleteDetails = async () => {
        this.props.startLoading(true);
        await this.setOrganizationDetails();
        await this.setUserAuthorization();
        await this.setExecutiveProducerEmails();
        this.props.startLoading(false);
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.fetchCompleteDetails();
        if (!this.state.userIsAllowed) {
            this.redirectBack();
        } else {
            const boardId = this.props.match.params.boardId;
            if (boardId) {
                this.props.startLoading(true);
                const detail = await this._createBoardService.getBoardDetail(boardId);
                const imagePath = await this.getImageSrc(detail.logo);
                this.setState(prevState => ({
                    ...this.state,
                    boardName: detail.name,
                    boardDescription: detail.description,
                    subscriptionFee: detail.subscriptionFee,
                    existingLogoPath: detail.logo,
                    localImagePath: imagePath,
                }));
                this.setMembers(detail.member);
                this.props.startLoading(false);
            }
        }
    };

    getImageSrc = async imageId => {
        const image = await this._awsStorageService.downloadFile(imageId);
        if (image && image.Body) {
            return URL.createObjectURL(image.Body);
        }
        return null;
    };

    setMembers = member => {
        const producers = member.filter(member => {
            return member.email && member.role === Constant.BOARD_MEMBERS.PRODUCER;
        });
        const directors = member.filter(member => {
            return member.email && member.role === Constant.BOARD_MEMBERS.DIRECTOR;
        });
        const members = member.filter(member => {
            return member.email && member.role === Constant.BOARD_MEMBERS.MEMBER;
        });

        producers.map(producer => this.setState({ producersChips: [...this.state.producersChips, producer.email] }));
        directors.map(director => this.setState({ directorsChips: [...this.state.directorsChips, director.email] }));
        members.map(m => this.setState({ membersChips: [...this.state.membersChips, m.email] }));
    };
    setSelectedLogo = logo => {
        const image = this._utilityService.validateImageFile(logo, Constant.FILE_UPLOAD_LIMIT);
        if (image.isValid) {
            this.setState({
                ...this.state,
                organizationLogo: logo,
                localImagePath: URL.createObjectURL(logo),
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            return;
        }

        this.setState({
            ...this.state,
            organizationLogo: null,
            localImagePath: '',
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: image.message,
            },
        });
    };

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    setBoardName = boardName => {
        this.setState({
            ...this.state,
            boardName,
            validationErrorMessage: { ...this.state.validationErrorMessage, boardNameValidation: '' },
        });
    };

    setBoardDescription = boardDescription => {
        this.setState({
            ...this.state,
            boardDescription,
            validationErrorMessage: { ...this.state.validationErrorMessage, descriptionBoxValidation: '' },
        });
    };
    setSubscriptionFee = subscriptionFee => {
        this.setState({
            ...this.state,
            subscriptionFee,
            validationErrorMessage: { ...this.state.validationErrorMessage, subscriptionFeeValidation: '' },
        });
    };
    addItemToDirectorsChips = chip => {
        const directorsChips = Array.isArray(chip) ? chip : this.state.directorsChips.slice();
        if (!Array.isArray(chip)) {
            directorsChips.push(chip);
        }
        this.setState({
            ...this.state,
            directorsChips,
            validationErrorMessage: { ...this.state.validationErrorMessage, directorEmailsValidation: '' },
        });
    };
    addItemToProducersChips = chip => {
        const producersChips = Array.isArray(chip) ? chip : this.state.producersChips.slice();
        if (!Array.isArray(chip)) {
            producersChips.push(chip);
        }
        this.setState({
            ...this.state,
            producersChips,
            validationErrorMessage: { ...this.state.validationErrorMessage, producerEmailValidation: '' },
        });
    };

    addItemToMembersChips = chip => {
        const membersChips = Array.isArray(chip) ? chip : this.state.membersChips.slice();
        if (!Array.isArray(chip)) {
            membersChips.push(chip);
        }
        this.setState({
            ...this.state,
            membersChips,
            validationErrorMessage: { ...this.state.validationErrorMessage, memberEmailValidation: '' },
        });
    };

    deleteItemFromProducersChips = index => {
        const producersChips = this.state.producersChips.slice();
        producersChips.splice(index, 1);
        this.setState({ ...this.state, producersChips }, async () => {
            await this.resetEmailValidationErrors('producerEmailValidation');
        });
    };

    deleteItemFromDirectorsChips = index => {
        const directorsChips = this.state.directorsChips.slice();
        directorsChips.splice(index, 1);
        this.setState({ ...this.state, directorsChips }, async () => {
            await this.resetEmailValidationErrors('directorEmailsValidation');
        });
    };

    deleteItemFromMembersChips = index => {
        const membersChips = this.state.membersChips.slice();
        membersChips.splice(index, 1);
        this.setState({ ...this.state, membersChips }, async () => {
            await this.resetEmailValidationErrors('memberEmailValidation');
        });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    checkUniqueEmails = (list1, list2) => {
        return !list1.some(l => list2.includes(l));
    };

    resetEmailValidationErrors = async roleEmail => {
        const validationErrorMessage = {
            ...this.state.validationErrorMessage,
        };
        if (roleEmail) {
            validationErrorMessage[roleEmail] = '';
        } else {
            validationErrorMessage.producerEmailValidation = '';
            validationErrorMessage.memberEmailValidation = '';
            validationErrorMessage.directorEmailsValidation = '';
        }
        await this.setState({
            ...this.state,
            validationErrorMessage,
        });
    };

    resetValidationError = async () => {
        await this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                boardNameValidation: '',
                descriptionBoxValidation: '',
                directorEmailsValidation: '',
                subscriptionFeeValidation: '',
                producerEmailValidation: '',
                memberEmailValidation: '',
                logoValidation: '',
            },
        });
    };

    validateForm = async () => {
        const {
            producerEmailValidation,
            directorEmailsValidation,
            memberEmailValidation,
        } = this.state.validationErrorMessage;
        if (producerEmailValidation || directorEmailsValidation || memberEmailValidation) {
            return false;
        }
        await this.resetValidationError();
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        if (!this.state.organizationLogo && !this.state.localImagePath) {
            validationErrorMessage.logoValidation = 'Please select the logo';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.boardName || !this.state.boardName.trim()) {
            validationErrorMessage.boardNameValidation = 'Please fill the board name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.subscriptionFee.trim()) {
            validationErrorMessage.subscriptionFeeValidation = 'Please enter subscription fees';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!/^[0-9]*\.?[0-9]*$/.test(this.state.subscriptionFee)) {
            validationErrorMessage.subscriptionFeeValidation = 'Please enter numeric characters only';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (Number(this.state.subscriptionFee) < 0) {
            validationErrorMessage.subscriptionFeeValidation = 'Please enter positive numbers only';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < this.state.directorsChips.length; i++) {
            let isValid = re.test(this.state.directorsChips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.directorEmailsValidation = 'Provided email address(es) are not valid';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }
        for (let i = 0; i < this.state.producersChips.length; i++) {
            let isValid = re.test(this.state.producersChips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.producerEmailValidation = 'Provided email address(es) are not valid';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }
        for (let i = 0; i < this.state.membersChips.length; i++) {
            let isValid = re.test(this.state.membersChips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.memberEmailValidation = 'Provided email address(es) are not valid';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }
        return true;
    };

    validateAllEmails = (roleEmail, chip, disappearValidationMsg) => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        if (!this.checkUniqueEmails(chip, this.state.epEmails) && roleEmail === 'producerEmailValidation') {
            validationErrorMessage.producerEmailValidation =
                'Executive producer(s) emails cannot be added into the producer field';
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(disappearValidationMsg, roleEmail);
            return false;
        }

        if (!this.checkUniqueEmails(chip, this.state.epEmails) && roleEmail === 'directorEmailsValidation') {
            validationErrorMessage.directorEmailsValidation =
                'Executive producer(s) emails cannot be added into the director field';
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(disappearValidationMsg, roleEmail);
            return false;
        }

        if (!this.checkUniqueEmails(chip, this.state.epEmails) && roleEmail === 'memberEmailValidation') {
            validationErrorMessage.memberEmailValidation =
                'Executive producer(s) emails cannot be added into the member field';
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(disappearValidationMsg, roleEmail);
            return false;
        }
        if (!this.checkUniqueEmails(this.state.directorsChips, chip) && roleEmail !== 'directorEmailsValidation') {
            if (roleEmail === 'producerEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'producerEmailValidation' ? 'Director​​​​​​​' : 'Producer'
                } emails cannot be added in to the ${
                    roleEmail === 'producerEmailValidation' ? 'producers' : 'directors'
                } field`;
            } else if (roleEmail === 'memberEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'memberEmailValidation' ? 'Director​​​​​​​' : 'Member'
                } emails cannot be added in to the ${
                    roleEmail === 'memberEmailValidation' ? 'members' : 'directors'
                } field`;
            }
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(disappearValidationMsg, roleEmail);
            return false;
        }

        if (!this.checkUniqueEmails(this.state.membersChips, chip) && roleEmail !== 'memberEmailValidation') {
            if (roleEmail === 'producerEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'producerEmailValidation' ? 'Member' : 'Producer'
                } emails cannot be added in to the ${
                    roleEmail === 'producerEmailValidation' ? 'producers' : 'members'
                } field`;
            } else if (roleEmail === 'directorEmailsValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'directorEmailsValidation' ? 'Member' : 'Director'
                } emails cannot be added in to the ${
                    roleEmail === 'directorEmailsValidation' ? 'directors' : 'members'
                } field`;
            }
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(disappearValidationMsg, roleEmail);
            return false;
        }

        if (!this.checkUniqueEmails(this.state.producersChips, chip) && roleEmail !== 'producerEmailValidation') {
            if (roleEmail === 'memberEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'memberEmailValidation' ? 'Producer' : 'Member'
                } emails cannot be added in to the ${
                    roleEmail === 'memberEmailValidation' ? 'members' : 'producers'
                } field`;
            } else if (roleEmail === 'directorEmailsValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'directorEmailsValidation' ? 'Producer' : 'Director'
                } emails cannot be added in to the ${
                    roleEmail === 'directorEmailsValidation' ? 'directors' : 'producers'
                } field`;
            }
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(disappearValidationMsg, roleEmail);
            return false;
        }

        return true;
    };

    disappearValidationMsg = async (disappearValidationMsg, roleEmail) => {
        if (disappearValidationMsg) {
            setTimeout(async () => {
                await this.resetEmailValidationErrors(roleEmail);
            }, 1500);
        }
    };

    uploadFileToStorage = async () => {
        const credentials = await this._authService.getAuthCredentials();
        const cognitoId = credentials.identityId;
        const storageMetaData = await this._awsStorageService.uploadFile(
            `${cognitoId}/${this.state.organizationLogo.name}`,
            this.state.organizationLogo,
            this.state.organizationLogo.type,
        );
        return storageMetaData;
    };

    getStorageMetaData = async () => {
        const boardId = this.props.match.params.boardId;
        let storageMetaData = '';
        if (boardId) {
            if (this.state.organizationLogo) {
                storageMetaData = await this.uploadFileToStorage();
            } else {
                storageMetaData = this.state.existingLogoPath;
            }
        } else {
            storageMetaData = await this.uploadFileToStorage();
        }
        return storageMetaData;
    };
    updateBoard = async (payload, boardId) => {
        await this._createBoardService.updateBoard(payload, boardId);
        this.initializeFormFields();
        this.props.history.goBack();
    };
    saveBoard = async payload => {
        await this._createBoardService.createBoard(payload);
        this.initializeFormFields();
        this.props.history.goBack();
    };
    createBoard = async e => {
        e.preventDefault();
        const boardId = this.props.match.params.boardId;
        const validated = await this.validateForm();
        if (!validated) {
            return;
        }
        this.props.startLoading(true);
        const storageMetaData = await this.getStorageMetaData();
        const payload = {
            organizationId: this.props.match.params.id,
            name: this.state.boardName,
            description: this.state.boardDescription,
            subscriptionFee: this.state.subscriptionFee,
            directors: this.state.directorsChips.slice(),
            logo: storageMetaData.key ? storageMetaData.key : storageMetaData,
            producers: this.state.producersChips.slice(),
            members: this.state.membersChips.slice(),
        };
        try {
            if (boardId) {
                await this.updateBoard(payload, boardId);
            } else {
                await this.saveBoard(payload);
            }
            this.initializeFormFields();
        } catch (e) {
            console.error({ e });
            this.props.startLoading(false);
        }
    };
    initializeFormFields = () => {
        this.setState({
            ...this.state,
            boardName: '',
            boardDescription: '',
            subscriptionFee: '',
            organizationLogo: null,
            localImagePath: '',
            producersChips: [],
            directorsChips: [],
            membersChips: [],
        });
    };

    getProducerPopover = () => {
        const producerPopover = (
            <Popover id="popover-basic" className="executiveproducer__popover">
                <Popover.Content className="ep__popovercontent">
                    <ul>
                        <li className="role__list__item">Producers manage a Board & designate Directors</li>
                    </ul>
                </Popover.Content>
            </Popover>
        );
        return producerPopover;
    };

    getDirectorPopover = () => {
        const directorPopover = (
            <Popover id="popover-basic" className="executiveproducer__popover">
                <Popover.Content className="ep__popovercontent">
                    <ul>
                        <li className="role__list__item">Directors manage Rooms & designate Moderators</li>
                    </ul>
                </Popover.Content>
            </Popover>
        );
        return directorPopover;
    };

    saveRecentImagesToLocal = selectedImage => {
        let locallySavedRecentImages = this._utilityService.getStorageItems('recentImages')
            ? JSON.parse(this._utilityService.getStorageItems('recentImages'))
            : [];
        locallySavedRecentImages = locallySavedRecentImages.filter(image => image !== selectedImage);
        locallySavedRecentImages.unshift(selectedImage);
        this._utilityService.setStorageItems('recentImages', JSON.stringify(locallySavedRecentImages));
    };

    getRecentImages = async () => {
        let locallySavedRecentImages = this._utilityService.getStorageItems('recentImages')
            ? JSON.parse(this._utilityService.getStorageItems('recentImages'))
            : [];
        await this.setState({ ...this.state, recentImages: locallySavedRecentImages });
    };

    onImageSearchChange = async searchedImage => {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
        await this.setState({
            ...this.state,
            searchedImage,
            isSearching: true,
        });
        this.debounceTimeout = setTimeout(async () => {
            await this.setState({
                ...this.state,
                libraryPagination: {
                    ...this.state.libraryPagination,
                    totalPages: 0,
                    currentPage: 1,
                },
                libraryImages: [],
            });

            if (searchedImage) {
                this.getLibraryImages();
            }
        }, 2000);
    };

    onImageScrollDown = async () => {
        if (this.state.libraryPagination.currentPage >= this.state.libraryPagination.totalPages) {
            return;
        }
        this.getLibraryImages();
    };

    getLibraryImages = async () => {
        this.props.startLoading(true);
        const libraryImages = await this._commonService.getSplashimages({
            pageNo: this.state.libraryPagination.currentPage,
            searchedImage: this.state.searchedImage,
        });
        const listOfImages = this.state.libraryImages.slice();
        this.setState({
            ...this.state,
            libraryImages: listOfImages.concat(libraryImages.results),
            isSearching: this.state.libraryPagination.currentPage === 1 && libraryImages.results.length > 0,
            libraryPagination: {
                ...this.state.libraryPagination,
                totalPages: libraryImages.totalPages,
                currentPage: this.state.libraryPagination.currentPage + 1,
            },
        });
        this.props.startLoading(false);
    };

    openLibrary = async () => {
        await this.getRecentImages();
        this.setState({ ...this.state, isLibraryModalOpen: true });
    };

    closeLibraryModal = () => {
        this.setState({ ...this.state, isLibraryModalOpen: false });
    };

    openCropModal = () => {
        this.setState({ ...this.state, isCropModalOpen: true });
    };

    closeCropModal = confirm => {
        if (confirm) {
            this.state.selectedImage.search('blob') === -1 && this.saveRecentImagesToLocal(this.state.selectedImage);
            this.closeLibraryModal();
        }
        this.setState({ ...this.state, isCropModalOpen: false });
    };

    cancelCroping = async () => {
        await this.setState({ ...this.state, selectedImage: null });
        this.closeLibraryModal();
        this.closeCropModal();
        this.props.startLoading(false);
    };

    selectImage = async image => {
        if (typeof image === 'object') {
            const fr = new FileReader();
            fr.readAsArrayBuffer(image);
            fr.onload = async () => {
                const blob = new Blob([fr.result]);
                image = URL.createObjectURL(blob, { type: 'image/png' });
                await this.setState({ ...this.state, selectedImage: image, croperHeading: 'Logo' });
            };
        } else {
            await this.setState({ ...this.state, selectedImage: image, croperHeading: 'Image' });
        }
        this.openCropModal();
    };

    render() {
        return (
            <div className="create-board-wrapper">
                <h2 className="create-board__heading">Board Information</h2>
                <FileSelectComponent
                    validateImageFileMessage={this.validateImageFileMessage}
                    isLibraryIncluded={true}
                    openLibrary={this.openLibrary}
                    selectedImage={this.selectImage}
                    data-test="file_upload"
                    id="file-upload-create-board"
                    label="Upload Background Image"
                    ratio="16:9"
                    fileHandling={{
                        setSelectedLogo: this.setSelectedLogo,
                        localImagePath: this.state.localImagePath,
                    }}
                    validationErrorMessage={this.state.validationErrorMessage}
                />
                <Form className="create-board__form">
                    <Form.Group controlId="formBoardName" className="form-group--badge">
                        <Form.Label>Board Name</Form.Label>
                        <InputComponent
                            data-test="board_container_input_name"
                            textId={'board-container-input-name'}
                            onChange={this.setBoardName}
                            charLimit={50}
                            type="board_name"
                            value={this.state.boardName}
                            errorMessage={this.state.validationErrorMessage.boardNameValidation}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDescription" className="form-group--badge">
                        <Form.Label>Description</Form.Label>
                        <InputComponent
                            data-test="board_container_input_description"
                            textId={'board-container-input-description'}
                            onChange={this.setBoardDescription}
                            type="description_box"
                            classes={'form__textarea'}
                            charLimit={1000}
                            value={this.state.boardDescription}
                        />
                    </Form.Group>

                    <Form.Group controlId="formMembership" className="form-group--half">
                        <Form.Label>Membership subscription</Form.Label>
                        <div className="inner-wrapper">
                            <span className="dollar-sign">$</span>
                            <InputComponent
                                textId={'board-container-input-price'}
                                onChange={this.setSubscriptionFee}
                                classes={'inner-wrapper'}
                                value={this.state.subscriptionFee}
                                charLimit={50}
                                data-test="board_container_input_price"
                            />
                            <span className="form__postapend">/month</span>
                        </div>
                        <ErrorMessageComponent message={this.state.validationErrorMessage.subscriptionFeeValidation} />
                    </Form.Group>

                    <h2 className="create-board__heading">Administration</h2>

                    <Form.Group controlId="formProducer">
                        <Form.Label className="create-board__label-opacity-1">
                            <span className="opacity-50">Producers</span>
                            <OverlayTrigger
                                id="create-board-overlay"
                                trigger={['hover', 'focus']}
                                placement="top"
                                overlay={this.getProducerPopover()}
                                triggerId={'producers-info-trigger'}
                                data-test="create_prod_overlay"
                            >
                                <img className="label__popover" src={iconInfo} alt="info" />
                            </OverlayTrigger>
                        </Form.Label>
                        <ChipInputComponent
                            chipContainerId={'create-board-produer-chip'}
                            chipObject={{
                                chips: this.state.producersChips,
                                addItemToChips: this.addItemToProducersChips,
                                deleteItemFromChips: this.deleteItemFromProducersChips,
                                validateEmail: this.validateAllEmails,
                                roleEmail: 'producerEmailValidation',
                                resetEmailErrors: this.resetEmailValidationErrors,
                                inputPlaceHolder: this.state.producersChips.length ? '' : 'Email Address',
                            }}
                            errorMessage={this.state.validationErrorMessage.producerEmailValidation}
                        />
                    </Form.Group>

                    <Form.Group controlId="formDirectors">
                        <Form.Label className="create-board__label-opacity-1">
                            <span className="opacity-50">Directors</span>
                            <OverlayTrigger
                                triggerId={'directors-info-trigger'}
                                trigger={['hover', 'focus']}
                                placement="top"
                                overlay={this.getDirectorPopover()}
                                data-test="create_direct_overlay"
                            >
                                <img className="label__popover" src={iconInfo} alt="info" />
                            </OverlayTrigger>
                        </Form.Label>
                        <ChipInputComponent
                            chipContainerId={'create-board-director-chip'}
                            chipObject={{
                                chips: this.state.directorsChips,
                                addItemToChips: this.addItemToDirectorsChips,
                                deleteItemFromChips: this.deleteItemFromDirectorsChips,
                                validateEmail: this.validateAllEmails,
                                roleEmail: 'directorEmailsValidation',
                                resetEmailErrors: this.resetEmailValidationErrors,
                                inputPlaceHolder: this.state.directorsChips.length ? '' : 'Email Address',
                            }}
                            errorMessage={this.state.validationErrorMessage.directorEmailsValidation}
                        />
                    </Form.Group>

                    <Form.Group controlId="formMembers">
                        <Form.Label>Members</Form.Label>
                        <ChipInputComponent
                            chipContainerId={'create-board-member-chip'}
                            chipObject={{
                                chips: this.state.membersChips,
                                addItemToChips: this.addItemToMembersChips,
                                deleteItemFromChips: this.deleteItemFromMembersChips,
                                validateEmail: this.validateAllEmails,
                                roleEmail: 'memberEmailValidation',
                                resetEmailErrors: this.resetEmailValidationErrors,
                                inputPlaceHolder: this.state.membersChips.length ? '' : 'Email Address',
                            }}
                            errorMessage={this.state.validationErrorMessage.memberEmailValidation}
                        />
                    </Form.Group>

                    <div className="comodal__footer">
                        <Link
                            to="javascript:void(0)"
                            id="board-container-button-cancel"
                            data-test="cancel_btn"
                            onClick={e => {
                                e.preventDefault();
                                this.props.history.goBack();
                            }}
                            className={'btn__link'}
                        >
                            Cancel
                        </Link>

                        <ButtonComponent
                            id={'board-container-button-submit'}
                            className={'br__btn-theme-filled'}
                            name="Save"
                            data-test="save_btn"
                            onclick={this.createBoard}
                        />
                    </div>
                </Form>

                <ImageLibraryComponent
                    getRecentImages={this.getRecentImages}
                    searchedImage={this.state.searchedImage}
                    onImageSearchChange={this.onImageSearchChange}
                    onImageScrollDown={this.onImageScrollDown}
                    libraryImages={this.state.libraryImages}
                    recentImages={this.state.recentImages}
                    isLibraryModalOpen={this.state.isLibraryModalOpen}
                    closeLibraryModal={this.closeLibraryModal}
                    selectImage={this.selectImage}
                    isSearching={this.state.isSearching}
                    showHideErrorToaster={this.props.showHideErrorToaster}
                    startLoading={this.props.startLoading}
                />
                <ImageCropComponent
                    ratio={'16:9'}
                    croperHeading={this.state.croperHeading}
                    cancelCroping={this.cancelCroping}
                    defaultCropState={this.state.defaultCropState}
                    setSelectedLogo={this.setSelectedLogo}
                    isCropModalOpen={this.state.isCropModalOpen}
                    closeCropModal={this.closeCropModal}
                    selectedImage={this.state.selectedImage}
                    startLoading={this.props.startLoading}
                    showHideErrorToaster={this.props.showHideErrorToaster}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(null, mapDispatchToProps)(CreateBoardContainer);
