import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import { Form, OverlayTrigger } from 'react-bootstrap';
import iconInfo from '../../../../assets/images/icon-info.svg';
import { Constant } from '../../../../shared';
import {
    ChipInputComponent,
    FileSelectComponent,
    InputComponent,
    ErrorMessageComponent,
    ImageLibraryComponent,
    ImageCropComponent,
} from '../../../../shared';
import { DateTime } from 'luxon';
const CreateRoomForm = ({
    defaultCropState,
    searchedImage,
    libraryImages,
    saveRecentImagesToLocal,
    getRecentImages,
    recentImages,
    setSelectedLogo,
    localImagePath,
    validationErrorMessage,
    setRoomName,
    roomName,
    setRoomDescription,
    roomDescription,
    setRoomDate,
    isShareAble,
    isLoungeEnabled,
    isSearching,
    setRoomShareAble,
    setLoungeEnabled,
    roomDate,
    setStartTime,
    roomStartTime,
    setEndTime,
    roomEndTime,
    setTimeZone,
    timeZone,
    moderatorPopover,
    moderatorsChips,
    addItemToModeratorsChips,
    deleteItemFromModeratorsChips,
    speakersChips,
    addItemToSpeakersChips,
    deleteItemFromSpeakersChips,
    attendeesChips,
    addItemToAttendeesChips,
    deleteItemFromAttendeesChips,
    guestChips,
    addItemToGuestsChips,
    deleteItemFromGuestsChips,
    createRoom,
    validateEmails,
    resetEmailValidationErrors,
    onImageSearchChange,
    onImageScrollDown,
    validateImageFileMessage,
    showHideErrorToaster,
    startLoading,
    setGreenRoomMins,
    greenRoomMins,
    isStreamingEnabled,
    setStreamingEnabled,
    setYoutubeRtmpLink,
    youtubeRtmpLink,
    isRoomPastStartTime,
}) => {
    const history = useHistory();
    const [isLibraryModalOpen, setLibraryModal] = useState(false);
    const [isCropModalOpen, setCropModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [croperHeading, setCroperHeading] = useState('Image');
    const greenRoomMinsObj = [
        { label: '0 minutes', value: 0 },
        { label: '5 minutes', value: 5 },
        { label: '6 minutes', value: 6 },
        { label: '7 minutes', value: 7 },
        { label: '8 minutes', value: 8 },
        { label: '9 minutes', value: 9 },
        { label: '10 minutes', value: 10 },
        { label: '11 minutes', value: 11 },
        { label: '12 minutes', value: 12 },
        { label: '13 minutes', value: 13 },
        { label: '14 minutes', value: 14 },
        { label: '15 minutes', value: 15 },
    ];
    const selectTimeZone = e => {
        const selectedOption = Constant.TIME_ZONES.filter(tz => tz.id === e.target.value);
        setTimeZone(selectedOption[0]);
    };

    const selectImage = image => {
        if (typeof image === 'object') {
            const fr = new FileReader();
            fr.readAsArrayBuffer(image);
            fr.onload = function() {
                const blob = new Blob([fr.result]);
                const url = URL.createObjectURL(blob, { type: 'image/png' });
                setCroperHeading('Logo');
                setSelectedImage(url);
            };
        } else {
            setCroperHeading('Image');
            setSelectedImage(image);
        }
        openCropModal();
    };

    const openLibrary = () => {
        getRecentImages();
        setLibraryModal(true);
    };

    const closeLibraryModal = () => {
        setLibraryModal(false);
    };

    const openCropModal = () => {
        setCropModal(true);
    };

    const closeCropModal = confirm => {
        if (confirm) {
            selectedImage.search('blob') === -1 && saveRecentImagesToLocal(selectedImage);
            closeLibraryModal();
        }
        setCropModal(false);
    };

    const cancelCroping = () => {
        startLoading(false);
        setSelectedImage(null);
        closeLibraryModal();
        setCropModal(false);
    };

    return (
        <div className="create-room-wrapper" data-test="create_wrapper">
            <h2 className="create-room__heading">Room Information</h2>
            <FileSelectComponent
                isLibraryIncluded={true}
                validateImageFileMessage={validateImageFileMessage}
                id="file-upload-create-room"
                label="Upload Background Image"
                ratio="16:9"
                openLibrary={openLibrary}
                selectedImage={selectImage}
                fileHandling={{
                    setSelectedLogo: setSelectedLogo,
                    localImagePath: localImagePath,
                }}
                validationErrorMessage={validationErrorMessage}
            />
            <Form className="create-room__form" data-test="create_room_form">
                <Form.Group controlId="formRoomName" className="form-group--badge" data-test="form_group_room_name">
                    <Form.Label>Room Name</Form.Label>
                    <InputComponent
                        textId={'room-container-input-name'}
                        onChange={setRoomName}
                        charLimit={50}
                        type="board_name"
                        value={roomName}
                        errorMessage={validationErrorMessage.roomNameValidation}
                    />
                </Form.Group>

                <Form.Group
                    controlId="formDescription"
                    className="form-group--badge"
                    data-test="form_group_description"
                >
                    <Form.Label>Description</Form.Label>
                    <InputComponent
                        textId={'room-container-input-description'}
                        onChange={setRoomDescription}
                        type="description_box"
                        classes={'form__textarea'}
                        charLimit={1000}
                        value={roomDescription}
                    />
                </Form.Group>

                <div className="create-room__date-time-wrapper">
                    <Form.Group controlId="formDate" className="form-group--date" data-test="form_group_date">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            onChange={setRoomDate}
                            value={roomDate}
                            min={DateTime.now()
                                .toFormat('yyyy-MM-dd')
                                .toString()}
                        ></Form.Control>
                        <ErrorMessageComponent
                            id={'date-validation-message'}
                            message={validationErrorMessage.dateValidation}
                        />
                    </Form.Group>

                    <Form.Group controlId="formTimeStart" className="form-group--time" data-test="form_group_from">
                        <Form.Label>From</Form.Label>
                        <Form.Control type="time" onChange={setStartTime} value={roomStartTime}></Form.Control>
                        <ErrorMessageComponent
                            id={'time-start-validation-message'}
                            message={validationErrorMessage.startTimeValidation}
                        />
                    </Form.Group>

                    <Form.Group controlId="formTimeEnd" className="form-group--time" data-test="form_group_end">
                        <Form.Label>To</Form.Label>
                        <Form.Control type="time" onChange={setEndTime} value={roomEndTime}></Form.Control>
                        <ErrorMessageComponent
                            id={'time-end-validation-message'}
                            message={validationErrorMessage.endTimeValidation}
                        />
                    </Form.Group>
                </div>

                <Form.Group controlId="roomCreate.Timezone" data-test="form_group_zone">
                    <Form.Label>Time Zone</Form.Label>
                    <Form.Control as="select" onChange={selectTimeZone} value={timeZone}>
                        <option value="">{}</option>
                        {Constant.TIME_ZONES.sort((a, b) => {
                            return a.offset < b.offset ? -1 : a.offset > b.offset ? 1 : 0;
                        }).map(zone => {
                            return (
                                <option key={zone.id} value={zone.id}>
                                    {zone.text}
                                </option>
                            );
                        })}
                    </Form.Control>
                    <ErrorMessageComponent
                        id={'time-zone-validation-message'}
                        message={validationErrorMessage.timeZoneValidation}
                    />
                </Form.Group>

                <Form.Group controlId="roomCreate.GreenRoom">
                    <Form.Label>Green Room</Form.Label>
                    <Form.Control as="select" onChange={setGreenRoomMins} value={greenRoomMins}>
                        {greenRoomMinsObj.map(opt => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </Form.Control>
                    <ErrorMessageComponent
                        id={'green-room-validation-message'}
                        message={validationErrorMessage.greenRoomValidation}
                    />
                </Form.Group>

                <Form.Group className="d-flex align-items-center">
                    <label className="custom-checkbox-room">
                        <input
                            className="custom-checkbox__input"
                            type="checkbox"
                            id="shareable"
                            checked={isShareAble}
                            onChange={setRoomShareAble}
                        />
                        <span className="custom-checkbox__checkmark"></span>
                    </label>
                    <label htmlFor="shareable" className="custom-checkbox__label">
                        Create a public share link
                    </label>
                </Form.Group>

                <Form.Group className="d-flex align-items-center">
                    <label className="custom-checkbox-room">
                        <input
                            className="custom-checkbox__input"
                            type="checkbox"
                            id="lounge"
                            checked={isLoungeEnabled}
                            onChange={setLoungeEnabled}
                        />
                        <span className="custom-checkbox__checkmark"></span>
                    </label>
                    <label htmlFor="lounge" className="custom-checkbox__label">
                        Enable lounge
                    </label>
                </Form.Group>
                <Form.Group className="d-flex align-items-center">
                    <label className="custom-checkbox-room">
                        <input
                            className="custom-checkbox__input"
                            type="checkbox"
                            id="rtmp"
                            checked={isStreamingEnabled}
                            onChange={setStreamingEnabled}
                            disabled={isRoomPastStartTime}
                        />
                        <span className="custom-checkbox__checkmark"></span>
                    </label>
                    <label htmlFor="rtmp" className="custom-checkbox__label">
                        Enable RTMP streaming
                    </label>
                </Form.Group>

                {isStreamingEnabled && (
                    <Form.Group controlId="formRtmpLink" className="form-group--badge" data-test="form_group_room_name">
                        <Form.Label>Youtube Link</Form.Label>
                        <InputComponent
                            textId={'room-container-input-rtmp'}
                            onChange={setYoutubeRtmpLink}
                            type="board_name"
                            value={youtubeRtmpLink}
                            disable={isRoomPastStartTime}
                            errorMessage={validationErrorMessage.youtubeRtmpValidation}
                        />
                    </Form.Group>
                )}

                <h2 data-test="admin_heading" className="create-room__heading">
                    Administration
                </h2>

                <Form.Group data-test="form_group_moderator" controlId="formModerator">
                    <Form.Label>
                        Moderators
                        <OverlayTrigger
                            id="create-room-moderator-overlay"
                            trigger={['hover', 'focus']}
                            placement="top"
                            overlay={moderatorPopover && moderatorPopover}
                            triggerId={'moderator-info-trigger'}
                        >
                            <img className="label__popover" src={iconInfo} alt="info" />
                        </OverlayTrigger>
                    </Form.Label>
                    <ChipInputComponent
                        chipContainerId={'create-room-moderator-chip'}
                        chipObject={{
                            chips: moderatorsChips,
                            addItemToChips: addItemToModeratorsChips,
                            deleteItemFromChips: deleteItemFromModeratorsChips,
                            validateEmail: validateEmails,
                            roleEmail: 'moderatorEmailValidation',
                            resetEmailErrors: resetEmailValidationErrors,
                            inputPlaceHolder: moderatorsChips.length ? '' : 'Email Address',
                        }}
                        errorMessage={validationErrorMessage.moderatorEmailValidation}
                    />
                </Form.Group>

                <h2 className="create-room__heading" data-test="invite-speaker">
                    Invite Speakers
                </h2>

                <Form.Group data-test="form_group_email_address" controlId="formSpeakers">
                    <Form.Label>Email Address</Form.Label>
                    <ChipInputComponent
                        chipContainerId={'create-board-speaker-chip'}
                        chipObject={{
                            chips: speakersChips,
                            addItemToChips: addItemToSpeakersChips,
                            validateEmail: validateEmails,
                            roleEmail: 'speakerEmailsValidation',
                            resetEmailErrors: resetEmailValidationErrors,
                            deleteItemFromChips: deleteItemFromSpeakersChips,
                            inputPlaceHolder: speakersChips.length ? '' : 'Email Address',
                        }}
                        errorMessage={validationErrorMessage.speakerEmailsValidation}
                    />
                </Form.Group>

                <h2 data-test="invite-attendees" className="create-room__heading">
                    Invite Attendees
                </h2>

                <Form.Group data-test="formgroup_attendees" controlId="formAttendees">
                    <Form.Label>Attendees</Form.Label>
                    <ChipInputComponent
                        chipContainerId={'create-board-attendees-chip'}
                        chipObject={{
                            chips: attendeesChips,
                            addItemToChips: addItemToAttendeesChips,
                            deleteItemFromChips: deleteItemFromAttendeesChips,
                            validateEmail: validateEmails,
                            roleEmail: 'attendeeEmailValidation',
                            resetEmailErrors: resetEmailValidationErrors,
                            inputPlaceHolder: attendeesChips.length ? '' : 'Email Address',
                        }}
                        errorMessage={validationErrorMessage.attendeeEmailValidation}
                    />
                </Form.Group>

                <h2 data-test="invite-guest" className="create-room__heading">
                    Invite Guests
                </h2>

                <Form.Group data-test="create-board-guest-chip" controlId="formGuests">
                    <Form.Label>Guests</Form.Label>
                    <ChipInputComponent
                        chipContainerId={'create-board-guests-chip'}
                        chipObject={{
                            chips: guestChips,
                            addItemToChips: addItemToGuestsChips,
                            deleteItemFromChips: deleteItemFromGuestsChips,
                            validateEmail: validateEmails,
                            roleEmail: 'guestEmailValidation',
                            resetEmailErrors: resetEmailValidationErrors,
                            inputPlaceHolder: guestChips.length ? '' : 'Email Address',
                        }}
                        errorMessage={validationErrorMessage.guestEmailValidation}
                    />
                </Form.Group>

                <div className="comodal__footer">
                    <Link
                        to="javascript:void(0)"
                        data-test="cancel_link"
                        id="room-container-button-cancel"
                        onClick={e => {
                            e.preventDefault();
                            history.goBack();
                        }}
                        className={'btn__link'}
                    >
                        Cancel
                    </Link>

                    <ButtonComponent
                        id={'room-container-button-submit'}
                        className={'br__btn-theme-filled'}
                        name="Save"
                        onclick={createRoom}
                    />
                </div>
            </Form>
            <ImageLibraryComponent
                getRecentImages={getRecentImages}
                searchedImage={searchedImage}
                onImageSearchChange={onImageSearchChange}
                onImageScrollDown={onImageScrollDown}
                libraryImages={libraryImages}
                recentImages={recentImages}
                isLibraryModalOpen={isLibraryModalOpen}
                closeLibraryModal={closeLibraryModal}
                selectImage={selectImage}
                isSearching={isSearching}
                showHideErrorToaster={showHideErrorToaster}
                startLoading={startLoading}
            />
            <ImageCropComponent
                ratio={'16:9'}
                croperHeading={croperHeading}
                cancelCroping={cancelCroping}
                defaultCropState={defaultCropState}
                setSelectedLogo={setSelectedLogo}
                isCropModalOpen={isCropModalOpen}
                closeCropModal={closeCropModal}
                selectedImage={selectedImage}
                showHideErrorToaster={showHideErrorToaster}
                startLoading={startLoading}
            />
        </div>
    );
};

export default CreateRoomForm;
