import { Http } from '../../../../core';

export class OrganizationDetailsDashboardService {
    /**
     * @name getBoards
     * @param {object} param contains organizationId required for specific organization, pageSize and pageIndex required for pagination
     * @desc Will fetch boards of an organization with pagination
     * @return {Promise}
     */
    getBoards = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(
                `/board?pageIndex=${param.pageIndex}&pageSize=${param.pageSize}&organizationId=${param.orgId}`,
            )
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
    /**
     * @name getOrganizationDetails
     * @param {object} param contains organizationId required for specific organization
     * @desc Will fetch specific organization details
     * @return {Promise}
     */
    getOrganizationDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/organization/details/${param.id}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
    /**
     * @name createBoard
     * @param {any} requestPayload request payload for board creation
     * @desc Will create an board
     * @return {Promise}
     */
    createBoard = requestPayload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post('board', requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
    /**
     * @name getBoardDetail
     * @param {any} param payload for specific board
     * @desc Will fetch specific board details
     * @return {Promise}
     */
    getBoardDetail = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/board/details/${param}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
    /**
     * @name updateBoard
     * @param {object} requestPayload required for board updation
     * @param {string} boardId required for specific board
     * @desc Will update specific board
     * @return {Promise}
     */
    updateBoard = (requestPayload, boardId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/board/${boardId}`, requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
    /**
     * @name getUser
     * @desc will fetch loggedIn user
     * @return {Promise}
     */
    getUser = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/me`)
                .then(async _successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };
    /**
     * @name getBoardDetail
     * @param {object} param contains boardId required for specific board
     * @desc Will fetch specific board details
     * @return {Promise}
     */
    getBoardDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/board/details/${param.boardId}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
    /**
     * @name getOrganizationDetails
     * @param {object} param contains organizationId for specific organization
     * @desc Will fetch specific organization detail
     * @return {Promise}
     */
    getOrganizationDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/organization/details/${param.id}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
}
