import React, { useState } from 'react';
import { Modal, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import './modal-component.style.scss';
import iconInfo from '../../../../assets/images/icon-info.svg';
import { ChipInputComponent, FileSelectComponent, InputComponent, ImageCropComponent } from '../../../../shared';

const ModalComponent = props => {
    const [isCropModalOpen, setCropModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const defaultCropState = {
        unit: 'px',
        aspect: {
            numerator: 1,
            denominator: 1,
        },
        width: 200,
        height: 200,
    };

    const selectImage = image => {
        const fr = new FileReader();
        fr.readAsArrayBuffer(image);
        fr.onload = () => {
            const blob = new Blob([fr.result]);
            const url = URL.createObjectURL(blob, { type: 'image/png' });
            setSelectedImage(url);
            openCropModal();
        };
    };

    const openCropModal = () => {
        setCropModal(true);
    };

    const closeCropModal = () => {
        setSelectedImage(null);
        setCropModal(false);
    };

    const cancelCroping = () => {
        setSelectedImage(null);
        setCropModal(false);
    };
    const popover = (
        <Popover id="popover-basic" className="executiveproducer__popover">
            <Popover.Content className="ep__popovercontent">
                <ul>
                    <li className="role__list__item">
                        Executive Producers have top level access, manage the Organization & designate Producers for
                        Boards.
                    </li>
                </ul>
            </Popover.Content>
        </Popover>
    );

    return (
        <>
            <Modal
                className="organization-modal"
                data-test="createOrganization__modal"
                centered
                show={props.show}
                onHide={props.handleClose}
            >
                <Modal.Body className="organization-modal__body">
                    <h3 className="organization-modal__heading co__heading">Organization</h3>

                    <Form className="organization-modal__form co__form">
                        <FileSelectComponent
                            id={'organization-modal-file-select'}
                            {...props}
                            selectedImage={selectImage}
                        />
                        <ImageCropComponent
                            ratio={'1:1'}
                            croperHeading="Logo"
                            cancelCroping={cancelCroping}
                            defaultCropState={defaultCropState}
                            setSelectedLogo={props.fileHandling.setSelectedLogo}
                            isCropModalOpen={isCropModalOpen}
                            closeCropModal={closeCropModal}
                            selectedImage={selectedImage}
                        />
                        <Form.Group controlId="formOrganizationName">
                            <Form.Label>Organization Name</Form.Label>
                            <InputComponent
                                value={props.organizationName}
                                textId={'organization-modal-input-name'}
                                charLimit={50}
                                type={'text'}
                                onChange={props.setOrganizationName}
                                errorMessage={props.validationErrorMessage.orgNameValidation}
                            />
                        </Form.Group>
                        <Form.Group controlId="formExecutiveProducer">
                            <Form.Label className="organization-modal__label-opacity-1">
                                <span className="opacity-50">Executive Producer(s)</span>
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
                                    <img
                                        id="organization-overlay"
                                        className="label__popover"
                                        src={iconInfo}
                                        alt="info"
                                    />
                                </OverlayTrigger>
                            </Form.Label>
                            <ChipInputComponent chipContainerId={'organization-modal-produer-chip'} {...props} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="comodal__footer">
                    <ButtonComponent
                        id={'organization-modal-component-button-close'}
                        className={'btn__link'}
                        name="Cancel"
                        onclick={props.handleClose}
                    />
                    <ButtonComponent
                        id={'organization-modal-component-button-submit'}
                        disabledSubmitButton={props.disabledSubmitButton}
                        className={'br__btn-theme-filled br__btn-theme-filled--width-93'}
                        name="Save"
                        onclick={props.createOrganization}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalComponent;
