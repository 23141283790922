import React from 'react';
import './room-detail-container.style.scss';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import BackComponent from '../../../../shared/components/back-component/back-component';
import { AuthService, startLoading, showHideErrorToaster, UserService, CloudinaryService } from '../../../../core';
import { Col, Row } from 'react-bootstrap';
import { RoomDetailsDashboardService } from '../../services';
import { Constant, Utility } from '../../../../shared';
import { connect } from 'react-redux';
import { RoomDataTableComponent, RoomSideBarComponent } from '../../components';
import iconQuestion from '../../../../assets/images/ic-question.svg';
import iconCheckmark from '../../../../assets/images/ic-checkmark.svg';
import iconDecline from '../../../../assets/images/ic-decline.svg';
import { DateTime } from 'luxon';
export class RoomDetailContainer extends React.Component {
    _userService = new UserService();
    _roomDetailService = new RoomDetailsDashboardService();
    _authService = new AuthService();
    _utilityService = new Utility();
    _cloudinaryService = new CloudinaryService();
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            roomId: '',
            classForTruncate: false,
            classForListing: 'room-detail__speakers-list',
            copyMessage: Constant.ROOM_DETAILS_COPY_MESSAGE,
        };
    }

    getMembersArray = async (membersArray, roles) => {
        const totalMembers = [];
        const memberStatusObj = {
            accepted: iconCheckmark,
            pending: iconQuestion,
            rejected: iconDecline,
        };
        await Promise.all(
            membersArray.map(async member => {
                if (roles.includes(member.role)) {
                    const fallBackImage =
                        member.image && (await this._cloudinaryService.getCloudinaryURL(member.image));
                    const memberObj = {
                        id: member.id,
                        name: member.name
                            ? member.name.slice(0, Constant.MEMBER_NAME_LIMIT)
                            : Constant.MEMBER_FALLBACK.name,
                        role: member.role,
                        createdAt: member.createdAt,
                        image: member.fullAvatarUrl || fallBackImage,
                        inviteStatus: member.inviteStatus,
                        inviteStatusIcon: memberStatusObj[member.inviteStatus],
                        blurb: member.blurb,
                        email: member.email,
                        state: member.state,
                        order: member.order,
                    };
                    totalMembers.push(memberObj);
                }
            }),
        );
        return totalMembers;
    };

    getZoneAbbr = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
        });
        return zone[0] ? zone[0].abbr : 'EST';
    };

    setTimeStamp = (startTime, endTime) => {
        const start = DateTime.fromISO(startTime, { zone: 'utc' })
            .toLocal()
            .toFormat('LLL d, yyyy | h:mma');
        const end = DateTime.fromISO(endTime, { zone: 'utc' })
            .toLocal()
            .toFormat('h:mma');
        const abbr = this.getZoneAbbr();
        return [start, ' - ', end, '\t', abbr].join('');
    };

    setClassForListing = members => {
        members.length > 3
            ? this.setState({
                  classForListing: 'room-detail__speakers-list room-detail__speakers-list--add-height',
              })
            : this.setState({
                  classForListing: 'room-detail__speakers-list',
              });
    };

    sortMembers = members => {
        members.sort((m1, m2) => {
            if (m1.order === null) {
                return 1;
            } else if (m2.order === null) {
                return -1;
            }
            if (m1.order < m2.order) {
                return -1;
            }
            if (m1.order > m2.order) {
                return 1;
            }
            return 0;
        });
    };

    getRoomSideBarDetails = async roomDetails => {
        const roomId = roomDetails.roomId;
        this.setState({ roomId });
        const name = roomDetails.name;
        const isShareAble = roomDetails.isShareAble;
        const isLoungeEnabled = roomDetails.isLoungeEnabled;
        const heroImage = roomDetails.image;
        const timeStamp = this.setTimeStamp(roomDetails.startTime, roomDetails.endTime);
        const description = this._utilityService.replaceHyperLinkWithAnchor(roomDetails.description);
        const boardId = roomDetails.board.boardId;
        const boardName = roomDetails.board.name;
        const orgLogo = this._utilityService.getStorageItems('orgLogo');
        const members = await this.getMembersArray(roomDetails.participants, [
            Constant.ROOM_MEMBERS.SPEAKER,
            Constant.ROOM_MEMBERS.MODERATOR,
            Constant.BOARD_MEMBERS.DIRECTOR,
            Constant.BOARD_MEMBERS.PRODUCER,
        ]);
        this.sortMembers(members);
        this.setClassForListing(members);
        return {
            roomId,
            name,
            heroImage,
            timeStamp,
            description,
            boardId,
            boardName,
            orgLogo,
            members,
            isShareAble,
            isLoungeEnabled,
        };
    };

    fetchRoomDetails = async (roomId, noFilter = true) => {
        const roomDetails = await this._roomDetailService.getRoomDetail(roomId, noFilter);
        return roomDetails;
    };

    setRoomTableAndSideBarDetails = (roomTableDetails, roomSideBarDetails) => {
        this.setState({ roomTableDetails, roomSideBarDetails });
    };

    setRoomDetails = async () => {
        const roomId = this.props.match.params.roomId;
        const roomDetails = await this.fetchRoomDetails(roomId);
        const members = await this.getMembersArray(roomDetails.participants, [
            Constant.ROOM_MEMBERS.GUEST,
            Constant.ROOM_MEMBERS.ATTENDEE,
        ]);
        const roomSideBarDetails = await this.getRoomSideBarDetails(roomDetails);
        await this.setRoomTableAndSideBarDetails(members, roomSideBarDetails);
    };

    updateUserRole = async (roomId, memberId, role) => {
        try {
            const param = { roomId, memberId, role };
            this.props.startLoading(true);
            await this._roomDetailService.updateRoomMember(param);
            if (role === Constant.ROOM_MEMBERS.ATTENDEE) {
                await this._roomDetailService.updateSpeakerListOrder(roomId, [{ userId: memberId, order: null }]);
            }
            await this.setRoomDetails();
            this.props.startLoading(false);
        } catch (e) {
            if (e.responseCode && e.responseCode === Constant.STATUS_CODE.NOT_ACCEPTABLE) {
                this.props.showHideErrorToaster({
                    show: true,
                    message: e.message,
                    bgColor: '#ff3547',
                });
            }
            this.props.startLoading(false);
        }
    };

    deleteMemberFromRoom = async (roomId, memberId) => {
        const param = { roomId, memberId };
        this.props.startLoading(true);
        await this._roomDetailService.deleteRoomMember(param);
        await this.setRoomDetails();
        this.props.startLoading(false);
    };

    setUserInfo = async () => {
        const userInfo = await this._userService.getUser();
        this.setState({ userInfo });
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.props.startLoading(true);
        try {
            await this.setRoomDetails();
            await this.setUserInfo();
        } catch (e) {
            this.props.history.replace({
                pathname: `/organization/${this.props.match.params.id}/boards/${this.props.match.params.boardId}`,
            });
        }
        this.props.startLoading(false);
        let iconButton = document.querySelector('.icon-button');
        if (iconButton) {
            iconButton.addEventListener('mouseleave', () => {
                this.setState({ ...this.state, copyMessage: Constant.ROOM_DETAILS_COPY_MESSAGE });
            });
        }
    }

    onSelectMenu = async (menuItem, memberId, boardId) => {
        switch (menuItem) {
            case 'Move to Audience':
                await this.updateUserRole(this.state.roomId, memberId, Constant.ROOM_MEMBERS.ATTENDEE);
                break;

            case 'Promote to Moderator':
                await this.updateUserRole(this.state.roomId, memberId, Constant.ROOM_MEMBERS.MODERATOR);
                break;

            case 'Promote to Speaker':
                await this.updateUserRole(this.state.roomId, memberId, Constant.ROOM_MEMBERS.SPEAKER);
                break;

            case 'Edit Profile':
                this.props.history.push(`/profile/${memberId}/${boardId}`);
                break;

            case 'Remove from Room':
                await this.deleteMemberFromRoom(this.state.roomId, memberId);
                break;

            default:
                break;
        }
    };

    onListClick = () => {};

    onLabelClick = () => {
        this.setState({
            collapseOpen: !this.state.collapseOpen,
            classForTruncate: !this.state.classForTruncate,
        });
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        try {
            this.props.startLoading(true);
            const members = this.reorder(
                this.state.roomSideBarDetails.members,
                result.source.index,
                result.destination.index,
            );

            const roomId = this.props.match.params.roomId;
            this._roomDetailService.updateSpeakerListOrder(
                roomId,
                members.map((member, index) => ({ userId: member.id, order: index + 1 })),
            );
            this.setState({
                ...this.state,
                roomSideBarDetails: { ...this.state.roomSideBarDetails, members },
            });
            this.props.startLoading(false);
        } catch (e) {
            this.props.startLoading(false);
        }
    };

    onInviteClick = () => {
        this.props.history.replace(
            `/organization/${this.props.match.params.id}/boards/${this.props.match.params.boardId}/rooms/${this.props.match.params.roomId}/${this.state.roomSideBarDetails.name}/room-invite`,
        );
    };

    copyToClipBoard = async e => {
        e.persist();
        this.setState({ ...this.state, copyMessage: Constant.ROOM_DETAILS_COPIED_MESSAGE });
        await navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}/room/invite?roomId=${this.state.roomId}`,
        );
    };

    render() {
        return (
            <div className="room-detail">
                <BackComponent
                    link={`/organization/${this.props.match.params.id}/boards/${this.props.match.params.boardId}`}
                ></BackComponent>
                <Row className="room-detail__row">
                    <Col md="4" xs="12" className="room-detail__left-col">
                        {this.state.roomSideBarDetails && (
                            <RoomSideBarComponent
                                onDragEnd={this.onDragEnd}
                                details={this.state.roomSideBarDetails}
                                collapseOpen={this.state.collapseOpen}
                                onLabelClick={this.onLabelClick}
                                onSelectMenu={this.onSelectMenu}
                                classForTruncate={this.state.classForTruncate}
                                classForListing={this.state.classForListing}
                                copyToClipBoard={this.copyToClipBoard}
                                copyMessage={this.state.copyMessage}
                                roomLink={`${window.location.protocol}//${window.location.host}/room/invite?roomId=${this.state.roomId}`}
                            />
                        )}
                    </Col>

                    <Col md="8" xs="12" className="room-detail__right-col">
                        {this.state.roomTableDetails && (
                            <React.Fragment>
                                <div className="room-detail__attendee-head">
                                    <h5 className="room-detail__attendee-count">
                                        Attendees | {this.state.roomTableDetails.length}
                                    </h5>
                                    <ButtonComponent
                                        className={'room-detail__invite-btn br__btn-theme-filled'}
                                        name={'Invite Attendees'}
                                        onclick={this.onInviteClick}
                                    />
                                </div>

                                <RoomDataTableComponent
                                    details={this.state.roomTableDetails}
                                    boardId={this.props.match.params.boardId}
                                    onClick={this.onListClick}
                                    onSelectMenu={this.onSelectMenu}
                                />
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(null, mapDispatchToProps)(RoomDetailContainer);
