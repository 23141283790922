import { Http } from '../../../../core';

export default class GuestPrivateRoomService {
    /**
     *
     * @name createRoom
     * @param payload {object type}
     * @desc this will be return the list of rooms
     * @return {Promise}
     */
    createRoom = payload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post('/room/private', payload)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(null);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     *
     * @name createRoom
     * @param payload {object type}
     * @desc this will be return the list of rooms
     * @return {Promise}
     */
    canCreatePrivateRoom = payload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get('/room/canCreatePrivateRoom')
                .then(_successLog => {
                    const data = _successLog && _successLog.data ? _successLog.data : null;
                    resolve(data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
