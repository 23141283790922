import React from 'react';
import { Modal } from 'react-bootstrap';
import './room-invite-modal-component.style.scss';
import iconCancel from '../../../../assets/images/ic-cancel.svg';
import invitationIcon from '../../../../assets/images/ic-invite-sent.svg';

const roomInviteComponent = props => {
    return (
        <>
            <Modal
                className="theme-modal br__success-modal"
                data-test="delete_success_modal"
                centered
                show={props.showRoomInviteModal}
                onHide={props.closeRoomInvitationModal}
            >
                <div
                    onClick={() => {
                        props.closeRoomInvitationModal();
                    }}
                    data-test="close_modal_div"
                >
                    <img
                        src={iconCancel}
                        className="br__create-organization-icon-close"
                        alt=""
                        data-test="cancel_icon"
                    />
                </div>
                <Modal.Body className="theme-modal__body">
                    <img
                        src={invitationIcon}
                        className="br__create-organization-icon-check"
                        data-test="check_icon"
                        alt=""
                    />
                    <h4 className="theme-modal-subheading" data-test="modal_header">
                        {props.roomInvitationMessage}
                    </h4>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default roomInviteComponent;
