import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import './file-select-component.style.scss';
import { CloudinaryImage, ErrorMessageComponent, MenuComponent } from '..';
import { Constant } from '../../services';
import iconCamera from '../../../assets/images/ic-camera.svg';
import iconCameraBlue from '../../../assets/images/ic-camera.png';
import { Utility } from '../..';

const FileSelectComponent = props => {
    const { id } = { ...props };
    const _utilityService = new Utility();
    let label;
    const classes = props.memberDetails
        ? {
              formFileClass: 'form-group--file text-center',
              formLabelClass: 'form-group--file-label position-relative',
              fileLabelClass: 'form-group--file-span d-none',
              logoBrowseClass: 'linkcolor',
          }
        : {
              formFileClass: 'form-group--file',
              formLabelClass: 'form-group--file-label',
              fileLabelClass: 'form-group--file-span',
              logoBrowseClass: 'linkcolor logo-browse',
          };

    const onSelectMenu = item => {
        switch (item) {
            case Constant.IMAGE_SELECTION_OPTIONS.IMAGE_LIBRARY:
                props.openLibrary();
                break;
            case Constant.IMAGE_SELECTION_OPTIONS.BROWSE_MY_FILES:
                label.click();
                break;
        }
    };

    const dragOver = e => {
        e.preventDefault();
    };

    const dragEnter = e => {
        e.preventDefault();
    };

    const dragLeave = e => {
        e.preventDefault();
    };

    const fileDrop = async e => {
        e.preventDefault();
        const image = _utilityService.validateImageFile(e.dataTransfer.files[0], Constant.FILE_UPLOAD_LIMIT);
        if (image.isValid) {
            if (props.isProfilePicture) {
                if (e.dataTransfer.files[0]) {
                    await props.fileHandling.setSelectedLogo(e.dataTransfer.files[0]);
                }
                return;
            }
            props.selectedImage
                ? props.selectedImage(e.dataTransfer.files[0])
                : await props.fileHandling.setSelectedLogo(e.dataTransfer.files[0]);
            return;
        }
        props.validateImageFileMessage(image.message);
    };

    const selectLogo = async e => {
        e.preventDefault();
        const image = _utilityService.validateImageFile(e.target.files[0], Constant.FILE_UPLOAD_LIMIT);
        if (image.isValid) {
            if (props.isProfilePicture) {
                if (e.target.files[0]) {
                    await props.fileHandling.setSelectedLogo(e.target.files[0]);
                }
                return;
            }
            props.selectedImage
                ? props.selectedImage(e.target.files[0])
                : props.fileHandling.setSelectedLogo(e.target.files[0]);
            return;
        }
        props.validateImageFileMessage(image.message);
    };

    return (
        <Form.Group controlId={id} className={classes.formFileClass}>
            <Form.Label ref={input => (label = input)} className={classes.formLabelClass}>
                <span id={`${id}-label`} className={classes.fileLabelClass}>
                    {props.label}
                </span>
                <div
                    id={`${id}-image-container`}
                    className="form__uploadlogo form__upload-background"
                    onDragOver={e => {
                        dragOver(e);
                    }}
                    onDragEnter={e => {
                        dragEnter(e);
                    }}
                    onDragLeave={e => {
                        dragLeave(e);
                    }}
                    onDrop={e => {
                        fileDrop(e);
                    }}
                >
                    {props.fileHandling.localImagePath ? (
                        <React.Fragment>
                            {props.isProfilePicture ? (
                                <CloudinaryImage
                                    id={`${id}-image`}
                                    className="form_selected_image img-fluid"
                                    alt="info"
                                    isAsync={props.isProfilePicture}
                                    cloudinaryImageKey={props.fileHandling.cloudinaryImageKey}
                                    src={props.fileHandling.localImagePath}
                                    setCloudinaryImageUrl={props.fileHandling.setCloudinaryImageUrl}
                                />
                            ) : (
                                <img
                                    id={`${id}-image`}
                                    className="form_selected_image img-fluid"
                                    src={props.fileHandling.localImagePath}
                                    alt="info"
                                />
                            )}
                            {props.memberDetails && (
                                <img
                                    src={props.cameraIcon ? iconCameraBlue : iconCamera}
                                    className="form-group--file__camera-img"
                                    alt=""
                                />
                            )}
                        </React.Fragment>
                    ) : (
                        <>
                            <h3 id={`${id}-ratio`} className="logo__ratio">
                                {props.ratio}
                            </h3>
                            <div id={`${id}-drag-container`} className="logo__drag">
                                <span id={`${id}-drag-label`}>Drag &amp; Drop</span>
                                <span id={`${id}-or`}>or</span>

                                {props.isLibraryIncluded ? (
                                    <div className="logo-browse-wrapper">
                                        <span id={`${id}-drag-browse`} className={classes.logoBrowseClass}>
                                            Browse My Files
                                        </span>
                                        <span
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                props.openLibrary();
                                            }}
                                            id={`${id}-drag-browse`}
                                            className="linkcolor logo-library"
                                        >
                                            Unsplash
                                        </span>
                                    </div>
                                ) : (
                                    <span id={`${id}-drag-browse`} className={classes.logoBrowseClass}>
                                        Browse My Files
                                    </span>
                                )}
                            </div>
                            {props.memberDetails && (
                                <img
                                    src={props.cameraIcon ? iconCameraBlue : iconCamera}
                                    className="form-group--file__camera-img"
                                    alt=""
                                />
                            )}
                        </>
                    )}
                </div>
                {props.memberDetails ? (
                    ''
                ) : (
                    <ErrorMessageComponent
                        id={`${id}-image-validation-message`}
                        message={props.validationErrorMessage.logoValidation}
                    />
                )}
            </Form.Label>
            {props.memberDetails ? (
                <div>
                    <ErrorMessageComponent
                        id={`${id}-image-validation-message`}
                        message={props.validationErrorMessage.logoValidation}
                    />
                </div>
            ) : (
                ''
            )}
            <Form.Control
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={e => {
                    selectLogo(e);
                }}
                onClick={e => (e.target.value = null)}
            />
            {props.isLibraryIncluded && props.fileHandling.localImagePath ? (
                <div
                    onDragOver={e => {
                        dragOver(e);
                    }}
                    onDragEnter={e => {
                        dragEnter(e);
                    }}
                    onDragLeave={e => {
                        dragLeave(e);
                    }}
                    onDrop={e => {
                        fileDrop(e);
                    }}
                >
                    <MenuComponent
                        id={`file-select`}
                        menuItems={[
                            {
                                name: Constant.IMAGE_SELECTION_OPTIONS.IMAGE_LIBRARY,
                            },
                            {
                                name: Constant.IMAGE_SELECTION_OPTIONS.BROWSE_MY_FILES,
                            },
                        ]}
                        onSelect={item => {
                            onSelectMenu(item);
                        }}
                    />
                </div>
            ) : (
                ''
            )}
        </Form.Group>
    );
};

export default FileSelectComponent;
