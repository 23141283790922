import React from 'react';
import '../organization-header/organization-header.style.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import dots from '../../../../assets/images/icon-dots.svg';
import { Constant, ImageComponent } from '../../../../shared';

const OrgHeader = props => {
    let list, producers;
    if (props.producers) {
        producers = [...props.producers];
        if (producers.length > 3) {
            producers = producers.slice(0, 3);
        }
        list = producers.map((producer, index) => (
            <p
                key={producer.id}
                id={`producer-name-${index + 1}`}
                className="producer__name"
                data-test="exec_producer_names_render"
            >
                {producer.name ? producer.name.slice(0, Constant.MEMBER_NAME_LIMIT) : Constant.MEMBER_FALLBACK.name}
            </p>
        ));
    }

    const membersPopover = members => {
        return (
            <Popover id="popover-basic" className="executiveproducer__popover">
                <Popover.Content className="ep__popovercontent">
                    <h5 className="head">Executive Producers</h5>
                    <ul>
                        <li className="role__list__item">{members.map(m => m.name).join(', ')}</li>
                    </ul>
                </Popover.Content>
            </Popover>
        );
    };

    return (
        <div className="organization-detail__meta">
            <ImageComponent
                id="org-logo"
                src={props.img}
                alt=""
                placeholder={null}
                className="organization-detail__logo img-fluid"
                data-test="org_logo_render"
            />
            <h2 id="" className="organization-detail__org-name" data-test="org_name_render">
                {props.title}
            </h2>
            <div className="organization-detail__producers">
                <h6
                    id="executive-producer-header"
                    className="organization-detail__producer-title"
                    data-test="exec_producer_header_render"
                >
                    Executive Producers
                </h6>
                {list}&nbsp;
                {props.producers && props.producers.length > 3 && (
                    <OverlayTrigger
                        id="create-board-overlay"
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={membersPopover(props.producers)}
                        triggerId={'members-info-trigger'}
                    >
                        <img src={dots} className="dropdown__dots" alt="" />
                    </OverlayTrigger>
                )}
            </div>
        </div>
    );
};

export default OrgHeader;
