import React from 'react';
import './menu-component.style.scss';
import { Dropdown } from 'react-bootstrap';

const menuComponent = props => {
    const { menuItems, onSelect, id } = { ...props };

    return (
        <>
            <Dropdown id={`${id}-container`} className="menu-dropdown menu__dropdown" alignRight>
                <Dropdown.Toggle
                    as="div"
                    id={`${id}-dropdown-basic`}
                    className="menu-dropdown__toggle"
                ></Dropdown.Toggle>

                <Dropdown.Menu id={`${id}-menu`} className="menu-dropdown__menu menu__dropdownmenu">
                    {menuItems.map((item, index) => (
                        <Dropdown.Item
                            id={`${id}-item-${index}`}
                            key={`${id}-item-${index}`}
                            onClick={() => {
                                onSelect(item.name);
                            }}
                            className={`menu-dropdown__menu-item ${
                                item.isDanger ? 'menu-dropdown__menu-item--error' : ''
                            }`}
                        >
                            {item.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default menuComponent;
