import React from 'react';
import { Constant } from '../../../shared';
import './successful-component.style.scss';

const SuccessfulComponent = props => {
    return (
        <div className="br__register-success" data-test="register_success">
            <h4 className="br__register-success-heading">
                {props.isAlreadyRegistered ? Constant.USER_ALREADY_REGISTERED : Constant.USER_REGISTER_SUCCESS}
            </h4>
            <p className="br__register-success-desc">
                Download the iOS app to view room invitations and build your profile.
            </p>
        </div>
    );
};

export default SuccessfulComponent;
