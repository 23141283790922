import { Http } from '../../../../core';

export class RoomJoinService {
    /**
     * @name getJoinRoom
     * @param {string} roomId required for joining room
     * @desc Will join the room
     * @return {Promise}
     */
    getJoinRoom = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/room/join/${roomId}`)
                .then(result => {
                    resolve(result.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    /**
     * @name getRoomDetail
     * @param {string} roomId required for specific room
     * @param {boolean} noFilter flag for backend to get room with or without filter
     * @desc Will fetch specific room detail with or without filter
     * @return {Promise}
     */
    getRoomDetail = (roomId, noFilter = false) => {
        const url = noFilter ? `room/details/${roomId}?noFilter=${noFilter}` : `room/details/${roomId}`;
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(url)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getStreamToken
     * @param {string} roomId required for specific room
     * @param {boolean} noFilter flag for backend to get room with or without filter
     * @desc Will fetch specific room detail with or without filter
     * @return {Promise}
     */
    getStreamToken = (userId, roomId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/room/stream/token/${userId}?roomId=${roomId}`)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getVoxeetToken
     * @desc Will get voxeet token
     * @return {Promise}
     */
    getVoxeetToken = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/voxeet/token`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    /**
     * @name updateUserState
     * @desc Will update user state against room Id
     * @return {Promise}
     */
    updateUserState = (roomId, userId, reqPayload) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.patch(`/room/state/${roomId}/${userId}`, reqPayload)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    /**
     * @name deleteConference
     * @desc Will delete the conference against roomId
     * @return {Promise}
     */
    deleteConference = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.delete(`/room/${roomId}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    startLounge = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post('/lounge', { roomId })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    joinLounge = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.patch(`/lounge/accept/${roomId}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    inquireLounge = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/lounge/inquireParticipation/${roomId}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };
}
