import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import './room-lounges-modal.style.scss';
const LoungeTimerExpired = props => {
    return (
        <>
            <Modal
                className="leaveguestroom__modal lounge-invite-expire"
                centered
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
            >
                <Modal.Body>
                    <p className="expired-timer">00:00</p>
                    <h4 className="modal__heading">Lounge Invitation Expired</h4>

                    <p className="modal__desc">All of the lounges have begun and are closed to new attendees.</p>
                </Modal.Body>
                <Modal.Footer className="modal__footer">
                    <Button variant="light" className="btn__themeoutline" onClick={props.onClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export { LoungeTimerExpired };
