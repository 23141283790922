import React from 'react';
import './guest-footer.style.scss';
import appStore from '../../../assets/images/app-store.png';
import betaLogo from '../../../assets/images/beta-logo-new.png';
import { Link } from 'react-router-dom';

export class GuestFooter extends React.Component {
    render() {
        return (
            <div className="guest-footer">
                <div className="guest-footer__left">
                    <img src={betaLogo} alt="" className="guest-footer__logo" />
                    <div>
                        <p className="guest-footer__text">
                            &#169; 2024 Boardroom One |{' '}
                            <a
                                href="https://boardroomone.notion.site/Privacy-Policy-65305b9070b042ef9be8f5296c46a014"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                        </p>
                        <a href="mailto:info@boardroom.one" className="guest-footer__text">
                            info@boardroom.one
                        </a>
                    </div>
                </div>
                <div className="guest-footer__right">
                    <Link
                        to="javascript:void(0)"
                        onClick={e => {
                            e.preventDefault();
                            window.open(process.env.REACT_APP_MOBILE_APPLICATION_DOWNLOAD_URL, '_blank');
                        }}
                    >
                        <img src={appStore} className="guest-footer__app-img" alt="appstore" />
                    </Link>
                </div>
            </div>
        );
    }
}

export default GuestFooter;
