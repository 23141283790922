import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header, GuestHeader, GuestFooter, GuestSidenav } from '../../';
import './layout.style.scss';
import { Utility, Constant } from '../../../shared';
import { UserService } from '../../services';
const DRAWER_TOGGLE_LEVERAGE = 1200;

function listenOnResize(drawerToggle) {
    window.addEventListener('resize', event => {
        if (event.target.innerWidth > DRAWER_TOGGLE_LEVERAGE) {
            drawerToggle(true);
            return;
        }
        drawerToggle(false);
    });
}

function Layout(props) {
    const [drawerOpen, setDrawerToggle] = React.useState(window.innerWidth > DRAWER_TOGGLE_LEVERAGE ? true : false);
    const _userService = new UserService();
    const _utilityService = new Utility();
    const handleDrawerToggle = () => {
        setDrawerToggle(!drawerOpen);
    };
    const navigateToHomePage = () => {
        if (props.isGuest) {
            const isAnonymous = _userService.isAnonymous();
            if (isAnonymous) {
                props.history.push(
                    `/guest-room-detail/${_utilityService.getSessionStorageItems(
                        Constant.SESSION_STORAGE_KEYS.ROOM_ID,
                    )}`,
                );
                return;
            }
            props.history.push('/guest-dashboard');
            return;
        }
        props.history.push('/');
    };
    listenOnResize(setDrawerToggle);
    return (
        <div className="layout">
            <div className="d-none"></div>
            <div className="layout__container">
                <>
                    {props.isGuest ? (
                        props.isGuestHeader ? (
                            <GuestHeader {...props} navigateToHomePage={navigateToHomePage} user={props.user} />
                        ) : (
                            ''
                        )
                    ) : (
                        <Header navigateToHomePage={navigateToHomePage} user={props.user} />
                    )}
                    {props.isGuest ? props.isGuestSidenav ? <GuestSidenav /> : '' : ''}
                    <div className="layout__content">{props.children}</div>
                    {props.isGuest ? props.isGuestFooter ? <GuestFooter /> : '' : ''}
                </>
            </div>
        </div>
    );
}

export default withRouter(Layout);
