import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Draggable from 'react-draggable-component';
import './screen-share-full-screen-modal.style.scss';
import minimizeScreen from '../../../../assets/images/minimize-screen.png';
import btnMuted from '../../../../assets/images/btn-muted.png';
import btnUnmuted from '../../../../assets/images/btn-unmuted.png';
import { useState } from 'react';
import cameraOn from '../../../../assets/images/start-video.png';
import cameraOff from '../../../../assets/images/stop-video.png';
import roomActionBtn from '../../../../assets/images/room-action-btn.png';
import { BtnDropDownComponent, Constant, IconButton } from '../../../../shared';

const ScreenShareFullScreenModal = props => {
    const [speakerVideoStream, setSpeakerVideoStream] = useState(null);
    const [activeSpeakerAvatar, setActiveSpeakerAvatar] = useState('');
    const [isVideoStarted, setIsVideoStarted] = useState(false);
    const [userCanSpeak, setUserCanSpeak] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [isModerator, setIsModerator] = useState(false);
    const [isGreenRoom, setIsGreenRoom] = useState(false);
    const [moderatorActionMenuItems, setModeratorActionMenuItems] = useState([]);
    const [isAttendee, setIsAttendee] = useState(false);
    const [handRaised, setHandRaised] = useState(false);

    useEffect(() => {
        initializeStates();
    }, [props]);

    const initializeStates = () => {
        setSpeakerVideoStream(props.speakerVideoStream);
        setActiveSpeakerAvatar(props.avatarUrl);
        setIsVideoStarted(props.isVideoStarted);
        setUserCanSpeak(props.canSpeak);
        setIsMuted(props.isMute);
        setIsModerator(props.isModerator);
        setIsGreenRoom(props.isGreenRoom);
        setModeratorActionMenuItems(props.moderatorActionMenuItems);
        setIsAttendee(props.isAttendee);
        setHandRaised(props.handRaised);
    };

    useEffect(() => {
        if (props.speakerVideoStream) {
            const videoTagId = 'full-screen-active-speaker-video';
            const videoNode = document.getElementById(videoTagId);
            navigator.attachMediaStream(videoNode, speakerVideoStream.stream);
        }
    }, [speakerVideoStream]);

    return (
        <div onKeyUp={props.onKeyUp}>
            <Modal
                className="full-screen-modal"
                data-test="createOrganization__modal"
                centered
                show={props.show}
                onHide={props.handleClose}
            >
                <img
                    src={minimizeScreen}
                    alt="img"
                    onClick={props.handleClose}
                    className="full-screen-modal__minize-icon img-fluid"
                    title="Minimize Screen"
                />
                <Modal.Body className="full-screen-modal__body">
                    <video
                        id={'full-screen-share-video'}
                        className={`img-fluid `}
                        playsInline={true}
                        autoPlay="autoplay"
                        muted={true}
                    ></video>

                    <div className="screenshare-footer">
                        <div className="d-flex align-items-center">
                            <Button
                                variant="light"
                                onClick={props.handleShowLeaveRoom}
                                className="btn__themeoutline mr-19"
                            >
                                Leave Room
                            </Button>
                            {isAttendee && (
                                <Button
                                    variant="light"
                                    className="btn__themeoutline btn-hand"
                                    onClick={props.handleHandGestureOperation}
                                >
                                    {handRaised
                                        ? Constant.USER_HAND_ACTION_STATUS.LOWER_HAND
                                        : Constant.USER_HAND_ACTION_STATUS.RAISE_HAND}
                                </Button>
                            )}
                            {isModerator && (
                                <BtnDropDownComponent
                                    src={roomActionBtn}
                                    hoverClass=".btn__themeoutline"
                                    isGreenRoom={isGreenRoom}
                                    menuItems={moderatorActionMenuItems}
                                    onSelect={item => {
                                        props.onSelectModeratorActionMenu(item);
                                    }}
                                />
                            )}
                        </div>
                        {userCanSpeak && (
                            <div className="guestroom__disclaimer-wrapper">
                                <IconButton
                                    image={isVideoStarted ? cameraOff : cameraOn}
                                    text={isVideoStarted ? 'Stop Video' : 'Start Video'}
                                    click={props.onCameraButtonClick}
                                    cssClass="guestroom-video-btn"
                                    iconClass="guestroom-video-btn-icon"
                                />
                                <img
                                    onClick={props.onMuteButtonClick}
                                    src={isMuted ? btnMuted : btnUnmuted}
                                    alt="btn"
                                    className="guestroom__mute-btn cursor-pointer"
                                />
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Draggable>
                    <div className="draggable-content active-video-container">
                        {speakerVideoStream ? (
                            <video
                                id="full-screen-active-speaker-video"
                                className="active-video-display video"
                                playsInline={true}
                                autoPlay="autoplay"
                                muted={true}
                                draggable="false"
                            ></video>
                        ) : (
                            <img className="active-video-display" draggable="false" src={activeSpeakerAvatar || ''} />
                        )}
                    </div>
                </Draggable>
            </Modal>
        </div>
    );
};

export default ScreenShareFullScreenModal;
