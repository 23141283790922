import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import './guest-modal-component.style.scss';
import { InputComponent } from '../../../../shared';

const guestModalComponent = props => {
    return (
        <>
            <Modal
                className="theme-modal"
                data-test="edit_profile_modal"
                centered
                show={props.modal}
                onHide={props.handleClose}
            >
                <Modal.Body className="theme-modal__body">
                    <Form className="theme-modal__form co__form">
                        <Form.Group controlId="formName">
                            <Form.Label>NAME</Form.Label>
                            <InputComponent
                                value={props.formDetails.name}
                                textId={'guest-name-input'}
                                charLimit={155}
                                onChange={props.setName}
                                errorMessage={props.validationErrorMessage.nameValidation}
                                onKeyDown={props.onKeyDown}
                            />{' '}
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>EMAIL ADDRESS</Form.Label>
                            <InputComponent
                                value={props.formDetails.email}
                                textId={'guest-form-email-address'}
                                classes={'br__form-control'}
                                onChange={props.setEmail}
                                errorMessage={props.validationErrorMessage.emailValidation}
                                onKeyDown={props.onKeyDown}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="comodal__footer">
                    <ButtonComponent
                        id={'edit-profile-button-close'}
                        className={'btn__link'}
                        name="Cancel"
                        onclick={props.handleClose}
                    />
                    <ButtonComponent
                        id={'edit-profile-button-submit'}
                        className={'br__btn-theme-filled br__btn-theme-filled--width-93'}
                        name="Add Guest"
                        onclick={props.submit}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default guestModalComponent;
