import React from 'react';
import { Modal } from 'react-bootstrap';
import './delete-success-modal-component.style.scss';
import iconCheck from '../../../assets/images/ic-checkmark-blue.svg';
import iconCancel from '../../../assets/images/ic-cancel.svg';

const deleteModalSuccessComponent = props => {
    return (
        <>
            <Modal
                className="theme-modal br__success-modal"
                data-test="delete_success_modal"
                centered
                show={props.showSuccessDeleteModal}
                onHide={props.closeSuccessDeleteModal}
            >
                <div
                    onClick={() => {
                        props.closeSuccessDeleteModal();
                    }}
                    data-test="close_modal_div"
                >
                    <img
                        src={iconCancel}
                        className="br__create-organization-icon-close"
                        alt=""
                        data-test="cancel_icon"
                    />
                </div>
                <Modal.Body className="theme-modal__body">
                    <img src={iconCheck} className="br__create-organization-icon-check" data-test="check_icon" alt="" />
                    <h4 className="theme-modal-subheading" data-test="modal_header">
                        {props.deletionSuccessModalInfo.modalMessage}
                    </h4>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default deleteModalSuccessComponent;
