import React from 'react';
import { Popover } from 'react-bootstrap';
const MyPopover = ({ heading, subHeading, items }) => {
    return (
        <Popover.Content className="ep__popovercontent">
            <h5 className="head">{heading && heading}</h5>
            <h4 className="popover__role">{subHeading && subHeading}</h4>
            {items &&
                items.length &&
                items.map((item, i) => (
                    <ul key={i}>
                        <li className="role__list__item">{item}</li>
                    </ul>
                ))}
        </Popover.Content>
    );
};
export default MyPopover;
