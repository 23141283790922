import { Http } from '../../../../core';

export class MembersDetailDashboardService {
    /**
     * @name getUser
     * @param {string} UserId required for specific user
     * @param {string} boardId required for specific board
     * @desc Will fetch specfic user and his/her board
     * @return {Promise}
     */
    getUserById = (userId, boardId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/profile/${userId}/${boardId}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };
    /**
     * @name getOrganizationDetails
     * @param {object} param contains organization id required for specific organization
     * @desc Will fetch specific organizations detail
     * @return {Promise}
     */
    getOrganizationDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/organization/details/${param.id}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };

    /**
     * @name getBoardDetails
     * @param {object} param contains boardId required for specific board
     * @desc Will fetch specific board detail
     * @return {Promise}
     */
    getBoardDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/board/details/${param.boardId}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };

    /**
     * @name getRooms
     * @param {object} param contains boardId required for specific board, pageSize and pageIndex required for  pagination
     * @desc Will fetch specific room with pagination
     * @return {Promise}
     */
    getRooms = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/room?boardId=${param.boardId}&pageSize=${param.pageSize}&pageIndex=${param.pageIndex}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
    /**
     * @name getUser
     * @desc will fetch loggedIn user
     * @return {Promise}
     */
    getUser = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/me`)
                .then(async _successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };

    /**
     * @name updateProfile
     * @param {object} requestPayload request payload for board creation
     * @desc Will create an board
     * @return {Promise}
     */

    updateProfile = (requestPayload, userId, boardId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`user/profile/${userId}/${boardId}`, requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    console.log('failed');
                    reject(errorLog);
                });
        });
    };

    /**
     * @name deleteMember
     * @param {any} param -> {boardId, userId}
     * @desc Will delete board's member
     * @return {Promise}
     */

    deleteMember = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.delete(`board/user/${param.boardId}/${param.userId}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    console.log('failed');
                    reject(errorLog);
                });
        });
    };
}
