import React from 'react';
import { Modal } from 'react-bootstrap';
import './delete-modal-component.style.scss';
import { ButtonComponent } from '..';

const deleteModalComponent = props => {
    return (
        <>
            <Modal
                className="theme-modal"
                data-test="delete_modal"
                centered
                show={props.showDeleteModal}
                onHide={() => {
                    props.isConfirmDelete(false);
                }}
            >
                <Modal.Body className="theme-modal__body">
                    <h5 className="theme-modal-sub-heading" data-test="modal_header">
                        {props.modalInfo.modalHeader}
                    </h5>
                    <p className="theme-modal-text" data-test="modal_paragraph">
                        {props.modalInfo.modalParagraph}
                    </p>
                </Modal.Body>
                <Modal.Footer className="comodal__footer">
                    <ButtonComponent
                        id={'edit-profile-button-submit'}
                        className={'btn__link'}
                        data-test="delete_button"
                        name="Cancel"
                        onclick={() => {
                            props.isConfirmDelete(false);
                        }}
                    />
                    <ButtonComponent
                        id={'edit-profile-button-submit'}
                        data-test="cancel_button"
                        className={'br__btn-theme-filled br__btn-theme-filled--width-93'}
                        name="Delete"
                        onclick={() => {
                            props.isConfirmDelete(true);
                        }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default deleteModalComponent;
