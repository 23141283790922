import React from 'react';
import './drop-down-three-dots.style.scss';
import { Dropdown } from 'react-bootstrap';
import dots from '../../../assets/images/icon-dots.svg';

const threeDotsDropDownComponent = props => {
    const { menuItems, onSelect, id, dotClass, hoverClass, itemClass, menuClass } = { ...props };

    return (
        <>
            <Dropdown id={`${id}-container`} className="theme-dropdown theme__dropdown" alignRight>
                <Dropdown.Toggle as="div" id={`${id}-dropdown-basic`} className="theme-dropdown__toggle">
                    <img src={dots} className={`theme-dropdown__dots ${dotClass}`} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu id={`${id}-menu`} className={`theme-dropdown__menu theme__dropdownmenu ${menuClass}`}>
                    {menuItems.map((item, index) => (
                        <Dropdown.Item
                            id={`${id}-item-${index}`}
                            key={`${id}-item-${index}`}
                            onClick={() => {
                                onSelect(item.name);
                            }}
                            className={`theme-dropdown__menu-item ${
                                item.isDanger ? 'theme-dropdown__menu-item--error' : ''
                            } ${item.isBlue ? ' theme-dropdown__menu-item--blue' : ''} ${hoverClass} ${itemClass}`}
                        >
                            {item.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default threeDotsDropDownComponent;
