import React from 'react';
import { CarouselRoomInvite, ImageComponent } from '../../../../shared';
import './room-invite-slider.style.scss';

const RoomInviteSlider = props => {
    const { boards, allBoardsData, guestData, addAllButtonName } = props.boardInfo;
    return (
        <>
            <CarouselRoomInvite>
                <div
                    className="room-invite__card"
                    onClick={() => props.onCardClick('all')}
                    style={{ border: allBoardsData.isClicked ? '1px solid #1a74e2' : 'none' }}
                >
                    <div className="room-invite__card-header">
                        <ImageComponent src={''} className="room-invite__bg-img img-fluid" alt="" />
                        <ImageComponent src={''} className="room-invite__org-img img-fluid" alt="" />
                    </div>
                    <div className="room-invite__card-body">
                        <h4 className="room-invite__board-name">{allBoardsData.name}</h4>
                        <div className="room-invite__add-all">
                            <p className="room-invite__count">{allBoardsData.totalMembers}</p>
                            <button onClick={e => props.onAddAllClick(e, 'all')} className="room-invite__add-btn">
                                {addAllButtonName}
                            </button>
                        </div>
                    </div>
                </div>

                {boards.map((card, index) => (
                    <div
                        className="room-invite__card"
                        key={index}
                        onClick={() => props.onCardClick('board', card.id)}
                        style={{ border: card.isClicked ? '1px solid #1a74e2' : 'none' }}
                    >
                        <div className="room-invite__card-header">
                            <ImageComponent
                                src={card.bgImg}
                                placeholder={null}
                                className="room-invite__bg-img img-fluid"
                                alt=""
                            />
                            <ImageComponent
                                src={card.orgImg}
                                placeholder={null}
                                className="room-invite__org-img img-fluid"
                                alt=""
                            />
                        </div>
                        <div className="room-invite__card-body">
                            <h4 className="room-invite__board-name">{card.name}</h4>
                            <div className="room-invite__add-all">
                                <p className="room-invite__count">{card.totalMembers}</p>
                                <button
                                    className="room-invite__add-btn"
                                    onClick={e => props.onAddAllClick(e, 'board', card.id)}
                                >
                                    {addAllButtonName}
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
                <div
                    className="room-invite__card"
                    onClick={() => props.onGuestCardClick()}
                    style={{ border: guestData.isClicked ? '1px solid #1a74e2' : 'none' }}
                >
                    <div className="room-invite__card-header">
                        <ImageComponent src={''} className="room-invite__bg-img img-fluid" alt="" />
                        <ImageComponent src={''} className="room-invite__org-img img-fluid" alt="" />
                    </div>
                    <div className="room-invite__card-body">
                        <h4 className="room-invite__board-name">{guestData.name}</h4>
                        <div className="room-invite__add-all">
                            <p className="room-invite__count">{guestData.totalMembers}</p>
                            <button onClick={e => props.onAddAllGuestClick(e)} className="room-invite__add-btn">
                                {addAllButtonName}
                            </button>
                        </div>
                    </div>
                </div>
            </CarouselRoomInvite>
        </>
    );
};

export default RoomInviteSlider;
