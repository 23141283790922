/**
 * declare the actions in constants file and import here
 *
 * create the action function like this
 *
 *
 * export function <actionFunctionName>(param) {
 *        return { type: IMPORTED_ACTION_FROM_CONSTANTS, param };
 * }
 *
 */

import { SET_SOMETHING, SET_ACCESS_TOKEN, START_LOADER } from './';
import { SET_USER, SHOW_HIDE_ERROR_TOASTER, SELECT_USER } from './constants';

export function setSomeThing(someThing) {
    return { type: SET_SOMETHING, someThing };
}

export function setAccessToken(accessToken) {
    return { type: SET_ACCESS_TOKEN, accessToken };
}

export function startLoading(isLoading, isMessage, message) {
    return { type: START_LOADER, isLoading, isMessage, message };
}

export function showHideErrorToaster(errorToaster) {
    return { type: SHOW_HIDE_ERROR_TOASTER, errorToaster };
}

export function setUser(user) {
    return { type: SET_USER, user };
}

export function selectUser(isUserSelected) {
    return { type: SELECT_USER, isUserSelected };
}
