import React from 'react';
import './guest-sidenav.style.scss';
import { Link } from 'react-router-dom';
// style for icons
import '../../../assets/fonts/style.css';
import { Constant } from '../../../shared';

export class GuestSidenav extends React.Component {
    render() {
        return (
            <div className="guest-sidenav">
                <div className="guest-sidenav__inner-wrap">
                    <ul className="guest-sidenav__list">
                        {Constant.NAVLIST.map(item => (
                            <li className="guest-sidenav__item" key={item.id}>
                                <Link to={item.link} className="guest-sidenav__item-link">
                                    <span className={item.icon + ' guest-sidenav__item-icon'}></span>
                                    <span className="guest-sidenav__item-name">{item.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default GuestSidenav;
