import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthService, showHideErrorToaster, startLoading } from '../../';
import './reset-password.style.scss';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { InputComponent, Utility } from '../../../shared';
import logo from '../../../assets/images/logo.png';
import viewOff from '../../../assets/images/ic-view-off.svg';
import viewOn from '../../../assets/images/ic-view-on.svg';
import { DateTime } from 'luxon';
export class ResetPasswordContainer extends Component {
    fromGuest = false;
    _authService = new AuthService();
    _utilityService = new Utility();
    emailSchema = Yup.object().shape({
        email: Yup.string().email(),
    });

    constructor() {
        super();
        this.state = {
            userEmail: '',
            emailCode: '',
            password: '',
            confirmPassword: '',
            validationErrorMessage: {
                emailCodeValidation: '',
                passwordValidation: '',
                confirmPasswordValidation: '',
            },
            showPassword: false,
            showConfirmPassword: false,
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
    }

    componentDidMount = () => {
        const search = this.props.location.search;
        const userEmail = new URLSearchParams(search).get('email');
        this.fromGuest = new URLSearchParams(search).get('fromGuest');
        this.fromGuest = this.fromGuest && this.fromGuest === 'true' ? true : false;
        this.setState({ ...this.state, userEmail });
    };

    setEmailCode = emailCode => {
        this.setState({ ...this.state, emailCode });
    };

    resubmitCode = async () => {
        const resp = await this._authService.forgotPassword(this.state.userEmail);
        this.props.showHideErrorToaster({
            show: true,
            message: resp ? resp.message : 'New verification code is sent to your email.',
            bgColor: resp ? '#ff3547' : 'green',
        });
    };

    setPassword = password => {
        this.setState({
            ...this.state,
            password,
            validationErrorMessage: { ...this.state.validationErrorMessage, passwordValidation: '' },
        });
    };

    setConfirmPassword = confirmPassword => {
        this.setState({
            ...this.state,
            confirmPassword: confirmPassword,
            validationErrorMessage: { ...this.state.validationErrorMessage, confirmPasswordValidation: '' },
        });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    setUserNewPassword = async () => {
        if (!this.validateForm()) {
            return;
        }
        this.props.startLoading(true);
        const resp = await this._authService.forgotPasswordSubmit(
            this.state.userEmail,
            this.state.emailCode,
            this.state.password,
        );
        if (resp) {
            this.props.showHideErrorToaster({
                show: true,
                message: resp.message,
                bgColor: resp ? '#ff3547' : 'green',
            });
        } else if (resp && resp.code === 'ExpiredCodeException') {
            this.setState({
                ...this.state,
                validationErrorMessage: { ...this.state.validationErrorMessage, emailCodeValidation: resp.message },
            });
        } else if (!resp) {
            let route = '/';
            if (this.fromGuest) {
                route = '/guest-login';
            }
            this.props.history.replace(route);
            this.props.showHideErrorToaster({
                show: true,
                message: 'Password changed successfully.',
                bgColor: 'green',
            });
        }
        this.props.startLoading(false);
    };

    showHide = field => {
        let mutateStateObj = {
            ...this.state,
        };
        mutateStateObj[field] = this.state[field] ? false : true;
        this.setState(mutateStateObj);
    };

    validateForm = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        if (!this.state.emailCode) {
            validationErrorMessage.emailCodeValidation = 'Please enter a valid code.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.password) {
            validationErrorMessage.passwordValidation = 'Please provide a valid password.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this._utilityService.validatePassword(this.state.password)) {
            validationErrorMessage.passwordValidation =
                'Please make sure password should be of 8 characters with at least 1 digit and 1 uppercase letter.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        if (!this.state.confirmPassword || this.state.password !== this.state.confirmPassword) {
            validationErrorMessage.confirmPasswordValidation =
                'Please make sure the password and confirm password fields are same.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        this.setErrorMessages({
            ...this.state,
            validationErrorMessage: {
                emailCodeValidation: '',
                passwordValidation: '',
                confirmPasswordValidation: '',
            },
        });
        return true;
    };

    render() {
        return (
            <>
                <Form>
                    <div className="forgot-password">
                        <div className="forgot-password__block">
                            <img src={logo} alt="logo" className="forgot-password__logo img-fluid" />
                            <div className="forgot-password__inner">
                                <p className="forgot-password__text">
                                    Enter the verification code received on email address
                                </p>
                                <Form.Group className="br__form-group" controlId="formEmail">
                                    <Form.Label className="br__form-label">Code</Form.Label>
                                    <InputComponent
                                        classes={'br__form-control'}
                                        value={this.state.emailCode}
                                        onChange={this.setEmailCode}
                                        errorMessage={this.state.validationErrorMessage.emailCodeValidation}
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="br__form-group"
                                    data-test="pass_form_group"
                                    controlId="formPassword"
                                >
                                    <Form.Label className="br__form-label" data-test="pass_form_label">
                                        Password
                                    </Form.Label>
                                    <div className="br__input-badge-wrapper">
                                        <InputComponent
                                            data-test="password"
                                            fieldType="password"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            value={this.state.password}
                                            onChange={this.setPassword}
                                            classes={'br__form-control br__form-control--badge'}
                                            errorMessage={this.state.validationErrorMessage.passwordValidation}
                                        />
                                        <img
                                            src={this.state.showPassword ? viewOff : viewOn}
                                            onClick={e => this.showHide('showPassword')}
                                            alt=""
                                            className="br__input-badge"
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group
                                    className="br__form-group"
                                    data-test="conf_pass_form_group"
                                    controlId="formFullName"
                                >
                                    <Form.Label className="br__form-label" data-test="conf_pass_form_label">
                                        Confirm Password
                                    </Form.Label>
                                    <div className="br__input-badge-wrapper">
                                        <InputComponent
                                            data-test="confirm_password"
                                            fieldType="password"
                                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                                            onChange={this.setConfirmPassword}
                                            value={this.state.confirmPassword}
                                            classes={'br__form-control br__form-control--badge'}
                                            errorMessage={this.state.validationErrorMessage.confirmPasswordValidation}
                                        />
                                        <img
                                            src={this.state.showConfirmPassword ? viewOff : viewOn}
                                            alt=""
                                            onClick={e => this.showHide('showConfirmPassword')}
                                            className="br__input-badge"
                                        />
                                    </div>
                                </Form.Group>
                                <Link
                                    to="javascript:void(0)"
                                    className="forgot-password__link"
                                    id="si_forgot_password"
                                    data-test="forgot_link"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.resubmitCode();
                                    }}
                                >
                                    Resend Code
                                </Link>
                                <Button className="forgot-password__btn-send" onClick={this.setUserNewPassword}>
                                    Reset
                                </Button>
                            </div>
                            <p className="disclaimer" data-test="disclaimer">
                                &copy; {this.state.yearNow} Boardroom One. All rights reserved.
                            </p>
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};
export default connect(null, mapDispatchToProps)(ResetPasswordContainer);
