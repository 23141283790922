import React from 'react';
import { BoardDashboardService } from '../../services';
import ModalComponent from '../../components/modal-component/modal-component';
import './board-container.style.scss';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import { AuthService } from '../../../../core';

export class BoardContainer extends React.Component {
    _dashboardService = new BoardDashboardService();
    _authService = new AuthService();
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    openBoardCreationModal = () => {
        this.setState({ modal: true });
    };

    onLogout = () => {
        this._authService.logout();
    };

    handleClose = () => {
        this.setState({ modal: false });
    };

    render() {
        return (
            <div data-test="board_content_wrapper" className="board_content_wrapper">
                <div className="bcw_action_bar">
                    <ul className="bcw_action_list">
                        <li>
                            <a className="bcw_action_link active" href="#">
                                Board Management
                            </a>
                        </li>
                        <li>
                            <a className="bcw_action_link" href="#">
                                User Permissions & Roles
                            </a>
                        </li>
                        <li>
                            <a className="bcw_action_link" href="#">
                                Billing Management
                            </a>
                        </li>
                    </ul>
                    <ButtonComponent
                        data-test="create_board"
                        className={'bcw_create_board_btn'}
                        name={'Create Board'}
                        onclick={this.openBoardCreationModal}
                    />
                    <ButtonComponent
                        data-test="logout_btn"
                        className={'bcw_create_board_btn'}
                        name={'Logout'}
                        onclick={this.onLogout}
                    />
                    <ModalComponent data-test="modal_comp" show={this.state.modal} handleClose={this.handleClose} />
                </div>
                <div data-test="welcome_heading">
                    <h1 style={{ textAlign: 'center', marginTop: 10 }}>Welcome</h1>
                </div>
            </div>
        );
    }
}

export default BoardContainer;
