import React from 'react';
import { connect } from 'react-redux';
import './organization-container.style.scss';
import { AuthService, AWSStorageService, startLoading } from '../../../../core';
import { CardComponent, ModalComponent, HeaderComponent } from '../../components';
import { Constant, Utility } from '../../../../shared';
import { OrganizationService } from '../../services';
import { OrganizationDetailsDashboardService } from '../../../organization-details/services';
export class OrganizationStylingContainer extends React.Component {
    _authService = new AuthService();
    _awsStorageService = new AWSStorageService();
    _orgaizationService = new OrganizationService();
    _organizationDetailService = new OrganizationDetailsDashboardService();
    _utilityService = new Utility();
    state = {
        modal: false,
        menuItems: Constant.ORGANIZATION_MENU_ITEMS,
        list: [],
        paginationQueryParams: {
            pageNo: 0,
            totalRecords: 0,
        },

        // these properties are used to fill the form
        organizationName: '',
        organizationLogo: null,
        localImagePath: '',
        chips: [],
        existingLogoPath: '',

        validationErrorMessage: {
            orgNameValidation: '',
            emailsValidation: '',
            logoValidation: '',
        },

        disabledSubmitButton: false,

        organizationId: null,
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', this.scrollEndHandler);
        this.fetchOrganization();
    };

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollEndHandler);
    }

    fetchOrganization = () => {
        this.props.startLoading(true);
        this._orgaizationService
            .getOrganizatons(this.state.paginationQueryParams.pageNo, Constant.PAGINATED_ITEM)
            .then(async _successLog => {
                if (this.state.paginationQueryParams.pageNo === 0 && !_successLog.results.length) {
                    this.props.history.replace({
                        pathname: `/unauthorized-error`,
                        search: '?fromOrg=true',
                    });
                }
                for (let i = 0; i < _successLog.results.length; i++) {
                    _successLog.results[i].src = await this.getImageSrc(_successLog.results[i].logo);
                }
                const list = this.state.list.slice();
                this.setState({
                    ...this.state,
                    list: list.concat(_successLog.results),
                    paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: _successLog.total },
                });
            })
            .finally(() => {
                this.props.startLoading(false);
            });
    };

    getImageSrc = async imageId => {
        const image = await this._awsStorageService.downloadFile(imageId);
        console.log('image id from aws', imageId);
        if (image && image.Body) {
            return URL.createObjectURL(image.Body);
        }
        return null;
    };

    /**
     * @name scrollEndHandler
     * @desc Handler to listen on document when scroll ends.
     * @return {void}
     */
    scrollEndHandler = event => {
        const wrappedElement = document.querySelector('body'),
            isBottom = parseInt(wrappedElement.getBoundingClientRect().bottom) <= parseInt(window.innerHeight);
        if (isBottom) {
            const currentRecordCount = Constant.PAGINATED_ITEM * (this.state.paginationQueryParams.pageNo + 1);
            const { paginationQueryParams } = this.state;
            if (paginationQueryParams.totalRecords > currentRecordCount) {
                ++paginationQueryParams.pageNo;
                this.fetchOrganization();
            }
        }
    };

    openBoardCreationModal = () => {
        this.setState({ modal: true });
    };

    handleClose = () => {
        this.initializeFormFields();
    };

    setSelectedLogo = logo => {
        const image = this._utilityService.validateImageFile(logo, Constant.FILE_UPLOAD_LIMIT);
        if (image.isValid) {
            this.setState({
                ...this.state,
                organizationLogo: logo,
                localImagePath: URL.createObjectURL(logo),
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            return;
        }

        this.setState({
            ...this.state,
            organizationLogo: null,
            localImagePath: '',
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: image.message,
            },
        });
    };

    setOrganizationName = organizationName => {
        this.setState({
            ...this.state,
            organizationName,
            validationErrorMessage: { ...this.state.validationErrorMessage, orgNameValidation: '' },
        });
    };

    addItemToChips = chip => {
        const chips = Array.isArray(chip) ? chip : this.state.chips.slice();
        if (!Array.isArray(chip)) {
            chips.push(chip);
        }
        this.setState({
            ...this.state,
            chips,
            validationErrorMessage: { ...this.state.validationErrorMessage, emailsValidation: '' },
        });
    };

    deleteItemFromChips = index => {
        const chips = this.state.chips.slice();
        chips.splice(index, 1);
        this.setState({ ...this.state, chips });
    };

    initializeFormFields = () => {
        this.setState({
            ...this.state,
            modal: false,
            organizationName: '',
            organizationLogo: null,
            localImagePath: '',
            chips: [],
            disabledSubmitButton: false,
            organizationId: null,
            validationErrorMessage: {
                orgNameValidation: '',
                emailsValidation: '',
                logoValidation: '',
            },
        });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    validateForm = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };

        if (!this.state.organizationId && !this.state.organizationLogo) {
            validationErrorMessage.logoValidation = Constant.CREATE_ORGANIZATION_VALIDATION.logoValidation;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (this.state.organizationId && !this.state.localImagePath) {
            validationErrorMessage.logoValidation = Constant.CREATE_ORGANIZATION_VALIDATION.logoValidation;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.organizationName || !this.state.organizationName.trim()) {
            validationErrorMessage.orgNameValidation = Constant.CREATE_ORGANIZATION_VALIDATION.orgNameValidation;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.chips.length) {
            validationErrorMessage.emailsValidation = Constant.CREATE_ORGANIZATION_VALIDATION.emailsValidation;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < this.state.chips.length; i++) {
            let isValid = re.test(this.state.chips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.emailsValidation =
                    Constant.CREATE_ORGANIZATION_VALIDATION.invalidEmailsValidation;
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }

        return true;
    };

    uploadFileToStorage = async () => {
        const credentials = await this._authService.getAuthCredentials();
        const cognitoId = credentials.identityId;
        const storageMetaData = await this._awsStorageService.uploadFile(
            `${cognitoId}/${this.state.organizationLogo.name}`,
            this.state.organizationLogo,
            this.state.organizationLogo.type,
        );
        return storageMetaData;
    };

    getStorageMetaData = async () => {
        let storageMetaData = '';
        if (this.state.organizationId) {
            if (this.state.organizationLogo) {
                storageMetaData = await this.uploadFileToStorage();
            } else {
                storageMetaData = this.state.existingLogoPath;
            }
        } else {
            storageMetaData = await this.uploadFileToStorage();
        }
        return storageMetaData;
    };

    saveOrganization = async orgPayload => {
        await this._orgaizationService.createOrganization(orgPayload);
        this.setState({
            ...this.state,
            list: [],
            paginationQueryParams: { ...this.state.paginationQueryParams, pageNo: 0, totalRecords: 0 },
        });
        this.fetchOrganization();
    };

    updateOrganization = async orgPayload => {
        await this._orgaizationService.updateOrganization(orgPayload, this.state.organizationId);
        const updatedOrganization = await this._organizationDetailService.getOrganizationDetails({
            id: this.state.organizationId,
        });
        let list = this.state.list.slice();
        list = await Promise.all(
            list.map(async item => {
                if (item.id === updatedOrganization.id) {
                    item = { ...updatedOrganization };
                    item.src = await this.getImageSrc(item.logo);
                }
                return item;
            }),
        );
        this.setState({ ...this.state, list });
        this.props.startLoading(false);
    };

    createOrganization = async () => {
        if (!this.validateForm()) {
            return;
        }
        this.props.startLoading(true);
        this.setState({ ...this.state, disabledSubmitButton: true });

        const storageMetaData = await this.getStorageMetaData();

        const orgPayload = {
            name: this.state.organizationName,
            logo: storageMetaData.key ? storageMetaData.key : storageMetaData,
            executiveProducers: this.state.chips.slice(),
        };

        try {
            if (this.state.organizationId) {
                await this.updateOrganization(orgPayload);
            } else {
                await this.saveOrganization(orgPayload);
            }
            this.initializeFormFields();
        } catch (e) {
            console.error({ e });
            this.setState({ ...this.state, disabledSubmitButton: false });
            this.props.startLoading(false);
        }
    };

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    onSelectMenu = async (selectedOption, selectedItem) => {
        switch (selectedOption) {
            case 'Edit':
                this.props.startLoading(true);
                let chips = [];
                if (selectedItem.member && selectedItem.member.length) {
                    chips = selectedItem.member.map(m => {
                        return m.email;
                    });
                }
                const imagePath = await this.getImageSrc(selectedItem.logo);
                this.props.startLoading(false);

                this.setState({
                    ...this.state,
                    chips,
                    localImagePath: imagePath,
                    existingLogoPath: selectedItem.logo,
                    organizationName: selectedItem.name,
                    organizationId: selectedItem.id,
                    modal: true,
                });
                /**
                 * Do edit work here
                 */
                break;

            case 'Delete':
                /**
                 * Do Delete work here
                 */
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <div className="organization">
                <HeaderComponent click={this.openBoardCreationModal} />
                <CardComponent
                    contentList={this.state.list}
                    menuItems={this.state.menuItems}
                    onSelectMenu={this.onSelectMenu}
                />
                <ModalComponent
                    chipObject={{
                        chips: this.state.chips,
                        addItemToChips: this.addItemToChips,
                        deleteItemFromChips: this.deleteItemFromChips,
                        inputPlaceHolder: this.state.chips.length ? '' : 'Email Address',
                    }}
                    errorMessage={this.state.validationErrorMessage.emailsValidation}
                    fileHandling={{
                        setSelectedLogo: this.setSelectedLogo,
                        localImagePath: this.state.localImagePath,
                    }}
                    organizationName={this.state.organizationName}
                    disabledSubmitButton={this.state.disabledSubmitButton}
                    validationErrorMessage={this.state.validationErrorMessage}
                    label={Constant.ORGANIZATION_MODAL_LOGO.LABEL}
                    ratio={Constant.ORGANIZATION_MODAL_LOGO.RATIO}
                    setOrganizationName={this.setOrganizationName}
                    createOrganization={this.createOrganization}
                    show={this.state.modal}
                    handleClose={this.handleClose}
                    validateImageFileMessage={this.validateImageFileMessage}
                />
            </div>
        );
    }
}

const mapStateToProp = state => {
    /**
     * This will return all the state declare inside the app directory in store
     */
    return {
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(mapStateToProp, mapDispatchToProps)(OrganizationStylingContainer);
