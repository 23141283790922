import React from 'react';
import { connect } from 'react-redux';
import { ThreeDotsDropDownComponent } from '..';
import './table-list-component.style.scss';
import { Utility, Constant } from '../../services';
import { v4 as uuidv4 } from 'uuid';

const tableListComponent = props => {
    const classAndLabel = {
        name: {
            class: 'board-listing__cel board-listing__cel--width-30',
            label: 'Board Name',
        },
        'board name': {
            class: 'board-listing__cel board-listing__cel--board-name',
            label: '',
        },
        producers: {
            class: 'board-listing__cel board-listing__cel--producer',
            label: 'Producers',
        },
        directors: {
            class: 'board-listing__cel board-listing__cel--director',
            label: 'Directors',
        },
        totalRooms: {
            class: 'board-listing__cel',
            label: 'Rooms',
        },
        totalMembers: {
            class: 'board-listing__cel',
            label: 'Members',
        },
        roomName: {
            class: 'board-listing__cel board-listing__cel--room-name',
            label: 'Room Name',
        },
        moderators: {
            class: 'board-listing__cel board-listing__cel--moderators',
            label: 'moderators',
        },
        speakers: {
            class: 'board-listing__cel board-listing__cel--speakers',
            label: 'Scheduled Eastern Time (GMT-4)',
        },
        roomStatusObj: {
            class: 'board-listing__cel',
            label: 'Status',
        },
        attendees: {
            class: 'board-listing__cel',
            label: 'attendees',
        },
        dropDown: {
            class: 'board-listing__cel board-listing__cel--dropdown',
            label: 'dropdown',
        },
        default: {
            class: 'board-listing__cel',
            label: '',
        },
    };

    const _utilityService = new Utility();
    const { id, member, status, ...info } = props.boardInfo;
    const { index } = props;
    const { isBoardScreen, isRoomScreen } = { ...props };
    const menuItems =
        status === Constant.ORGANIZATION_TABLE_UPCOMING_ROOM_STATUS
            ? Constant.ORGANIZATION_TABLE_UPCOMING_ROOM_MENU_ITEMS
            : props.menuItems;
    const getThreeDotsComponent = () => {
        if (props.user.isRoot) {
            return (
                <ThreeDotsDropDownComponent
                    id={`organization-detail-table-${index}`}
                    menuItems={menuItems}
                    onSelect={item => {
                        props.onSelectMenu(item, id);
                    }}
                />
            );
        }
        if (
            isBoardScreen &&
            (props.isUserExecutiveProducer || _utilityService.isUserPermissionGranted(props.user, member, 'producer'))
        ) {
            return (
                <ThreeDotsDropDownComponent
                    id={`organization-detail-table-${index}`}
                    menuItems={menuItems}
                    onSelect={item => {
                        props.onSelectMenu(item, id);
                    }}
                />
            );
        }

        if (isRoomScreen) {
            return (
                <ThreeDotsDropDownComponent
                    id={`organization-detail-table-${index}`}
                    menuItems={menuItems}
                    onSelect={item => {
                        props.onSelectMenu(item, id);
                    }}
                />
            );
        }

        return null;
    };

    return (
        <React.Fragment>
            {Object.keys(info).map((key, i) => {
                return (
                    <td
                        id={`table-body-row-data-${index + 1}-${i}`}
                        key={uuidv4()}
                        data-test="table_body_row_data_render"
                        className={classAndLabel[key] ? classAndLabel[key].class : classAndLabel['default'].class}
                        onClick={() => props.onClick(id)}
                        data-label={classAndLabel[key] ? classAndLabel[key].label : classAndLabel['default'].label}
                    >
                        {Array.isArray(props.boardInfo[key])
                            ? props.boardInfo[key].map(value => (
                                  <p key={uuidv4()} className="board-listing--truncate">
                                      {value}
                                  </p>
                              ))
                            : props.boardInfo[key]}
                    </td>
                );
            })}
            <td
                id="table-meatball-menu"
                className={classAndLabel['dropDown'].class}
                data-label={classAndLabel['dropDown'].label}
            >
                {getThreeDotsComponent()}
            </td>
        </React.Fragment>
    );
};

const mapStateToProp = state => {
    /**
     * This will return all the state declare inside the app directory in store
     */
    return {
        ...state.app,
    };
};

export default connect(mapStateToProp)(tableListComponent);
