import React from 'react';
import '../board-header/board-header.style.scss';
import ButtonComponent from '../../components/button-component/button-component';
import { BoardHeaderSubtitleComponent, ImageComponent } from '..';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const BoardHeader = props => {
    return (
        <>
            <Row className="board-detail__header">
                <Col xs="12" sm="5" md="5" lg="5" xl="5" className="board-detail__main-info">
                    <div className="board-detail__images">
                        <ImageComponent
                            id="board-logo"
                            src={props.boardDetails.boardLogo}
                            alt=""
                            placeholder={null}
                            className="img-fluid board-detail__cover-img"
                            data-test="board-logo"
                        />
                        <ImageComponent
                            id="org-logo"
                            src={props.boardDetails.orgLogo}
                            alt=""
                            placeholder={null}
                            data-test="org-logo"
                            className="img-fluid board-detail__org-img board-detail__org-img--web"
                        />
                    </div>
                    <div className="board-detail__title-wrap">
                        <ImageComponent
                            id="org-logo"
                            src={props.boardDetails.orgLogo}
                            alt=""
                            placeholder={null}
                            data-test="org-logo"
                            className="img-fluid board-detail__org-img board-detail__org-img--mob"
                        />

                        <div>
                            <h4 id="board-detail-title" data-test="board-title" className="board-detail__title">
                                {props.boardDetails.name}{' '}
                            </h4>
                            <p id="board-detail-board-name" data-test="org-title" className="board-detail__board-name">
                                {props.boardDetails.orgName}
                            </p>
                        </div>
                    </div>
                </Col>
                {Object.keys(props.boardDetails.subtitles).map((subtitle, index) => (
                    <BoardHeaderSubtitleComponent
                        key={uuidv4()}
                        subtitle={subtitle}
                        subtitles={props.boardDetails.subtitles}
                        getDescription={props.getDescription}
                        getHeadings={props.getHeadings}
                        index={index}
                    />
                ))}
            </Row>
            <div className="board-detail__btn-wrapper">
                <ButtonComponent
                    id="board-header-button"
                    className={'br__btn-theme-filled br__btn-theme-filled--width-181'}
                    name={props.buttonName}
                    onclick={props.redirect}
                />
            </div>
        </>
    );
};

export default BoardHeader;
