import React, { useState, useEffect, useRef } from 'react';
import { AWSStorageService, CloudinaryService } from '../../../core';
import './image-component.style.scss';

const ImageContainer = ({ src, placeholder, isAvatar, ...attributes }) => {
    const _cloudinaryService = new CloudinaryService();
    const [isImageFetched, setIsImageFetched] = useState(false);
    const [image, setImage] = useState('');

    const getImageSrc = async (imageId, isCancelled) => {
        if (imageId) {
            try {
                let image = '';
                if (isAvatar) {
                    image = await _cloudinaryService.getCloudinaryURL(imageId);
                } else {
                    image = await _cloudinaryService.getCloudinaryPublicURL(imageId);
                }
                if (!isCancelled && image) {
                    setIsImageFetched(true);
                    setImage(image);
                }
            } catch (error) {
                console.log('error:', error.message);
            }
        }
    };

    useEffect(() => {
        let isCancelled = false;
        (async function fetchImage() {
            await getImageSrc(src, isCancelled);
        })();
        return () => {
            isCancelled = true;
        };
    }, [src]);

    return (
        <React.Fragment>
            <img src={isImageFetched ? image : placeholder} {...attributes} alt="" />
        </React.Fragment>
    );
};

export default ImageContainer;
