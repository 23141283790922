import 'bootstrap/scss/bootstrap.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import { setSomeThing, AuthContainer, setAccessToken, setUser, CommonService, VoxeetService } from './core';

import { Http, Routes } from './core';
import './App.module.style.scss';

import { Loader, ToastComponent, Utility } from './shared';
import { BroadcastChannel } from 'broadcast-channel';

const routes = new Routes();

function App(props) {
    const _commonService = new CommonService();
    let channel = null;
    try {
        channel = new BroadcastChannel('auth');
    } catch (error) {
        channel = null;
    }
    const _utilityService = new Utility();
    const _voxeetService = new VoxeetService();
    const isRouteIsSameAsCurrentPath = route => {
        return route.path.split('/')[1] === props.location.pathname.split('/')[1];
    };
    const isPublicRoute = routes.getPublicRoutes().some(route => {
        return isRouteIsSameAsCurrentPath(route);
    });
    const isGuest = routes
        .getPublicRoutes()
        .concat(routes.getRoutes())
        .some(route => {
            return route.isGuest && isRouteIsSameAsCurrentPath(route);
        });
    const isGuestHeader = routes
        .getPublicRoutes()
        .concat(routes.getRoutes())
        .some(route => {
            return route.isGuestHeader && isRouteIsSameAsCurrentPath(route);
        });
    const isGuestFooter = routes
        .getPublicRoutes()
        .concat(routes.getRoutes())
        .some(route => {
            return route.isGuestFooter && isRouteIsSameAsCurrentPath(route);
        });
    const isGuestSidenav = routes
        .getPublicRoutes()
        .concat(routes.getRoutes())
        .some(route => {
            return route.isGuestSidenav && isRouteIsSameAsCurrentPath(route);
        });
    new Http();

    /**
     *
     * call the store function like this and pass the updated object like this
     *
     * setSomething({key_1: 'value 3', key_2: 'value 4'})
     *
     * below is the sample condition for updating the state and log
     * we have to remove console.log and this sample condition when we got the concepts
     */

    if (props.someThing.key_1 !== null && props.someThing.key_1 === 'value 1') {
        props.setSomething({ key_1: 'value 3', key_2: 'value 4' });
    }

    const emptyAccessToken = () => {
        props.setAccessToken('');
        props.setUser('');
    };

    if (channel) {
        channel.onmessage = e => {
            switch (e.case) {
                case 'signOut':
                    if (isGuest) {
                        if (sessionStorage.getItem('logoutPrevent') === 'yes') {
                            setTimeout(() => {
                                sessionStorage.removeItem('logoutPrevent');
                            }, 6000);
                            return;
                        }
                        emptyAccessToken();
                        props.history.replace({ pathname: '/guest' });
                    } else {
                        let path = e.pathname
                            ? { pathname: e.pathname }
                            : { pathname: '/unauthorized-error', search: 'fromOrg=true' };
                        if (e.pathname) {
                            emptyAccessToken();
                            path = { pathname: e.pathname };
                        } else {
                            path = { pathname: '/unauthorized-error', search: 'fromOrg=true' };
                        }
                        props.history.replace(path);
                    }
                    break;
                default:
                    console.log('unknown auth event', e);
            }
        };
    }

    useEffect(() => {
        (async function saveCloudinaryURLToStorage() {
            const settingsOptions = await _commonService.getSettings();
            _utilityService.setSessionStorageItems(
                'cloudinaryBaseUrl',
                JSON.stringify({
                    cloudinaryProfileBaseUrl: settingsOptions.cloudinaryProfileBaseUrl,
                    cloudinaryPublicBaseUrl: settingsOptions.cloudinaryPublicBaseUrl,
                }),
            );
        })();
    }, [props.accessToken]);

    useEffect(() => {
        if (isGuest && _utilityService.isPlatformIos()) {
            props.history.replace({
                pathname: '/room/invite/',
            });
        }
    }, []);

    return (
        <>
            <Loader />
            <AuthContainer
                isPublicRoute={isPublicRoute}
                isGuest={isGuest}
                isGuestHeader={isGuestHeader}
                isGuestFooter={isGuestFooter}
                isGuestSidenav={isGuestSidenav}
            >
                <Switch>
                    {routes.getRoutes().map(route => (
                        <Route key={route.path} {...route} />
                    ))}
                    <Redirect path="*" to="/not-found" />
                </Switch>
            </AuthContainer>

            {/* Not require any authentication */}
            <Switch>
                {routes.getPublicRoutes().map(route => (
                    <Route key={route.path} {...route} />
                ))}
            </Switch>
            <ToastComponent />
        </>
    );
}

const mapStateToProp = state => {
    /**
     * This will return all the state declare inside the app directory in store
     */
    return {
        ...state.app,
        ...state.authReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSomething: something => dispatch(setSomeThing(something)),
        setAccessToken: token => dispatch(setAccessToken(token)),
        setUser: user => dispatch(setUser(user)),
    };
};

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(App));
