import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { showHideErrorToaster, startLoading, MagicLinkService } from '../..';
import './create-anonymous-user-profile.style.scss';
import { Button, Form } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { Constant, InputComponent, Utility } from '../../../shared';
import logoDolby from '../../../assets/images/logo-dolby.png';
import betaLogo from '../../../assets/images/beta-logo.png';
import { UserService, CommonService } from '../../services';
import { GuestRoomDetailService } from '../../../content/guest-room-detail/services';
export class CreateAnonymousUserContainer extends Component {
    _magicLinkService = new MagicLinkService();
    _utilityService = new Utility();
    _userService = new UserService();
    _commonService = new CommonService();
    _guestRoomDetailService = new GuestRoomDetailService();

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            roomId: null,
            validationErrorMessage: {
                nameValidation: '',
            },
            error: null,
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
    }

    setUsername = name => {
        this.setState({
            ...this.state,
            name,
            validationErrorMessage: { ...this.state.validationErrorMessage, nameValidation: '' },
        });
    };

    componentDidMount = () => {
        this.getRoomId();
    };

    getRoomId = () => {
        const params = this.props.match.params;
        this.setState({ ...this.state, roomId: params.roomId });
    };

    onKeyDown = async event => {
        const keyPressed = event.key;
        if (keyPressed === Constant.KEYS.ENTER) {
            await this.submit();
        }
    };

    validateForm = async () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        if (!this.state.name) {
            validationErrorMessage.nameValidation = 'Please write your name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const isShareable = await this.isRoomShareable();
        if (!isShareable) {
            this.displayToasterMessage('The room is not shareable', true);
            return false;
        }

        return true;
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    isRoomShareable = async () => {
        try {
            const shareableRoom = await this._commonService.isRoomShareable(this.state.roomId);
            return shareableRoom.isShareAble;
        } catch (error) {
            return false;
        }
    };

    displayToasterMessage = (message, isError) => {
        this.props.showHideErrorToaster({
            show: true,
            message: message,
            bgColor: isError ? '#ff3547' : 'green',
        });
    };

    createAnonymousSession = accessToken => {
        this._utilityService.setSessionStorageItems(Constant.SESSION_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        this._utilityService.setSessionStorageItems(Constant.SESSION_STORAGE_KEYS.ROOM_ID, this.state.roomId);
        this._utilityService.setSessionStorageItems(Constant.SESSION_STORAGE_KEYS.IS_ANONYMOUS, true);
    };

    showHideLoader = (isStart = false) => {
        this.props.startLoading(isStart);
    };

    submit = async () => {
        try {
            this.showHideLoader(true);
            if (!(await this.validateForm())) {
                this.showHideLoader();
                return;
            }
            const payload = {
                name: this.state.name,
                roomId: this.state.roomId,
            };
            const userAccessToken = await this._userService.createAnonymousUser(payload);
            if (userAccessToken && userAccessToken.accessToken) {
                this.createAnonymousSession(userAccessToken.accessToken);
                const isRoomLive = await this.isRoomLive();
                if (isRoomLive) {
                    this.props.history.replace(`/room-join/${this.state.roomId}`);
                    return;
                }
                this.props.history.replace(`/guest-room-detail/${this.state.roomId}`);
            }
            this.showHideLoader();
        } catch (error) {
            this.showHideLoader();
            if (error.response && error.response.status === 406) {
                this.displayToasterMessage('This session has ended.', true);
                return;
            }
            this.displayToasterMessage('Something went wrong.', true);
        }
    };

    isRoomLive = async () => {
        const invitedRoom = await this.fetchRoomDetails();
        if (invitedRoom) {
            const currentTime = DateTime.local();
            const startTime = DateTime.fromISO(invitedRoom.startTime, { zone: 'utc' }).toLocal();
            const endTime = DateTime.fromISO(invitedRoom.endTime, { zone: 'utc' }).toLocal();
            return !invitedRoom.ended && startTime <= currentTime && endTime >= currentTime;
        }
        return false;
    };

    fetchRoomDetails = async (noFilter = true) => {
        const roomDetails = await this._guestRoomDetailService.getRoomDetail(this.state.roomId, noFilter);
        return roomDetails;
    };

    render() {
        return (
            <>
                <div className="create-anonymous-user">
                    <div className="create-anonymous-user__block">
                        <div className="create-anonymous-user__logos">
                            <img
                                src={betaLogo}
                                alt="betaLogo"
                                className="create-anonymous-user__logos__beta-logo img-fluid"
                                data-test="logo_render"
                            />
                            <img
                                src={logoDolby}
                                alt="logoDolby"
                                className="create-anonymous-user__logos__logo img-fluid"
                                data-test="logo_render"
                            />
                        </div>
                        <div className="create-anonymous-user__inner">
                            <Form.Group className="br__form-group" controlId="formEmail">
                                <Form.Label className="br__form-label">Display Name</Form.Label>
                                <InputComponent
                                    classes={'br__form-control'}
                                    textId={'create-anonymous-user-container-email'}
                                    onChange={this.setUsername}
                                    value={this.state.name}
                                    errorMessage={this.state.validationErrorMessage.nameValidation}
                                    onKeyDown={this.onKeyDown}
                                />
                            </Form.Group>
                            <Button
                                className="create-anonymous-user__btn-send"
                                onClick={() => {
                                    this.submit();
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                        <div className="magic-disclaimer" data-test="disclaimer">
                            <p className="magic-disclaimer__text">
                                &copy; {this.state.yearNow} Boardroom One. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(CreateAnonymousUserContainer));
