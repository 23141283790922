import React, { useState, useEffect } from 'react';
import { Constant, ImageComponent, IconButton, ButtonComponent } from '../../../../shared';
import iconShare from '../../../../assets/images/ic-share.svg';
import tagLive from '../../../../assets/images/tag-live-transparent.png';
import tagRSVP from '../../../../assets/images/tag-rsv-pd.png';
import tagInvite from '../../../../assets/images/tag-invite.png';
import tagDeclined from '../../../../assets/images/tag-declined.png';
import iconLounge from '../../../../assets/images/group.png';
import iconClock from '../../../../assets/images/ic-clock.svg';
import { Form } from 'react-bootstrap';
import '../guest-room-detail/guest-room-detail.style.scss';
import tagGreenRoom from '../../../../assets/images/green-room-ic.png';

function GuestRoomDetail(props) {
    const [roomTag, setRoomTag] = useState('');
    const [buttonName, setButtonName] = useState('');
    const [upcomingGreenRoomStatus, setUpcomingGreenRoomStatus] = useState('');
    const isPrivateRoom = props.details.roomType === Constant.ROOM_TYPE.PRIVATE;
    let createdBy = isPrivateRoom
        ? props.details.participants.filter(participant => participant.id === props.details.createdBy)
        : null;
    createdBy = createdBy && createdBy.length ? createdBy[0] : null;
    const badge_src_mapping = {
        greenRoomStarted: tagGreenRoom,
        accepted: tagRSVP,
        live: tagLive,
        rejected: tagDeclined,
        pending: tagInvite,
        lounge: iconLounge,
        expired: 'Expired',
    };
    useEffect(() => {
        setButtonName('');
        if (props.details.greenRoomStatus === Constant.GREEN_ROOM_STATUS.UPCOMING) {
            setUpcomingGreenRoomStatus(props.showGreenRoomStatus(props.details));
        }
        if (props.details.roomType === Constant.ROOM_TYPE.LOUNGE) {
            setRoomTag(badge_src_mapping[props.details.roomType]);
        } else {
            setRoomTag(badge_src_mapping[props.details.status]);
        }
        setButtons();
    }, [props]);

    const setButtons = () => {
        if (props.details.status === Constant.GREEN_ROOM_STATUS.STARTED) {
            setButtonName('Join Now');
            setUpcomingGreenRoomStatus('');
        } else if (props.details.status === 'accepted') {
            !props.isAnonymous && setButtonName('Decline');
        } else if (props.details.status === 'live') {
            setButtonName('Join Now');
        } else if (props.details.status === 'rejected') {
            setUpcomingGreenRoomStatus('');
            !props.isAnonymous && setButtonName('Accept');
        } else if (props.details.status === 'pending') {
            !props.isAnonymous && setButtonName('Decline');
        }
    };
    return (
        <div className="guest-room-detail__left">
            {!isPrivateRoom ? (
                <ImageComponent
                    className="guest-room-detail__img-hero"
                    data-test="hero_img"
                    src={props.details.heroImage}
                    placeholder={null}
                    alt=""
                />
            ) : (
                ''
            )}
            <div className="guest-room-detail__status">
                {!props.details.hideBadgeBtn && (
                    <div className="text-danger">
                        {props.details.timeDiff &&
                        (props.details.status === 'accepted' || props.details.status === 'pending') ? (
                            <div className="room-start-timer">
                                <img src={iconClock} alt="" className="room-start-timer-img" data-test="icon-clock" />
                                <span className="room-start-timer-text" data-test="room-start-timer-text">
                                    {props.details.timeDiff}{' '}
                                </span>
                            </div>
                        ) : roomTag !== 'Expired' ? (
                            <img
                                className="guest-room-detail__status-tag"
                                src={roomTag}
                                alt=""
                                data-test="room-detail-status-tag"
                            />
                        ) : (
                            ''
                        )}
                    </div>
                )}
                <div className="guest-room-detail__time-wrap">
                    <p className="guest-room-detail__time" data-test="room-detail-time">
                        {props.details.timeStamp}
                    </p>
                </div>
            </div>
            {upcomingGreenRoomStatus && <p className="guest-room-detail__greenroom-time">{upcomingGreenRoomStatus}</p>}
            <div className="guest-room-detail__room-info">
                {
                    <ImageComponent
                        className="guest-room-detail__org-img"
                        data-test="org_logo"
                        src={isPrivateRoom ? createdBy && createdBy.image : props.details.orgLogo}
                        placeholder={null}
                        alt=""
                        isAvatar={isPrivateRoom}
                    />
                }
                <div>
                    <h2 className="guest-room-detail__room-name" data-test="room_name">
                        {props.details.name}
                    </h2>
                    <h5 className="guest-room-detail__board-name" data-test="board_name">
                        By {isPrivateRoom ? createdBy && createdBy.name : props.details.boardName}
                    </h5>
                </div>
            </div>
            {!props.details.hideBadgeBtn && (
                <span>
                    {props.details.status === 'pending' && buttonName && (
                        <ButtonComponent
                            className={'guest-room-detail__btn-outline guest-room-detail__btn-outline--accept'}
                            name="Accept"
                            onclick={props.changeStatus}
                            param={'accepted'}
                        />
                    )}
                    &nbsp;&nbsp;
                    {buttonName && (
                        <ButtonComponent
                            className={`guest-room-detail__btn-outline guest-room-detail__btn-outline--${buttonName.toLocaleLowerCase()}`}
                            name={buttonName}
                            onclick={props.changeStatus}
                            param={buttonName.toLocaleLowerCase()}
                        />
                    )}
                </span>
            )}

            {props.details.isShareAble && (
                <div className="guest-room-detail__link-wrapper">
                    <label className="guest-room-detail__link-label" data-test="link-heading">
                        Link
                    </label>
                    <div className="guest-room-detail__link-inner">
                        <Form.Control
                            className="guest-room-detail__link-input"
                            type="text"
                            value={props.roomLink}
                            placeholder="Some link here"
                            readOnly
                            disabled
                        />
                        <IconButton
                            image={iconShare}
                            text="Copy Link"
                            title={props.copyMessage}
                            click={props.copyToClipBoard}
                            cssClass="guest-room-detail__icon-btn guest-room-detail__copyBtn"
                        />
                    </div>
                    <p className="guest-room-detail__link-tagline" data-test="room-detail-tagline">
                        {Constant.ROOM_DETAILS_LINK_COPIED_MESSAGE}
                    </p>
                </div>
            )}
            <div
                className="guest-room-detail__room-desc"
                data-test="room-details-description"
                dangerouslySetInnerHTML={{
                    __html: props.details.description.slice(0, Constant.DESCRIPTION_LIMIT),
                }}
            ></div>
        </div>
    );
}

export default GuestRoomDetail;
