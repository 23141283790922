import React from 'react';
import { Popover } from 'react-bootstrap';
import { AuthService, AWSStorageService, showHideErrorToaster, startLoading, CommonService } from '../../../../core';
import { Utility, MyPopover, Constant } from '../../../../shared';
import { CreateRoomDashboardService } from '../../services';
import { CreateRoomForm } from '../../components';
import { connect } from 'react-redux';
import './createRoom-container.style.scss';
import { DateTime } from 'luxon';
export class CreateRoomContainer extends React.Component {
    _authService = new AuthService();
    _utilityService = new Utility();
    _commonService = new CommonService();
    _awsStorageService = new AWSStorageService();
    _createRoomService = new CreateRoomDashboardService();
    debounceTimeout = null;
    constructor(props) {
        super(props);
        this.state = {
            isRoomPastStartTime: false,
            roomName: '',
            youtubeRtmpLink: '',
            roomDescription: '',
            roomDate: '',
            roomStartTime: DateTime.local()
                .plus({ minutes: 30 - (DateTime.local().minute % 30) })
                .toFormat('HH:mm'),
            roomEndTime: DateTime.local()
                .plus({ minutes: 30 - (DateTime.local().minute % 30) })
                .plus({ hours: 1 })
                .toFormat('HH:mm'),
            timeZone: '',
            greenRoomMins: 0,
            greenRoomStartTime: null,
            utc: [],
            organizationLogo: null,
            localImagePath: '',
            speakersChips: [],
            moderatorsChips: [],
            attendeesChips: [],
            guestChips: [],
            existingLogoPath: '',
            libraryImages: [],
            recentImages: [],
            defaultCropState: {
                unit: 'px',
                aspect: {
                    numerator: 16,
                    denominator: 9,
                },
                width: 294.8,
                height: 165.825,
            },
            libraryPagination: {
                totalPages: 0,
                currentPage: 1,
            },
            searchedImage: '',
            isSearching: false,
            isShareAble: false,
            isLoungeEnabled: false,
            validationErrorMessage: {
                roomNameValidation: '',
                youtubeRtmpValidation: '',
                dateValidation: '',
                descriptionBoxValidation: '',
                speakerEmailsValidation: '',
                moderatorEmailValidation: '',
                attendeeEmailValidation: '',
                guestEmailValidation: '',
                logoValidation: '',
                startTimeValidation: '',
                greenRoomValidation: '',
                endTimeValidation: '',
                timeZoneValidation: '',
            },
        };
    }

    userIsRoot = async () => {
        const userInfo = await this._createRoomService.getUser();
        return userInfo.isRoot;
    };

    setUserAuthorizationParams = () => {
        return {
            id: this._utilityService.getStorageItems('orgId'),
            boardId: this.props.match.params.boardId,
        };
    };

    isUserAllowed = async () => {
        if (await this.userIsRoot()) {
            return true;
        }
        const param = this.setUserAuthorizationParams();
        const orgMemberDetails = await this._createRoomService.getOrganizationDetails(param);
        const boardMemberDetails = await this._createRoomService.getBoardDetails(param);
        return this._createRoomService.getUser().then(_successLog => {
            let memberFound = orgMemberDetails.member.find(member => member.id === _successLog.id);
            memberFound =
                memberFound ||
                (boardMemberDetails && boardMemberDetails.member.find(member => member.id === _successLog.id));
            return memberFound ? true : false;
        });
    };

    setUserAuthorization = async () => {
        const userIsAllowed = await this.isUserAllowed();
        this.setState({ userIsAllowed });
    };

    redirectBack = () => {
        this.props.showHideErrorToaster({
            show: true,
            message: Constant.NOT_ALLOWED_MESSAGE,
            bgColor: '#ff3547',
        });
        this.props.history.replace({
            pathname: '/',
        });
    };

    getTimeZone = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const isInDST = machineTime.isInDST;
        const zones = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
        });
        const zoneIsInDST = zones.find(zone => zone.isdst === isInDST) || zones[0];
        return zoneIsInDST;
    };

    setTimeZone = e => {
        const fallBackValue = Constant.FALLBACK_CONST;
        e
            ? this.setState({
                  ...this.state,
                  timeZone: e.id,
                  utc: e.utc,
                  validationErrorMessage: { ...this.state.validationErrorMessage, timeZoneValidation: '' },
              })
            : this.setState({
                  ...this.state,
                  timeZone: fallBackValue.id,
                  utc: fallBackValue.utc,
                  validationErrorMessage: { ...this.state.validationErrorMessage, timeZoneValidation: '' },
              });
    };

    setGreenRoomMins = e => {
        this.setState({
            ...this.state,
            greenRoomMins: parseInt(e.target.value),
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                greenRoomValidation: '',
                startTimeValidation: '',
            },
        });
    };

    getLibraryImages = async () => {
        this.props.startLoading(true);
        const libraryImages = await this._commonService.getSplashimages({
            pageNo: this.state.libraryPagination.currentPage,
            searchedImage: this.state.searchedImage,
        });
        const listOfImages = this.state.libraryImages.slice();
        this.setState({
            ...this.state,
            libraryImages: listOfImages.concat(libraryImages.results),
            isSearching: this.state.libraryPagination.currentPage === 1 && libraryImages.results.length > 0,
            libraryPagination: {
                ...this.state.libraryPagination,
                totalPages: libraryImages.totalPages,
                currentPage: this.state.libraryPagination.currentPage + 1,
            },
        });
        this.props.startLoading(false);
    };

    onImageSearchChange = async searchedImage => {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
        await this.setState({
            ...this.state,
            searchedImage,
            isSearching: true,
        });
        this.debounceTimeout = setTimeout(async () => {
            await this.setState({
                ...this.state,
                libraryPagination: {
                    ...this.state.libraryPagination,
                    totalPages: 0,
                    currentPage: 1,
                },
                libraryImages: [],
            });

            if (searchedImage) {
                this.getLibraryImages();
            }
        }, 2000);
    };

    onImageScrollDown = async () => {
        if (this.state.libraryPagination.currentPage >= this.state.libraryPagination.totalPages) {
            return;
        }
        this.getLibraryImages();
    };

    saveRecentImagesToLocal = selectedImage => {
        let locallySavedRecentImages = this._utilityService.getStorageItems('recentImages')
            ? JSON.parse(this._utilityService.getStorageItems('recentImages'))
            : [];
        locallySavedRecentImages = locallySavedRecentImages.filter(image => image !== selectedImage);
        locallySavedRecentImages.unshift(selectedImage);
        this._utilityService.setStorageItems('recentImages', JSON.stringify(locallySavedRecentImages));
    };

    getRecentImages = () => {
        let locallySavedRecentImages = this._utilityService.getStorageItems('recentImages')
            ? JSON.parse(this._utilityService.getStorageItems('recentImages'))
            : [];
        this.setState({ ...this.state, recentImages: locallySavedRecentImages });
    };

    componentDidMount = async () => {
        try {
            window.scrollTo(0, 0);
            this.props.startLoading(true);
            await this.setUserAuthorization();
            if (!this.state.userIsAllowed) {
                this.redirectBack();
            } else {
                const { roomId, boardId } = this.props.match.params;
                const duplicate = this.getQueryParams().get('duplicate');
                if (!roomId) {
                    this.setTimeZone(this.getTimeZone());
                    this.fetchModeratorEmails(boardId);
                } else if (roomId && duplicate !== 'room') {
                    await this.getRoomDetail(roomId);
                } else {
                    this.setTimeZone(this.getTimeZone());
                    await this.getRoomDetail(roomId, duplicate);
                }
                this.props.startLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    getQueryParams = () => {
        return new URLSearchParams(this.props.location.search);
    };

    fetchModeratorEmails = async boardId => {
        const moderatorEmails = await this._createRoomService.getModeratorsEmail(boardId);
        this.setState({
            ...this.state,
            moderatorsChips: moderatorEmails,
        });
    };

    getImageSrc = async imageId => {
        const image = await this._awsStorageService.downloadFile(imageId);
        if (image && image.Body) {
            return URL.createObjectURL(image.Body);
        }
        return null;
    };

    parseDateToShow(time, timezone) {
        const formattedDate = DateTime.fromISO(time, { zone: 'utc' })
            .toLocal()
            .toFormat('yyyy-MM-dd');
        return formattedDate;
    }

    parseTimeToShow(time, timezone) {
        const zone = Constant.TIME_ZONES.filter(tz => tz.id === timezone);
        this.setTimeZone(zone[0]);
        const withOffset = DateTime.fromISO(time, { zone: 'utc' })
            .setZone(this.state.utc.includes(DateTime.local().zoneName) ? DateTime.local().zoneName : this.state.utc[0])
            .toFormat('HH:mm');
        return withOffset;
    }
    isRoomPastStartTime(room) {
        const now = DateTime.local();
        const start = DateTime.fromISO(room.startTime, { zone: 'utc' }).toLocal();
        // const end = DateTime.fromISO(room.endTime, { zone: 'utc' }).toLocal();
        return now > start;
    }
    async getRoomDetail(roomId, duplicate = null) {
        const detail = await this._createRoomService.getRoomDetail(roomId, true);
        const isDuplicateRoom = duplicate && duplicate.toLowerCase() === 'room';
        const date = duplicate !== 'room' ? detail && detail.startTime && this.parseDateToShow(detail.startTime) : '';
        const sTime =
            duplicate !== 'room'
                ? detail && detail.startTime && this.parseTimeToShow(detail.startTime, detail.timezone)
                : this.state.roomStartTime;
        const eTime =
            duplicate !== 'room'
                ? detail && detail.endTime && this.parseTimeToShow(detail.endTime, detail.timezone)
                : this.state.roomEndTime;
        const gTime =
            duplicate !== 'room'
                ? detail &&
                  detail.greenRoomStartTime &&
                  this.parseTimeToShow(detail.greenRoomStartTime, detail.timezone)
                : this.state.greenRoomStartTime;
        const imagePath = detail && detail.image && (await this.getImageSrc(detail.image));
        const isRoomPastStartTime = !isDuplicateRoom && this.isRoomPastStartTime(detail);
        this.setState(prevState => ({
            ...this.state,
            roomName: detail.name,
            isRoomPastStartTime: isRoomPastStartTime,
            youtubeRtmpLink: detail.youtubeStreamLink || '',
            isShareAble: detail.isShareAble,
            isLoungeEnabled: detail.isLoungeEnabled,
            isStreamingEnabled: detail.isYoutubeStreamEnabled,
            roomDescription: detail.description,
            roomDate: date,
            roomStartTime: sTime,
            roomEndTime: eTime,
            organizationLogo: null,
            existingLogoPath: detail.image,
            localImagePath: imagePath,
            speakersChips: [],
            moderatorsChips: [],
            attendeesChips: [],
            guestChips: [],
            greenRoomMins: detail.greenRoomStartTime
                ? Number(
                      DateTime.fromISO(detail.startTime)
                          .diff(DateTime.fromISO(detail.greenRoomStartTime))
                          .toFormat('mm'),
                  )
                : 0,
            greenRoomStartTime: gTime,
        }));
        this.setMembers(detail.participants);
    }
    setMembers = member => {
        let speakers = [];
        let moderators = [];
        let attendees = [];
        let guests = [];

        moderators =
            member &&
            member.filter(member => {
                return member.email && member.role === Constant.ROOM_MEMBERS.MODERATOR;
            });
        guests =
            member &&
            member.filter(member => {
                return member.email && member.role === Constant.ROOM_MEMBERS.GUEST;
            });
        attendees =
            member &&
            member.filter(member => {
                return member.email && member.role === Constant.ROOM_MEMBERS.ATTENDEE;
            });
        speakers =
            member &&
            member.filter(member => {
                return member.email && member.role === Constant.ROOM_MEMBERS.SPEAKER;
            });

        moderators.map(moderator =>
            this.setState({ moderatorsChips: [...this.state.moderatorsChips, moderator.email] }),
        );
        guests.map(guest => this.setState({ guestChips: [...this.state.guestChips, guest.email] }));
        attendees.map(attendee => this.setState({ attendeesChips: [...this.state.attendeesChips, attendee.email] }));
        speakers.map(speaker => this.setState({ speakersChips: [...this.state.speakersChips, speaker.email] }));
    };

    setSelectedLogo = logo => {
        const image = this._utilityService.validateImageFile(logo, Constant.FILE_UPLOAD_LIMIT);
        if (image.isValid) {
            this.setState({
                ...this.state,
                organizationLogo: logo,
                localImagePath: URL.createObjectURL(logo),
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            return;
        }

        this.setState({
            ...this.state,
            organizationLogo: null,
            localImagePath: '',
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: image.message,
            },
        });
    };

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    setRoomName = roomName => {
        this.setState({
            ...this.state,
            roomName,
            validationErrorMessage: { ...this.state.validationErrorMessage, roomNameValidation: '' },
        });
    };
    setYoutubeRtmpLink = youtubeRtmpLink => {
        this.setState({
            ...this.state,
            youtubeRtmpLink,
            validationErrorMessage: { ...this.state.validationErrorMessage, youtubeRtmpValidation: '' },
        });
    };

    setRoomShareAble = e => {
        this.setState({
            ...this.state,
            isShareAble: e.target.checked,
        });
    };

    setLoungeEnabled = e => {
        this.setState({
            ...this.state,
            isLoungeEnabled: e.target.checked,
        });
    };

    setStreamingEnabled = e => {
        const flag = e.target.checked;
        this.setState(
            {
                ...this.state,
                isStreamingEnabled: flag,
            },
            () => {
                if (!flag) {
                    this.setYoutubeRtmpLink('');
                }
            },
        );
    };

    setRoomDescription = roomDescription => {
        this.setState({
            ...this.state,
            roomDescription,
            validationErrorMessage: { ...this.state.validationErrorMessage, descriptionBoxValidation: '' },
        });
    };

    setRoomDate = e => {
        this.setState({
            ...this.state,
            roomDate: e.target.value,
            validationErrorMessage: { ...this.state.validationErrorMessage, dateValidation: '' },
        });
    };

    setStartTime = e => {
        this.setState({
            ...this.state,
            roomStartTime: e.target.value,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                startTimeValidation: '',
                greenRoomValidation: '',
            },
        });
    };

    setEndTime = e => {
        this.setState({
            ...this.state,
            roomEndTime: e.target.value,
            validationErrorMessage: { ...this.state.validationErrorMessage, endTimeValidation: '' },
        });
    };

    addItemToSpeakersChips = chip => {
        const speakersChips = Array.isArray(chip) ? chip : this.state.speakersChips.slice();
        if (!Array.isArray(chip)) {
            speakersChips.push(chip);
        }
        this.setState({
            ...this.state,
            speakersChips,
            validationErrorMessage: { ...this.state.validationErrorMessage, speakerEmailsValidation: '' },
        });
    };
    addItemToModeratorsChips = chip => {
        const moderatorsChips = Array.isArray(chip) ? chip : this.state.moderatorsChips.slice();
        if (!Array.isArray(chip)) {
            moderatorsChips.push(chip);
        }
        this.setState({
            ...this.state,
            moderatorsChips,
            validationErrorMessage: { ...this.state.validationErrorMessage, moderatorEmailValidation: '' },
        });
    };
    addItemToAttendeesChips = chip => {
        const attendeesChips = Array.isArray(chip) ? chip : this.state.attendeesChips.slice();
        if (!Array.isArray(chip)) {
            attendeesChips.push(chip);
        }
        this.setState({
            ...this.state,
            attendeesChips,
            validationErrorMessage: { ...this.state.validationErrorMessage, attendeeEmailValidation: '' },
        });
    };

    addItemToGuestsChips = chip => {
        const guestChips = Array.isArray(chip) ? chip : this.state.guestChips.slice();
        if (!Array.isArray(chip)) {
            guestChips.push(chip);
        }
        this.setState({
            ...this.state,
            guestChips,
            validationErrorMessage: { ...this.state.validationErrorMessage, guestEmailValidation: '' },
        });
    };

    deleteItemFromModeratorsChips = index => {
        const moderatorsChips = this.state.moderatorsChips.slice();
        moderatorsChips.splice(index, 1);
        this.setState({ ...this.state, moderatorsChips });
    };

    deleteItemFromSpeakersChips = index => {
        const speakersChips = this.state.speakersChips.slice();
        speakersChips.splice(index, 1);
        this.setState({ ...this.state, speakersChips });
    };

    deleteItemFromAttendeesChips = index => {
        const attendeesChips = this.state.attendeesChips.slice();
        attendeesChips.splice(index, 1);
        this.setState({ ...this.state, attendeesChips });
    };

    deleteItemFromGuestsChips = index => {
        const guestChips = this.state.guestChips.slice();
        guestChips.splice(index, 1);
        this.setState({ ...this.state, guestChips });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    checkUniqueEmails = (list1, list2) => {
        return !list1.some(l => list2.includes(l));
    };

    validateForm = async () => {
        const {
            moderatorEmailValidation,
            speakerEmailsValidation,
            attendeeEmailValidation,
            guestEmailValidation,
        } = this.state.validationErrorMessage;
        if (moderatorEmailValidation || speakerEmailsValidation || attendeeEmailValidation || guestEmailValidation) {
            return;
        }
        await this.resetValidationError();
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        // const { roomId } = this.props.match.params;
        if (!this.state.organizationLogo && !this.state.localImagePath) {
            validationErrorMessage.logoValidation = 'Please select the logo';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.roomName || !this.state.roomName.trim()) {
            validationErrorMessage.roomNameValidation = 'Please fill the room name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        if (this.state.isStreamingEnabled && (!this.state.youtubeRtmpLink || !this.state.youtubeRtmpLink.trim())) {
            validationErrorMessage.youtubeRtmpValidation = 'Please fill the youtube link';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.roomDate) {
            validationErrorMessage.dateValidation = 'Please select a date';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.roomStartTime) {
            validationErrorMessage.startTimeValidation = 'Please select a starting time';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.roomEndTime) {
            validationErrorMessage.endTimeValidation = 'Please select an ending time';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const start = DateTime.fromISO(this.state.roomStartTime)
            .minus({ minutes: this.state.greenRoomMins })
            .toFormat('HH:mm');
        const end = DateTime.fromISO(this.state.roomEndTime).toFormat('HH:mm');
        const currentDate = DateTime.now()
            .toFormat('yyyy-MM-dd')
            .toString();
        const currentTime = DateTime.local()
            .setZone(this.state.utc.includes(DateTime.local().zoneName) ? DateTime.local().zoneName : this.state.utc[0])
            .toFormat('HH:mm');
        if (this.state.roomDate < currentDate) {
            validationErrorMessage.dateValidation = 'Date must not be in the past. Please select valid date.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (this.state.roomDate === currentDate) {
            if (end <= currentTime) {
                validationErrorMessage.endTimeValidation = 'Please select a valid end time.';
                this.setErrorMessages(validationErrorMessage);
                return false;
            } else {
                if (start < currentTime) {
                    validationErrorMessage.startTimeValidation = 'Please select a valid start time.';
                    if (this.state.greenRoomMins > 0) {
                        validationErrorMessage.greenRoomValidation =
                            'Please make sure the gap between green room and the room starting time.';
                    }
                    this.setErrorMessages(validationErrorMessage);
                    return false;
                }
            }
        }

        if (start >= end) {
            validationErrorMessage.endTimeValidation = 'Please select a valid end time';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.timeZone) {
            validationErrorMessage.timeZoneValidation = 'Please select a time zone';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.moderatorsChips.length) {
            validationErrorMessage.moderatorEmailValidation = 'Please provide email address(es) for moderators';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < this.state.moderatorsChips.length; i++) {
            let isValid = re.test(this.state.moderatorsChips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.moderatorEmailValidation = 'Provided email address(es) are not valid';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }

        for (let i = 0; i < this.state.speakersChips.length; i++) {
            let isValid = re.test(this.state.speakersChips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.speakerEmailsValidation = 'Provided email address(es) are not valid';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }

        for (let i = 0; i < this.state.attendeesChips.length; i++) {
            let isValid = re.test(this.state.attendeesChips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.attendeeEmailValidation = 'Provided email address(es) are not valid';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }

        for (let i = 0; i < this.state.guestChips.length; i++) {
            let isValid = re.test(this.state.guestChips[i].toLowerCase());
            if (!isValid) {
                validationErrorMessage.guestEmailValidation = 'Provided email address(es) are not valid';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }

        return true;
    };

    validateAllEmails = (roleEmail, chip, dsiappearValidationMsg = false) => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };

        if (!this.checkUniqueEmails(this.state.moderatorsChips, chip) && roleEmail !== 'moderatorEmailValidation') {
            if (roleEmail === 'speakerEmailsValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'speakerEmailsValidation' ? 'Moderator' : 'Speaker'
                } emails cannot be added in to the ${
                    roleEmail === 'speakerEmailsValidation' ? 'speakers' : 'moderators'
                } field.`;
            } else if (roleEmail === 'attendeeEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'attendeeEmailValidation' ? 'Moderator' : 'Attendee'
                } emails cannot be added in to the ${
                    roleEmail === 'attendeeEmailValidation' ? 'attendees' : 'moderators'
                } field.`;
            } else if (roleEmail === 'guestEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'guestEmailValidation' ? 'Moderator' : 'Guest'
                } emails cannot be added in to the ${
                    roleEmail === 'guestEmailValidation' ? 'guests' : 'moderators'
                } field.`;
            }
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(dsiappearValidationMsg, roleEmail);
            return false;
        }

        if (!this.checkUniqueEmails(this.state.speakersChips, chip) && roleEmail !== 'speakerEmailsValidation') {
            if (roleEmail === 'moderatorEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'moderatorEmailValidation' ? 'Speaker' : 'Moderator'
                } emails cannot be added in to the ${
                    roleEmail === 'moderatorEmailValidation' ? 'moderators' : 'speakers'
                } field.`;
            } else if (roleEmail === 'attendeeEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'attendeeEmailValidation' ? 'Speaker' : 'Attendee'
                } emails cannot be added in to the ${
                    roleEmail === 'attendeeEmailValidation' ? 'attendees' : 'speakers'
                } field.`;
            } else if (roleEmail === 'guestEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'guestEmailValidation' ? 'Speaker' : 'Guest'
                } emails cannot be added in to the ${
                    roleEmail === 'guestEmailValidation' ? 'guests' : 'speakers'
                } field.`;
            }
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(dsiappearValidationMsg, roleEmail);
            return false;
        }

        if (!this.checkUniqueEmails(this.state.attendeesChips, chip) && roleEmail !== 'attendeeEmailValidation') {
            if (roleEmail === 'moderatorEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'moderatorEmailValidation' ? 'Attendee' : 'Moderator'
                } emails cannot be added in to the ${
                    roleEmail === 'moderatorEmailValidation' ? 'moderators' : 'attendees'
                } field.`;
            } else if (roleEmail === 'speakerEmailsValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'speakerEmailsValidation' ? 'Attendee' : 'Speaker'
                } emails cannot be added in to the ${
                    roleEmail === 'speakerEmailsValidation' ? 'speakers' : 'attendees'
                } field.`;
            } else if (roleEmail === 'guestEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'guestEmailValidation' ? 'Attendee' : 'Guest'
                } emails cannot be added in to the ${
                    roleEmail === 'guestEmailValidation' ? 'guests' : 'attendees'
                } field.`;
            }
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(dsiappearValidationMsg, roleEmail);
            return false;
        }

        if (!this.checkUniqueEmails(this.state.guestChips, chip) && roleEmail !== 'guestEmailValidation') {
            if (roleEmail === 'moderatorEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'moderatorEmailValidation' ? 'Guest' : 'Moderator'
                } emails cannot be added in to the ${
                    roleEmail === 'moderatorEmailValidation' ? 'moderators' : 'guests'
                } field.`;
            } else if (roleEmail === 'speakerEmailsValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'speakerEmailsValidation' ? 'Guest' : 'Speaker'
                } emails cannot be added in to the ${
                    roleEmail === 'speakerEmailsValidation' ? 'speakers' : 'guests'
                } field.`;
            } else if (roleEmail === 'attendeeEmailValidation') {
                validationErrorMessage[roleEmail] = `${
                    roleEmail === 'attendeeEmailValidation' ? 'Guest' : 'Attendee'
                } emails cannot be added in to the ${
                    roleEmail === 'attendeeEmailValidation' ? 'attendees' : 'guests'
                } field.`;
            }
            this.setErrorMessages(validationErrorMessage);
            this.disappearValidationMsg(dsiappearValidationMsg, roleEmail);
            return false;
        }

        return true;
    };

    disappearValidationMsg = async (disappearValidationMsg, roleEmail) => {
        if (disappearValidationMsg) {
            setTimeout(async () => {
                await this.resetEmailValidationErrors(roleEmail);
            }, 1500);
        }
    };

    uploadFileToStorage = async () => {
        const credentials = await this._authService.getAuthCredentials();
        const cognitoId = credentials.identityId;
        const storageMetaData = await this._awsStorageService.uploadFile(
            `${cognitoId}/${this.state.organizationLogo.name}`,
            this.state.organizationLogo,
            this.state.organizationLogo.type,
        );
        return storageMetaData;
    };

    getStorageMetaData = async () => {
        const { roomId } = this.props.match.params;
        let storageMetaData = '';
        if (roomId) {
            if (this.state.organizationLogo) {
                storageMetaData = await this.uploadFileToStorage();
            } else {
                storageMetaData = this.state.existingLogoPath;
            }
        } else {
            storageMetaData = await this.uploadFileToStorage();
        }
        return storageMetaData;
    };

    getCompleteTime = (date, time) => {
        return DateTime.fromISO(date + 'T' + time, {
            zone: this.state.utc.includes(DateTime.local().zoneName) ? DateTime.local().zoneName : this.state.utc[0],
        })
            .toUTC()
            .toISO();
    };

    resetValidationError = async () => {
        await this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                roomNameValidation: '',
                dateValidation: '',
                youtubeRtmpValidation: '',
                descriptionBoxValidation: '',
                speakerEmailsValidation: '',
                moderatorEmailValidation: '',
                attendeeEmailValidation: '',
                guestEmailValidation: '',
                logoValidation: '',
                startTimeValidation: '',
                endTimeValidation: '',
                timeZoneValidation: '',
            },
        });
    };

    resetEmailValidationErrors = async roleEmail => {
        const validationErrorMessage = {
            ...this.state.validationErrorMessage,
        };
        if (roleEmail) {
            validationErrorMessage[roleEmail] = '';
        } else {
            validationErrorMessage.speakerEmailsValidation = '';
            validationErrorMessage.moderatorEmailValidation = '';
            validationErrorMessage.attendeeEmailValidation = '';
            validationErrorMessage.guestEmailValidation = '';
        }
        await this.setState({
            ...this.state,
            validationErrorMessage,
        });
    };

    saveRoom = async payload => {
        const { roomId } = this.props.match.params;
        const duplicate = this.getQueryParams().get('duplicate');
        roomId && duplicate !== 'room'
            ? await this._createRoomService.updateRoom(payload, roomId)
            : await this._createRoomService.createRoom(payload);
        this.initializeFormFields();
        this.props.history.goBack();
    };
    createRoom = async e => {
        e.preventDefault();
        const validate = await this.validateForm();
        if (!validate) {
            return;
        }
        this.props.startLoading(true);
        const storageMetaData = await this.getStorageMetaData();
        const start = this.getCompleteTime(this.state.roomDate, this.state.roomStartTime);
        const end = this.getCompleteTime(this.state.roomDate, this.state.roomEndTime);
        const payload = {
            name: this.state.roomName,
            isYoutubeStreamEnabled: this.state.isStreamingEnabled,
            youtubeStreamLink: this.state.youtubeRtmpLink,
            description: this.state.roomDescription,
            isShareAble: this.state.isShareAble,
            isLoungeEnabled: this.state.isLoungeEnabled,
            startTime: start,
            endTime: end,
            boardId: this.props.match.params.boardId,
            image: storageMetaData.key ? storageMetaData.key : storageMetaData,
            timezone: this.state.timeZone,
            moderators: this.state.moderatorsChips.slice(),
            speakers: this.state.speakersChips.slice(),
            attendees: this.state.attendeesChips.slice(),
            guests: this.state.guestChips.slice(),
            greenRoomMins: this.state.greenRoomMins,
        };

        try {
            await this.saveRoom(payload);
            this.initializeFormFields();
        } catch (e) {
            this.props.startLoading(false);
        }
    };

    initializeFormFields = () => {
        this.setState({
            ...this.state,
            roomName: '',
            roomDescription: '',
            organizationLogo: null,
            localImagePath: '',
            moderatorsChips: [],
            speakersChips: [],
        });
    };
    render() {
        const moderatorPopover = (
            <Popover id="popover-basic" className="moderator__popover">
                <MyPopover items={Constant.MODERATORS_DETAIL_ROOM && Constant.MODERATORS_DETAIL_ROOM.items} />
            </Popover>
        );
        return (
            <CreateRoomForm
                {...this.state}
                setSelectedLogo={this.setSelectedLogo}
                validateImageFileMessage={this.validateImageFileMessage}
                setRoomName={this.setRoomName}
                setYoutubeRtmpLink={this.setYoutubeRtmpLink}
                setRoomShareAble={this.setRoomShareAble}
                setRoomDescription={this.setRoomDescription}
                setRoomDate={this.setRoomDate}
                setStartTime={this.setStartTime}
                setEndTime={this.setEndTime}
                setSelectedOption={this.selectTimeZone}
                setTimeZone={this.setTimeZone}
                setLoungeEnabled={this.setLoungeEnabled}
                setStreamingEnabled={this.setStreamingEnabled}
                moderatorPopover={moderatorPopover}
                saveRecentImagesToLocal={this.saveRecentImagesToLocal}
                getRecentImages={this.getRecentImages}
                addItemToModeratorsChips={this.addItemToModeratorsChips}
                deleteItemFromModeratorsChips={this.deleteItemFromModeratorsChips}
                addItemToSpeakersChips={this.addItemToSpeakersChips}
                deleteItemFromSpeakersChips={this.deleteItemFromSpeakersChips}
                addItemToAttendeesChips={this.addItemToAttendeesChips}
                deleteItemFromAttendeesChips={this.deleteItemFromAttendeesChips}
                addItemToGuestsChips={this.addItemToGuestsChips}
                deleteItemFromGuestsChips={this.deleteItemFromGuestsChips}
                createRoom={this.createRoom}
                validateEmails={this.validateAllEmails}
                resetEmailValidationErrors={this.resetEmailValidationErrors}
                onImageSearchChange={this.onImageSearchChange}
                onImageScrollDown={this.onImageScrollDown}
                showHideErrorToaster={this.props.showHideErrorToaster}
                startLoading={this.props.startLoading}
                setGreenRoomMins={this.setGreenRoomMins}
                greenRoomMins={this.state.greenRoomMins}
            />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(null, mapDispatchToProps)(CreateRoomContainer);
