import React from 'react';
import { connect } from 'react-redux';
import './card-component.style.scss';
import { Col, Row, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Constant, ImageComponent, ThreeDotsDropDownComponent, Utility } from '../../../../shared';
import dots from '../../../../assets/images/icon-dots.svg';

const cardComponent = props => {
    const { contentList, onSelectMenu, menuItems } = { ...props };
    const _utilityService = new Utility();
    contentList.forEach((element, index) => {
        if (element.member && element.member.length > 0) {
            element.membersName = element.member
                .filter(m => m.role === Constant.ORGANIZATION_CARDS_ROLE_FILTER_CONSTANT)
                .map(elem => elem.name || Constant.MEMBER_FALLBACK.name);
            if (element.membersName && element.membersName.length <= 3) {
                element.membersName = element.membersName.join(', ');
            } else {
                element.membersName = element.membersName.slice(0, 3).join(', ');
                element.showToolTip = true;
            }
        }
    });

    const membersPopover = members => {
        return (
            <Popover id="popover-basic" className="executiveproducer__popover">
                <Popover.Content className="ep__popovercontent">
                    <h5 className="head">Executive Producers</h5>
                    <ul>
                        <li className="role__list__item">
                            {members
                                .filter(m => m.role === Constant.ORGANIZATION_CARDS_ROLE_FILTER_CONSTANT)
                                .map(elem => elem.name || Constant.MEMBER_FALLBACK.name)
                                .join(', ')}
                        </li>
                    </ul>
                </Popover.Content>
            </Popover>
        );
    };

    return (
        <>
            {contentList && contentList.length ? (
                <Row className="organization__listing">
                    {contentList.map((item, index) => {
                        const isExecutiveProducer = _utilityService.isUserPermissionGranted(
                            props.user,
                            item.member,
                            Constant.ORGANIZATION_CARDS_ROLE_FILTER_CONSTANT,
                        );
                        return (
                            <Col md="4" className="organization__col" key={item.id}>
                                <div className="organization__card" data-test="organization__card">
                                    <div className="organization__card-header">
                                        <ImageComponent
                                            id={`src-${index + 1}`}
                                            src={item.logo}
                                            placeholder={null}
                                            className="organization__card-img img-fluid"
                                            alt=""
                                        />
                                        {props.user.isRoot || isExecutiveProducer ? (
                                            <ThreeDotsDropDownComponent
                                                id={`organization-list-${index}`}
                                                menuItems={menuItems}
                                                onSelect={selectedOption => {
                                                    onSelectMenu(selectedOption, item);
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <Link
                                        id={`org-name-${index + 1}`}
                                        to={{
                                            pathname: `/organization/${item.id}`,
                                        }}
                                        className="organization__card-body"
                                        data-test="card_component_link"
                                    >
                                        <div className="organization__card-org-name">{item.name}</div>
                                        <h5 className="organization__card-body-heading" data-test="exec_prod">
                                            Executive Producer
                                        </h5>
                                        <p
                                            id={`ep-value-${index + 1}`}
                                            className="organization__card-role-names"
                                            data-test="members_name"
                                        >
                                            {item.membersName}
                                            &nbsp;
                                            {item.showToolTip && (
                                                <OverlayTrigger
                                                    id="create-board-overlay"
                                                    trigger={['hover', 'focus']}
                                                    placement="top"
                                                    overlay={membersPopover(item.member)}
                                                    triggerId={'members-info-trigger'}
                                                >
                                                    <img src={dots} className="dropdown__dots" alt="" />
                                                </OverlayTrigger>
                                            )}
                                        </p>
                                        <div className="organization__card-footer">
                                            <div
                                                className="organization__card-single-info"
                                                data-test="organization_card_footer"
                                            >
                                                <h5
                                                    id={`member-label-${index + 1}`}
                                                    className="organization__card-body-heading"
                                                >
                                                    Members
                                                </h5>
                                                <p
                                                    id={`member-value-${index + 1}`}
                                                    className="organization__card-single-count"
                                                >
                                                    {_utilityService.getNumberWithCommas(
                                                        (item.member && item.member.length) || 0,
                                                    )}
                                                </p>
                                            </div>
                                            <div
                                                className="organization__card-single-info"
                                                data-test="organization_single_info"
                                            >
                                                <h5
                                                    id={`board-label-${index + 1}`}
                                                    className="organization__card-body-heading"
                                                >
                                                    Boards
                                                </h5>
                                                <p
                                                    id={`board-value-${index + 1}`}
                                                    className="organization__card-single-count"
                                                >
                                                    {_utilityService.getNumberWithCommas(item.totalBoards || 0)}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            ) : (
                <div id="empty-container" className="br__organization-empty" data-test="organization_empty"></div>
            )}
        </>
    );
};

const mapStateToProp = state => {
    /**
     * This will return all the state declare inside the app directory in store
     */
    return {
        ...state.app,
    };
};

export default connect(mapStateToProp)(cardComponent);
