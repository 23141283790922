import Pubnub from 'pubnub';
import Emitter from '../../services/emitter/emitter';

export class PubnubService {
    pubnub;
    listener;

    initialize = (pubNubAuthKey, userId) => {
        this.pubnub = new Pubnub({
            publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
            subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
            uuid: userId,
            authKey: pubNubAuthKey,
            presenceTimeout: 120,
            heartbeatInterval: 30,
        });
        this.listener = {
            status: this.handleStatusEvent,
            message: this.handleMessageEvent,
            presence: this.handlePresenceEvent,
            signal: this.handleSignal,
            messageAction: this.handleMessageAction,
            objects: this.handleObjects,
            file: this.handleFile,
        };
    };

    addChannelGroup = (channels, channelGroup) => {
        this.pubnub.channelGroups.addChannels(
            {
                channels,
                channelGroup,
            },
            status => {
                console.log({ status });
            },
        );
    };

    getPubNub = () => {
        return this.pubnub;
    };

    reconnect = () => {
        this.pubnub.reconnect();
    };

    publish = async (message, channel) => {
        await this.pubnub.publish(
            {
                message,
                channel,
            },
            (status, response) => {
                // handle status, response
            },
        );
    };

    subscribe = (channel, withPresence) => {
        this.pubnub.subscribe(
            {
                channels: channel,
                withPresence,
            },
            (status, response) => {
                // handle status, response
            },
        );
    };

    unsubscribe = async channel => {
        await this.pubnub.unsubscribe({
            channels: channel,
        });
    };

    handleMessageEvent = m => {
        const { channel, subscription, timetoken, message, publisher } = m;
        Emitter.emit('message_received', { m });
    };

    handleStatusEvent = s => {
        const {
            affectedChannelGroups,
            affectedChannels,
            category,
            operation,
            lastTimetoken,
            currentTimetoken,
            subscribedChannels,
        } = s;
        Emitter.emit('status_received', { s });
    };

    handlePresenceEvent = p => {
        const { action, channel, occupancy, state, subscription, timestamp, timetoken, uuid } = p;
        Emitter.emit('presence_received', { p });
    };

    handleSignal = s => {
        const { channel, subscription, timetoken, message, publisher } = s;
        Emitter.emit('signal_received', { signal: s });
    };

    handleMessageAction = ma => {
        const { channel, publisher, message } = ma;
        Emitter.emit('message_action_received', { messageAction: ma });
    };

    handleObjects = o => {
        const { channel, subscription, timetoken, message } = o;
        Emitter.emit('object_received', { object: o });
    };

    handleFile = f => {
        const { channel, subscription, timetoken, message, file, publisher } = f;
        Emitter.emit('file_received', { file: f });
    };

    hereNow = roomId => {
        return this.pubnub.hereNow({
            channels: roomId,
            includeState: true,
        });
    };

    addEventListener = () => {
        this.pubnub.addListener(this.listener);
    };

    removeEventListener = () => {
        this.pubnub.removeListener(this.listener);
    };
}
