import { Http } from '../../../../core';

export class OrganizationService {
    /**
     * @name createOrganization
     * @param {object} requestPayload request payload for organization creation
     * @desc Will create an organization
     * @return {Promise}
     */
    createOrganization = requestPayload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post('organization', requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name updateOrganization
     * @param {object} requestPayload request payload for organization updation
     * @param {string} organizationId selected Id For updating the organization
     * @desc Will update an organization
     * @return {Promise}
     */
    updateOrganization = (requestPayload, organizationId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`organization/${organizationId}`, requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getOrganizatons
     * @param {number} pageNo for pagination
     * @param {number} pageSize size of data per page
     * @desc will fetch the list of organizations
     * @return {Promise}
     */
    getOrganizatons = (pageNo, pageSize) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`organization?pageIndex=${pageNo}&pageSize=${pageSize}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };
}
