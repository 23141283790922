import React from 'react';
import '../board-header-subtitles/board-header-subtitles.style.scss';
import { Col, OverlayTrigger } from 'react-bootstrap';
import { Utility, Constant } from '../../services';
import { v4 as uuidv4 } from 'uuid';

const BoardHeaderSubtitle = props => {
    const { subtitles, subtitle, index } = props;
    const { boardListClass, popover, boardListClassParent } =
        (props.getDescription && props.getDescription(subtitles, subtitle)) || false;
    const lists = subtitles[subtitle];
    const _utilityService = new Utility();
    return (
        <>
            <Col
                id={`board-detail-column-${index}`}
                className={boardListClassParent || 'board-detail__subtitle-wrap'}
                xs="6"
                sm="2"
                md="2"
                lg="2"
                xl="2"
            >
                <h6
                    id={`board-detail-subtitle-${index}`}
                    data-test="board-detail-subtitle"
                    className="board-detail__subtitle"
                >
                    {props.getHeadings(subtitle)}
                </h6>
                {lists && Array.isArray(lists) ? (
                    lists.map((list, i) => (
                        <p
                            key={uuidv4()}
                            id={`board-detail-names-${index}-${i}`}
                            data-test="board-detail-subtitle-values"
                            className="board-detail__names-list"
                        >
                            {list}
                        </p>
                    ))
                ) : boardListClass ? (
                    <p
                        id={`board-detail-names-${index}`}
                        data-test="board-detail-subtitle-values"
                        className={boardListClass}
                        dangerouslySetInnerHTML={{ __html: _utilityService.replaceHyperLinkWithAnchor(lists) }}
                    ></p>
                ) : (
                    <p
                        id={`board-detail-names-${index}`}
                        data-test="board-detail-subtitle-values"
                        className="board-detail__names-list"
                    >
                        {lists}
                    </p>
                )}
                {subtitles[subtitle] &&
                    subtitle === Constant.BOARD_HEADER_DESCRIPTION_SUBTITLE &&
                    subtitles[subtitle].length > Constant.BOARD_HEADER_DESCRIPTION_SUBTITLE_POPOVER_LIMIT && (
                        <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
                            <h6
                                id="board-details-readmore"
                                data-test="read-more-popover"
                                className="board-detail__readmore"
                            >
                                {Constant.BOARD_HEADER_DESCRIPTION_SUBTITLE_POPOVER_LABEL}
                            </h6>
                        </OverlayTrigger>
                    )}
            </Col>
        </>
    );
};

export default BoardHeaderSubtitle;
