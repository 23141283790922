import React from 'react';
import '../room-speakers-component/room-speakers-component.style.scss';
import dragDrop from '../../../../assets/images/ic-drag-drop.svg';
import iconUserPlaceholder from '../../../../assets/images/ic-user.svg';

import { Media } from 'react-bootstrap';
import { ThreeDotsDropDownComponent } from '../../../../shared';

const RoomSpeakerComponent = props => {
    return (
        <React.Fragment>
            <li className="room-detail__speakers-item">
                <img
                    data-test="drag_drop_img"
                    {...props.dragHandleProps}
                    className="room-detail__drag-drop"
                    src={dragDrop}
                    alt="img"
                />
                <Media className="room-detail__speaker-info">
                    <img
                        // width={64}
                        // height={64}
                        className="mr-3 img-fluid room-detail__speaker-img"
                        src={props.member.image || iconUserPlaceholder}
                        placeholder={iconUserPlaceholder}
                        alt="img"
                    />
                    <Media.Body>
                        <div className="room-detail__speaker-head">
                            <h5 className="room-detail__speaker-name" title={props.member.name}>
                                <span className="room-detail__speaker-name-span">{props.member.name}</span>
                                <img
                                    className="room-detail__icon-identity"
                                    src={props.member.inviteStatusIcon}
                                    alt="img"
                                />
                            </h5>
                            <h6 className="room-detail__speaker-role">{props.member.role}</h6>
                        </div>
                        <p className="room-detail__speaker-blurb" title={props.member.blurb}>
                            {props.member.blurb}
                        </p>
                    </Media.Body>
                </Media>
                <ThreeDotsDropDownComponent
                    menuItems={props.member.menuItems}
                    onSelect={item => {
                        props.onSelectMenu(item, props.member.id, props.boardId);
                    }}
                />
            </li>
        </React.Fragment>
    );
};

export default RoomSpeakerComponent;
