import React from 'react';
import { connect } from 'react-redux';
import { startLoading, showHideErrorToaster, AuthService, UserService } from '../../../../core';
import { Constant, InputComponent, ButtonComponent, ErrorMessageComponent } from '../../../../shared';
import './guest-create-private-room.style.scss';
import { Link } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import { DateTime } from 'luxon';
import iconCancel from '../../../../assets/images/ic-cancel.svg';
import iconPrivateRoom from '../../../../assets/images/ic-private-room-created.png';
import { GuestPrivateRoomService } from '../../services';

export class GuestCreatePrivateRoom extends React.Component {
    _guestPrivateRoomService = new GuestPrivateRoomService();
    _authService = new AuthService();
    _userService = new UserService();
    createdRoomResponse = null;

    constructor(props) {
        super(props);
        this.state = {
            isSuccessModalOpen: false,
            roomName: '',
            roomDescription: '',
            roomDate: '',
            roomStartTime: DateTime.local()
                .plus({ minutes: 30 - (DateTime.local().minute % 30) })
                .toFormat('HH:mm'),
            roomEndTime: DateTime.local()
                .plus({ minutes: 30 - (DateTime.local().minute % 30) })
                .plus({ hours: 1 })
                .toFormat('HH:mm'),
            timeZone: '',
            utc: [],
            validationErrorMessage: {
                roomNameValidation: '',
                dateValidation: '',
                startTimeValidation: '',
                endTimeValidation: '',
                timeZoneValidation: '',
            },
        };
    }

    componentDidMount = () => {
        const isAnonymous = this._userService.isAnonymous();
        if (!isAnonymous) {
            this.canCreatePrivateRooms();
            return;
        }
        this.displayUnauthorizedAndNavigateToLogin();
    };

    canCreatePrivateRooms = async () => {
        try {
            const privateRoomAuthorized = await this._guestPrivateRoomService.canCreatePrivateRoom();
            if (privateRoomAuthorized && privateRoomAuthorized.authorized) {
                this.setTimeZone(this.getTimeZone());
                return;
            }
            this.displayUnauthorizedAndNavigateToLogin();
        } catch (error) {}
    };

    displayUnauthorizedAndNavigateToLogin = () => {
        this.displayToasterMessage(Constant.UNAUTHORIZED_MESSAGE, true);
        this._authService.logout();
        this.props.history.replace({
            pathname: '/guest',
        });
    };

    displayToasterMessage = (message, isError) => {
        this.props.showHideErrorToaster({
            show: true,
            message: message,
            bgColor: isError ? '#ff3547' : 'green',
        });
    };

    openCloseModal = open => {
        this.setState({ ...this.state, isSuccessModalOpen: open });
        if (!open) {
            this.props.history.push({
                pathname: `/guest-room-detail/${this.createdRoomResponse.id}`,
            });
        }
    };

    setErrorMessages = validationErrorMessage => this.setState({ ...this.state, validationErrorMessage });

    validateForm = () => {
        const validationErrorMessage = {
            ...this.state.validationErrorMessage,
        };
        if (!this.state.roomName || !this.state.roomName.trim()) {
            validationErrorMessage.roomNameValidation = 'Please fill the room name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.roomDate) {
            validationErrorMessage.dateValidation = 'Please select a date';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.roomStartTime) {
            validationErrorMessage.startTimeValidation = 'Please select a starting time';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.roomEndTime) {
            validationErrorMessage.endTimeValidation = 'Please select an ending time';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const currentDate = DateTime.now()
            .toFormat('yyyy-MM-dd')
            .toString();
        const currentTime = DateTime.local()
            .setZone(this.state.utc.includes(DateTime.local().zoneName) ? DateTime.local().zoneName : this.state.utc[0])
            .toFormat('HH:mm');
        const start = DateTime.fromISO(this.state.roomStartTime).toFormat('HH:mm');
        const end = DateTime.fromISO(this.state.roomEndTime).toFormat('HH:mm');
        if (this.state.roomDate < currentDate) {
            validationErrorMessage.dateValidation = 'Date must not be in the past. Please select valid date.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (this.state.roomDate === currentDate) {
            if (end <= currentTime) {
                validationErrorMessage.endTimeValidation = 'Please select a valid end time.';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
            if (start < currentTime) {
                validationErrorMessage.startTimeValidation = 'Please select a valid start time.';
                this.setErrorMessages(validationErrorMessage);
                return false;
            }
        }

        if (start >= end) {
            validationErrorMessage.endTimeValidation = 'Please select a valid end time';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.timeZone) {
            validationErrorMessage.timeZoneValidation = 'Please select a time zone';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        return true;
    };

    getTimeZone = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const isInDST = machineTime.isInDST;
        const zones = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
        });
        const zoneIsInDST = zones.find(zone => zone.isdst === isInDST) || zones[0];
        return zoneIsInDST;
    };

    setTimeZone = zone => {
        const fallBackValue = Constant.FALLBACK_CONST;
        let timeZone = fallBackValue.id;
        let utc = fallBackValue.utc;
        if (zone) {
            timeZone = zone.id;
            utc = zone.utc;
        }
        this.setState({
            ...this.state,
            timeZone,
            utc,
            validationErrorMessage: { ...this.state.validationErrorMessage, timeZoneValidation: '' },
        });
    };

    setRoomName = roomName => {
        this.setState({
            ...this.state,
            roomName,
            validationErrorMessage: { ...this.state.validationErrorMessage, roomNameValidation: '' },
        });
    };

    setRoomDescription = roomDescription => {
        this.setState({
            ...this.state,
            roomDescription,
            validationErrorMessage: { ...this.state.validationErrorMessage, descriptionBoxValidation: '' },
        });
    };

    setRoomDate = e => {
        this.setState({
            ...this.state,
            roomDate: e.target.value,
            validationErrorMessage: { ...this.state.validationErrorMessage, dateValidation: '' },
        });
    };

    setStartTime = e => {
        this.setState({
            ...this.state,
            roomStartTime: e.target.value,
            validationErrorMessage: { ...this.state.validationErrorMessage, startTimeValidation: '' },
        });
    };

    setEndTime = e => {
        this.setState({
            ...this.state,
            roomEndTime: e.target.value,
            validationErrorMessage: { ...this.state.validationErrorMessage, endTimeValidation: '' },
        });
    };

    selectTimeZone = e => {
        const selectedOption = Constant.TIME_ZONES.filter(tz => tz.id === e.target.value);
        this.setTimeZone(selectedOption[0]);
    };

    getCompleteTime = (date, time) => {
        return DateTime.fromISO(date + 'T' + time, {
            zone: this.state.utc.includes(DateTime.local().zoneName) ? DateTime.local().zoneName : this.state.utc[0],
        })
            .toUTC()
            .toISO();
    };

    save = async () => {
        if (!this.validateForm()) {
            return;
        }
        try {
            this.props.startLoading(true);
            const payload = {
                name: this.state.roomName,
                description: this.state.roomDescription,
                startTime: this.getCompleteTime(this.state.roomDate, this.state.roomStartTime),
                endTime: this.getCompleteTime(this.state.roomDate, this.state.roomEndTime),
                timezone: this.state.timeZone,
            };
            this.createdRoomResponse = await this._guestPrivateRoomService.createRoom(payload);
            this.openCloseModal(true);
        } catch (error) {
        } finally {
            this.props.startLoading(false);
        }
    };

    render() {
        return (
            <>
                <div className="create-private-room">
                    <h2 className="create-private-room__heading">Room Information</h2>

                    <div className="create-private-room__form">
                        <Form.Group
                            controlId="formRoomName"
                            className="form-group--badge"
                            data-test="form_group_room_name"
                        >
                            <Form.Label>Room Name</Form.Label>
                            <InputComponent
                                textId={'room-container-input-name'}
                                onChange={this.setRoomName}
                                charLimit={50}
                                type="board_name"
                                value={this.state.roomName}
                                errorMessage={this.state.validationErrorMessage.roomNameValidation}
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formDescription"
                            className="form-group--badge"
                            data-test="form_group_description"
                        >
                            <Form.Label>Description</Form.Label>
                            <InputComponent
                                textId={'room-container-input-description'}
                                onChange={this.setRoomDescription}
                                type="description_box"
                                classes={'form__textarea'}
                                charLimit={1000}
                                value={this.state.roomDescription}
                            />
                        </Form.Group>

                        <div className="create-private-room__date-time-wrapper">
                            <Form.Group controlId="formDate" className="form-group--date" data-test="form_group_date">
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    onChange={this.setRoomDate}
                                    value={this.state.roomDate}
                                    min={DateTime.now()
                                        .toFormat('yyyy-MM-dd')
                                        .toString()}
                                ></Form.Control>
                                <ErrorMessageComponent
                                    id={'date-validation-message'}
                                    message={this.state.validationErrorMessage.dateValidation}
                                />
                            </Form.Group>

                            <Form.Group
                                controlId="formTimeStart"
                                className="form-group--time"
                                data-test="form_group_from"
                            >
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                    type="time"
                                    onChange={this.setStartTime}
                                    value={this.state.roomStartTime}
                                ></Form.Control>
                                <ErrorMessageComponent
                                    id={'time-start-validation-message'}
                                    message={this.state.validationErrorMessage.startTimeValidation}
                                />
                            </Form.Group>

                            <Form.Group controlId="formTimeEnd" className="form-group--time" data-test="form_group_end">
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                    type="time"
                                    onChange={this.setEndTime}
                                    value={this.state.roomEndTime}
                                ></Form.Control>
                                <ErrorMessageComponent
                                    id={'time-end-validation-message'}
                                    message={this.state.validationErrorMessage.endTimeValidation}
                                />
                            </Form.Group>
                        </div>

                        <Form.Group controlId="roomCreate.Timezone" data-test="form_group_zone">
                            <Form.Label>Time Zone</Form.Label>
                            <Form.Control as="select" onChange={this.selectTimeZone} value={this.state.timeZone}>
                                <option value="">{}</option>
                                {Constant.TIME_ZONES.sort((a, b) => {
                                    return a.offset < b.offset ? -1 : a.offset > b.offset ? 1 : 0;
                                }).map(zone => {
                                    return (
                                        <option key={zone.id} value={zone.id}>
                                            {zone.text}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                            <ErrorMessageComponent id={'time-zone-validation-message'} message={''} />
                        </Form.Group>

                        <div className="comodal__footer">
                            <ButtonComponent
                                id="room-container-button-cancel"
                                className={'btn__link'}
                                name="Cancel"
                                onclick={e => {
                                    e.preventDefault();
                                    this.props.history.goBack();
                                }}
                            />

                            <ButtonComponent
                                id={'room-container-button-submit'}
                                className={'br__btn-theme-filled'}
                                name="Create Room"
                                onclick={this.save}
                            />
                        </div>
                    </div>
                </div>

                <>
                    <Modal
                        className="theme-modal guest-private-room-modal"
                        centered
                        show={this.state.isSuccessModalOpen}
                    >
                        <div
                            onClick={() => {
                                this.openCloseModal(false);
                            }}
                        >
                            <img src={iconCancel} className="br__create-organization-icon-close" />
                        </div>
                        <Modal.Body className="theme-modal__body">
                            <img src={iconPrivateRoom} className="br__create-organization-icon-check" />
                            <h4 className="theme-modal-subheading" data-test="modal_header">
                                Private Room Created
                            </h4>
                        </Modal.Body>
                    </Modal>
                </>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCreatePrivateRoom);
