import { Http } from '..';

export class UserRelations {
    /**
     * @name getUserRelationList
     * @desc this function fetch the relational list for current user based on params passed like inner-circle, followers and followings
     * @return {Promise}
     */
    getUserRelationList = payload => {
        return new Promise((resolve, reject) => {
            let url = `/relation/${payload.relation}?takeCount=${payload.takeCount}&skipCount=${payload.skipCount}`;
            url = payload.searchTxt ? `${url}&search=${payload.searchTxt}` : url;
            Http.REQUEST.get(url)
                .then(
                    _successLog => {
                        return resolve(_successLog.data);
                    },
                    _errorLog => {
                        return reject(_errorLog);
                    },
                )
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name blockPerson
     * @desc this function blocks the person from follower list
     * @return {Promise}
     */
    blockPerson = userId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/relation/block/${userId}`)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getOtherUserProfile
     * @desc this function returns the other person profile.
     * @return {Promise}
     */
    getOtherUserProfile = userId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/user/profile/${userId}`)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getUserMutualRooms
     * @desc Returns the list of mutual rooms of a given user to the current user.
     * @return {Promise}
     */
    getUserMutualRooms = userId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/user/mutual-rooms/${userId}`)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
