import React from 'react';

const tableHeaderComponent = props => {
    const { index } = props;
    return (
        <React.Fragment>
            <td
                data-test="table_header_data"
                id={`table-header-row-data-${index + 1}`}
                className="board-listing__cel--head"
            >
                {props.heading}
            </td>
        </React.Fragment>
    );
};

export default tableHeaderComponent;
