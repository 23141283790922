import { Http } from '../';
// import Emitter from '../../services/emitter/emitter';
export class MagicLinkService {
    /**
     * @name sendMagicLink
     * @param {string} email required for sending magic link
     * @desc Will send magic link to the user
     * @return {Promise}
     */
    sendMagicLink = email => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post(`/user/sendLoginLink`, email)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
