import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import appStore from '../../../assets/images/app-store.png';
import './invite-container.style.scss';
import { Utility } from '../../../shared';

const InviteContainer = props => {
    const _utilityService = new Utility();
    const isPlatformIos = _utilityService.isPlatformIos();

    (() => {
        if (isPlatformIos) {
            return;
        }
        const queryParams = new URLSearchParams(props.location.search);
        const roomId = queryParams.get('roomId') || null;
        if (roomId) {
            props.history.replace(`/login?roomId=${roomId}`);
        } else {
            props.history.replace(`/not-found`);
        }
    })();

    return (
        <div className="invite-wrapper" data-test="invite_container">
            {isPlatformIos ? (
                <div className="invite-wrapper__block">
                    <img
                        src={logo}
                        alt="logo"
                        className="invite-wrapper__logo img-fluid mb-4"
                        data-test="invite-logo"
                    />
                    <p className="mb-3" data-test="invite-text">
                        You have not installed the app.
                    </p>
                    <p>
                        Get{' '}
                        <Link
                            to="javascript:void(0)"
                            onClick={e => {
                                e.preventDefault();
                                window.open(process.env.REACT_APP_MOBILE_APPLICATION_DOWNLOAD_URL, '_blank');
                            }}
                        >
                            Boardroom One
                        </Link>{' '}
                        in the App Store.
                    </p>
                    <Link
                        to="javascript:void(0)"
                        onClick={e => {
                            e.preventDefault();
                            window.open(process.env.REACT_APP_MOBILE_APPLICATION_DOWNLOAD_URL, '_blank');
                        }}
                    >
                        <img src={appStore} alt="app store" className="img-fluid mt-3 invite-wrapper__app-img" />
                    </Link>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default InviteContainer;
