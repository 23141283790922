import React from 'react';
import './organization-detail-container.style.scss';
import BackComponent from '../../../../shared/components/back-component/back-component';
import { AuthService, AWSStorageService, startLoading } from '../../../../core';
import OrgHeader from '../../components/organization-header/organization-header';
import { Link } from 'react-router-dom';
import { OrganizationDetailsDashboardService } from '../../services';
import { Constant, DataTableComponent, Utility } from '../../../../shared';
import { connect } from 'react-redux';

export class OrganizationDetailStylingContainer extends React.Component {
    _awsStorageService = new AWSStorageService();
    _dashboardService = new OrganizationDetailsDashboardService();
    _authService = new AuthService();
    _utilityService = new Utility();
    permissionUpdated = false;
    constructor(props) {
        super(props);
        this.state = {
            boardDetails: [],
            boardId: '',
            menuItems: Constant.ORGANIZATION_DETAILS_MENU_ITEMS,
            organizationDetails: {},
            paginationQueryParams: {
                pageNo: 0,
                totalRecords: 0,
            },
            isUserExecutiveProducer: false,
        };
    }

    getRoles = (membersArray, role) => {
        const totalMembers = [];
        membersArray.forEach(member => {
            if (member.role === role) {
                const name = member.name
                    ? member.name.slice(0, Constant.MEMBER_NAME_LIMIT)
                    : Constant.MEMBER_FALLBACK.name;
                totalMembers.push(name);
            }
        });
        return totalMembers;
    };

    setParams = (pageIndex, pageSize) => {
        const param = {
            pageIndex,
            pageSize,
            orgId: this.state.organizationDetails.id,
        };
        return param;
    };

    fetchBoardDetails = async params => {
        const boards = await this._dashboardService.getBoards(params);
        return boards;
    };

    handleBoardDetails = boards => {
        const boardInfo = boards.results.map(board => {
            const boardInfo = {
                name: board.name,
                totalRooms: board.totalRooms,
                totalMembers: board.member.length,
                directors: this.getRoles(board.member, Constant.BOARD_MEMBERS.DIRECTOR),
                producers: this.getRoles(board.member, Constant.BOARD_MEMBERS.PRODUCER),
                member: board.member,
                id: board.boardId,
            };
            return boardInfo;
        });
        return boardInfo;
    };

    setBoardDetails = (boardInfo, total) => {
        this.setState({
            ...this.state,
            paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: total },
            boardDetails: [...this.state.boardDetails, ...boardInfo],
        });
    };

    containsBoardDetails = async () => {
        const params = this.setParams(0, Constant.ORGANIZATION_DETAILS_CHECK_BOARD_PARAM);
        const boards = await this.fetchBoardDetails(params);
        return boards.results.length > 0;
    };

    getBoardDetails = async () => {
        const params = this.setParams(this.state.paginationQueryParams.pageNo, Constant.PAGINATED_BOARDS);
        const boards = await this.fetchBoardDetails(params);
        const boardInfo = this.handleBoardDetails(boards);
        this.setBoardDetails(boardInfo, boards.total);
    };

    fetchOrganizationDetails = async params => {
        const orgDetails = await this._dashboardService.getOrganizationDetails(params);
        return orgDetails;
    };

    setOrgDetailsInStorage = orgDetails => {
        this._utilityService.setStorageItems('orgId', orgDetails.id);
        this._utilityService.setStorageItems('orgName', orgDetails.name);
        this._utilityService.setStorageItems('orgLogo', orgDetails.logo);
    };

    getOrganizationDetails = async () => {
        const params = this.props.match.params;
        const orgDetails = await this.fetchOrganizationDetails(params);
        this.setOrgDetailsInStorage(orgDetails);
        this.setState({ organizationDetails: orgDetails });
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', this.scrollEndHandler);
        this.props.startLoading(true);
        await this.getOrganizationDetails();
        (await this.containsBoardDetails()) || this.props.user.isRoot || this.state.isUserExecutiveProducer
            ? await this.getBoardDetails()
            : this.props.history.replace({
                  pathname: `/`,
              });
        this.props.startLoading(false);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollEndHandler);
    }

    componentDidUpdate = () => {
        if (!this.permissionUpdated && this.props.user.id && this.state.organizationDetails.name) {
            const isUserExecutiveProducer = this._utilityService.isUserPermissionGranted(
                this.props.user,
                this.state.organizationDetails.member,
                Constant.ORGANIZATION_MEMBERS.EXECUTIVE_PRODUCER,
            );
            this.setState({ ...this.state, isUserExecutiveProducer });
            this.permissionUpdated = true;
        }
    };

    onSelectMenu = async (menuItem, id) => {
        switch (menuItem) {
            case 'Edit Board':
                this.props.history.push({
                    pathname: `/organization/create-board/${this.state.organizationDetails.id}/${id}`,
                });

                break;
            case 'Invite Members':
                /**
                 * Do invite work here
                 */
                break;
            case 'Manage Members':
                this.props.history.push({
                    pathname: `/organization/${this.state.organizationDetails.id}/board/${id}/members`,
                });
                break;

            case 'Delete Board':
                /**
                 * Do Delete work here
                 */
                break;
            default:
                break;
        }
    };
    /**
     * @name scrollEndHandler
     * @desc Handler to listen on document when scroll ends.
     * @return {void}
     */
    scrollEndHandler = async event => {
        const wrappedElement = document.querySelector('body'),
            isBottom = parseInt(wrappedElement.getBoundingClientRect().bottom) <= parseInt(window.innerHeight);
        if (isBottom) {
            const currentRecordCount = Constant.PAGINATED_BOARDS * (this.state.paginationQueryParams.pageNo + 1);
            const { paginationQueryParams } = this.state;
            if (paginationQueryParams.totalRecords > currentRecordCount) {
                this.props.startLoading(true);
                ++paginationQueryParams.pageNo;
                await this.getBoardDetails();
                this.props.startLoading(false);
            }
        }
    };
    getHeadings = headings => {
        const convertedHeaders = [];
        if (headings) {
            headings.forEach(heading => {
                convertedHeaders.push(Constant.BOARDS_CONSTANTS[heading]);
            });
            convertedHeaders.push('');
        }
        return convertedHeaders;
    };

    onListClick = boardId => {
        this.props.history.push({
            pathname: `/organization/${this.state.organizationDetails.id}/boards/${boardId}`,
        });
    };

    render() {
        return (
            <div className="organization-detail">
                <BackComponent id="back-link-org-details" link="/"></BackComponent>
                <div className="organization-detail__head">
                    {this.state.organizationDetails && (
                        <OrgHeader
                            img={this.state.organizationDetails.logo}
                            title={this.state.organizationDetails.name}
                            producers={this.state.organizationDetails.member}
                        />
                    )}
                    {this.props.user.isRoot || this.state.isUserExecutiveProducer ? (
                        <Link
                            id="org-detail-create-new-board"
                            to={{
                                pathname: `/organization/create-board/${this.state.organizationDetails.id}`,
                                state: { org: this.state.organizationDetails.id },
                            }}
                            className="br__btn-theme-filled"
                        >
                            Create New Board
                        </Link>
                    ) : (
                        ''
                    )}
                </div>

                <div className="organization-detail__table-wrapper organization-detail__table-wrapper--org">
                    <DataTableComponent
                        isBoardScreen={true}
                        isUserExecutiveProducer={this.state.isUserExecutiveProducer}
                        data={this.state.boardDetails}
                        getHeadings={this.getHeadings}
                        onClick={this.onListClick}
                        onSelectMenu={this.onSelectMenu}
                        menuItems={this.state.menuItems}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetailStylingContainer);
