import { Http } from '../../../../core';
import axios from 'axios';
export class GuestRoomDetailService {
    /**
     * @name getGuestRoomDetailService
     * @param {string} roomId required for specific room
     * @param {boolean} noFilter flag for backend to get room with or without filter
     * @desc Will fetch specific room detail with or without filter
     * @return {Promise}
     */
    getRoomDetail = async (roomId, noFilter = false) => {
        let http = new Http();
        const url = noFilter ? `room/details/${roomId}?noFilter=${noFilter}` : `room/details/${roomId}`;
        const authToken = await http.getAccessToken();
        var delimeter = '';
        if (!process.env.REACT_APP_API_BASE_URL.endsWith('/')) delimeter = '/';
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}${delimeter}${url}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `token ${authToken}`,
                    },
                })
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    getGuestRoomDetailParticipantProfile = participantId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/profile/${participantId}`)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    acceptOrDeclineGuestRoomDetailStatus = (requestPayload, roomId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.patch(`room/invite/${roomId}`, requestPayload)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    followUnFollowUser = (userId, requestPayload) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/relation/follow/${userId}`, requestPayload)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    innerCircle = (userId, requestPayload) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/relation/inner-circle/${userId}`, requestPayload)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
