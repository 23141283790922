import React, { useState, useEffect } from 'react';
import './cloudinary-image.style.scss';
import { CloudinaryService, Http, startLoading } from '../../../core';
import { Constant, Utility } from '../..';
import { connect } from 'react-redux';

const CloudinaryImage = ({ src, isAsync, setCloudinaryImageUrl, startLoading, cloudinaryImageKey, ...attributes }) => {
    const _utilityService = new Utility();
    const _cloudinaryService = new CloudinaryService();
    const [bgRemovedStatus, setBgRemovedStatus] = useState(false);

    const validateAsyncProcess = result => {
        return result.isBackgroundRemoved;
    };

    const getCloudinaryWebhook = () => {
        return _cloudinaryService.fetchCloudinaryWebhook(cloudinaryImageKey);
    };

    useEffect(() => {
        if (isAsync && src && cloudinaryImageKey) {
            (async function fetchImage() {
                startLoading(true, true, Constant.CLOUDINARY_LOADER_MESSAGE);
                try {
                    const result = await _utilityService.poll({
                        fn: getCloudinaryWebhook,
                        validate: validateAsyncProcess,
                        interval: 3000,
                        maxAttempts: 25,
                    });
                    setBgRemovedStatus(result.isBackgroundRemoved);
                    setCloudinaryImageUrl(src);
                    startLoading(false, false, '');
                } catch (e) {
                    console.error({ e });
                    startLoading(false, false, '');
                }
            })();
        }
    }, [src, bgRemovedStatus]);

    return (
        <React.Fragment>
            <img src={src} {...attributes} />
        </React.Fragment>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        startLoading: (isLoading, isMessage, message) => dispatch(startLoading(isLoading, isMessage, message)),
    };
};

export default connect(null, mapDispatchToProps)(CloudinaryImage);
