import React from 'react';
import { startLoading } from '../..';
import { AuthService, showHideErrorToaster } from '../..';
import './guest-authentication-container.style.scss';
import { Constant, Utility } from '../../../shared';
import jwt_decode from 'jwt-decode';
import { DateTime } from 'luxon';
import { CommonService } from '../../services/common/common';
import { connect } from 'react-redux';
import { UserService } from '../../services';
import { GuestRoomDetailService } from '../../../content/guest-room-detail/services';
import { BroadcastChannel } from 'broadcast-channel';
export class GuestAuthenticationContainer extends React.Component {
    _authService = new AuthService();
    _commonService = new CommonService();
    _utilityService = new Utility();
    _userService = new UserService();
    _guestRoomDetailService = new GuestRoomDetailService();
    _utilityService = new Utility();
    _channel = null;

    magicLinkToken = null;
    magicLinkDecodedToken = null;
    roomId = null;
    isLoginFlowCompleted = false;

    constructor(props) {
        super(props);
        try {
            this._channel = new BroadcastChannel('auth');
        } catch (error) {
            this._channel = null;
        }
    }

    componentDidMount() {
        if (this._utilityService.isPlatformIos()) {
            this.props.history.replace({
                pathname: '/room/invite/',
            });
            return;
        }
        this.doAuthenticationFlow();
    }

    componentDidUpdate() {
        this.redirectToDashboardIfProfileCompleted();
    }

    doAuthenticationFlow = async () => {
        try {
            this.props.startLoading(true);
            this.getTokenFromQuery();

            if (!this.magicLinkToken) {
                if (this.roomId) {
                    await this.authenticateAsPerShareableRoom();
                    return;
                }
                await this.getCurrentUserAndSetEmail();
                return;
            }
            const currentUser = await this._authService.getCurrentAuthenticatedUser();
            if (currentUser && currentUser.attributes.email !== this.magicLinkDecodedToken.email) {
                await this._authService.logoutWithoutClearingStore();
                sessionStorage.setItem('logoutPrevent', 'yes');
                if (this._channel) {
                    this._channel.postMessage({ case: 'signOut' });
                }
            }
            await this.doPasswordLessLogin();
            await this.getCurrentUserAndSetEmail();
        } catch (error) {
            error = error.getExceptionType ? error.getExceptionType() : null;
            if (!error) {
                this.displayToasterMessage(Constant.ERROR_MESSAGES.AUTHENTICATION_FAILED, true);
                this.logoutAndRedirectToMagicLinkSender();
                return;
            }
            if (error === Constant.AUTH_EXCEPTION.TYPE) {
                await this.handleAuthenticationFlow();
            }
        } finally {
            this.props.startLoading(false);
        }
    };

    authenticateAsPerShareableRoom = async () => {
        const isShareable = await this.isRoomShareable();
        if (isShareable) {
            const currentUser = await this._authService.getCurrentAuthenticatedUser();
            if (!currentUser) {
                this.props.history.replace(`/anonymous-user/${this.roomId}`);
                return;
            }
            await this._commonService.joinRoomShareableRoom(this.roomId);
            await this.getUpdatedUser();
            this.isLoginFlowCompleted = true;
            return;
        }
        await this.getCurrentUserAndSetEmail();
    };

    isRoomShareable = async () => {
        try {
            const shareableRoom = await this._commonService.isRoomShareable(this.roomId);
            return shareableRoom.isShareAble;
        } catch (error) {
            return false;
        }
    };

    getTokenFromQuery = () => {
        this.queryParams = new URLSearchParams(this.props.location.search);
        this.magicLinkToken = this.queryParams.get('token') || null;
        try {
            this.magicLinkDecodedToken = jwt_decode(this.magicLinkToken);
        } catch (e) {
            this.magicLinkDecodedToken = null;
        }
        this.roomId = this.queryParams.get('roomId');
    };

    doPasswordLessLogin = async () => {
        try {
            if (this.magicLinkDecodedToken) {
                await this._authService.doPasswordLessLogin(this.magicLinkDecodedToken.email, this.magicLinkToken);
            }
        } catch (error) {
            throw error;
        }
    };

    handleAuthenticationFlow = async () => {
        const currentUser = await this._authService.getCurrentAuthenticatedUser();
        if (!currentUser || currentUser.attributes.email !== this.magicLinkDecodedToken.email) {
            const error = {
                message: Constant.ERROR_MESSAGES.LINK_EXPIRE,
            };
            this.logoutAndRedirectToMagicLinkSender(error);
            return;
        }
        await this.getCurrentUserAndSetEmail();
    };

    isConferenceExists = room => {
        if (room.ended === undefined) {
            room.conferenceExists = false;
            room.conferenceEnded = false;
            return room;
        }
        room.conferenceExists = true;
        room.conferenceEnded = false;
        if (room.ended) {
            room.conferenceEnded = true;
        }
        return room;
    };

    isRoomLive = async () => {
        try {
            let invitedRoom = await this.fetchRoomDetails();
            if (invitedRoom) {
                const currentTime = DateTime.local();
                const startTime = DateTime.fromISO(invitedRoom.startTime, { zone: 'utc' }).toLocal();
                const endTime = DateTime.fromISO(invitedRoom.endTime, { zone: 'utc' }).toLocal();
                invitedRoom = this.isConferenceExists(invitedRoom);
                return (
                    (invitedRoom.conferenceExists && !invitedRoom.conferenceEnded && startTime <= currentTime) ||
                    (!invitedRoom.conferenceExists && startTime <= currentTime && endTime >= currentTime)
                );
            }
            return false;
        } catch (error) {
            throw error;
        }
    };

    fetchRoomDetails = async (noFilter = true) => {
        try {
            const roomDetails = await this._guestRoomDetailService.getRoomDetail(this.roomId, noFilter);
            return roomDetails;
        } catch (error) {
            throw error;
        }
    };

    getCurrentUserAndSetEmail = async () => {
        const currentUser = await this._authService.getCurrentAuthenticatedUser();
        if (!currentUser) {
            this.logoutAndRedirectToMagicLinkSender();
            return;
        }
        await this.getUpdatedUser();
        this.isLoginFlowCompleted = true;
    };

    getUpdatedUser = async () => {
        await this._userService.getUser();
    };

    logoutAndRedirectToMagicLinkSender = async (error = null) => {
        let pathname = `/guest`;
        if (error) {
            pathname += `/${this._utilityService.encryptObjectToBase64(error)}`;
        }
        await this._authService.logout();
        this.props.history.replace({
            pathname,
        });
    };

    redirectToDashboardIfProfileCompleted = async () => {
        /**
         * This condition will execute when the authentication flow complete
         */
        if (!this.isLoginFlowCompleted) {
            return;
        }
        const currentAuthenticatedUser = await this._authService.getCurrentAuthenticatedUser();
        if (!currentAuthenticatedUser || this.props.user.email !== currentAuthenticatedUser.attributes.email) {
            return;
        }
        this.isLoginFlowCompleted = false;
        if (this.props.user.name && this.props.user.image) {
            if (this.roomId) {
                try {
                    const isShareable = await this.isRoomShareable();
                    if (isShareable) {
                        await this._commonService.joinRoomShareableRoom(this.roomId);
                    }
                    const isRoomLive = await this.isRoomLive();
                    if (isRoomLive) {
                        this.props.history.replace(`/room-join/${this.roomId}`);
                        return;
                    }
                    this.props.history.replace(`/guest-room-detail/${this.roomId}`);
                } catch (error) {
                    if (error.response && error.response.status && error.response.status === 403) {
                        this.displayToasterMessage(Constant.ERROR_MESSAGES.USER_IS_NOT_INVITED, true);
                        this.props.history.replace('/guest-dashboard');
                        return;
                    }
                    this.displayToasterMessage('Something went wrong', true);
                    this.props.history.replace('/guest-dashboard');
                }
            } else {
                // navigate to dashboard here this function will be change
                this.props.history.replace(`/guest-dashboard`);
            }
        } else {
            // redirect to edit profile page
            let path = `/guest-profile`;
            if (this.roomId) {
                path = `/guest-profile?roomId=${this.roomId}`;
            }
            this.props.history.replace(path);
        }
    };

    displayToasterMessage = (message, isError) => {
        this.props.showHideErrorToaster({
            show: true,
            message: message,
            bgColor: isError ? '#ff3547' : 'green',
        });
    };

    render() {
        return <div></div>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestAuthenticationContainer);
