import { SET_ACCESS_TOKEN } from './constants';

const initialState = {
    accessToken: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return Object.assign({}, state, { accessToken: action.accessToken });
        default:
            return state;
    }
};
