import React from 'react';
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import './toast-component.style.scss';
import warningImg from '../../../assets/images/error.png';
import successImg from '../../../assets/images/success.png';
import { showHideErrorToaster } from '../../../core';

const ToastComponent = props => {
    const { errorToaster } = { ...props };
    const hideToaster = () => {
        props.showHideErrorToaster({
            show: false,
            message: '',
        });
    };

    return (
        <>
            {errorToaster.show && (
                <Toast
                    style={{ zIndex: 100000, backgroundColor: errorToaster.bgColor }}
                    show={errorToaster.show}
                    onClick={() => {
                        hideToaster();
                    }}
                    onClose={() => {
                        hideToaster();
                    }}
                    className="toast-block"
                    delay={3000}
                    autohide
                >
                    <Toast.Body>
                        <div className="toast__body">
                            <img
                                src={errorToaster.bgColor == 'green' ? successImg : warningImg}
                                alt="img"
                                className="toast__img"
                            />
                            <p className="toast__text">{errorToaster.message}</p>
                        </div>
                    </Toast.Body>
                </Toast>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showHideErrorToaster: errorToaster => dispatch(showHideErrorToaster(errorToaster)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastComponent);
