import VoxeetSDK from '@voxeet/voxeet-web-sdk';

import { RoomJoinService } from '../../../content/room-join/services/room-join/room-join';
import { store } from '../../';
import { showHideErrorToaster } from '../../store';
import Emitter from '../../services/emitter/emitter';
import { Constant } from '../../../shared';

export class VoxeetService {
    static closeTimer;
    _roomJoinService = new RoomJoinService();

    initialize = async user => {
        try {
            const { accessToken } = await this._roomJoinService.getVoxeetToken();
            await VoxeetSDK.initializeToken(accessToken, () => {
                return this._roomJoinService
                    .getVoxeetToken()
                    .then(tokenObject => tokenObject.accessToken)
                    .catch(() => null);
            });

            if (VoxeetSDK.session.participant) {
                await VoxeetSDK.session.close();
            }
            await VoxeetSDK.session.open({
                name: user.name,
                externalId: user.id,
                avatarUrl: user.fullAvatarUrl,
            });
        } catch (error) {
            console.log(error);
        }
    };

    joinConference = async ({ accessToken, conferenceId }) => {
        const conference = await VoxeetSDK.conference.fetch(conferenceId);
        await VoxeetSDK.conference.join(conference, {
            conferenceAccessToken: accessToken,
            constraints: {
                audio: true,
                video: false,
            },
        });
    };

    listenConference = async ({ accessToken, conferenceId }) => {
        try {
            const conference = await VoxeetSDK.conference.fetch(conferenceId);
            await VoxeetSDK.conference.listen(conference, {
                conferenceAccessToken: accessToken,
                constraints: {
                    audio: false,
                    video: false,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    startVideo = async sessionParticipant => {
        try {
            await VoxeetSDK.conference.startVideo(sessionParticipant);
        } catch (error) {
            console.error({ error });
        }
    };

    stopVideo = async sessionParticipant => {
        try {
            await VoxeetSDK.conference.stopVideo(sessionParticipant);
        } catch (error) {
            console.error({ error });
        }
    };

    startScreenShare = async () => {
        try {
            await VoxeetSDK.conference.startScreenShare();
        } catch (error) {
            console.error({ error });
        }
    };

    stopScreenShare = async () => {
        try {
            await VoxeetSDK.conference.stopScreenShare();
        } catch (error) {
            console.error({ error });
        }
    };

    leaveConference = async () => {
        try {
            await VoxeetSDK.conference.leave();
        } catch (error) {
            console.log(error);
        }
    };

    kickParticipant = async id => {
        try {
            const participants = VoxeetSDK.conference && VoxeetSDK.conference.current.participants;
            if (!participants) {
                return;
            }
            participants.forEach(p => {
                if (p.info.externalId === id) {
                    VoxeetSDK.conference.kick(p);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    getCurrentParticipant = () => {
        return VoxeetSDK.session.participant;
    };

    muteParticipant = async sessionParticipant => {
        try {
            await VoxeetSDK.conference.mute(sessionParticipant, true);
        } catch (error) {
            console.error({ error });
        }
    };

    unMuteParticipant = async sessionParticipant => {
        try {
            await VoxeetSDK.conference.mute(sessionParticipant, false);
        } catch (error) {
            console.error({ error });
        }
    };

    startRecording = async () => {
        await VoxeetSDK.recording.start();
    };

    stopRecording = async () => {
        await VoxeetSDK.recording.stop();
    };

    closeVoxeetSession = async () => {
        try {
            if (VoxeetSDK.session && VoxeetSDK.session.participant) {
                await VoxeetSDK.session.close();
            }
        } catch (error) {
            console.log(error);
        }
    };

    clearInterval = () => {
        console.log({ eventimeOut: VoxeetService.closeTimer });
        if (VoxeetService.closeTimer) {
            clearInterval(VoxeetService.closeTimer);
        }
    };

    disconnectVoxeet = async () => {
        try {
            if (VoxeetSDK.session) {
                await this.leaveConference();
                await this.closeVoxeetSession();
                this.clearInterval();
            }
            return;
        } catch (e) {
            console.log(e);
        }
    };
}

VoxeetSDK.conference.on('joined', e => {
    let isIntervalRunning = false;
    VoxeetService.closeTimer = setInterval(() => {
        if (isIntervalRunning) {
            return;
        }
        isIntervalRunning = true;
        const participants = VoxeetSDK.conference && VoxeetSDK.conference.current.participants;
        if (!participants) {
            return;
        }
        const activeSpeakers = [];
        participants.forEach(p => {
            VoxeetSDK.conference.isSpeaking(p, status => {
                if (status) {
                    activeSpeakers.push({ subjectId: p.info.externalId, avatarUrl: p.info.avatarUrl });
                }
            });
        });
        Emitter.emit('active_speaker', {
            subjectIds: activeSpeakers.map(activeSpeaker => activeSpeaker.subjectId),
            avatarUrl: activeSpeakers.map(activeSpeaker => activeSpeaker.avatarUrl),
        });
        isIntervalRunning = false;
    }, 1000);
});

VoxeetSDK.conference.on('left', e => {
    clearInterval(VoxeetService.closeTimer);
});

VoxeetSDK.conference.on('ended', event => {
    console.log('confernece has been ended', event);
    clearInterval(VoxeetService.closeTimer);
    // Emitter.emit('conference_ended', {});
});

VoxeetSDK.conference.on('error', event => {
    if (event && event.data && event.data.error_reason) {
        switch (event.data.error_reason) {
            case Constant.VOXEET_ERROR_EVENTS.SCREEN_SHARE_IN_PROGRESS:
                showToaster('Something went wrong with screen sharing. Please try again.');
                break;

            default:
                break;
        }
    }
    console.log({ event });
    // Emitter.emit('voxeet_disconnected', event);
});

VoxeetSDK.conference.on('streamAdded', (participant, stream) => {
    if (!stream || !participant || !participant.info.externalId) {
        return;
    }
    if (stream.type === 'ScreenShare') {
        Emitter.emit('addScreenShare', { participant, stream });
        return;
    }

    if (stream.getVideoTracks().length) {
        Emitter.emit('addVideoNode', { participant, stream });
    }
});

VoxeetSDK.conference.on('streamUpdated', (participant, stream) => {
    console.log('stream updated');
    if (!stream || !participant || !participant.info.externalId) {
        return;
    }
    if (stream.type === 'ScreenShare') {
        stream.getVideoTracks().length && Emitter.emit('addScreenShare', { participant, stream });
        return;
    }

    if (stream.getVideoTracks().length) {
        Emitter.emit('addVideoNode', { participant, stream });
    } else {
        Emitter.emit('removeVideoNode', { participant });
    }
});

VoxeetSDK.conference.on('streamRemoved', (participant, stream) => {
    if (stream.type === 'ScreenShare') {
        Emitter.emit('removeScreenShare', { participant, stream });
        return;
    }
});

function showToaster(message) {
    store.dispatch(
        showHideErrorToaster({
            show: true,
            message: message,
            bgColor: '#ff3547',
        }),
    );
}
