import React from 'react';
import {
    IconButton,
    InputComponent,
    ThreeDotsDropDownComponent,
    ButtonComponent,
    ImageComponent,
    Constant,
} from '../../../shared';
import iconArrowRight from '../../../assets/images/ic-arrow-right.svg';
import iconSearch from '../../../assets/images/ic-search.svg';
import icon_placeHolder from '../../../assets/images/icon-placeholder.png';
import './innercircle.style.scss';
import { Modal } from 'react-bootstrap';
import { UserRelations } from '../..';
import { startLoading } from '../../../core';
import { GuestRoomDetailService } from '../../../content/guest-room-detail/services';
import { connect } from 'react-redux';
export class InnerCircle extends React.Component {
    _userRelationService = new UserRelations();
    _guestRoomDetailService = new GuestRoomDetailService();
    debounceTimeout = null;
    constructor(props) {
        super(props);
        this.state = {
            menuItems: [
                { name: 'View Profile', isBlue: true },
                { name: 'Remove from Inner Circle', isBlue: true },
            ],
            isRemoveModalOpen: false,
            list: [],
            payload: {
                takeCount: Constant.INNER_CIRCLE_PAGINATED_ITEM,
                skipCount: 0,
                searchTxt: '',
            },
            modalData: {
                modalheading: '',
                modalMsg: '',
            },
            pageTotal: 0,
            total: 0,
            relationId: '',
            relationName: '',
            scrollDebounce: true,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', this.scrollEndHandler);
        this.getRelationList(this.props.match.params.relation);
        this.setModalAndDropDownData();
    }

    setModalAndDropDownData = () => {
        let menuItems = [
            {
                name: 'View Profile',
                isBlue: true,
                itemAction: relation => {
                    this.props.history.push(`/guest-profile-view?profileId=${relation?.id}`);
                },
            },
        ];
        let modalData = {};
        let relation = this.props.match.params.relation;
        if (relation === 'followers') {
            menuItems = [
                ...menuItems,
                {
                    isBlue: true,
                    name: 'Blocking a Person',
                    itemAction: relation =>
                        this.setState({
                            ...this.state,
                            isRemoveModalOpen: true,
                            relationId: relation?.id,
                        }),
                },
            ];
            modalData = {
                modalheading: 'Blocking a person.',
                modalMsg: 'Are you sure you want to block this person?',
                modalYesFunction: () => this.blockPerson(),
            };
        } else if (relation === 'following') {
            menuItems = [
                ...menuItems,
                {
                    isBlue: true,
                    name: 'Unfollow',
                    itemAction: relation =>
                        this.setState({
                            ...this.state,
                            isRemoveModalOpen: true,
                            relationId: relation?.id,
                            modalData: {
                                ...this.state.modalData,
                                modalMsg: `Are you sure you want to unfollow ${relation?.name}?`,
                            },
                        }),
                },
            ];
            modalData = {
                modalheading: 'Unfollow',
                modalMsg: `Are you sure you want to unfollow ${this.state.relationName}`,
                modalYesFunction: () => this.unfollowUser(),
            };
        } else if (relation === 'innerCircle') {
            menuItems = [
                ...menuItems,
                {
                    isBlue: true,
                    name: 'Remove from Inner Circle',
                    itemAction: relation =>
                        this.setState({
                            ...this.state,
                            isRemoveModalOpen: true,
                            relationId: relation?.id,
                        }),
                },
            ];
            modalData = {
                modalheading: 'Remove from Inner Circle',
                modalMsg: 'Are you sure you want to remove this person from your inner circle?',
                modalYesFunction: () => this.removeFromInnerCircle(),
            };
        }
        this.setState({
            ...this.state,
            menuItems,
            modalData,
        });
    };

    unfollowUser = async () => {
        try {
            this.props.startLoading(true);
            const response = await this._guestRoomDetailService.followUnFollowUser(this.state.relationId, {
                follow: false,
            });
            if (response.isSuccess) {
                let list = this.state.list;
                list = list.filter(item => item.id !== this.state.relationId);
                this.setState({
                    ...this.state,
                    list,
                    payload: { ...this.state.payload, skipCount: list.length },
                });
                this.getRelationList(this.props.match.params.relation);
                this.closeRemoveModal();
                return;
            }
            this.closeRemoveModal();
            this.props.showHideErrorToaster({
                show: true,
                message: 'Something went wrong',
                bgColor: '#ff3547',
            });
        } catch (error) {
            this.closeRemoveModal();
            this.props.showHideErrorToaster({
                show: true,
                message: error.message,
                bgColor: '#ff3547',
            });
        } finally {
            this.props.startLoading(false);
        }
    };

    blockPerson = async () => {
        try {
            this.props.startLoading(true);
            const response = await this._userRelationService.blockPerson(this.state.relationId);
            if (response.isSuccess) {
                let list = this.state.list;
                list = list.filter(item => item.id !== this.state.relationId);
                this.setState({
                    ...this.state,
                    list,
                    payload: { ...this.state.payload, skipCount: list.length },
                });
                this.getRelationList(this.props.match.params.relation);
                this.closeRemoveModal();
                return;
            }
            this.closeRemoveModal();
            this.props.showHideErrorToaster({
                show: true,
                message: 'Something went wrong',
                bgColor: '#ff3547',
            });
        } catch (error) {
            this.closeRemoveModal();
            this.props.showHideErrorToaster({
                show: true,
                message: error.message,
                bgColor: '#ff3547',
            });
        } finally {
            this.props.startLoading(false);
        }
    };

    removeFromInnerCircle = async userId => {
        try {
            this.props.startLoading(true);
            const response = await this._guestRoomDetailService.innerCircle(this.state.relationId, {
                innerCircle: false,
            });
            if (response.isSuccess) {
                let list = this.state.list;
                list = list.filter(item => item.id !== this.state.relationId);
                this.setState({
                    ...this.state,
                    list,
                    payload: { ...this.state.payload, skipCount: list.length },
                });
                this.getRelationList(this.props.match.params.relation);
                this.closeRemoveModal();
                return;
            }
            this.closeRemoveModal();
            this.props.showHideErrorToaster({
                show: true,
                message: 'Something went wrong',
                bgColor: '#ff3547',
            });
        } catch (error) {
            this.closeRemoveModal();
            this.props.showHideErrorToaster({
                show: true,
                message: error.message,
                bgColor: '#ff3547',
            });
        } finally {
            this.props.startLoading(false);
        }
    };

    onSearchBoxChange = value => {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
        this.setState({
            ...this.state,
            payload: {
                ...this.state.payload,
                searchTxt: value,
            },
        });
        this.debounceTimeout = setTimeout(async () => {
            this.setState(
                {
                    ...this.state,
                    list: [],
                    payload: {
                        ...this.state.payload,
                        skipCount: 0,
                    },
                },
                () => this.getRelationList(this.props.match.params.relation),
            );
        }, 1000);
    };

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollEndHandler);
    }

    scrollEndHandler = event => {
        const wrappedElement = document.querySelector('body'),
            isBottom = parseInt(wrappedElement.getBoundingClientRect().bottom) <= parseInt(window.innerHeight);
        if (isBottom) {
            const currentRecordCount = this.state.list.length;
            if (this.state.total > currentRecordCount) {
                this.setState({
                    ...this.state,
                    payload: { ...this.state.payload, skipCount: this.state.list.length },
                });
                this.getRelationList(this.props.match.params.relation);
            }
        }
    };

    getRelationList = async relation => {
        try {
            this.props.startLoading(true);
            const data = await this._userRelationService.getUserRelationList({ ...this.state.payload, relation });
            const list = this.state.list.slice();
            this.setState({
                ...this.state,
                list: list.concat(data.results),
                pageTotal: data.page_total,
                total: data.total,
            });
            this.props.startLoading(false);
        } catch (e) {
            this.props.startLoading(false);
            throw e;
        }
    };

    navigateToHome = () => {
        this.props.history.goBack();
    };

    openRemoveModal = () => {
        this.setState({ ...this.state, isRemoveModalOpen: true });
    };

    closeRemoveModal = () => {
        this.setState({ ...this.state, isRemoveModalOpen: false });
    };
    render() {
        return (
            <>
                <div className="innercircle">
                    <div className="innercircle__top-wrap">
                        <IconButton
                            data-test="past-room-home-btn"
                            image={iconArrowRight}
                            text="Back"
                            cssClass="innercircle__icon-btn"
                            iconClass="rotate-icon"
                            click={() => {
                                this.navigateToHome();
                            }}
                        />
                        <div className="innercircle__search-wrap">
                            <InputComponent
                                value={this.state.payload.searchTxt}
                                errorMessage={''}
                                classes={'br__form-control innercircle__search-input'}
                                hasIcon="true"
                                icon={iconSearch}
                                iconClass={'innercircle__search-icon'}
                                iconWrapperClass={'innercircle__search-box'}
                                onChange={this.onSearchBoxChange}
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    <h3 className="innercircle__heading">{this.props.match?.params.relation}</h3>
                    {this.state.list.length > 0 ? (
                        <ul className="innercircle__list">
                            {this.state.list.map(relation => (
                                <li className="innercircle__item" key={relation?.id}>
                                    <div className="innercircle__profile-left">
                                        <ImageComponent
                                            src={relation?.image}
                                            placeholder={icon_placeHolder}
                                            alt=""
                                            className="innercircle__profile-img"
                                        />
                                        <h5 className="innercircle__profile-name">{relation?.name}</h5>
                                    </div>
                                    <p className="innercircle__profile-blurb">{relation?.blurb}</p>
                                    <div className="innercircle__profile-right">
                                        <ThreeDotsDropDownComponent
                                            dotClass="guest-room-detail__rotate-kabob"
                                            hoverClass=""
                                            itemClass="innercircle__dropdown-menu-item"
                                            menuItems={this.state.menuItems}
                                            onSelect={item => {
                                                let index = this.state.menuItems.findIndex(x => x.name === item);
                                                index > -1 && this.state.menuItems[index].itemAction(relation);
                                            }}
                                            relation={relation}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="innercircle__not-found">Data Not Found!</p>
                    )}
                </div>

                {/* remove modal */}
                <>
                    <Modal className="innercircle__modal" centered show={this.state.isRemoveModalOpen}>
                        <Modal.Body className="p-0">
                            <h4 className="innercircle__modal-heading">{this.state.modalData.modalheading}</h4>
                            <p className="innercircle__modal-text">{this.state.modalData.modalMsg}</p>
                        </Modal.Body>
                        <div className="innercircle__modal-footer">
                            <ButtonComponent
                                className={'br__btn-theme-filled innercircle__btn-filled'}
                                name="Yes"
                                onclick={this.state.modalData.modalYesFunction}
                            />
                            <ButtonComponent
                                className={'innercircle__btn-outline'}
                                name="No"
                                onclick={this.closeRemoveModal}
                            />
                        </div>
                    </Modal>
                </>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(null, mapDispatchToProps)(InnerCircle);
