import {
    OrganizationStylingContainer,
    OrganizationDetailContainer,
    CreateBoardContainer,
    BoardDetailsContainer,
    CreateRoomContainer,
    RoomDetailContainer,
    UpdateProfileContainer,
    MembersDetailContainer,
    RoomJoinContainer,
    RoomInviteContainer,
    GuestDashboardContainer,
    GuestRoomDetailContainer,
    GuestPastRoomContainer,
    GuestAdvanceSearchContainer,
    GuestCreatePrivateRoom,
} from '../../../content';

import { Utility } from '../../../shared';
import {
    LogoutContainer,
    RegisterContainer,
    InviteContainer,
    ErrorContainer,
    UnauthorizedErrorContainer,
    NotFoundContainer,
    ForgotPasswordContainer,
    ResetPasswordContainer,
    RoomWaitContainer,
    GuestProfileContainer,
    ThankyouRoomContainer,
    GuestLoginContainer,
    GuestProfileEditContainer,
    MagicLinkSenderContainer,
    MagicLinkSendStatus,
    GuestAuthenticationContainer,
    CreateAnonymousUserContainer,
    GuestProfileViewContainer,
    RegistrationRedirectionContainer,
    GuestConfirmEmail,
    VerifyEmailStatus,
    BCast,
    InnerCircle,
} from '../../containers';
import PrivateRoomContainer from '../../containers/private-room-message/private-room-message';
export class Routes {
    utilityService = new Utility();
    /**
     * @name privateRoutes
     * @param {void}
     * @return {routes array[]}
     */
    privateRoutes = () => {
        /**
         * define private route here which required authentication
         */
        const routes = [
            {
                exact: true,
                path: '/logout',
                component: LogoutContainer,
            },

            {
                exact: true,
                path: '/profile/:userId/:boardId',
                component: UpdateProfileContainer,
            },

            {
                exact: true,
                path: '/organization/:id',
                component: OrganizationDetailContainer,
            },
            {
                exact: true,
                path: '/private-room-experience',
                component: PrivateRoomContainer,
                isGuest: true,
                isGuestHeader: true,
            },
            {
                // guest web route
                exact: true,
                path: '/room-join/:id',
                component: RoomJoinContainer,
                isGuest: true,
                isGuestHeader: true,
            },
            {
                exact: true,
                path: '/lounge-join/:id',
                component: RoomJoinContainer,
                isGuest: true,
                isGuestHeader: true,
            },
            {
                // guest web route
                path: '/guest-profile',
                component: GuestProfileEditContainer,
                isGuest: true,
                isGuestFooter: true,
            },
            {
                exact: true,
                path: '/organization/:id/boards/:boardId',
                component: BoardDetailsContainer,
            },
            {
                exact: true,
                path: '/organization/:id/boards/:boardId/rooms/:roomId',
                component: RoomDetailContainer,
            },

            {
                exact: true,
                path: '/organization/create-board/:id/:boardId?',
                component: CreateBoardContainer,
            },
            {
                exact: true,
                path: '/create-room/:boardId/:roomId?',
                component: CreateRoomContainer,
            },
            {
                exact: true,
                path: '/organization/:orgId/board/:id/members',
                component: MembersDetailContainer,
            },
            {
                exact: true,
                path: '/organization/:id/boards/:boardId/rooms/:roomId/:roomName/room-invite',
                component: RoomInviteContainer,
            },
            {
                exact: true,
                path: '/guest-room-detail/:roomId',
                isGuest: true,
                isGuestHeader: true,
                component: GuestRoomDetailContainer,
            },
            {
                exact: true,
                path: '/',
                component: OrganizationStylingContainer,
            },
            {
                // guest dashboard route
                path: '/guest-dashboard',
                component: GuestDashboardContainer,
                isGuestHeader: true,
                isGuest: true,
                isGuestSidenav: true,
                isGuestFooter: true,
            },
            //new route added for guest view profile
            {
                path: '/guest-profile-view',
                component: GuestProfileViewContainer,
                isGuestHeader: true,
                isGuest: true,
                isGuestFooter: true,
                isGuestSidenav: true,
            },
            {
                path: '/guest-past-rooms',
                component: GuestPastRoomContainer,
                isGuestHeader: true,
                isGuest: true,
                isGuestFooter: true,
                isGuestSidenav: true,
            },
            {
                path: '/guest-mutual-rooms/:userId',
                component: GuestPastRoomContainer,
                isGuestHeader: true,
                isGuest: true,
                isGuestFooter: true,
                isGuestSidenav: true,
            },
            {
                path: '/advance-search',
                component: GuestAdvanceSearchContainer,
                isGuestHeader: true,
                isGuest: true,
                isGuestFooter: true,
            },
            {
                path: '/guest-create-private-room',
                component: GuestCreatePrivateRoom,
                isGuestHeader: true,
                isGuest: true,
                isGuestFooter: true,
            },
            {
                path: '/bcast',
                component: BCast,
                isGuest: true,
                isGuestHeader: true,
                isGuestFooter: true,
                isGuestSidenav: true,
            },
            {
                path: '/relation/:relation',
                component: InnerCircle,
                isGuest: true,
                isGuestHeader: true,
                isGuestFooter: true,
                isGuestSidenav: true,
            },
        ];

        return routes;
    };

    /**
     * @name publicRoutes
     * @param {void}
     * @return {routes array[]}
     */
    publicRoutes = () => {
        /**
         * define public route here which doesn't require any authentication
         */
        const routes = [
            {
                exact: true,
                path: '/user-register/:module/:id',
                component: RegisterContainer,
            },
            {
                exact: true,
                path: '/room/invite/:token?',
                component: InviteContainer,
            },
            {
                exact: true,
                path: '/error/expired',
                component: ErrorContainer,
            },
            {
                exact: true,
                path: '/unauthorized-error/:unauthorized?',
                component: UnauthorizedErrorContainer,
            },
            {
                exact: true,
                path: '/thankyou-for-joining-room/:roomId?/:queryStatus?',
                component: ThankyouRoomContainer,
            },
            {
                exact: true,
                path: '/not-found',
                component: NotFoundContainer,
            },
            {
                exact: true,
                path: '/forgot-password',
                component: ForgotPasswordContainer,
            },
            {
                exact: true,
                path: '/reset-password',
                component: ResetPasswordContainer,
            },
            {
                // guest web route
                path: '/login',
                component: GuestAuthenticationContainer,
                isGuest: true,
            },
            {
                // guest web route
                path: '/guest-user-profile', // /login guest changed to /guest-user-profile
                component: GuestProfileContainer,
                isGuest: true,
            },
            {
                // guest web route
                path: '/room-wait/:roomId',
                component: RoomWaitContainer,
                isGuest: true,
            },
            {
                // guest web route
                path: '/guest-login',
                component: GuestLoginContainer,
                isGuest: true,
            },
            {
                // guest web route
                path: '/guest/:error?',
                component: MagicLinkSenderContainer,
                isGuest: true,
            },
            {
                // guest web route
                path: '/magic-link-status/:email?',
                component: MagicLinkSendStatus,
                isGuest: true,
            },
            {
                // guest web route
                path: '/anonymous-user/:roomId',
                component: CreateAnonymousUserContainer,
                isGuest: true,
            },
            {
                // guest web route
                path: '/guest-confirm-email',
                component: GuestConfirmEmail,
                isGuest: true,
            },
            {
                // guest web route
                path: '/verify-email-status/:email?',
                component: VerifyEmailStatus,
                isGuest: true,
            },
            {
                // registration for new user
                path: '/register',
                component: RegistrationRedirectionContainer,
                isGuest: true,
                isGuestHeader: true,
                isGuestFooter: true,
            },
        ];

        return routes;
    };

    /**
     * @name getRoutes
     * @desc Returns routes.
     * @return {JSX[]}
     */
    getRoutes = () => {
        return this.privateRoutes();
    };

    /**
     * @name getPublicRoutes
     * @desc Returns routes.
     * @return {JSX[]}
     */
    getPublicRoutes = () => {
        return this.publicRoutes();
    };
}
