import { Http } from '../../../../core';

export class BoardDetailsDashboardService {
    /**
     * @name getBoardDetails
     * @param {object} param contain board Id required for specific board
     * @desc Will fetch specific board details
     * @return {Promise}
     */
    getBoardDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/board/details/${param.boardId}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };

    /**
     * @name getRooms
     * @param {object} param contain boardId required for specific board,pageSize and pageIndex required for pagination.
     * @desc Will get rooms of a specific board.
     * @return {Promise}
     */
    getRooms = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/room?boardId=${param.boardId}&pageSize=${param.pageSize}&pageIndex=${param.pageIndex}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };

    /**
     * @name deleteRoom
     * @param {object} param contain boardId and roomId required for specific board and room
     * @desc Will delete a specific room of a specific board
     * @return {Promise}
     */
    deleteRoom = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.delete(`/room/${param.boardId}/${param.roomId}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
}
