import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import backImg from '../../../assets/images/back-img.png';
import './image-crop-component.style.scss';
import { ReactCrop } from '../';

import { ButtonComponent, ErrorMessageComponent } from '..';
import { Constant } from '../../services';

const ImageCropComponent = props => {
    const { defaultCropState, ratio, selectedImage, cancelCroping, croperHeading } = props;
    const [crop, setCrop] = useState({
        ...defaultCropState,
        aspect: defaultCropState.aspect.numerator / defaultCropState.aspect.denominator,
    });
    const [cropError, setCropError] = useState(false);
    const [isAddBtnDisabled, setAddBtnDisabled] = useState(false);
    let imgRef = useRef(null);
    let canvasElement;

    const showHideLoader = shouldDisplay => {
        if (!props.startLoading) {
            return;
        }
        props.startLoading(shouldDisplay);
    };

    const makeClientCrop = async crop => {
        try {
            if (imgRef && crop.width && crop.height) {
                showHideLoader(true);
                const file = await getCroppedImg(imgRef.current, crop, `${Date.now()}.jpeg`);
                showHideLoader(false);
                props.setSelectedLogo(file);
                props.closeCropModal(true);
            } else {
                setCropError(true);
            }
        } catch (error) {
            showHideLoader(false);
            props.showHideErrorToaster({
                show: true,
                message: error.message || 'Something went wrong in cropping',
                bgColor: '#ff3547',
            });
        }
    };

    useEffect(() => {
        selectedImage && showHideLoader(true);
        setCrop({
            ...defaultCropState,
            aspect: defaultCropState.aspect.numerator / defaultCropState.aspect.denominator,
        });
    }, [selectedImage]);

    const getCroppedImg = (img, crop, fileName) => {
        const image = img;
        const canvas = canvasElement;
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        const byteSize = canvas.width * canvas.height;
        if (byteSize > Constant.FILE_UPLOAD_LIMIT) {
            throw { message: 'Image size too large, resize upto 20 MB' };
        }
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                try {
                    if (!blob) {
                        return reject({
                            message:
                                'There has been an error cropping your image, Please select appropriate size for uploading purpose',
                        });
                    }
                    blob.name = fileName;
                    const file = new File([blob], blob.name, { type: blob.type });
                    if (file.size > Constant.FILE_UPLOAD_LIMIT) {
                        return reject({ message: 'Image size too large, resize upto 20 MB' });
                    }
                    resolve(file);
                } catch (error) {
                    reject({ message: 'Image is not processed' });
                }
            }, 'image/jpeg');
        });
    };

    const onImageLoad = img => {
        imgRef.current = img;
        showHideLoader(false);
    };

    const onImageError = () => {
        setAddBtnDisabled(true);
        showHideLoader(false);
        props.showHideErrorToaster({
            show: true,
            message: 'Error while loading image',
            bgColor: '#ff3547',
        });
    };

    /**
     * These scripts will inject custom div into ReactCrop croping tool
     */
    const cropContainer = document.getElementsByClassName('ReactCrop__crop-selection')[0];
    if (cropContainer) {
        const lastElement = cropContainer.lastElementChild;
        if (lastElement.innerText !== ratio) {
            const div = document.createElement('div');
            div.className = 'dimension_text';
            div.innerText = ratio;
            cropContainer.appendChild(div);
        }
    }

    return (
        <>
            <Modal
                className="theme-modal img-crop-modal"
                data-test="delete_success_modal"
                centered
                backdrop="static"
                show={props.isCropModalOpen}
                onHide={props.closeCropModal}
            >
                <img
                    data-test="back_icon"
                    src={backImg}
                    className="img-fluid back__img cursor-pointer"
                    alt="back"
                    onClick={() => {
                        props.closeCropModal();
                    }}
                />
                <Modal.Body className="theme-modal__body">
                    <h3 className="img-cro-heading">Edit {croperHeading}</h3>
                    <ReactCrop
                        className="img-crop-wrapper"
                        crossorigin="anonymous"
                        src={selectedImage}
                        crop={crop}
                        onImageLoaded={onImageLoad}
                        onImageError={onImageError}
                        onChange={newCrop => {
                            setCrop(newCrop);
                        }}
                        onComplete={c => {
                            setCropError(false);
                            setCrop(c);
                        }}
                    />
                    {cropError ? (
                        <ErrorMessageComponent id={`image-crop-message`} message={'Please select the area to crop'} />
                    ) : (
                        ''
                    )}
                    <canvas ref={c => (canvasElement = c)} className="img-crop-canvas" />
                </Modal.Body>
                <Modal.Footer className="comodal__footer border-0">
                    <ButtonComponent
                        className={'btn__link'}
                        name="Cancel"
                        onclick={() => {
                            cancelCroping();
                        }}
                    />
                    <ButtonComponent
                        disabledSubmitButton={isAddBtnDisabled}
                        className={'br__btn-theme-filled br__btn-theme-filled--width-93'}
                        name="Add"
                        onclick={() => {
                            makeClientCrop(crop);
                        }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImageCropComponent;
