import { Http } from '../';

export class UserRegister {
    /**
     * @name userRegisterForOrganization
     * @paylod {any} reqired to update the user
     * @assnIn {String} association id for user
     * @desc this function will update the user info for organization like fullname, password, blurb, image
     * @return {Promise}
     */
    userRegisterForOrganization = (payload, assnId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/organization/profile/${assnId}`, payload)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name getOrgUserDetail
     * @assnIn {String} association id for user
     * @desc this function fetch the associated user for organization
     * @return {Promise}
     */
    getOrgUserDetail = assnId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/organization/profile/${assnId}`)
                .then(
                    _successLog => {
                        return resolve(_successLog.data);
                    },
                    _errorLog => {
                        return reject(_errorLog);
                    },
                )
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name getSignedUrlForOrganization
     * @assnIn {String} association id for user
     * @desc this function will fetch the url for upload image
     * @return {Promise}
     */
    getSignedUrlForOrganization = (assnId, content_type) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/organization/signedUrl/${assnId}?content_type=${content_type}`)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name userRegisterForBoard
     * @paylod {any} reqired to update the user
     * @assnIn {String} association id for user
     * @desc this function will update the user info for organization like fullname, password, blurb, image
     * @return {Promise}
     */
    userRegisterForBoard = (payload, assnId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/board/profile/${assnId}`, payload)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name getBoardUserDetail
     * @assnIn {String} association id for user
     * @desc this function fetch the associated user for board
     * @return {Promise}
     */
    getBoardUserDetail = assnId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/board/profile/${assnId}`)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name getSignedUrlForOrganization
     * @assnIn {String} association id for user
     * @desc this function will fetch the url for upload image
     * @return {Promise}
     */
    getSignedUrlForBoard = (assnId, content_type) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/board/signedUrl/${assnId}?content_type=${content_type}`)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name sendRegistrationEmail
     * @paylod {any} reqired to send registration email
     * @return {Promise}
     */
    sendRegistrationEmail = payload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post(`/user/sendRegistrationEmail`, payload)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name userEmailVerify
     * @paylod {any} reqired to verify the user
     * @return {Promise}
     */
    userEmailVerify = payload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post(`/user/email/verify`, payload)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name getSignedUrlForUser
     * @assnIn {String} association id for user
     * @desc this function will fetch the url for upload image
     * @return {Promise}
     */
    getSignedUrlForUser = content_type => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/user/signedUrl?content_type=${content_type}`)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };

    /**
     * @name registerUser
     * @paylod {any} reqired for user registration
     * @return {Promise}
     */
    registerUser = payload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post(`/user/register`, payload)
                .then(_successLog => {
                    return resolve(_successLog.data);
                })
                .catch(_error => {
                    reject(_error);
                });
        });
    };
}
