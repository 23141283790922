import React from 'react';
import './room-invite-container.style.scss';
import BackComponent from '../../../../shared/components/back-component/back-component';
import { AuthService, AWSStorageService, CloudinaryService, startLoading, UserService } from '../../../../core';
import { RoomInviteDashboardService } from '../../services';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import {
    GuestModal,
    RoomInviteDataTableComponent,
    RoomInviteHeader,
    RoomInviteSlider,
    RoomInviteModal,
} from '../../components';
import placeholder from '../../../../assets/images/icon-placeholder.png';
import iconAdd from '../../../../assets/images/ic-add.svg';
import { Constant, ImageComponent, Utility } from '../../../../shared';
import org from '../../../../assets/images/org-1.png';
import iconTable from '../../../../assets/images/ic-table.svg';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
export class RoomInviteContainer extends React.Component {
    _awsStorageService = new AWSStorageService();
    _roomInviteService = new RoomInviteDashboardService();
    _userService = new UserService();
    _authService = new AuthService();
    _utilityService = new Utility();
    _cloudinaryService = new CloudinaryService();
    _schema = Yup.object().shape({
        email: Yup.string().email(),
    });
    constructor(props) {
        super(props);
        this.searchText = React.createRef();
        this.state = {
            roomName: this.props.match.params.roomName,
            members: [],
            showRoomInviteModal: false,
            showAddGuestModal: false,
            addGuestButton: false,
            emptyMembers: true,
            boardInfo: {
                allBoardsData: {
                    bgImg: '',
                    orgImg: '',
                    boardName: 'All',
                    count: '2,345',
                },
                guestData: {
                    bgImg: '',
                    orgImg: '',
                    boardName: 'Guest',
                    count: '2,345',
                },
                boards: [],
                addAllButtonName: 'Add All',
            },
            roomInvite: {
                roomId: this.props.match.params.roomId,
                boardId: [],
                inclusions: [],
                guests: [],
                exclusionIds: [],
            },
            exclusions: [],
            paginationQueryParams: {
                pageIndex: 0,
                totalRecords: 0,
            },
            formDetails: {
                name: '',
                email: '',
                blurb: '---',
                isSelected: true,
                selectedRole: Constant.ROOM_INVITE_DEFAULT_ROLE,
            },
            guestMembers: [],
            validationErrorMessage: {
                nameValidation: '',
                emailValidation: '',
            },
            roleOptions: ['speaker', 'moderator', 'attendee'],
            boardIds: '',
            boardType: '',
            selectedAll: false,
            roomInvitationMessage: 'Invitations Sent!',
            searchQuery: '',
            selectedMembers: 0,
            showAll: false,
            guestCount: 0,
        };
    }

    getBoards = async () => {
        const { results: boards, totalMembers } = await this._roomInviteService.getBoards();
        for (const element of boards) {
            element.orgImg = org;
            element.bgImg = element.logo;
            element.isClicked = false;
        }
        const boardInfo = {
            allBoardsData: {
                bgImg: '',
                orgImg: '',
                name: 'All',
                isClicked: false,
                totalMembers: totalMembers,
            },
            guestData: {
                bgImg: '',
                orgImg: '',
                name: 'Add Guests',
                isClicked: false,
                totalMembers: 0,
            },
            addAllButtonName: 'Add All',
            boards: boards,
        };
        this.setState({ ...this.state, boardInfo });
    };

    setUserInfo = async () => {
        const userInfo = await this._userService.getUser();
        this.setState({ userInfo });
    };

    setInclusionUpdate = member => {
        this.setState(
            {
                ...this.state,
                roomInvite: {
                    ...this.state.roomInvite,
                    inclusions: [...this.state.roomInvite.inclusions, { userId: member.id, role: member.selectedRole }],
                },
            },
            () => this.getSelectedMembersCount(),
        );
    };

    setInclusionOverWrite = inclusionArray => {
        this.setState(
            {
                ...this.state,
                roomInvite: {
                    ...this.state.roomInvite,
                    inclusions: [...inclusionArray],
                },
            },
            () => this.getSelectedMembersCount(),
        );
    };

    setExclusionUpdate = async member => {
        this.setState(
            {
                ...this.state,
                exclusions: [...this.state.exclusions, { id: member.id, boardIds: member.boardIds }],
                roomInvite: {
                    ...this.state.roomInvite,
                    exclusionIds: [...this.state.roomInvite.exclusionIds, member.id],
                },
            },
            () => this.getSelectedMembersCount(),
        );
    };

    setExclusionOverWrite = async (exclusionArrayIds, exclusions) => {
        this.setState(
            {
                ...this.state,
                exclusions: [...exclusions],
                roomInvite: {
                    ...this.state.roomInvite,
                    exclusionIds: [...exclusionArrayIds],
                },
            },
            () => this.getSelectedMembersCount(),
        );
    };

    setSelectedBoardUpdate = async id => {
        await this.setState({
            ...this.state,
            roomInvite: {
                ...this.state.roomInvite,
                boardId: [...this.state.roomInvite.boardId, id],
            },
        });
    };

    setSelectedBoardOverwrite = async boardIdArray => {
        await this.setState({
            ...this.state,
            selectedAll: true,
            roomInvite: {
                ...this.state.roomInvite,
                boardId: [...boardIdArray],
            },
        });
    };

    setGuestsOverWrite = guestsArray => {
        this.setState({
            ...this.state,
            roomInvite: {
                ...this.state.roomInvite,
                guests: [...guestsArray],
            },
        });
    };

    isExcludedOrDefault = () => {
        return { selectedRole: Constant.ROOM_INVITE_DEFAULT_ROLE, isSelected: false };
    };

    isIncluded = includedMember => {
        return { selectedRole: includedMember.role, isSelected: true };
    };

    isInSelectedBoards = () => {
        return { selectedRole: Constant.ROOM_INVITE_DEFAULT_ROLE, isSelected: true };
    };

    getRoleAndSelection = element => {
        const inclusions = [...this.state.roomInvite.inclusions];
        const exclusions = [...this.state.roomInvite.exclusionIds];
        const selectedBoardId = [...this.state.roomInvite.boardId];
        const { selectedRole, isSelected } = inclusions.some(user => {
            return user.userId === element.id;
        })
            ? this.isIncluded(inclusions[inclusions.findIndex(user => user.userId === element.id)])
            : !exclusions.includes(element.id) &&
              (element.boardIds.some(boardId => {
                  return !element.isRoot && selectedBoardId.includes(boardId);
              }) ||
                  (!element.isRoot && this.state.selectedAll))
            ? this.isInSelectedBoards()
            : this.isExcludedOrDefault();
        return { selectedRole, isSelected };
    };

    getBoardMembers = async params => {
        const members = await this._roomInviteService.getBoardMembers(params);
        await this.getBoardImages(members.results);
        const pageCount = this.state.paginationQueryParams.pageIndex + 1;
        if (this.state.paginationQueryParams.pageIndex !== 0) {
            members.results = [...this.state.members, ...members.results];
        }
        this.setState({
            ...this.state,
            members: members.results,
            paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: pageCount * members.total },
        });
    };

    getBoardImages = async members => {
        for (const element of members) {
            element.name = element.name
                ? element.name.slice(0, Constant.MEMBER_NAME_LIMIT)
                : Constant.MEMBER_FALLBACK.name;
            element.isImageFetched = false;
            element.blurb = element.blurb || Constant.MEMBER_FALLBACK.blurb;
            const fallBackImage = element.image && (await this._cloudinaryService.getCloudinaryURL(element.image));
            element.memberColumn = (
                <div className="room-invite__member-name">
                    <img
                        src={element.fullAvatarUrl || fallBackImage || placeholder}
                        alt=""
                        placeholder={placeholder}
                        width="40"
                        height="40"
                        className="img-fluid room-invite__member-name-img"
                    />
                    <p className="word-break-all">{element.name}</p>
                </div>
            );
            const { selectedRole, isSelected } = this.getRoleAndSelection(element);
            element.selectedRole = selectedRole;
            element.isSelected = isSelected;
        }
    };

    onKeyDown = async event => {
        const keyPressed = event.key;
        if (keyPressed === Constant.KEYS.ENTER) {
            this.submitGuestForm();
        }
    };

    setName = name => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, name },
            validationErrorMessage: { ...this.state.validationErrorMessage, nameValidation: '' },
        });
    };

    setEmail = email => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, email },
            validationErrorMessage: { ...this.state.validationErrorMessage, emailValidation: '' },
        });
    };

    getSelectedMembersCount = async (board = null) => {
        let { inclusions, exclusionIds, boardId } = this.state.roomInvite;
        inclusions = this.commaSeperateIds(inclusions);
        exclusionIds = this.commaSeperateIds(exclusionIds);
        boardId = this.commaSeperateIds(boardId);
        const { totalMembers } = await this._roomInviteService.getSelectedMembersCount({
            inclusionIds: inclusions,
            boardIds: boardId,
            exclusionIds,
            showAll: board === 'all' ? true : this.state.showAll,
        });
        this.setState({
            ...this.state,
            selectedMembers: this.state.guestCount > 0 ? this.state.guestCount + totalMembers : totalMembers,
        });
    };

    commaSeperateIds = members => {
        let ids = null;
        members.forEach((elem, i) => {
            if (i === 0) {
                ids = elem.userId ? elem.userId : elem;
            } else {
                ids += `,${elem.userId ? elem.userId : elem}`;
            }
        });
        return ids;
    };

    selectRole = (e, id) => {
        const members = this.state.members.map(member => {
            if (member.id === id) {
                member.selectedRole = e.target.value;

                if (member.isSelected) {
                    const inclusions = this.state.roomInvite.inclusions.filter(user => {
                        return user.userId !== member.id;
                    });
                    inclusions.push({ userId: member.id, role: member.selectedRole });
                    this.setInclusionOverWrite(inclusions);
                }
            }
            return member;
        });
        this.setState({ members });
    };

    selectGuestRole = (e, id) => {
        const guestMembers = this.state.guestMembers.map(member => {
            if (member.id === id) {
                member.selectedRole = e.target.value;
            }
            return member;
        });
        this.setState({ guestMembers });
    };
    searchBoardMembers = async user => {
        this.setState({
            ...this.state,
            paginationQueryParams: { ...this.state.paginationQueryParams, pageIndex: 0 },
            searchQuery: user,
            emptyMembers: false,
        });
        this.props.startLoading(true);
        const members = await this._roomInviteService.getBoardMembers({
            boardIds: this.state.boardIds,
            pageIndex: 0,
            pageSize: Constant.PAGINATED_BOARD_MEMBERS,
            searchQuery: user,
        });
        this.props.startLoading(false);
        await this.getBoardImages(members.results);
        const pageCount = this.state.paginationQueryParams.pageIndex + 1;
        this.setState({
            ...this.state,
            members: members.results,
            paginationQueryParams: {
                ...this.state.paginationQueryParams,
                pageIndex: 0,
                totalRecords: pageCount * members.total,
            },
        });
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        this.props.startLoading(true);
        await this.setUserInfo();
        await this.getBoards();
        this.props.startLoading(false);
    };

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollEndHandler);
    }

    /**
     * @name scrollEndHandler
     * @desc Handler to listen on document when scroll ends.
     * @return {void}
     */
    scrollEndHandler = async event => {
        const wrappedElement = document.querySelector('body'),
            isBottom = parseInt(wrappedElement.getBoundingClientRect().bottom) <= parseInt(window.innerHeight);
        if (isBottom) {
            const currentRecordCount =
                Constant.PAGINATED_BOARD_MEMBERS * (this.state.paginationQueryParams.pageIndex + 1);
            const { paginationQueryParams } = this.state;
            if (paginationQueryParams.totalRecords >= currentRecordCount) {
                this.props.startLoading(true);
                ++paginationQueryParams.pageIndex;
                await this.getBoardMembers({
                    boardIds: this.state.boardIds,
                    pageIndex: this.state.paginationQueryParams.pageIndex,
                    pageSize: Constant.PAGINATED_BOARD_MEMBERS,
                    searchQuery: this.state.searchQuery,
                });
                this.props.startLoading(false);
            }
        }
    };

    onGuestCardClick = () => {
        document.removeEventListener('scroll', this.scrollEndHandler);
        this.handleCardHighlight('guest', null);
        this.setState({ addGuestButton: true, searchQuery: '' });
        this.state.guestMembers.length === 0
            ? this.setState({ emptyMembers: true })
            : this.setState({ emptyMembers: false });
    };

    handleCardHighlight = (card, id) => {
        const state = { ...this.state };
        state.boardInfo.boards.forEach(element => {
            if (element.id === id) {
                element.isClicked = true;
            } else {
                element.isClicked = false;
            }
        });
        state.boardInfo.allBoardsData.isClicked = false;
        state.boardInfo.guestData.isClicked = false;
        switch (card) {
            case 'all':
                state.boardInfo.allBoardsData.isClicked = true;
                state.boardInfo.guestData.isClicked = false;
                break;
            case 'guest':
                state.boardInfo.guestData.isClicked = true;
                state.boardInfo.allBoardsData.isClicked = false;
                break;
            default:
                break;
        }
        this.setState({ state });
    };

    onCardClick = async (board, boardIds = null) => {
        document.addEventListener('scroll', this.scrollEndHandler);
        await this.setState({
            ...this.state,
            paginationQueryParams: { ...this.state.paginationQueryParams, pageIndex: 0, totalRecords: 0 },
        });

        try {
            this.props.startLoading(true);
            if (board !== 'all') {
                this.handleCardHighlight('board', boardIds);
                await this.getBoardMembers({
                    boardIds,
                    pageIndex: 0,
                    pageSize: Constant.PAGINATED_BOARD_MEMBERS,
                    searchQuery: this.state.searchQuery,
                });
                this.setState({ ...this.state, boardIds: boardIds, addGuestButton: false });
            } else {
                this.handleCardHighlight('all', null);
                this.setState({ ...this.state, boardIds, addGuestButton: false });
                await this.getBoardMembers({
                    boardIds,
                    pageIndex: 0,
                    pageSize: Constant.PAGINATED_BOARD_MEMBERS,
                    searchQuery: this.state.searchQuery,
                });
            }
            this.state.members.length === 0
                ? this.setState({ emptyMembers: true })
                : this.setState({ emptyMembers: false });
            this.props.startLoading(false);
        } catch (e) {
            console.error(e);
            this.props.startLoading(false);
        }
    };

    reflectChangesOnAllMembers = members => {
        const totalMembers = [...members];
        members.forEach(member => {
            if (!member.isRoot) {
                if (member.selectedRole !== Constant.ROOM_INVITE_DEFAULT_ROLE) {
                    this.setInclusionUpdate(member);
                }
                member.isSelected = true;
            }
        });
        return totalMembers;
    };

    filterExclusions = id => {
        if (this.state.roomInvite.boardId.length > 0) {
            const filteredIds = this.state.exclusions.map(exclusion => {
                if (exclusion.boardIds.includes(id)) {
                    return exclusion.id;
                }
            });

            if (filteredIds.length > 0) {
                const filteredExclusionIds = this.state.roomInvite.exclusionIds.filter(exclusionIds => {
                    return !filteredIds.includes(exclusionIds);
                });
                const filteredExclusions = this.state.exclusions.filter(exclusion => {
                    return !filteredIds.includes(exclusion.id);
                });

                return { filteredExclusionIds, filteredExclusions };
            }
        }
        return;
    };

    addAllMembers = async (boardInfo, id) => {
        switch (boardInfo) {
            case 'all':
                await this.setState({ ...this.state, boardIds: null, showAll: true });
                const totalBoardIds = this.state.boardInfo.boards.filter(board => {
                    return board.boardId;
                });
                await this.setSelectedBoardOverwrite(totalBoardIds);
                await this.setExclusionOverWrite([], []);
                break;
            case 'board':
                if (this.filterExclusions(id)) {
                    const { filteredExclusionIds, filteredExclusions } = this.filterExclusions(id);
                    await this.setExclusionOverWrite(filteredExclusionIds, filteredExclusions);
                }
                !this.state.roomInvite.boardId.includes(id) && (await this.setSelectedBoardUpdate(id));
                break;
            default:
                break;
        }
        const members = id === this.state.boardIds && this.reflectChangesOnAllMembers(this.state.members);
        this.setState({ members });
        await this.getSelectedMembersCount(boardInfo);
    };

    onAddAllClick = (e, boardInfo, id = null) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.boardIds === id) {
            !this.state.selectedAll && this.addAllMembers(boardInfo, id);
        } else {
            this.onCardClick(boardInfo, id);
        }
    };

    onAddAllGuestClick = () => {
        const guestMembers = this.reflectChangesOnAllMembers(this.state.guestMembers);
        if (this.state.guestCount === 0 || this.state.guestCount < guestMembers.length) {
            this.setState({
                guestMembers,
                selectedMembers: this.state.selectedMembers + guestMembers.length - this.state.guestCount,
                guestCount: guestMembers.length,
            });
        } else {
            this.setState({ guestMembers });
        }
    };

    checkBoxSelect = member => {
        const exclusions = [...this.state.roomInvite.exclusionIds];
        if (exclusions.includes(member.id)) {
            const updatedExclusionIds = exclusions.filter(exclusion => {
                return exclusion !== member.id;
            });
            const updatedExclusions = exclusions.filter(exclusion => {
                return exclusion.id !== member.id;
            });
            this.setExclusionOverWrite(updatedExclusionIds, updatedExclusions);
            return;
        }
        this.setInclusionUpdate(member);
    };

    checkBoxUnselect = member => {
        const selectedBoardIds = [...this.state.roomInvite.boardId];
        const alreadySelected =
            this.state.selectedAll ||
            selectedBoardIds.some(selectedBoardId => {
                return member.boardIds.includes(selectedBoardId);
            });
        const isNotRootOrCurrentUser = !member.isRoot;
        if (alreadySelected && member.selectedRole === Constant.ROOM_INVITE_DEFAULT_ROLE && isNotRootOrCurrentUser) {
            this.setExclusionUpdate(member);
            return;
        }
        const filteredInclusion = this.state.roomInvite.inclusions.filter(m => m.userId !== member.id);
        this.setInclusionOverWrite(filteredInclusion);
    };

    handleSelection = (checked, member) => {
        checked ? this.checkBoxSelect(member) : this.checkBoxUnselect(member);
    };

    onCheckboxChange = (e, id) => {
        const checked = e.target.checked;
        const members = [...this.state.members];
        members.forEach(member => {
            if (member.id === id) {
                member.isSelected = checked;
                this.handleSelection(checked, member);
            }
        });
        this.setState({ members });
    };

    onGuestCheckboxChange = (e, id) => {
        const checked = e.target.checked;
        if (checked) {
            this.setState({
                selectedMembers: this.state.selectedMembers + 1,
                guestCount: this.state.guestCount + 1,
            });
        } else {
            this.setState({
                selectedMembers: this.state.selectedMembers > 0 ? this.state.selectedMembers - 1 : 0,
                guestCount: this.state.guestCount > 0 ? this.state.guestCount - 1 : 0,
            });
        }
        const guestMembers = [...this.state.guestMembers];
        guestMembers.forEach(member => {
            if (member.id === id) {
                member.isSelected = checked;
            }
        });
        this.setState({ guestMembers });
    };

    resetMembers = members => {
        members.forEach(member => {
            member.isSelected = false;
        });
        return members;
    };

    clearAll = () => {
        const members = this.resetMembers([...this.state.members]);
        const guestMembers = this.resetMembers([...this.state.guestMembers]);

        this.setState({
            ...this.state,
            members,
            selectedMembers: 0,
            guestCount: 0,
            guestMembers,
            selectedAll: false,
            roomInvite: {
                ...this.state.roomInvite,
                boardId: [],
                inclusions: [],
                guests: [],
                exclusionIds: [],
            },
        });
    };

    getGuestsArray = members => {
        const guests = [];
        members.forEach(member => {
            member.isSelected && guests.push({ name: member.name, email: member.email, role: member.selectedRole });
        });
        return guests;
    };

    sendInvite = async () => {
        try {
            const { boardId, inclusions } = { ...this.state.roomInvite };
            const guests = this.getGuestsArray(this.state.guestMembers);
            await this.setGuestsOverWrite(guests);
            if ((inclusions.length || boardId.length || guests.length) > 0 || this.state.showAll) {
                this.props.startLoading(true);
                await this._roomInviteService.sendInvite(this.state.roomInvite);
                this.setState({
                    ...this.state,
                    showRoomInviteModal: true,
                });
                this.props.startLoading(false);
            }
        } catch (e) {
            console.error(e);
            this.props.startLoading(false);
        }
    };

    closeRoomInvitationModal = () => {
        this.setState({
            ...this.state,
            showRoomInviteModal: false,
        });
        this.props.history.replace(
            `/organization/${this.props.match.params.id}/boards/${this.props.match.params.boardId}/rooms/${this.props.match.params.roomId}`,
        );
    };

    onAddGuestClick = () => {
        this.setState({ showAddGuestModal: true });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    validateForm = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        const re = /[`!@#$%^&*()_=\[\]{};\\|<>\/?~]/;

        if (!this.state.formDetails.name.trim()) {
            validationErrorMessage.nameValidation = 'Please provide name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (re.test(this.state.formDetails.name.trim())) {
            validationErrorMessage.nameValidation = 'Special characters are not allowed in Name';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.email.trim()) {
            validationErrorMessage.emailValidation = 'Please write email';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this._schema.isValidSync({ email: this.state.formDetails.email })) {
            validationErrorMessage.emailValidation = 'Provided email address is not valid';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        return true;
    };

    initializeFormFields = () => {
        this.setState({
            ...this.state,
            showAddGuestModal: false,
            formDetails: {
                name: '',
                email: '',
                blurb: '---',
                isSelected: true,
                selectedRole: Constant.ROOM_INVITE_DEFAULT_ROLE,
            },
            validationErrorMessage: {
                nameValidation: '',
                emailValidation: '',
            },
        });
    };

    handleGuestModalClose = () => {
        this.initializeFormFields();
    };

    submitGuestForm = async () => {
        if (!this.validateForm()) {
            return;
        }
        const { name } = { ...this.state.formDetails };
        const id = uuidv4();
        const memberColumn = (
            <div className="member-name">
                <ImageComponent
                    src={''}
                    alt=""
                    placeholder={placeholder}
                    width="40"
                    height="40"
                    className="img-fluid member-name__img"
                />
                <p className="word-break-all">{name}</p>
            </div>
        );
        const member = { id, memberColumn, ...this.state.formDetails };
        await this.setState({
            ...this.state,
            guestMembers: [...this.state.guestMembers, member],
            guestCount: this.state.guestCount + 1,
            selectedMembers: this.state.selectedMembers + 1,
            emptyMembers: false,
            showAddGuestModal: false,
            boardInfo: {
                ...this.state.boardInfo,
                guestData: {
                    ...this.state.boardInfo.guestData,
                    totalMembers: this.state.boardInfo.guestData.totalMembers + 1,
                },
            },
        });
        await this.initializeFormFields();
    };

    onSearchBoxChange = () => {
        this.searchText.current.value =
            this.searchText.current.value.length > Constant.MEMBER_NAME_LIMIT
                ? this.searchText.current.value.substring(0, Constant.MEMBER_NAME_LIMIT)
                : this.searchText.current.value;
        this.searchBoardMembers(this.searchText.current.value);
    };

    render() {
        return (
            <div className="room-invite">
                <BackComponent
                    link={`/organization/${this.props.match.params.id}/boards/${this.props.match.params.boardId}/rooms/${this.props.match.params.roomId}`}
                    id="back-link-room-invite"
                ></BackComponent>
                <GuestModal
                    formDetails={this.state.formDetails}
                    validationErrorMessage={this.state.validationErrorMessage}
                    modal={this.state.showAddGuestModal}
                    handleClose={this.handleGuestModalClose}
                    setName={this.setName}
                    setEmail={this.setEmail}
                    submit={this.submitGuestForm}
                    onKeyDown={this.onKeyDown}
                />
                <RoomInviteHeader
                    sendInvite={this.sendInvite}
                    clearAll={this.clearAll}
                    selectedMembers={this.state.selectedMembers}
                    roomName={this.state.roomName}
                />
                <RoomInviteModal
                    showRoomInviteModal={this.state.showRoomInviteModal}
                    closeRoomInvitationModal={this.closeRoomInvitationModal}
                    roomInvitationMessage={this.state.roomInvitationMessage}
                />
                <RoomInviteSlider
                    onCardClick={this.onCardClick}
                    onGuestCardClick={this.onGuestCardClick}
                    onAddAllClick={this.onAddAllClick}
                    onAddAllGuestClick={this.onAddAllGuestClick}
                    boardInfo={this.state.boardInfo}
                />
                <div className="room-invite__body">
                    {this.state.addGuestButton && (
                        <div className="room-invite__btn-add-guest-wrapper">
                            <button className="room-invite__btn-add-guest" onClick={this.onAddGuestClick}>
                                <img src={iconAdd} alt="" className="room-invite__btn-add-guest-icon" />
                                Add New Guest
                            </button>
                        </div>
                    )}
                    {this.state.emptyMembers && !this.state.searchQuery ? (
                        <div className="room-invite__empty-wrapper">
                            <img src={iconTable} className="room-invite__empty-img" alt="" />
                            <p className="room-invite__empty-text">Select a board or group to invite individually.</p>
                        </div>
                    ) : (
                        <>
                            {!this.state.addGuestButton && (
                                <div className="room-invite__search">
                                    <Form.Group controlId="searchMember">
                                        <Form.Label className="room-invite__search-label">Search Members</Form.Label>
                                        <Form.Control
                                            className="room-invite__search-input"
                                            type="text"
                                            ref={this.searchText}
                                            onChange={this._utilityService.debounce(this.onSearchBoxChange, 700)}
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                </div>
                            )}
                            <RoomInviteDataTableComponent
                                roleOptions={this.state.roleOptions}
                                data={this.state.addGuestButton ? this.state.guestMembers : this.state.members}
                                onCheckboxChange={
                                    this.state.addGuestButton ? this.onGuestCheckboxChange : this.onCheckboxChange
                                }
                                selectRole={this.state.addGuestButton ? this.selectGuestRole : this.selectRole}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(null, mapDispatchToProps)(RoomInviteContainer);
