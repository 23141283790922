import React from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import './unauthorized-error.style.scss';
import { AuthService } from '../../services';
import { ButtonComponent, Constant } from '../../../shared';
import { BroadcastChannel } from 'broadcast-channel';

const UnauthorizedErrorContainer = props => {
    const _authService = new AuthService();
    const queryFlag = props.match.params.unauthorized;
    const search = props.location.search;
    let channel = null;
    try {
        channel = new BroadcastChannel('auth');
    } catch (error) {
        channel = null;
    }
    let fromGuest = new URLSearchParams(search).get('fromGuest') || false;
    let fromOrg = new URLSearchParams(search).get('fromOrg') || false;
    fromGuest = fromGuest && fromGuest === 'true' ? true : false;
    fromOrg = fromOrg && fromOrg === 'true' ? true : false;
    const errMsg = queryFlag
        ? Constant.UNAUTHORIZED_MESSAGE
        : 'You are not allowed to login into the command center, Please download the iOS application.';
    if (!fromOrg) {
        setTimeout(() => {
            !queryFlag && _authService.logout();
            redirectUserToThereRespectiveLogin();
        }, 5000);
    }

    const logout = async () => {
        await _authService.logout();
    };

    const redirectUserToThereRespectiveLogin = () => {
        let pathName = '/';
        props.history.replace({
            pathname: pathName,
        });
        if (channel) {
            channel.postMessage({ case: 'signOut', pathname: '/' });
        }
    };

    const logoutAndNavigate = async () => {
        await logout();
        redirectUserToThereRespectiveLogin();
    };

    return (
        <div className="login_wrapper" data-test="unauthorized-error">
            <div className="login-block">
                <img src={logo} alt="logo" className="login__logo img-fluid mb-4" />
                <p className="unauthorized_msg-alignment">{errMsg}</p>
                {fromOrg && (
                    <div className="unauthorized_btn-container">
                        {' '}
                        <ButtonComponent
                            onclick={() => logoutAndNavigate()}
                            name="Logout"
                            className={'br__btn-theme-filled unauthorized_btn'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withRouter(UnauthorizedErrorContainer);
