import { Http } from '../../../../core';
import { Constant } from '../../../../shared';

export class RoomDetailsDashboardService {
    /**
     * @name getRoomDetail
     * @param {string} roomId required for specific room
     * @param {boolean} noFilter flag for backend to get room with or without filter
     * @desc Will fetch specific room detail with or without filter
     * @return {Promise}
     */
    getRoomDetail = (roomId, noFilter = false) => {
        const url = noFilter ? `room/details/${roomId}?noFilter=${noFilter}` : `room/details/${roomId}`;
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(url)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name updateSpeakerListOrder
     * @param {Array} Array conatins speaker Ids list required for updating
     * @param {string} roomId required for specific room
     * @desc Will update speaker list order
     * @return {Promise}
     */
    updateSpeakerListOrder = (roomId, speakerList) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.patch(`room/user/${roomId}`, { participants: speakerList })
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };

    /**
     * @name updateRoomMember
     * @param {object} param contains roomId, memberId required for specific room member and member role
     * @desc will update specific member of a specific room
     * @return {Promise}
     */
    updateRoomMember = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.patch(`room/role/${param.roomId}/${param.memberId}`, { role: param.role })
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        if (
                            _errorLog.response &&
                            _errorLog.response.status &&
                            _errorLog.response.status === Constant.STATUS_CODE.NOT_ACCEPTABLE
                        ) {
                            return reject({
                                responseCode: Constant.STATUS_CODE.NOT_ACCEPTABLE,
                                message: 'The room session has been ended.',
                            });
                        }
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };

    /**
     * @name deleteRoomMember
     * @param {object} param contains roomId and memberId for specific room specific member
     * @desc Will delete specific member of a specific room
     * @return {Promise}
     */
    deleteRoomMember = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.delete(`room/user/${param.roomId}/${param.memberId}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
}
