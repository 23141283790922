import React, { Component } from 'react';
import './guest-room-detail-container.style.scss';
import { GuestRoomDetail, GuestRoomDetailParticipants, GuestRoomDetailParticipantProfile } from '../../components';
import { Constant, Utility, IconButton } from '../../../../shared';
import { connect } from 'react-redux';
import { startLoading, showHideErrorToaster, UserService, CloudinaryService, selectUser } from '../../../../core';
import { GuestRoomDetailService } from '../../services';
import iconQuestion from '../../../../assets/images/ic-question.svg';
import iconCheckmark from '../../../../assets/images/ic-checkmark.svg';
import iconDecline from '../../../../assets/images/ic-decline.svg';
import { DateTime } from 'luxon';
import { Col, Row } from 'react-bootstrap';
import iconArrowRight from '../../../../assets/images/ic-arrow-right.svg';
import { RoomDetailsDashboardService } from '../../../room-details/services';

export class GuestRoomDetailContainer extends Component {
    _userService = new UserService();
    _utilityService = new Utility();
    _guestRoomDetailService = new GuestRoomDetailService();
    _roomDetailService = new RoomDetailsDashboardService();
    _cloudinaryService = new CloudinaryService();
    isPolling = false;
    pollingId = null;
    user = null;
    profileMenuItems = [{ name: Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE, isBlue: true }];
    constructor(props) {
        super(props);
        this.state = {
            roomId: '',
            participants: null,
            classForTruncate: false,
            classForListing: 'room-detail__speakers-list',
            collapseOpenProfile: false,
            copyMessage: Constant.ROOM_DETAILS_COPY_MESSAGE,
            guestRoomDetailParticipant: null,
            isAnonymous: false,
        };
    }

    async componentDidMount() {
        this.user = await this.getUser();
        await this.fetchRoomDetail();
        this.setSelfRole(this.getParticipantRole(this.user.id));
        this.startPolling();
        this.checkIfAnonymous();
        this.addMouseLeaveEventToCpyBtn();
    }

    setSelfRole = role => {
        this.user.roomRole = role;
        this.user.roomRoleClassification = [role, 's'].join('');
    };

    isConferenceExists = room => {
        if (room.ended === undefined) {
            room.conferenceExists = false;
            room.conferenceEnded = false;
            return room;
        }
        room.conferenceExists = true;
        room.conferenceEnded = false;
        if (room.ended) {
            room.conferenceEnded = true;
        }
        return room;
    };

    getUser = async () => {
        if (this.props.user && this.props.user.id) {
            return this.props.user;
        }
        return await this._userService.getUser();
    };

    getParticipantDetails = (room, id) => {
        return room.participants.find(participant => participant.id === id);
    };

    userIsSpeakerPlus = participant => {
        return (
            participant &&
            (participant.role === Constant.ROOM_MEMBERS.MODERATOR || participant.role === Constant.ROOM_MEMBERS.SPEAKER)
        );
    };

    isGreenRoom = room => {
        return !!room.greenRoomStartTime;
    };

    getCurrentTime = () => {
        return DateTime.local();
    };

    getLocalTimeFromUtcTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' }).toLocal();
    };

    showGreenRoomStatus = room => {
        return [Constant.GREEN_ROOM_MSG, this.getFormattedTime(room.greenRoomStartTime)].join(' ');
    };

    fetchRoomDetail = async () => {
        this.props.startLoading(true);
        try {
            await this.setRoomDetails();
        } catch (e) {
            console.error(e);
        }
        this.props.startLoading(false);
    };

    checkIfAnonymous() {
        this.setState({ ...this.state, isAnonymous: this._userService.isAnonymous() });
    }

    componentDidUpdate() {
        if (this.props.isUserSelected) {
            this.fetchGuestRoomDetailParticipant(this.props.user.id);
            this.props.selectUser(false);
        }
    }

    addMouseLeaveEventToCpyBtn = () => {
        let iconButton = document.querySelector('.guest-room-detail__copyBtn');
        if (iconButton) {
            iconButton.addEventListener('mouseleave', () => {
                this.setState({ ...this.state, copyMessage: Constant.ROOM_DETAILS_COPY_MESSAGE });
            });
        }
    };

    componentWillUnmount = () => {
        this.stopPolling();
    };

    navigateToPrivateRoomMessage = () => {
        this.props.history.replace({
            pathname: `/private-room-experience`,
        });
    };

    fetchRoomDetails = async (roomId, noFilter = true) => {
        const roomDetails = await this._roomDetailService.getRoomDetail(roomId, noFilter);
        return roomDetails;
    };

    setRoomDetails = async () => {
        const roomId = this.props.match.params.roomId;
        const roomDetails = this.isConferenceExists(await this.fetchRoomDetails(roomId));

        const isPrivateRoom = roomDetails.roomType === Constant.ROOM_TYPE.PRIVATE;
        const members = await this.getMembersArray(isPrivateRoom, roomDetails.participants, [
            Constant.ROOM_MEMBERS.SPEAKER,
            Constant.ROOM_MEMBERS.MODERATOR,
            Constant.ROOM_MEMBERS.ATTENDEE,
            Constant.ROOM_MEMBERS.GUEST,
        ]);
        this.setClassForListing(isPrivateRoom, members);
        const guestRoomDetail = await this.getGuestRoomDetails(roomDetails);
        this.updateRoomDetailStatus(guestRoomDetail);
        await this.setGuestRoomDetailsAndParticipants(members, guestRoomDetail);
    };

    setGuestRoomDetailsAndParticipants = (participants, guestRoomDetail) => {
        this.setState({ participants, guestRoomDetail });
    };

    sortMembers = members => {
        for (const participant in members) {
            members[participant].sort((m1, m2) => {
                if (m1.order === null) {
                    return 1;
                } else if (m2.order === null) {
                    return -1;
                }
                if (m1.order < m2.order) {
                    return -1;
                }
                if (m1.order > m2.order) {
                    return 1;
                }
                return 0;
            });
        }
    };

    setClassForListing = (isPrivateRoom, members) => {
        if (isPrivateRoom) {
            this.addClassListingForMembers(members.attendees);
            return;
        }
        this.addClassListingForMembers(members.speakers);
        this.addClassListingForMembers(members.attendees);
    };

    addClassListingForMembers = members => {
        members.length > 3
            ? this.setState({
                  classForListing: 'room-detail__speakers-list room-detail__speakers-list--add-height',
              })
            : this.setState({
                  classForListing: 'room-detail__speakers-list',
              });
    };

    getZoneAbbr = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
            return;
        });
        return zone[0] ? zone[0].abbr : 'EST';
    };

    getTimezone = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
        });
        return zone[0] ? zone[0].abbr : 'EST';
    };

    getFormattedDay = date => {
        const roomStartTime = DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toFormat('yyyy-MM-dd');
        const localTime = DateTime.local();
        const today = localTime.toISODate();
        const tomorrow = localTime.plus({ days: 1 }).toISODate();
        const yesterday = localTime.minus({ days: 1 }).toISODate();
        if (today === roomStartTime) {
            return Constant.DATE_OBJ.TODAY;
        }
        if (tomorrow === roomStartTime) {
            return Constant.DATE_OBJ.TOMORROW;
        }
        if (yesterday === roomStartTime) {
            return Constant.DATE_OBJ.YESTERDAY;
        }
        return;
    };

    getFormattedDate = date => {
        return (
            this.getFormattedDay(date) ||
            DateTime.fromISO(date, { zone: 'utc' })
                .toLocal()
                .toFormat('LLL dd, yyyy')
        );
    };

    getFormattedTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' })
            .toLocal()
            .toFormat('h:mma');
    };

    getFormattedRoomDateTime = room => {
        return `${this.getFormattedDate(room.startTime)} | ${this.getFormattedTime(
            room.startTime,
        )} - ${this.getFormattedTime(room.endTime)} ${this.getTimezone()}`;
    };

    setProfileTimeStamp = date => {
        return DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toFormat('LLLL d, yyyy');
    };

    getGuestRoomDetails = async roomDetails => {
        const roomId = roomDetails.roomId;
        this.setState({ roomId });
        const name = roomDetails.name;
        const isShareAble = roomDetails.isShareAble;
        const isLoungeEnabled = roomDetails.isLoungeEnabled;
        const status = this.getStatus(
            roomDetails.startTime,
            roomDetails.endTime,
            roomDetails.status,
            roomDetails.ended,
            roomDetails.conferenceExists,
        );
        const prevStatus = roomDetails.status;
        const greenRoomStartTime = roomDetails.greenRoomStartTime;
        const startTime = roomDetails.startTime;
        const endTime = roomDetails.endTime;
        const timeStamp = this.getFormattedRoomDateTime(roomDetails);
        const description = this._utilityService.replaceHyperLinkWithAnchor(roomDetails.description);
        const roomType = roomDetails.roomType;
        const participants = roomDetails.participants;
        const createdBy = roomDetails.createdBy;
        const conferenceExists = roomDetails.conferenceExists;
        const conferenceEnded = roomDetails.conferenceEnded;
        let heroImage = null;
        let boardId = null;
        let boardName = null;
        let orgLogo = null;
        if (roomDetails.roomType !== Constant.ROOM_TYPE.PRIVATE) {
            boardId = roomDetails.board.boardId;
            boardName = roomDetails.board.name;
            orgLogo = roomDetails.organization && roomDetails.organization.logo;
            heroImage = roomDetails.image;
        }
        return {
            roomId,
            name,
            status,
            prevStatus,
            greenRoomStartTime,
            heroImage,
            timeStamp,
            description,
            boardId,
            boardName,
            orgLogo,
            isShareAble,
            isLoungeEnabled,
            startTime,
            endTime,
            roomType,
            participants,
            createdBy,
            conferenceExists,
            conferenceEnded,
        };
    };

    getMembersArray = async (isPrivateRoom, membersArray, roles) => {
        const speakers = [];
        const attendees = [];
        const memberStatusObj = {
            accepted: iconCheckmark,
            pending: iconQuestion,
            rejected: iconDecline,
        };
        await Promise.all(
            membersArray.map(async member => {
                if (roles.includes(member.role)) {
                    const memberObj = {
                        id: member.id,
                        name: member.name
                            ? member.name.slice(0, Constant.MEMBER_NAME_LIMIT)
                            : Constant.MEMBER_FALLBACK.name,
                        role: member.role,
                        createdAt: member.createdAt,
                        fullAvatarUrl: member.fullAvatarUrl,
                        image: member.image,
                        inviteStatus: member.inviteStatus,
                        inviteStatusIcon: memberStatusObj[member.inviteStatus],
                        blurb: member.blurb,
                        email: member.email,
                        state: member.state,
                        order: member.order,
                        isAnonymous: member.isAnonymous,
                    };
                    if (isPrivateRoom) {
                        attendees.push(memberObj);
                    } else {
                        if (
                            member.role !== Constant.ROOM_MEMBERS.ATTENDEE &&
                            member.role !== Constant.ROOM_MEMBERS.GUEST
                        ) {
                            speakers.push(memberObj);
                        } else {
                            member.inviteStatus === Constant.ROOM_INVITE_STATUS.ACCEPTED
                                ? attendees.push(memberObj)
                                : this.setToNullIfSelectedUserGone(member.id);
                        }
                    }
                }
            }),
        );
        if (isPrivateRoom) {
            return { attendees };
        }
        return { speakers, attendees };
    };

    followUnfollowUser = async (userId, payload) => {
        try {
            const response = await this._guestRoomDetailService.followUnFollowUser(userId, payload);
            if (response.isSuccess) {
                this.fetchGuestRoomDetailParticipant(userId);
            } else {
                this.props.showHideErrorToaster({
                    show: true,
                    message: 'Something went wrong',
                    bgColor: '#ff3547',
                });
            }
        } catch (error) {
            this.props.showHideErrorToaster({
                show: true,
                message: error.message,
                bgColor: '#ff3547',
            });
        }
    };

    updateInnerCircle = async (userId, payload) => {
        try {
            const response = await this._guestRoomDetailService.innerCircle(userId, payload);
            if (response.isSuccess) {
                this.fetchGuestRoomDetailParticipant(userId);
            } else {
                this.props.showHideErrorToaster({
                    show: true,
                    message: 'Something went wrong',
                    bgColor: '#ff3547',
                });
            }
        } catch (error) {
            this.props.showHideErrorToaster({
                show: true,
                message: error.message,
                bgColor: '#ff3547',
            });
        }
    };

    getParticipantRole = participantId => {
        if (this.state.roomId) {
            const participant =
                this.state.participants.speakers.find(participant => participant.id === participantId) ||
                this.state.participants.attendees.find(participant => participant.id === participantId);
            return participant.role;
        }
        return;
    };

    isUserAnonymous = participantId => {
        if (this.state.roomId) {
            const participant =
                this.state.participants.speakers.find(participant => participant.id === participantId) ||
                this.state.participants.attendees.find(participant => participant.id === participantId);
            return participant.isAnonymous;
        }
        return;
    };

    fetchGuestRoomDetailParticipant = async participantId => {
        this.props.startLoading(true);
        this.setState({ guestRoomDetailParticipant: null });
        const guestRoomDetailParticipant = await this._guestRoomDetailService.getGuestRoomDetailParticipantProfile(
            participantId,
        );
        guestRoomDetailParticipant.role = this.getParticipantRole(participantId);
        guestRoomDetailParticipant.profileMenuItems = [];
        if (!this.isUserAnonymous(participantId)) {
            guestRoomDetailParticipant.profileMenuItems = this.profileMenuItems;
        }
        if (guestRoomDetailParticipant.id === this.user.id && !this.isUserAnonymous(participantId)) {
            guestRoomDetailParticipant.profileMenuItems = this.profileMenuItems;
            guestRoomDetailParticipant.profileMenuItems = [
                ...this.profileMenuItems,
                { name: Constant.ROOM_USER_PROFILE_ACTIONS.EDIT_PROFILE, isBlue: true },
            ];
        }
        guestRoomDetailParticipant.memberSince &&
            (guestRoomDetailParticipant.timeStamp = this.setProfileTimeStamp(guestRoomDetailParticipant.memberSince));
        this.setState({ guestRoomDetailParticipant });
        this.props.startLoading(false);
    };

    setToNullIfSelectedUserGone = id => {
        if (!this.state.guestRoomDetailParticipant) {
            return;
        }
        if (this.state.guestRoomDetailParticipant.id === id) {
            this.hideProfile();
        }
    };

    acceptOrDeclineGuestRoomDetailStatus = async (e, status) => {
        this.stopPolling();
        this.props.startLoading(true);
        if (status === 'decline') {
            status = 'rejected';
        } else if (status === 'accept') {
            status = 'accepted';
        } else if (status === 'join now') {
            let pathname = `/room-join/${this.state.roomId}`;
            if (this.state.guestRoomDetail.roomType === Constant.ROOM_TYPE.LOUNGE) {
                pathname = `/lounge-join/${this.state.roomId}`;
            }
            this.props.history.push({
                pathname,
                state: { prevPath: this.props.location.pathname },
            });
            return;
        }
        await this._guestRoomDetailService.acceptOrDeclineGuestRoomDetailStatus({ status }, this.state.roomId);
        await this.fetchRoomDetail();
        this.startPolling();
        this.props.startLoading(false);
    };

    copyToClipBoard = async e => {
        e.persist();
        this.setState({ ...this.state, copyMessage: Constant.ROOM_DETAILS_COPIED_MESSAGE });
        await navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}/room/invite?roomId=${this.state.roomId}`,
        );
    };

    onProfileCollapseLabelClick = () => {
        this.setState({
            ...this.state,
            collapseOpenProfile: !this.state.collapseOpenProfile,
            classForTruncate: !this.state.classForTruncate,
        });
    };

    isLiveRoom = (startTime, endTime, hasEnded, hasConference) => {
        const currentTime = this.getCurrentTime();

        return (
            (hasConference && !hasEnded && startTime <= currentTime) ||
            (!hasConference && startTime <= currentTime && endTime >= currentTime)
        );
    };

    getStatus = (startTime, endTime, status, hasEnded, hasConference) => {
        const timeNow = DateTime.local();
        const start = DateTime.fromISO(startTime, { zone: 'utc' }).toLocal();
        const end = DateTime.fromISO(endTime, { zone: 'utc' })
            .toLocal()
            .plus({ minutes: 1 });
        const isLive = this.isLiveRoom(start, end, hasEnded, hasConference);
        if (hasEnded || (!hasConference && timeNow > end)) {
            status = Constant.ROOM_STATUS.EXPIRED;
        } else if (
            isLive &&
            (status === 'accepted' || status === 'pending' || status === Constant.GREEN_ROOM_STATUS.STARTED)
        ) {
            status = Constant.ROOM_STATUS.LIVE;
        }
        return status;
    };

    handleGreenRoom = room => {
        try {
            if (!room.isGreenRoom || room.greenRoomStatus === Constant.GREEN_ROOM_STATUS.LIVE) {
                return;
            }
            const participant = this.getParticipantDetails(room, this.user.id);
            if (this.userIsSpeakerPlus(participant)) {
                const currentTime = this.getCurrentTime();
                const startTime = this.getLocalTimeFromUtcTime(room.startTime);
                const greenRoomStartTime = this.getLocalTimeFromUtcTime(room.greenRoomStartTime);
                if (currentTime < greenRoomStartTime) {
                    room.greenRoomStatus = Constant.GREEN_ROOM_STATUS.UPCOMING;
                    return;
                }
                if (currentTime >= greenRoomStartTime && currentTime < startTime) {
                    room.status = Constant.GREEN_ROOM_STATUS.STARTED;
                    room.greenRoomStatus = Constant.GREEN_ROOM_STATUS.LIVE;
                    return;
                }
                room.greenRoomStatus = Constant.GREEN_ROOM_STATUS.LIVE;
            }
        } catch (e) {
            console.error({ e });
        }
    };

    updateRoomDetailStatus = roomDetail => {
        const timeNow = DateTime.local();
        const startTime = DateTime.fromISO(roomDetail.startTime, { zone: 'utc' }).toLocal();
        const timeDiff = startTime.diff(timeNow);
        roomDetail.isGreenRoom = this.isGreenRoom(roomDetail);
        this.handleGreenRoom(roomDetail);
        if (Number(timeDiff.toFormat('mm')) > 14 || roomDetail.status === 'live') {
            roomDetail.timeDiff = null;
        }
        if (
            Number(timeDiff.toFormat('mm')) <= 14 &&
            Number(timeDiff.toFormat('mm')) >= 0 &&
            !(roomDetail.greenRoomStatus === 'started')
        ) {
            roomDetail.timeDiff = timeDiff.toFormat('mm:ss');
        } else {
            roomDetail.timeDiff = null;
            roomDetail.prevStatus = roomDetail.status;
            roomDetail.status = this.getStatus(
                roomDetail.startTime,
                roomDetail.endTime,
                roomDetail.status,
                roomDetail.ended,
                roomDetail.conferenceExists,
            );
            if (roomDetail.prevStatus === 'live' && roomDetail.status === 'expired') {
                roomDetail.hideBadgeBtn = true;
            }
        }
    };

    startPolling = () => {
        let roomDetail = this.state.guestRoomDetail;
        this.pollingId = setInterval(() => {
            if (!roomDetail || this.isPolling || this.state.guestRoomDetail.status === 'rejected') {
                return;
            }
            this.isPolling = true;
            this.updateRoomDetailStatus(roomDetail);
            this.setState({
                ...this.state,
                guestRoomDetail: roomDetail,
            });

            this.isPolling = false;
        }, 1000);
    };

    stopPolling = () => {
        if (this.pollingId) {
            clearInterval(this.pollingId);
        }
    };

    hideProfile = () => {
        this.setState({ ...this.state, guestRoomDetailParticipant: null });
    };

    navigateToHomePage = () => {
        this.props.history.replace({
            pathname: '/guest-dashboard',
        });
    };

    onSelectActionMenu = (item, participantId) => {
        switch (item) {
            case Constant.ROOM_USER_PROFILE_ACTIONS.VIEW_FULL_PROFILE: {
                const url =
                    participantId === this.user.id
                        ? `/guest-profile-view`
                        : `/guest-profile-view?profileId=${participantId}`;
                this.props.history.push(url);
                break;
            }
            case Constant.ROOM_USER_PROFILE_ACTIONS.EDIT_PROFILE:
                this.props.history.push('/guest-profile');
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <div>
                {/* <GuestDashboardHeader user={this.props.user} /> */}
                <div className="guest-room-detail-top-btns">
                    {this._userService.isAnonymous() ? (
                        ''
                    ) : (
                        <IconButton
                            click={() => {
                                this.navigateToHomePage();
                            }}
                            image={iconArrowRight}
                            text="Home"
                            cssClass="guest-room-detail__icon-btn"
                            iconClass="rotate-icon"
                        />
                    )}

                    {/* <ButtonComponent
                        className={'guest-room-detail__btn-filled br__btn-theme-filled'}
                        name="Invite"
                    /> */}
                </div>
                <Row className="guest-room-detail">
                    <Col lg="4" md="4" sm="12" className="guest-room-detail__left-col">
                        {this.state.guestRoomDetail && (
                            <GuestRoomDetail
                                showGreenRoomStatus={this.showGreenRoomStatus}
                                details={this.state.guestRoomDetail}
                                changeStatus={this.acceptOrDeclineGuestRoomDetailStatus}
                                copyToClipBoard={this.copyToClipBoard}
                                copyMessage={this.state.copyMessage}
                                classForListing={this.state.classForListing}
                                isAnonymous={this.state.isAnonymous}
                                roomLink={`${window.location.protocol}//${window.location.host}/room/invite?roomId=${this.state.roomId}`}
                            />
                        )}
                    </Col>
                    <Col lg="4" md="4" sm="12" className="guest-room-detail__mid-col">
                        {this.state.participants && (
                            <GuestRoomDetailParticipants
                                participants={this.state.participants}
                                fetchGuestRoomDetailParticipant={this.fetchGuestRoomDetailParticipant}
                            />
                        )}
                    </Col>
                    <Col lg="4" md="4" sm="12" className="guest-room-detail__right-col">
                        <GuestRoomDetailParticipantProfile
                            user={this.user}
                            actionMenuItems={this.actionMenuItems}
                            participant={this.state.guestRoomDetailParticipant}
                            collapseOpen={this.state.collapseOpenProfile}
                            classForTruncate={this.state.classForTruncate}
                            onLabelClick={this.onProfileCollapseLabelClick}
                            hideProfile={this.hideProfile}
                            followUnfollowUser={this.followUnfollowUser}
                            updateInnerCircle={this.updateInnerCircle}
                            onSelectActionMenu={this.onSelectActionMenu}
                            isAnonymous={this.state.isAnonymous}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
        selectUser: isUserSelected => dispatch(selectUser(isUserSelected)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestRoomDetailContainer);
