import React from 'react';
import logo from '../../../assets/images/logo.png';
import './not-found-container.style.scss';

const NotFoundContainer = props => {
    return (
        <div className="login_wrapper" data-test="not_found_container">
            <div className="login-block">
                <img src={logo} alt="logo" className="login__logo img-fluid mb-4" />
                <p className="content-styling">404 Not Found</p>
            </div>
        </div>
    );
};

export default NotFoundContainer;
