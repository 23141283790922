import React, { Component } from 'react';
import { connect } from 'react-redux';
import './guest-past-room-container.style.scss';
import { Constant, ImageComponent, IconButton, InputComponent, Utility } from '../../../../shared';
import iconArrowRight from '../../../../assets/images/ic-arrow-right.svg';
import icon_placeHolderOrg from '../../../../assets/images/org-1.png';
import iconSearch from '../../../../assets/images/ic-search.svg';
import iconPrivateRoom from '../../../../assets/images/private-room.png';
import iconRecording from '../../../../assets/images/ic-recording.png';
import { AWSStorageService, startLoading, showHideErrorToaster } from '../../../../core';
import { GuestPastRoomService } from '../../services';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
export class GuestPastRoomContainer extends Component {
    _awsStorageService = new AWSStorageService();
    _guestPastRoomService = new GuestPastRoomService();
    _utilityService = new Utility();
    debounceTimeout = null;
    constructor(props) {
        super(props);
        const search = this.props.location.search;
        const searchParams = new URLSearchParams(search);
        this.filters = this._utilityService.decryptObjectFromBase64(searchParams.get('filters'));
        this.state = {
            pastRooms: [],
            paginationQueryParams: {
                pageNo: 0,
                totalRecords: 0,
            },
            isAdvanceFilterApplied: !this.isAllFiltersAreCleared(),
            optionalQueryParams: {
                search: null,
                name: this.filters ? this.filters['name'] : '',
                description: this.filters ? this.filters['description'] : '',
                speakerPlusName: this.filters ? this.filters['speakerPlusName'] : '',
                startTime:
                    this.filters && this.filters['startTime']
                        ? DateTime.fromISO(this.filters['startTime'], { hour: 0, minute: 0, seconds: 0 }).toUTC()
                        : '',
                endTime:
                    this.filters && this.filters['endTime']
                        ? DateTime.fromISO(this.filters['endTime'], { hour: 23, minute: 59, seconds: 59 }).toUTC()
                        : '',
                isLoungeEnabled: this.filters ? this.filters['isLoungeEnabled'] : false,
                isShareable: this.filters ? this.filters['isShareable'] : false,
            },
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', this.scrollEndHandler);
        if (this.props.match?.params?.userId) {
            this.fetchMutualRooms();
            return;
        }
        this.fetchPastRooms();
    };

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollEndHandler);
    }

    fetchMutualRooms = () => {
        this.props.startLoading(true);
        this._guestPastRoomService
            .getUserMutualRooms(
                this.props.match.params.userId,
                this.state.paginationQueryParams.pageNo,
                Constant.GUEST_PAGINATED_ITEM,
                this.state.optionalQueryParams,
            )
            .then(async _successLog => {
                if (this.state.paginationQueryParams.pageNo === 0 && !_successLog.results.length) {
                    this.setState({
                        ...this.state,
                        pastRooms: _successLog.results,
                        paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: _successLog.total },
                    });
                    return;
                }
                const list = this.state.pastRooms.slice();
                this.setState({
                    ...this.state,
                    pastRooms: list.concat(_successLog.results),
                    paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: _successLog.total },
                });
            })
            .finally(() => {
                this.props.startLoading(false);
            });
    };

    fetchPastRooms = () => {
        this.props.startLoading(true);
        this._guestPastRoomService
            .getPastRooms(
                this.state.paginationQueryParams.pageNo,
                Constant.GUEST_PAGINATED_ITEM,
                this.state.optionalQueryParams,
            )
            .then(async _successLog => {
                if (this.state.paginationQueryParams.pageNo === 0 && !_successLog.results.length) {
                    this.setState({
                        ...this.state,
                        pastRooms: _successLog.results,
                        paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: _successLog.total },
                    });
                    return;
                }
                const list = this.state.pastRooms.slice();
                this.setState({
                    ...this.state,
                    pastRooms: list.concat(_successLog.results),
                    paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: _successLog.total },
                });
            })
            .finally(() => {
                this.props.startLoading(false);
            });
    };

    scrollEndHandler = event => {
        const wrappedElement = document.querySelector('body'),
            isBottom = parseInt(wrappedElement.getBoundingClientRect().bottom) <= parseInt(window.innerHeight);
        if (isBottom) {
            const currentRecordCount = Constant.GUEST_PAGINATED_ITEM * (this.state.paginationQueryParams.pageNo + 1);
            const { paginationQueryParams } = this.state;
            if (paginationQueryParams.totalRecords > currentRecordCount) {
                ++paginationQueryParams.pageNo;
                if (this.props.match?.params?.userId) {
                    this.fetchMutualRooms();
                    return;
                }
                this.fetchPastRooms();
            }
        }
    };

    getTimezone = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
            return false;
        });
        return zone[0] ? zone[0].abbr : 'EST';
    };

    getFormattedDay = date => {
        const roomStartTime = DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toFormat('yyyy-MM-dd');
        const localTime = DateTime.local();
        const today = localTime.toISODate();
        const tomorrow = localTime.plus({ days: 1 }).toISODate();
        const yesterday = localTime.minus({ days: 1 }).toISODate();
        if (today === roomStartTime) {
            return Constant.DATE_OBJ.TODAY;
        }
        if (tomorrow === roomStartTime) {
            return Constant.DATE_OBJ.TOMORROW;
        }
        if (yesterday === roomStartTime) {
            return Constant.DATE_OBJ.YESTERDAY;
        }
        return;
    };

    getFormattedDate = date => {
        return (
            this.getFormattedDay(date) ||
            DateTime.fromISO(date, { zone: 'utc' })
                .toLocal()
                .toFormat('LLL dd, yyyy')
        );
    };

    getFormattedTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' })
            .toLocal()
            .toFormat('h:mma');
    };

    getFormattedRoomDateTime = room => {
        return `${this.getFormattedDate(room.startTime)} | ${this.getFormattedTime(
            room.startTime,
        )} - ${this.getFormattedTime(room.endTime)} ${this.getTimezone()}`;
    };

    navigateToHome = () => {
        this.props.history.replace({ pathname: '/guest-dashboard' });
    };

    navigateToRoomDetail = roomId => {
        this.props.history.push({
            pathname: `/guest-room-detail/${roomId}`,
        });
    };

    onSearchBoxChange = value => {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
        this.setState({
            ...this.state,
            optionalQueryParams: {
                ...this.state.optionalQueryParams,
                search: value,
            },
        });
        this.debounceTimeout = setTimeout(async () => {
            this.setState(
                {
                    ...this.state,
                    pastRooms: [],
                    paginationQueryParams: {
                        pageNo: 0,
                        totalRecords: 0,
                    },
                },
                () => this.fetchPastRooms(),
            );
        }, 1000);
    };

    removeSpecificFilter = attr => {
        delete this.filters[attr];
        if (this.isAllFiltersAreCleared()) {
            this.clearAllFilters();
            return;
        }
        this.setState(
            {
                ...this.state,
                isAdvanceFilterApplied: true,
                optionalQueryParams: {
                    search: null,
                    ...this.filters,
                    startTime:
                        this.filters && this.filters.startTime
                            ? DateTime.fromISO(this.filters['startTime'], { hour: 0, minute: 0, seconds: 0 }).toFormat(
                                  'yyyy-MM-dd HH:mm:ss',
                              )
                            : '',
                    endTime:
                        this.filters && this.filters.endTime
                            ? DateTime.fromISO(this.filters['endTime'], { hour: 23, minute: 59, seconds: 59 }).toFormat(
                                  'yyyy-MM-dd HH:mm:ss',
                              )
                            : '',
                },
                paginationQueryParams: {
                    pageNo: 0,
                    totalRecords: 0,
                },
                pastRooms: [],
            },
            () => {
                this.fetchPastRooms();
            },
        );
    };

    clearAllFilters = () => {
        this.filters = null;
        this.setState(
            {
                ...this.state,
                isAdvanceFilterApplied: false,
                optionalQueryParams: {
                    search: null,
                },
                paginationQueryParams: {
                    pageNo: 0,
                    totalRecords: 0,
                },
                pastRooms: [],
            },
            () => {
                this.fetchPastRooms();
            },
        );
    };

    isAllFiltersAreCleared = () => {
        for (const key in this.filters) {
            if (this.filters[key]) {
                return false;
            }
        }
        return true;
    };

    render() {
        return (
            <div className="guest-past-rooms">
                <div className="guest-past-rooms__top-wrap">
                    <IconButton
                        data-test="past-room-home-btn"
                        image={iconArrowRight}
                        text="Home"
                        cssClass="guest-past-rooms__icon-btn"
                        iconClass="rotate-icon"
                        click={() => {
                            this.navigateToHome();
                        }}
                    />
                    <div className="guest-past-rooms__search-wrap">
                        {!this.props.match?.params?.userId && !this.state.isAdvanceFilterApplied ? (
                            <InputComponent
                                value={this.state.optionalQueryParams.search}
                                errorMessage={''}
                                classes={'br__form-control guest-past-rooms__search-input'}
                                hasIcon="true"
                                icon={iconSearch}
                                iconClass={'guest-past-rooms__search-icon'}
                                iconWrapperClass={'guest-past-rooms__search-box'}
                                onChange={this.onSearchBoxChange}
                            />
                        ) : (
                            ''
                        )}
                        {!this.props.match?.params?.userId && (
                            <Link
                                to={`/advance-search${
                                    this.filters
                                        ? `?filters=${this._utilityService.encryptObjectToBase64(this.filters)}`
                                        : ''
                                }`}
                            >
                                Advance
                            </Link>
                        )}
                    </div>
                </div>
                <h3 className="guest-past-rooms__heading">
                    {!this.props?.match?.params?.userId ? 'Past Rooms' : 'Mutual Rooms'}
                </h3>

                {/* advance search filters */}
                {this.state.isAdvanceFilterApplied ? (
                    <div className="guest-past-rooms__search-filter">
                        <h6 className="guest-past-rooms__search-filter-heading">Filtered By</h6>
                        <div className="guest-past-rooms__search-result-wrap">
                            <ul className="guest-past-rooms__search-result-list">
                                {this.state.optionalQueryParams.name ? (
                                    <li className="guest-past-rooms__search-result-item">
                                        Room Name{' '}
                                        <span
                                            onClick={() => {
                                                this.removeSpecificFilter('name');
                                            }}
                                            className="guest-past-rooms__search-close"
                                        >
                                            x
                                        </span>{' '}
                                    </li>
                                ) : (
                                    ''
                                )}
                                {this.state.optionalQueryParams.description ? (
                                    <li className="guest-past-rooms__search-result-item">
                                        Room Description{' '}
                                        <span
                                            onClick={() => {
                                                this.removeSpecificFilter('description');
                                            }}
                                            className="guest-past-rooms__search-close"
                                        >
                                            x
                                        </span>{' '}
                                    </li>
                                ) : (
                                    ''
                                )}
                                {this.state.optionalQueryParams.speakerPlusName ? (
                                    <li className="guest-past-rooms__search-result-item">
                                        Moderator / Speaker Name{' '}
                                        <span
                                            onClick={() => {
                                                this.removeSpecificFilter('speakerPlusName');
                                            }}
                                            className="guest-past-rooms__search-close"
                                        >
                                            x
                                        </span>{' '}
                                    </li>
                                ) : (
                                    ''
                                )}
                                {this.state.optionalQueryParams.startTime ? (
                                    <li className="guest-past-rooms__search-result-item">
                                        Date From{' '}
                                        <span
                                            onClick={() => {
                                                this.removeSpecificFilter('startTime');
                                            }}
                                            className="guest-past-rooms__search-close"
                                        >
                                            x
                                        </span>{' '}
                                    </li>
                                ) : (
                                    ''
                                )}
                                {this.state.optionalQueryParams.endTime ? (
                                    <li className="guest-past-rooms__search-result-item">
                                        Date To{' '}
                                        <span
                                            onClick={() => {
                                                this.removeSpecificFilter('endTime');
                                            }}
                                            className="guest-past-rooms__search-close"
                                        >
                                            x
                                        </span>{' '}
                                    </li>
                                ) : (
                                    ''
                                )}
                                {this.state.optionalQueryParams.isLoungeEnabled ? (
                                    <li className="guest-past-rooms__search-result-item">
                                        Lounge Enabled{' '}
                                        <span
                                            onClick={() => {
                                                this.removeSpecificFilter('isLoungeEnabled');
                                            }}
                                            className="guest-past-rooms__search-close"
                                        >
                                            x
                                        </span>{' '}
                                    </li>
                                ) : (
                                    ''
                                )}
                                {this.state.optionalQueryParams.isShareable ? (
                                    <li className="guest-past-rooms__search-result-item">
                                        Shareable{' '}
                                        <span
                                            onClick={() => {
                                                this.removeSpecificFilter('isShareable');
                                            }}
                                            className="guest-past-rooms__search-close"
                                        >
                                            x
                                        </span>{' '}
                                    </li>
                                ) : (
                                    ''
                                )}
                            </ul>
                            <p
                                className="guest-past-rooms__clear-label"
                                onClick={() => {
                                    this.clearAllFilters();
                                }}
                            >
                                Clear All Filters
                            </p>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {this.state.pastRooms.length ? (
                    <ul className="guest-past-rooms__list">
                        {this.state.pastRooms.map(room => {
                            const isPrivate = room.roomType === Constant.ROOM_TYPES.PRIVATE;
                            return (
                                <li key={room.roomId} className="guest-past-rooms__item">
                                    <div>
                                        {isPrivate ? (
                                            <img
                                                src={iconPrivateRoom}
                                                alt=""
                                                className="guest-past-rooms__room-type-icon"
                                            />
                                        ) : (
                                            ''
                                        )}
                                        <div className="d-flex align-items-center flex-wrap mb-2">
                                            <p className="guest-past-rooms__room-time">
                                                {this.getFormattedRoomDateTime(room)}
                                            </p>
                                            {room.recordingTotalDurationInSeconds ? (
                                                <img
                                                    src={iconRecording}
                                                    alt="img"
                                                    className="guest-past-rooms__recording-img"
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="guest-past-rooms__room-info">
                                            {!isPrivate ? (
                                                <ImageComponent
                                                    src={room.organization.logo}
                                                    placeholder={icon_placeHolderOrg}
                                                    alt=""
                                                    className="guest-past-rooms__org-img"
                                                />
                                            ) : (
                                                ''
                                            )}
                                            <div>
                                                <h2
                                                    className="guest-past-rooms__room-name"
                                                    onClick={() => {
                                                        this.navigateToRoomDetail(room.roomId);
                                                    }}
                                                >
                                                    {room.name}
                                                </h2>
                                                {!isPrivate ? (
                                                    <h5 className="guest-past-rooms__board-name">
                                                        By {room.board.name}
                                                    </h5>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    `No ${!this.props.match?.params?.userId ? 'past' : 'mutual'} rooms are available`
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: (isLoading, isMessage, message) => dispatch(startLoading(isLoading, isMessage, message)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestPastRoomContainer);
