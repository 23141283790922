import axios from 'axios';
import { AuthService } from '../';
import { store } from '../../';
import { showHideErrorToaster, startLoading } from '../../store';
import { Constant, Utility } from '../../../shared';
import { BroadcastChannel } from 'broadcast-channel';

export class Http {
    _authService = new AuthService();
    _channel = null;
    _utilityService = new Utility();
    static REQUEST = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        // baseURL: 'http://b1-api-dev.herokuapp.com'
    });
    static axios = axios;

    constructor() {
        this.handleRequest();
        this.handleResponse();
        try {
            this._channel = new BroadcastChannel('auth');
        } catch (error) {
            this._channel = null;
        }
    }

    showToaster(message) {
        store.dispatch(
            showHideErrorToaster({
                show: true,
                message: message,
                bgColor: '#ff3547',
            }),
        );
    }

    startLoadingFunc(isLoading) {
        store.dispatch(startLoading(isLoading));
    }

    getAccessToken = async () => {
        try {
            const accessKey = this._utilityService.getSessionStorageItems(Constant.SESSION_STORAGE_KEYS.ACCESS_TOKEN);
            if (accessKey) {
                return accessKey;
            }
            return await this._authService.getAuthToken();
        } catch (error) {
            throw 'No current user';
        }
    };

    /**
     * @name requestHandler
     * @param {object} request
     * @desc Callback being fired upon every request.
     * @return {object} modifedRequest
     */
    async requestHandler(request) {
        request.headers['Content-Type'] = 'application/json';
        try {
            const authToken = await this.getAccessToken();
            request.headers['Authorization'] = 'Bearer ' + (authToken || '');
        } catch (error) {}
        return request;
    }

    /**
     * @name errorHandler
     * @param {object} error
     * @desc Callback being fired when error occurs while making HTTP call.
     * @return {object} error
     */
    errorHandler(error) {
        this.startLoadingFunc(false);
        if (error.response) {
            if (Constant.RESPONSE_ERROR[error.response.status]) {
                if (error.response.config.url == '/user/email/verify' && error.response.status === 400) {
                    throw error;
                }

                if (error.response.status === 400) {
                    this.showToaster(
                        error.response.data && error.response.data.message[0]
                            ? error.response.data.message[0]
                            : 'Bad Request',
                    );
                    return;
                }

                if (error.response.config.method === 'delete' && error.response.status === 404) {
                    this.showToaster(
                        error.response.data && error.response.data.message !== "Room doesn't exist"
                            ? error.response.data.message
                            : 'The room has already been deleted.',
                    );
                } else if (error.response.config.method === 'get' && error.response.status === 404) {
                    throw error;
                } else {
                    this.showToaster(Constant.RESPONSE_ERROR[error.response.status]);
                }

                if (error.response.status === 401 || error.response.status === 403) {
                    setTimeout(() => {
                        this._authService.logout();
                        if (this._channel) {
                            this._channel.postMessage({ case: 'signOut', pathname: '/' });
                        }
                    }, 4000);
                }
                return;
            }
        } else if (axios.isCancel(error)) {
            throw error;
        }
        return Promise.reject({ ...error });
    }

    /**
     * @name successHandler
     * @param {object} response
     * @desc Callback being fired when HTTP request passes with success state.
     * @return {object}
     */
    successHandler(response) {
        return response;
    }

    /**
     * @name handleRequest
     * @desc Wrapper method handling all requests.
     * @return {void}
     */
    handleRequest() {
        Http.REQUEST.interceptors.request.use(async request => {
            return await this.requestHandler(request);
        });
    }

    /**
     * @name handleResponse
     * @desc Wrapper method handling all response.
     * @return {void}
     */
    handleResponse() {
        Http.REQUEST.interceptors.response.use(
            response => this.successHandler(response),
            error => this.errorHandler(error),
        );
    }
}
