import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import dolby from '../../../assets/images/powered-by-dolby.png';
import appStore from '../../../assets/images/app-store.png';
import './bcast-container.style.scss';
import { Container } from 'react-bootstrap';

const BCast = props => {
    return (
        <div className="">
            <div className="login_wrapper">
                <div className="login-block">
                    <h3 className="text-center">Under Construction</h3>
                </div>
            </div>
        </div>
    );
};

export default BCast;
