import React from 'react';
import { Link } from 'react-router-dom';
import backImg from '../../../assets/images/back-img.png';
import './back-component.style.scss';

const backComponent = props => {
    return props.link ? (
        <Link to={props.link}>
            <img src={backImg} className="img-fluid back__img" alt="back" />
        </Link>
    ) : (
        <Link>
            <img src={backImg} className="img-fluid back__img" alt="back" onClick={props.onclick} />
        </Link>
    );
};

export default backComponent;
