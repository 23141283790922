import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { RoomJoinService } from '../../services';
import { useHistory } from 'react-router';
import { NoLoungeExist } from './no-lounge-exist';
import iconLoader from '../../../../assets/images/ic-loader.png';
import './room-lounges-modal.style.scss';

const _roomJoinService = new RoomJoinService();
const LoungeHoldScreen = props => {
    const history = useHistory();
    const [showNoLoungeMessage, setNoLoungeMessage] = useState(false);

    useEffect(() => {
        let abort = false;
        const timer = setTimeout(async () => {
            const response = await _roomJoinService.inquireLounge(props.roomId);
            if (response && response.loungeRoomId && !abort) {
                history.push(`/lounge-join/${response.loungeRoomId}`);
            } else {
                setNoLoungeMessage(true);
            }
        }, 60000);
        return () => {
            abort = true;
            clearTimeout(timer);
        };
    }, []);

    const handleNoLoungeClose = () => {
        setNoLoungeMessage(false);
        history.push('/guest-dashboard');
    };
    return (
        <>
            {showNoLoungeMessage && (
                <NoLoungeExist show={showNoLoungeMessage} onClose={handleNoLoungeClose}></NoLoungeExist>
            )}
            <Modal className="leaveguestroom__modal hold-tight-modal" centered show={props.show} backdrop="static">
                <Modal.Body>
                    <img src={iconLoader} className="hold-tight-icon" />
                    <h4 className="hold-tight-heading">Hold tight!</h4>

                    <p className="hold-tight-desc">We are setting up your lounge...</p>
                </Modal.Body>
            </Modal>
        </>
    );
};
export { LoungeHoldScreen };
