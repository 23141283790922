import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import './modal-component.style.scss';
import { Constant, ErrorMessageComponent, FileSelectComponent, InputComponent } from '../../../../shared';

const modalComponent = props => {
    return (
        <>
            <Modal
                className="theme-modal"
                data-test="edit_profile_modal"
                centered
                show={props.modal}
                onHide={props.handleClose}
            >
                <Modal.Body className="theme-modal__body">
                    <Form className="theme-modal__form co__form">
                        <FileSelectComponent
                            id="modal-file-select"
                            label={props.label}
                            ratio={props.ratio}
                            isProfilePicture={true}
                            fileHandling={props.fileHandling}
                            validationErrorMessage={props.validationErrorMessage}
                            validateImageFileMessage={props.validateImageFileMessage}
                            memberDetails={props.memberDetails}
                        />

                        <Form.Group controlId="formFullName">
                            <Form.Label>Full Name</Form.Label>
                            <InputComponent
                                value={props.formDetails.fullName}
                                textId={'full-name-input'}
                                charLimit={155}
                                onChange={props.setFullName}
                                errorMessage={props.validationErrorMessage.nameValidation}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBlurb">
                            <Form.Label>Blurb</Form.Label>
                            <InputComponent
                                value={props.formDetails.blurb}
                                textId={'form-blurb-name'}
                                charLimit={100}
                                onChange={props.setBlurb}
                                errorMessage={props.validationErrorMessage.blurbValidation}
                                type="textarea"
                            />
                        </Form.Group>

                        <Form.Group controlId="select-role">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={props.selectRole}
                                value={props.formDetails.role}
                                disabled={props.roleFieldDisabled}
                            >
                                {!props.roleFieldDisabled &&
                                    props.accessibleRoles &&
                                    Object.keys(props.accessibleRoles).map(role => {
                                        return (
                                            <option key={role} value={role} data-test="select-option">
                                                {props.accessibleRoles[role]}
                                            </option>
                                        );
                                    })}
                                {props.roleFieldDisabled && (
                                    <option value={''} data-test="select-option">
                                        {Constant.MEMBER_ROLES[props.formDetails.defaultRole]}
                                    </option>
                                )}
                            </Form.Control>
                            <ErrorMessageComponent
                                id={'user-role-validation-message'}
                                message={props.validationErrorMessage.roleValidation}
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmailAddress">
                            <Form.Label>Email</Form.Label>
                            <InputComponent
                                disable={true}
                                value={props.formDetails.email}
                                textId={'form-email-address'}
                                classes={'br__form-control'}
                                readonly={true}
                                errorMessage={props.validationErrorMessage.emailValidation}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="comodal__footer">
                    <ButtonComponent
                        id={'edit-profile-button-close'}
                        className={'btn__link btn__link--primary'}
                        name="Cancel"
                        onclick={props.handleClose}
                    />
                    <ButtonComponent
                        param={props.formDetails.userId}
                        id={'edit-profile-button-submit'}
                        disabledSubmitButton={props.disabledSubmitButton}
                        className={'br__btn-theme-filled br__btn-theme-filled--width-93'}
                        name="Save"
                        onclick={props.submit}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default modalComponent;
