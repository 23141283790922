import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const modalComponent = props => {
    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Board</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group data-test="board_group" controlId="formBoardName">
                            <Form.Label>Board Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Board Name" />
                        </Form.Group>

                        <Form.Group data-test="board_description" controlId="formBoardDescription">
                            <Form.Label>Board Description</Form.Label>
                            <Form.Control type="text" placeholder="Enter Board Description" />
                        </Form.Group>

                        <Form.Group data-test="board_director" controlId="formBoardDirector">
                            <Form.Label>Board Director(optional)</Form.Label>
                            <Form.Control type="text" placeholder="Enter Board Director" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" data-test="cancel_btn" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" data-test="create_board" onClick={props.createBoard}>
                        Create Board
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default modalComponent;
