import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { showHideErrorToaster, startLoading, MagicLinkService } from '../../';
import './magic-link-sender.style.scss';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Constant, InputComponent, Utility } from '../../../shared';
import logoDolby from '../../../assets/images/logo-dolby.png';
import { DateTime } from 'luxon';
import betaLogo from '../../../assets/images/beta-logo.png';

export class MagicLinkSenderContainer extends Component {
    _magicLinkService = new MagicLinkService();
    _utilityService = new Utility();

    emailSchema = Yup.object().shape({
        email: Yup.string().email(),
    });
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            validationErrorMessage: {
                emailValidation: '',
            },
            error: null,
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
    }

    setUserEmail = userEmail => {
        this.setState({ ...this.state, userEmail });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    sendUseremailCode = async () => {
        if (this.validateEmail()) {
            try {
                this.props.startLoading(true);
                const resp = await this._magicLinkService.sendMagicLink({ email: this.state.userEmail });
                if (resp) {
                    this.props.history.replace({
                        pathname: `/magic-link-status/${this._utilityService.encryptObjectToBase64({
                            email: this.state.userEmail,
                        })}`,
                    });
                }
            } catch (error) {
                this.props.showHideErrorToaster({
                    show: true,
                    message: 'Magic link sending failed.',
                    bgColor: '#ff3547',
                });
            } finally {
                this.props.startLoading(false);
            }
        }
    };

    validateEmail = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };
        const isEmailValid = this.emailSchema.isValidSync({ email: this.state.userEmail });
        if (!isEmailValid || !this.state.userEmail) {
            validationErrorMessage.userEmailValidation = `Please enter a valid email address`;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        return true;
    };

    checkIfAnyErrorThenDisplay = () => {
        let error = this.props.match.params.error;
        error = error ? this._utilityService.decryptObjectFromBase64(error) : null;
        this.setState({
            ...this.state,
            error,
        });
    };

    onKeyDown = async event => {
        const keyPressed = event.key;
        if (keyPressed === Constant.KEYS.ENTER) {
            await this.sendUseremailCode();
        }
    };

    componentDidMount = () => {
        this.checkIfAnyErrorThenDisplay();
    };

    render() {
        return (
            <>
                <div className="magic-link-sender">
                    <div className="magic-link-sender__block">
                        <div className="magic-link-sender__logos">
                            <img
                                src={betaLogo}
                                alt="betalogo"
                                className="magic-link-sender__logos__beta-logo img-fluid"
                                data-test="logo_render"
                            />
                            <img
                                src={logoDolby}
                                alt="logo"
                                className="magic-link-sender__logos__logo img-fluid"
                                data-test="logo_render"
                            />
                        </div>
                        {/* <img src={logoDolby} alt="logo" className="magic-link-sender__logo img-fluid" /> */}
                        <div className="magic-link-sender__inner">
                            <Form.Group className="br__form-group" controlId="formEmail">
                                <Form.Label className="br__form-label">Email Address</Form.Label>
                                <InputComponent
                                    classes={'br__form-control'}
                                    textId={'magic-link-sender-container-email'}
                                    onChange={this.setUserEmail}
                                    type="email"
                                    value={this.state.userEmail}
                                    errorMessage={this.state.validationErrorMessage.userEmailValidation}
                                    onKeyDown={this.onKeyDown}
                                />
                            </Form.Group>
                            {this.state.error ? (
                                <label className="magic-link-sender__error">
                                    Your Magic Link has expired. Just click "Send Magic Link" again and we'll send you a
                                    new one.
                                </label>
                            ) : (
                                ''
                            )}
                            <Button className="magic-link-sender__btn-send" onClick={this.sendUseremailCode}>
                                Send Magic Link
                            </Button>

                            <p className="magic-link-sender__register">
                                Are you a new member?{' '}
                                <Link to="/guest-confirm-email" className="magic-link-sender__register-link">
                                    Register Here
                                </Link>
                            </p>
                        </div>
                        <div className="magic-link-sender__disclaimer" data-test="disclaimer">
                            <div className="text-center">
                                <Link
                                    to="/guest-login"
                                    className="loginform__link"
                                    id="si_need_help"
                                    data-test="need_help_link"
                                    onMouseDown={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    Log in with Password
                                </Link>
                            </div>
                            <p className="magic-link-sender__disclaimer-text">
                                &copy; {this.state.yearNow} Boardroom One. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(MagicLinkSenderContainer));
