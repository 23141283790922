import React from 'react';
import './board-details-container.style.scss';
import BackComponent from '../../../../shared/components/back-component/back-component';
import { AuthService, AWSStorageService, startLoading } from '../../../../core';
import liveTag from '../../../../assets/images/tag-live.png';
import { BoardDetailsDashboardService } from '../../services';
import {
    BoardHeaderComponent,
    Constant,
    DataTableComponent,
    DeleteModalComponent,
    DeleteModalSuccessComponent,
    Utility,
} from '../../../../shared';
import { connect } from 'react-redux';
import { Popover } from 'react-bootstrap';
import upcomingTag from '../../../../assets/images/tag-upcoming.png';
import { DateTime } from 'luxon';
export class BoardDetailContainer extends React.Component {
    _awsStorageService = new AWSStorageService();
    _boardDetailService = new BoardDetailsDashboardService();
    _authService = new AuthService();
    _utilityService = new Utility();
    constructor(props) {
        super(props);
        this.state = {
            roomDetails: [],
            singleRoomDetail: {},
            showDeleteModal: false,
            showSuccessDeleteModal: false,
            deletionModalInfo: {
                modalHeader: 'Delete Room',
                modalParagraph: null,
            },
            deletionSuccessModalInfo: {
                modalMessage: 'Room successfully deleted.',
            },

            paginationQueryParams: {
                pageNo: 0,
                totalRecords: 0,
            },
            roomStatus: null,
            menuItems: [
                { name: 'Edit Room', isDanger: false },
                { name: 'Duplicate Room', isDanger: false },
            ],
        };
    }

    setParams = (pageIndex, pageSize) => {
        const param = {
            pageIndex,
            pageSize,
            boardId: this.props.match.params.boardId,
        };
        return param;
    };

    getMemberBySpecifiedRole = (membersArray, role) => {
        const totalMembers = [];
        membersArray.forEach(member => {
            if (member.role === role) {
                const name = member.name
                    ? member.name.slice(0, Constant.MEMBER_NAME_LIMIT)
                    : Constant.MEMBER_FALLBACK.name;
                totalMembers.push(name);
            }
        });
        return totalMembers;
    };

    handleBoardDetails = board => {
        const id = board.boardId;
        const name = board.name;
        const orgName = this._utilityService.getStorageItems('orgName');
        const storedOrgLogo = this._utilityService.getStorageItems('orgLogo');
        const producers = this.getMemberBySpecifiedRole(board.member, Constant.BOARD_MEMBERS.PRODUCER);
        const directors = this.getMemberBySpecifiedRole(board.member, Constant.BOARD_MEMBERS.DIRECTOR);
        const description = board.description;
        const boardLogo = board.logo;
        const orgLogo = storedOrgLogo;
        return { id, name, orgName, boardLogo, orgLogo, subtitles: { producers, directors, description } };
    };

    setBoardDetails = boardInfo => {
        this.setState({
            boardDetails: boardInfo,
        });
    };

    fetchBoardDetails = async () => {
        const params = this.setParams();
        const boardDetails = await this._boardDetailService.getBoardDetails(params);
        const boardInfo = this.handleBoardDetails(boardDetails);
        this.setBoardDetails(boardInfo);
    };

    getStatus = (startTime, endTime, hasEnded) => {
        let status;
        const timeNow = DateTime.local();
        const start = DateTime.fromISO(startTime, { zone: 'utc' }).toLocal();
        const end = DateTime.fromISO(endTime, { zone: 'utc' }).toLocal();
        if (hasEnded || timeNow > end) {
            status = 'expired';
        } else {
            timeNow < start ? (status = 'upcoming') : (status = 'live');
        }
        return this.handleStatusTag(status);
    };

    handleStatusTag = status => {
        const statusObj = {
            upcoming: <img src={upcomingTag} className="board-listing__img" alt="" />,
            live: <img src={liveTag} className="board-listing__img" alt="" />,
            expired: <span className="expired-status">Expired</span>,
        };
        const roomStatusObj = statusObj[status];
        return { status, roomStatusObj };
    };

    handleRoomDetails = room => {
        const roomName = room.name;
        const moderators = this.getMemberBySpecifiedRole(room.member, Constant.ROOM_MEMBERS.MODERATOR);
        const speakers = this.getMemberBySpecifiedRole(room.member, Constant.ROOM_MEMBERS.SPEAKER);
        const timeStamp = DateTime.fromISO(room.startTime, { zone: 'utc' }).toLocal();
        const startTime = timeStamp.toFormat('MM/dd/yyyy hh:mm a');
        const { status, roomStatusObj } = this.getStatus(room.startTime, room.endTime, room.ended);
        const attendees = [room.totalMembersAccepted, '/', room.totalMembers].join('');
        const id = room.roomId;
        return { roomName, moderators, speakers, startTime, status, roomStatusObj, attendees, id };
    };

    fetchRoomDetails = async params => {
        const rooms = await this._boardDetailService.getRooms(params);
        return rooms;
    };

    setRoomDetails = (roomInfo, total) => {
        const pageCount = this.state.paginationQueryParams.pageNo + 1;
        this.setState({
            ...this.state,
            paginationQueryParams: { ...this.state.paginationQueryParams, totalRecords: pageCount * total },
            roomDetails: [...this.state.roomDetails, ...roomInfo],
        });
    };

    getRoomDetails = async () => {
        const params = this.setParams(this.state.paginationQueryParams.pageNo, Constant.PAGINATED_ROOMS);
        const rooms = await this.fetchRoomDetails(params);
        const roomInfo = rooms.results.map(room => {
            const roomInfo = this.handleRoomDetails(room);
            return roomInfo;
        });
        this.setRoomDetails(roomInfo, rooms.page_total);
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', this.scrollEndHandler);
        this.props.startLoading(true);
        await Promise.all([this.fetchBoardDetails(), this.getRoomDetails()]);
        this.props.startLoading(false);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollEndHandler);
    }

    /**
     * @name scrollEndHandler
     * @desc Handler to listen on document when scroll ends.
     * @return {void}
     */
    scrollEndHandler = async event => {
        const wrappedElement = document.querySelector('body'),
            isBottom = parseInt(wrappedElement.getBoundingClientRect().bottom) <= parseInt(window.innerHeight);
        if (isBottom) {
            const currentRecordCount = Constant.PAGINATED_ROOMS * (this.state.paginationQueryParams.pageNo + 1);
            const { paginationQueryParams } = this.state;
            if (paginationQueryParams.totalRecords >= currentRecordCount) {
                this.props.startLoading(true);
                ++paginationQueryParams.pageNo;
                await this.getRoomDetails();
                this.props.startLoading(false);
            }
        }
    };

    openDeletionModal = roomId => {
        this.props.startLoading(true);
        this.setState({ showDeleteModal: true });
        const rooms = this.state.roomDetails;
        const singleRoomDetail = rooms.find(room => room.id === roomId);
        this.setState({ singleRoomDetail: singleRoomDetail });
        this.setState({
            deletionModalInfo: {
                ...this.state.deletionModalInfo,
                modalParagraph: `This will delete all data relating to ${singleRoomDetail.name}, and will permanently remove this Room. This action cannot be undone.`,
            },
        });
        this.props.startLoading(false);
    };

    isConfirmDelete = async isConfirm => {
        if (isConfirm) {
            this.props.startLoading(true);
            const param = {
                boardId: this.props.match.params.boardId,
                roomId: this.state.singleRoomDetail.id,
            };
            this._boardDetailService
                .deleteRoom(param)
                .then(async _successLog => {
                    if (_successLog.isSuccess) {
                        const refreshedRoomDetails = this.state.roomDetails.filter(
                            roomDetail => roomDetail.id !== this.state.singleRoomDetail.id,
                        );

                        this.setState({
                            ...this.state,
                            roomDetails: refreshedRoomDetails,
                            showDeleteModal: false,
                            showSuccessDeleteModal: true,
                        });
                    }
                    this.props.startLoading(false);
                })
                .catch(() => {
                    this.props.startLoading(false);
                });
        } else {
            this.closeDeleteModal();
        }
    };

    onSelectMenu = (menuItem, roomId) => {
        const { boardId } = this.props.match.params;
        switch (menuItem) {
            case 'Edit Room':
                this.props.history.push({
                    pathname: `/create-room/${boardId}/${roomId}`,
                });
                break;
            case 'Duplicate Room':
                this.props.history.push({
                    pathname: `/create-room/${boardId}/${roomId}`,
                    search: '?duplicate=room',
                });
                break;
            case 'Invite Attendees':
                /**
                 * Do invite work here
                 */
                break;
            case 'Delete Room':
                this.openDeletionModal(roomId);
                break;
            default:
                break;
        }
    };

    getBoardHeading = heading => {
        return Constant.BOARD_HEADER[heading];
    };

    getTimeZone = () => {
        const timezoneValue = DateTime.local();
        const zoneUtc = timezoneValue.offsetNameLong.includes('GMT') ? 'Time' : timezoneValue.offsetNameLong;
        return [zoneUtc, ' (', 'UTC', timezoneValue.toFormat('Z'), ')'].join('');
    };

    getTableHeadings = headings => {
        const convertedHeaders = [];
        if (headings) {
            headings.forEach(heading => {
                heading === 'startTime'
                    ? convertedHeaders.push('Scheduled ' + '\n' + this.getTimeZone())
                    : convertedHeaders.push(Constant.ROOM_CONSTANTS[heading]);
            });
            convertedHeaders.push('');
        }
        return convertedHeaders;
    };

    getDescription = (subtitles, subtitle) => {
        let boardListClass, popover, boardListClassParent;
        if (subtitle === Constant.BOARD_DETAILS_SUBTITLE_CONSTANT) {
            if (subtitles[subtitle] && subtitles[subtitle].length > 80) {
                popover = (
                    <Popover
                        id="popover-basic"
                        className="executiveproducer__popover executiveproducer__popover--width-400"
                    >
                        <Popover.Content className="board-detail__popover-content">
                            <h4 className="board-detail__popover-head">Board Description</h4>
                            <p className="board-detail__popover-desc">{subtitles[subtitle]}</p>
                        </Popover.Content>
                    </Popover>
                );
            }
            boardListClass = 'board-detail__board-description board-detail__truncate';
            boardListClassParent = 'col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-30';
        }
        return { popover, boardListClass, boardListClassParent };
    };

    onListClick = roomInfo => {
        const storedOrgId = this._utilityService.getStorageItems('orgId');
        this.props.history.push({
            pathname: `/organization/${storedOrgId}/boards/${this.state.boardDetails.id}/rooms/${roomInfo}`,
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
        });
    };

    closeSuccessDeleteModal = () => {
        this.setState({
            ...this.state,
            showSuccessDeleteModal: false,
        });
    };
    redirect = () => {
        this.props.history &&
            this.props.history.push({
                pathname: `/create-room/${this.props.match.params.boardId}`,
            });
    };

    render() {
        return (
            <div className="organization-detail-wrpper" data-test="organization_detail_wrapper">
                <BackComponent
                    id="back-link-org-details"
                    link={`/organization/${this.props.match.params.id}`}
                ></BackComponent>
                {this.state.boardDetails && (
                    <BoardHeaderComponent
                        boardDetails={this.state.boardDetails}
                        getHeadings={this.getBoardHeading}
                        getDescription={this.getDescription}
                        boardId={this.props.match.params.boardId}
                        buttonName="Create New Room"
                        redirect={this.redirect}
                    />
                )}

                <div className="organization-detail__table-wrapper board-detail__table-wrapper">
                    <DataTableComponent
                        isRoomScreen={true}
                        data={this.state.roomDetails}
                        getHeadings={this.getTableHeadings}
                        onClick={this.onListClick}
                        onSelectMenu={this.onSelectMenu}
                        menuItems={this.state.menuItems}
                    />
                </div>
                <DeleteModalComponent
                    singleRoomDetail={this.state.singleRoomDetail}
                    modalInfo={this.state.deletionModalInfo}
                    showDeleteModal={this.state.showDeleteModal}
                    isConfirmDelete={this.isConfirmDelete}
                    closeDeleteModal={this.closeDeleteModal}
                />
                <DeleteModalSuccessComponent
                    deletionSuccessModalInfo={this.state.deletionSuccessModalInfo}
                    showSuccessDeleteModal={this.state.showSuccessDeleteModal}
                    closeSuccessDeleteModal={this.closeSuccessDeleteModal}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(null, mapDispatchToProps)(BoardDetailContainer);
