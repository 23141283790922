import React, { Component } from 'react';
import './guest-advance-search-container.style.scss';
import { IconButton, ButtonComponent, ErrorMessageComponent, InputComponent, Utility } from '../../../../shared';
import iconArrowRight from '../../../../assets/images/ic-arrow-right.svg';
import { Form } from 'react-bootstrap';
import { DateTime } from 'luxon';

export class GuestAdvanceSearchContainer extends Component {
    _utilityService = new Utility();

    constructor(props) {
        super(props);
        const search = this.props.location.search;
        const searchParams = new URLSearchParams(search);
        const filters = this._utilityService.decryptObjectFromBase64(searchParams.get('filters'));
        this.state = {
            filters: {
                name: filters ? filters['name'] : '',
                description: filters ? filters['description'] : '',
                speakerPlusName: filters ? filters['speakerPlusName'] : '',
                startTime: filters ? filters['startTime'] : '',
                endTime: filters ? filters['endTime'] : '',
                isLoungeEnabled: filters ? filters['isLoungeEnabled'] : false,
                isShareable: filters ? filters['isShareable'] : false,
            },
            validationErrorMessage: {
                startTimeValidation: '',
                endTimeValidation: '',
            },
            isFilterReset: false,
        };
    }

    navigateToHome = () => {
        this.props.history.goBack();
    };

    setValuesInState = (value, attr) => {
        const state = { ...this.state };
        state['filters'][attr] = value;
        this.setState({
            ...state,
            isFilterReset: false,
        });
    };

    setIsShareable = e => {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                isShareable: e.target.checked,
            },
            isFilterReset: false,
        });
    };

    setLoungeEnabled = e => {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                isLoungeEnabled: e.target.checked,
            },
            isFilterReset: false,
        });
    };

    reset = () => {
        this.setState({
            ...this.state,
            filters: {
                name: '',
                description: '',
                speakerPlusName: '',
                startTime: '',
                endTime: '',
                isLoungeEnabled: false,
                isShareable: false,
            },
            validationErrorMessage: {
                startTimeValidation: '',
                endTimeValidation: '',
            },
            isFilterReset: true,
        });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    getToday = () => {
        return DateTime.local().toISODate();
    };

    validateForm = () => {
        const validationErrorMessage = {
            ...this.state.validationErrorMessage,
            startTimeValidation: '',
            endTimeValidation: '',
        };
        if (this.state.filters.startTime && this.state.filters.startTime > this.getToday()) {
            validationErrorMessage.startTimeValidation = 'Please select a valid start date.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        if (
            this.state.filters.startTime &&
            this.state.filters.endTime &&
            this.state.filters.startTime > this.state.filters.endTime
        ) {
            validationErrorMessage.endTimeValidation = 'Please select a valid end date.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }
        return true;
    };

    applyFilter = () => {
        if (!this.validateForm()) {
            return;
        }
        let path = '/guest-past-rooms';
        if (!this.state.isFilterReset && !this.isAllFiltersAreCleared()) {
            const base64Encoded = this._utilityService.encryptObjectToBase64(this.state.filters);
            path = path.concat(`?filters=${base64Encoded}`);
        }
        this.props.history.push(path);
    };

    isAllFiltersAreCleared = () => {
        for (const key in this.state.filters) {
            if (this.state.filters[key]) {
                return false;
            }
        }
        return true;
    };

    render() {
        return (
            <div className="advance-search">
                <IconButton
                    data-test="past-room-home-btn"
                    image={iconArrowRight}
                    text="BACK"
                    cssClass="advance-search__icon-btn"
                    iconClass="rotate-icon"
                    click={() => {
                        this.navigateToHome();
                    }}
                />

                <h1 className="advance-search__main-heading">Filters</h1>
                <h2 className="advance-search__section-heading">Room</h2>
                <div className="advance-search__form">
                    <Form.Group controlId="formRoomName" className="form-group--badge" data-test="form_group_room_name">
                        <Form.Label>Room Name</Form.Label>
                        <InputComponent
                            textId={'room-container-input-name'}
                            onChange={value => {
                                this.setValuesInState(value, 'name');
                            }}
                            charLimit={50}
                            value={this.state.filters.name}
                            errorMessage={''}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId="formDescription"
                        className="form-group--badge"
                        data-test="form_group_description"
                    >
                        <Form.Label>Description</Form.Label>
                        <InputComponent
                            textId={'room-container-input-description'}
                            onChange={value => {
                                this.setValuesInState(value, 'description');
                            }}
                            type="description_box"
                            classes={'form__textarea'}
                            charLimit={1000}
                            value={this.state.filters.description || ''}
                        />
                    </Form.Group>

                    <h2 className="advance-search__section-heading">Administration</h2>

                    <Form.Group controlId="formModerator" data-test="form_group_moderator">
                        <Form.Label>Moderator / Speaker</Form.Label>
                        <InputComponent
                            placeholder={'Moderator / Speaker'}
                            textId={'room-container-input-name'}
                            onChange={value => {
                                this.setValuesInState(value, 'speakerPlusName');
                            }}
                            value={this.state.filters.speakerPlusName}
                            errorMessage={''}
                        />
                    </Form.Group>

                    <h2 className="advance-search__section-heading">Date</h2>

                    <div className="advance-search__date-time-wrapper">
                        <Form.Group
                            controlId="formDate"
                            className="advance-search__form-group--date"
                            data-test="form_group_from"
                        >
                            <Form.Label>From</Form.Label>
                            <Form.Control
                                type="date"
                                onChange={e => {
                                    this.setValuesInState(e.target.value, 'startTime');
                                }}
                                value={this.state.filters.startTime}
                            ></Form.Control>
                            <ErrorMessageComponent
                                id={'date-validation-message'}
                                message={this.state.validationErrorMessage.startTimeValidation}
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formDate"
                            className="advance-search__form-group--date mr-0"
                            data-test="form_group_to"
                        >
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                type="date"
                                onChange={e => {
                                    this.setValuesInState(e.target.value, 'endTime');
                                }}
                                value={this.state.filters.endTime}
                            ></Form.Control>
                            <ErrorMessageComponent
                                id={'date-validation-message'}
                                message={this.state.validationErrorMessage.endTimeValidation}
                            />
                        </Form.Group>
                    </div>

                    <Form.Group className="d-flex align-items-center" data-test="form_group_lounge">
                        <label className="custom-checkbox-room">
                            <input
                                className="custom-checkbox__input"
                                type="checkbox"
                                id="lounge"
                                onChange={this.setLoungeEnabled}
                                checked={this.state.filters.isLoungeEnabled}
                            />
                            <span className="custom-checkbox__checkmark"></span>
                        </label>
                        <label htmlFor="lounge" className="custom-checkbox__label">
                            Had lounge
                        </label>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center" data-test="form_group_public_link">
                        <label className="custom-checkbox-room">
                            <input
                                className="custom-checkbox__input"
                                type="checkbox"
                                id="shareable"
                                onChange={this.setIsShareable}
                                checked={this.state.filters.isShareable}
                            />
                            <span className="custom-checkbox__checkmark"></span>
                        </label>
                        <label htmlFor="shareable" className="custom-checkbox__label">
                            Had public link
                        </label>
                    </Form.Group>

                    <div className="advance-search__footer">
                        <ButtonComponent
                            onclick={this.reset}
                            data-test="cancel_link"
                            id="room-container-button-cancel"
                            className={'advance-search__btn-link'}
                            name="Reset"
                        />

                        <ButtonComponent
                            onclick={e => {
                                this.applyFilter();
                            }}
                            id={'room-container-button-submit'}
                            className={'br__btn-theme-filled'}
                            name="Apply Filters"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default GuestAdvanceSearchContainer;
