/**
 * Declare action constants here
 *
 * like
 *
 *
 * export const <ACTION_VARIABLE_NAME> = "<SET_THE_NAME_FOR_ACTION>";
 *
 */

export const SET_SOMETHING = 'SET_SOMETHING';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const START_LOADER = 'START_LOADER';
export const SHOW_HIDE_ERROR_TOASTER = 'SHOW_HIDE_ERROR_TOASTER';
export const SET_USER = 'SET_USER';
export const SELECT_USER = 'SELECT_USER';
