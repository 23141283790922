import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthService } from '../../services';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import viewOff from '../../../assets/images/ic-view-off.svg';
import viewOn from '../../../assets/images/ic-view-on.svg';
import logo from '../../../assets/images/logo.png';
import './login.style.scss';
import { connect } from 'react-redux';
import { startLoading } from '../../store';
import { DateTime } from 'luxon';
export class LoginContainer extends React.Component {
    _authService = new AuthService();

    constructor(props) {
        super(props);

        this.state = {
            formDetails: {
                email: '',
                password: '',
                showPassword: false,
            },
            buttonDisabled: false,
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (!this.props.accessToken && location.pathname !== '/' && location.pathname !== '/forgot-password') {
                this.props.history.replace({ pathname: '/' });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    // Function to disable our button
    handleBufferAndLoader = bool => {
        this.props.startLoading(bool);
        this.setState({
            ...this.state,
            buttonDisabled: bool,
        });
    };

    showHide = () => {
        this.setState({
            showPassword: this.state.showPassword ? false : true,
        });
    };

    onSubmit = async (fields, actions) => {
        this.handleBufferAndLoader(true);
        try {
            this.props.history.replace({
                pathname: `/`,
            });
            await this._authService.login(fields);
        } catch (e) {
            actions.setFieldError('password', e.getMessage());
            this.handleBufferAndLoader(false);
        }
    };
    render() {
        return (
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
                render={({ errors }) => (
                    <Form>
                        <div className="login_wrapper">
                            <div className="login-block">
                                <img src={logo} alt="logo" className="login__logo img-fluid" data-test="logo_render" />
                                <div className="loginform_wrapper">
                                    <div id="user_div" className="br__form-group" data-test="email_form_render">
                                        <label
                                            className="br__form-label"
                                            htmlFor="si_email"
                                            data-test="email_form_label"
                                        >
                                            Email Address
                                        </label>
                                        <Field
                                            name="email"
                                            type="text"
                                            id="si_email"
                                            className="br__form-control form-control br__form-control--font-18"
                                            placeholder=" "
                                            data-test="email_form_field"
                                            autoFocus={true}
                                        />
                                        <ErrorMessage name="email" component="div" className="loginform__error" />
                                    </div>
                                    <div id="pass_div" className="br__form-group" data-test="password_form_render">
                                        <label
                                            className="br__form-label"
                                            htmlFor="si_pass"
                                            data-test="password_form_label"
                                        >
                                            Password
                                        </label>
                                        <div className="br__input-badge-wrapper">
                                            <Field
                                                name="password"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                id="si_pass"
                                                placeholder=" "
                                                className="br__form-control br__form-control--badge form-control br__form-control--font-18"
                                                data-test="password_form_field"
                                            />
                                            <img
                                                src={this.state.showPassword ? viewOff : viewOn}
                                                onClick={this.showHide}
                                                className="br__input-badge"
                                                alt=""
                                                data-test="shuteye_render"
                                            />
                                        </div>
                                        <ErrorMessage name="password" component="div" className="loginform__error" />
                                    </div>
                                    <div className="text-right">
                                        <Link
                                            to="/forgot-password"
                                            className="loginform__link"
                                            id="si_forgot_password"
                                            data-test="forgot_link"
                                            onMouseDown={e => {
                                                e.preventDefault();
                                            }}
                                        >
                                            Forgot password?
                                        </Link>
                                    </div>
                                    <Button
                                        className="btn_signin"
                                        type="submit"
                                        id="si_signin"
                                        data-test="signin_button"
                                        disabled={this.state.buttonDisabled}
                                    >
                                        Sign In
                                    </Button>
                                    <div className="text-center">
                                        <a
                                            href="https://boardroomone.notion.site/boardroomone/Organization-Management-2bca263b20f34f16b66d5ec6d9d2a186"
                                            target="_blank"
                                            className="loginform__link"
                                            id="si_need_help"
                                            data-test="need_help_link"
                                            onMouseDown={e => {
                                                e.preventDefault();
                                            }}
                                        >
                                            Need help?
                                        </a>
                                    </div>
                                </div>
                                <p className="disclaimer" data-test="disclaimer">
                                    &copy; {this.state.yearNow} Boardroom One. All rights reserved.
                                </p>
                            </div>
                        </div>
                    </Form>
                )}
            />
        );
    }
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Please make sure you have specified email address.')
        .email('Please enter a valid email address'),
    password: Yup.string().required('Please make sure you have specified password.'),
});

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(LoginContainer));
