import { Button, Modal } from 'react-bootstrap';
import React from 'react';
const NoLoungeExist = props => {
    return (
        <>
            <Modal
                className="leaveguestroom__modal"
                centered
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
            >
                <Modal.Body>
                    <h4 className="modal__heading">No lounge Exist</h4>

                    <p className="modal__desc">Lounge doesn't exist</p>
                </Modal.Body>
                <Modal.Footer className="modal__footer">
                    <Button variant="light" className="btn__themeoutline" onClick={props.onClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export { NoLoungeExist };
