import React, { Component } from 'react';
import { AuthService, showHideErrorToaster, startLoading } from '../../';
import './magic-link-status.style.scss';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Utility, Exception } from '../../../shared';
import logoDolby from '../../../assets/images/logo-dolby.png';
import betaLogo from '../../../assets/images/beta-logo.png';
import inviteIcon from '../../../assets/images/ic-invite-sent.svg';
import { MagicLinkService } from '../../';
import { DateTime } from 'luxon';

export class MagicLinkSendStatus extends Component {
    _authService = new AuthService();
    _utilityService = new Utility();
    _magicLinkService = new MagicLinkService();
    emailSchema = Yup.object().shape({
        email: Yup.string().email(),
    });
    yearNow = DateTime.now()
        .toFormat('yyyy')
        .toString();

    resendMagicLink = async () => {
        try {
            this.props.startLoading(true);
            let emailInfo = this.props.match.params.email;
            emailInfo = emailInfo ? this._utilityService.decryptObjectFromBase64(emailInfo) : null;
            if (!emailInfo) {
                throw new Exception('Email Not Provided', 'Invalid Email Address');
            }
            await this._magicLinkService.sendMagicLink({ email: emailInfo.email });
            this.displayToasterMessage('Magic Link Sent!');
        } catch (e) {
            this.displayToasterMessage('Magic link sending failed.', true);
            return;
        } finally {
            this.props.startLoading(false);
        }
    };

    displayToasterMessage = (message, isError) => {
        this.props.showHideErrorToaster({
            show: true,
            message: message,
            bgColor: isError ? '#ff3547' : 'green',
        });
    };

    returnToLogin = () => {
        this.props.history.replace({
            pathname: '/guest',
        });
    };

    render() {
        return (
            <>
                <div className="magic-status">
                    <div className="magic-status__block">
                        <div className="magic-status__logos">
                            <img
                                src={betaLogo}
                                alt="betaLogo"
                                className="magic-status__logos__beta-logo img-fluid"
                                data-test="logo_render"
                            />
                            <img
                                src={logoDolby}
                                alt="logoDolby"
                                className="magic-status__logos__logo img-fluid"
                                data-test="logo_render"
                            />
                        </div>
                        <div className="magic-status__inner text-center">
                            <img src={inviteIcon} alt="invite" className="magic-status__invite" />
                            <h5 className="magic-status__heading">Magic Link sent!</h5>
                            <p className="magic-status__info">
                                We sent an email to you. It contains a magic link that will sign you in.
                            </p>

                            <Button
                                className="magic-status__btn-resend"
                                type="submit"
                                id="si_signin"
                                data-test="signin_button"
                                onClick={() => {
                                    this.resendMagicLink();
                                }}
                            >
                                Resend
                            </Button>
                            <Button
                                className="magic-status__btn-return"
                                type="submit"
                                onClick={() => {
                                    this.returnToLogin();
                                }}
                            >
                                Return to Log In
                            </Button>
                        </div>
                        <p className="disclaimer" data-test="disclaimer">
                            &copy; {this.yearNow} Boardroom One. All rights reserved.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(null, mapDispatchToProps)(MagicLinkSendStatus);
