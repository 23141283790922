import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Http, showHideErrorToaster, startLoading } from '../..';
import logo from '../../../assets/images/logo.png';
import './thankyou-room.style.scss';

const ThankyouRoomContainer = props => {
    const pingApi = () => {
        fetch('https://www.google.com/', {
            mode: 'no-cors',
        })
            .then(() => {
                props.history.replace(`/room-join/${props.match.params.roomId}`);
            })
            .catch(e => {
                props.showHideErrorToaster({
                    show: true,
                    message: 'Network Not Available',
                    bgColor: '#ff3547',
                });
            });
    };

    return (
        <div className="login_wrapper">
            <div className="login-block">
                <img src={logo} alt="logo" className="login__logo img-fluid mb-4" />
                <p className="text-center">
                    {props.match.params.queryStatus === 'internet-disrupted'
                        ? 'Your internet has been disconnected'
                        : props.match.params.queryStatus === 'ejected'
                        ? 'You have been removed from the room'
                        : 'Thank you for joining the room, complete web experience coming soon, get your hands on the experience with our iOS app'}
                </p>
                <div className="text-center mt-3">
                    {props.match.params.queryStatus === 'internet-disrupted' && (
                        <Button className="br__btn-theme-filled br__btn-theme-filled--width-138" onClick={pingApi}>
                            Retry
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(ThankyouRoomContainer));
