import React from 'react';
import { Media, Table } from 'react-bootstrap';
import { Constant, ThreeDotsDropDownComponent } from '../../../../shared';
import iconUserPlaceholder from '../../../../assets/images/ic-user.svg';
import './room-table-component.style.scss';
import { v4 as uuidv4 } from 'uuid';

const RoomTableComponent = props => {
    const menuItems = Constant.ROOM_DETAILS_ATTENDEES_MENU_ITEMS;
    return (
        <div>
            <Table className="organization__board-listing-table room-detail__table">
                <thead id="table-header" className="board-listing__head">
                    <tr id="table-header-row" className="board-listing__row--head">
                        <td className="board-listing__cel--head">RSVP’d</td>
                        <td className="board-listing__cel--head padding-left-52 board-listing__cel--head--width-210">
                            Attendees
                        </td>
                        <td className="board-listing__cel--head board-listing__cel--head--width-248"></td>
                        <td className="board-listing__cel--head">Email Address</td>
                        <td className="board-listing__cel--head"></td>
                    </tr>
                </thead>
                <tbody
                    id="table-body"
                    className="board-listing__body room-detail__table-body"
                    data-test="table_body_render"
                >
                    {props.details &&
                        props.details.map(member => (
                            <tr key={uuidv4()} className="room-detail__table-row">
                                <td className="text-center room-detail__table-cel room-detail__table-cel--icon">
                                    <img data-test="memeber_invite_icon" src={member.inviteStatusIcon} alt="" />
                                </td>
                                <td className="room-detail__table-cel" data-test="media_componet">
                                    <Media className="align-items-start">
                                        <img
                                            src={member.image || iconUserPlaceholder}
                                            placeholder={iconUserPlaceholder}
                                            className="mr-2 room-detail__attende-img"
                                            alt=""
                                        />

                                        <Media.Body className="room-detail__media-body">
                                            <h5 className="room-detail__attende-name" title={member.name}>
                                                {member.name}
                                            </h5>
                                            <img
                                                className="room-detail__rsvp-icon"
                                                src={member.inviteStatusIcon}
                                                alt=""
                                            />
                                            <h6 className="room-detail__attende-role">{member.role}</h6>
                                        </Media.Body>
                                    </Media>
                                </td>
                                <td className="room-detail__table-cel" data-test="member_blurb">
                                    <h6 className="room-detail__attende-blurb" title={member.blurb}>
                                        {member.blurb}
                                    </h6>
                                </td>
                                <td
                                    className="room-detail__table-cel room-detail__table-cel--email"
                                    data-test="member_email"
                                >
                                    <label className="room-detail__attende-email">{member.email}</label>
                                </td>
                                <td
                                    className="room-detail__table-cel room-detail__table-cel--dropdown"
                                    data-test="three_dots_dropdown"
                                >
                                    <ThreeDotsDropDownComponent
                                        menuItems={menuItems}
                                        onSelect={item => {
                                            props.onSelectMenu(item, member.id, props.boardId);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
};

export default RoomTableComponent;
