import React from 'react';
import './guest-room-detail-participants.style.scss';
import { Media } from 'react-bootstrap';
import iconUserPlaceholder from '../../../../assets/images/ic-user.svg';
import { ImageComponent } from '../../../../shared';

function GuestRoomDetailParticipants(props) {
    const { participants } = { ...props };
    return (
        <React.Fragment>
            {Object.keys(participants).map((key, i) => {
                return (
                    <div key={`main-container-${i}`}>
                        <h4 className="guest-room-detail__users-heading" data-test="user-heading">
                            {key} | {participants[key].length}
                        </h4>
                        <ul
                            className={`guest-room-detail__users-list ${
                                key == 'speakers' ? 'guest-room-detail__users-list--speaker' : ''
                            }`}
                            data-test="user-list"
                        >
                            {participants[key].map((participant, index) => (
                                <li
                                    className="guest-room-detail__users-item"
                                    key={`li-container-${index}`}
                                    onClick={() => props.fetchGuestRoomDetailParticipant(participant.id)}
                                    data-test="user-list-item"
                                >
                                    <Media className="guest-room-detail__users-info">
                                        {participant.fullAvatarUrl ? (
                                            <img
                                                data-test="fullAvatarUrl"
                                                src={participant.fullAvatarUrl}
                                                alt=""
                                                className={`guest-room-detail__users-img ${
                                                    key == 'attendees' ? 'guest-room-detail__users-img--attendee' : ''
                                                }`}
                                            />
                                        ) : (
                                            <ImageComponent
                                                src={participant.image}
                                                isAvatar={true}
                                                placeholder={iconUserPlaceholder}
                                                alt=""
                                                className={`guest-room-detail__users-img ${
                                                    key == 'attendees' ? 'guest-room-detail__users-img--attendee' : ''
                                                }`}
                                            />
                                        )}
                                        <Media.Body>
                                            <h5
                                                className={`guest-room-detail__users-name ${
                                                    key == 'attendees' ? 'guest-room-detail__users-name--attendee' : ''
                                                }`}
                                                data-test="participant-name-heading"
                                                title={participant.name}
                                            >
                                                {participant.name}
                                            </h5>
                                            <p
                                                className="guest-room-detail__users-blurb"
                                                data-test="participant-blurb"
                                                title={participant.blurb}
                                            >
                                                {participant.blurb}
                                            </p>
                                        </Media.Body>
                                    </Media>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
        </React.Fragment>
    );
}

export default GuestRoomDetailParticipants;
