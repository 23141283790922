import React from 'react';
import './chip-input-component.style.scss';
import { Constant } from '../../services';
import { ErrorMessageComponent } from '..';
import * as Yup from 'yup';

const ChipInputComponent = props => {
    const { errorMessage, chipContainerId } = { ...props };
    const [isInputFocused, setFocus] = React.useState(false);
    const { chipObject } = { ...props };
    const [emailErrorMsg, setEmailErrorMsg] = React.useState('');
    const schema = Yup.object().shape({
        email: Yup.string().email(),
    });
    const onKeyDown = async event => {
        const keyPressed = event.key;
        if (keyPressed === Constant.KEYS.ENTER) {
            event.preventDefault();
            if (!validateEmail(event)) {
                return;
            }
            updateChips(event);
            setTimeout(() => {
                scrollToTheBottom();
            });
        } else if (keyPressed === Constant.KEYS.BACKSPACE) {
            setEmailErrorMsg('');
            if (!event.target.value && chipObject.chips.length) {
                deleteChip(chipObject.chips[chipObject.chips.length - 1]);
            }
            if (chipObject.resetEmailErrors) {
                await chipObject.resetEmailErrors(chipObject.roleEmail);
            }
        }
    };

    const validateEmail = ({ target: { value } }) => {
        const isEmailValid = schema.isValidSync({ email: value });
        setEmailErrorMsg('');
        if (!isEmailValid) {
            setEmailErrorMsg('Provided email address is not valid');
            return false;
        } else if (chipObject.validateEmail && !chipObject.validateEmail(chipObject.roleEmail, [value])) {
            return false;
        } else {
            setEmailErrorMsg('');
            return true;
        }
    };
    const onTextPaste = e => {
        e.preventDefault();
        const text = (e.originalEvent || e).clipboardData.getData('text/plain');
        let emails = text.match(/[\w\.]+\@[\w]+(?:\.[\w]{3}|\.[\w]{2}\.[\w]{2})\b/g);
        if (!emails || emails.length === 0) {
            return;
        }
        emails = emails.map(email => email.trim().toLowerCase());
        if (
            chipObject.validateEmail &&
            !chipObject.validateEmail(chipObject.roleEmail, [...chipObject.chips, ...emails], true)
        ) {
            return;
        }
        chipObject.addItemToChips([...new Set([...chipObject.chips, ...emails])]);
    };

    const updateChips = event => {
        const value = event.target.value;
        if (!value) {
            return;
        }
        const chip = value.trim().toLowerCase();
        if (chip && chipObject.chips.indexOf(chip) < 0) {
            chipObject.addItemToChips(chip);
        }
        event.target.value = '';
    };

    const deleteChip = chip => {
        const index = chipObject.chips.indexOf(chip);
        if (index >= 0) {
            chipObject.deleteItemFromChips(index);
        }
    };

    const focusInput = event => {
        setFocus(true);
        const children = event.target.children;
        if (children.length) children[children.length - 1].focus();
    };

    const onFocusOut = () => {
        setFocus(false);
    };

    const scrollToTheBottom = () => {
        const container = document.getElementById(chipContainerId);
        container.scrollTop = container.scrollHeight;
    };

    return (
        <>
            <div
                id={chipContainerId}
                className={`chips ${isInputFocused ? 'chips-input-on-focus-in' : 'chips-input-on-focus-out'}`}
                onClick={e => {
                    focusInput(e);
                }}
            >
                {chipObject.chips.map((chip, index) => (
                    <span id={`${chipContainerId}-${index}`} className="chip" key={index}>
                        <span id={`${chipContainerId}-value-${index}`} className="chip-value">
                            {chip}
                        </span>
                        <button
                            id={`${chipContainerId}-button-${index}`}
                            type="button"
                            className="chip-delete-button"
                            onClick={() => {
                                deleteChip(chip);
                            }}
                        >
                            x
                        </button>
                    </span>
                ))}
                <input
                    tabIndex={-1}
                    id={`${chipContainerId}-input`}
                    type="text"
                    onBlur={() => {
                        onFocusOut();
                    }}
                    className="chips-input"
                    placeholder={chipObject.inputPlaceHolder}
                    autoComplete="off"
                    onKeyDown={e => {
                        onKeyDown(e);
                    }}
                    onPaste={e => {
                        onTextPaste(e);
                    }}
                />
            </div>
            <ErrorMessageComponent
                id={`${chipContainerId}-error-message`}
                message={emailErrorMsg ? emailErrorMsg : errorMessage}
            />
        </>
    );
};

export default ChipInputComponent;
