import { Http } from '../../../../core';

export class CreateRoomDashboardService {
    /**
     * @name createRoom
     * @param {object} requestPayload request payload for room creation
     * @desc Will create a room
     * @return {Promise}
     */
    createRoom = requestPayload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post('room', requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
    /**
     * @name getRoomDetail
     * @param {string} roomId required for getting room details
     * @param {boolean} noFilter flag varriable for backend
     * @desc Will get specific room details
     * @return {Promise}
     */
    getRoomDetail = (roomId, noFilter = false) => {
        const url = noFilter ? `room/details/${roomId}?noFilter=${noFilter}` : `room/details/${roomId}`;
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(url)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
    /**
     * @name updateRoom
     * @param {object} requestPayload contains updated room data
     * @param {roomId} roomId required for updating specific room
     * @desc Will update specific room
     * @return {Promise}
     */
    updateRoom = (requestPayload, roomId) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`room/${roomId}`, requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
    /**
     * @name getModeratorsEmail
     * @param {string} boardId required for getting specific board
     * @desc Will fetch moderators email of a specific board
     * @return {Promise}
     */
    getModeratorsEmail = boardId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/room/hierarchicalmembers/${boardId}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
    /**
     * @name getUser
     * @desc will fetch loggedIn user
     * @return {Promise}
     */
    getUser = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/me`)
                .then(async _successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };
    /**
     * @name getBoardDetail
     * @param {object} param contains boardId for specific board
     * @desc Will fetch specific board details
     * @return {Promise}
     */
    getBoardDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/board/details/${param.boardId}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
    /**
     * @name getOrganizationDetails
     * @param {object} param contain id required for specific organization
     * @desc Will fetch specific organization detail
     * @return {Promise}
     */
    getOrganizationDetails = param => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/organization/details/${param.id}`)
                .then(
                    _successLog => {
                        resolve(_successLog.data);
                        return _successLog.data;
                    },
                    _errorLog => {
                        resolve();
                    },
                )
                .catch(err => {
                    console.log(err.message);
                });
        });
    };
}
