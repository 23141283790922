import { WebRTCAdaptor } from './core/webrtc-adaptor';
import { Constant } from '../../../shared';
import Emitter from '../../services/emitter/emitter';
export class WebRTCService {
    webRTCAdaptor = null;
    initialize = () => {
        this.webRTCAdaptor = new WebRTCAdaptor({
            websocket_url: process.env.REACT_APP_ANT_MEDIA_SOCKET_URL,
            mediaConstraints: Constant.WEBRTC_CONSTANTS.MEDIA_CONSTRAINTS,
            peerconnection_config: Constant.WEBRTC_CONSTANTS.PC_CONFIG,
            sdp_constraints: Constant.WEBRTC_CONSTANTS.SDP_CONTRAINTS,
            remoteVideoId: Constant.WEBRTC_CONSTANTS.REMOTE_VIDEO_ID,
            isPlayMode: Constant.WEBRTC_CONSTANTS.IS_PLAY_MODE,
            debug: Constant.WEBRTC_CONSTANTS.DEBUG,
            callback: this.adaptorCallback,
            callbackError: this.callbackError,
        });
    };

    /**
     * For any adaptor callback event use this function
     */
    adaptorCallback = (info, description) => {
        switch (info) {
            case Constant.WEBRTC_CONSTANTS.CALLBACK_CONSTANTS.INITIALIZED:
                Emitter.emit('reconnection', { reconnected: true });
                break;
            case Constant.WEBRTC_CONSTANTS.CALLBACK_CONSTANTS.PLAY_STARTED:
                break;
            case Constant.WEBRTC_CONSTANTS.CALLBACK_CONSTANTS.PLAY_FINISHED:
                break;
            case Constant.WEBRTC_CONSTANTS.CALLBACK_CONSTANTS.CLOSED:
                Emitter.emit('connection_closed', { connectionClosed: true });
                break;
        }
    };

    /**
     * adaptor error callback
     *
     * some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
     */
    callbackError = error => {
        console.log('error callback: ' + JSON.stringify(error));
    };

    /**
     * Pass the stream id and token recieved from the API
     * /room/stream/token/{userId}?roomId={roomId}
     *
     * this will be return the streamId and token
     */
    play = (streamId, token) => {
        this.webRTCAdaptor.play(streamId, token);
    };

    /**
     * for stopping the streaming just pass the stream id
     */
    stop = streamId => {
        this.webRTCAdaptor.stop(streamId);
    };
}
