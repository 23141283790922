import { Constant } from '../constant/constant';

export class Utility {
    /**common utils functions and varables goes here which will be shared all the app */
    localStorage = window.localStorage;
    sessionStorage = window.sessionStorage;

    /**
     * @name getNumberWithCommas
     * @param {Number} number
     * @desc this function will return the number format like 1,000
     * @return {string}
     */
    getNumberWithCommas = number => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    /**
     * @name validateImageFile
     * @param {string} type of file
     * @desc this function will return the true if file is valid image otherwise false
     * @return {Boolean}
     */
    validateImageFile = (image, limit) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (validTypes.indexOf(image.type) === -1) {
            return { isValid: false, message: 'Selected File is not an image.' };
        } else if (image.size > limit) {
            return { isValid: false, message: 'Failed to upload an image. Please upload file below 20 MB.' };
        }
        return { isValid: true, message: 'valid' };
    };

    /**
     * @name setStorageItems
     * @param {string} type of file
     * @desc this function will return the true if file is valid image otherwise false
     * @return {Boolean}
     */
    setStorageItems = (key, value) => {
        this.localStorage.setItem(key, value);
    };

    /**
     * @name getStorageItems
     * @param {string} type of file
     * @desc this function will return the true if file is valid image otherwise false
     * @return {Boolean}
     */
    getStorageItems = key => {
        return this.localStorage.getItem(key);
    };

    /**
     * @name setSessionStorageItems
     * @param {string, any} key, value
     * @desc this function will save item into session storage
     * @return {void}
     */

    setSessionStorageItems = (key, value) => {
        this.sessionStorage.setItem(key, value);
    };

    /**
     * @name removeAllItemsFromLocalStorage
     * @param {string} key
     * @desc this will clear all the things in session
     * @return {Void}
     */
    removeAllItemsFromStorage = () => {
        return this.localStorage.clear();
    };

    /**
     * @name getSessionStorageItems
     * @param {string} key
     * @desc this function will return item from session storage
     * @return {Boolean}
     */
    getSessionStorageItems = key => {
        return this.sessionStorage.getItem(key);
    };

    /**
     * @name removeItemFromSessionStorage
     * @param {string} key
     * @desc this function will remove item from session storage
     * @return {void}
     */
    removeItemFromSessionStorage = key => {
        return this.sessionStorage.removeItem(key);
    };

    /**
     * @name removeAllItemsFromSessionStorage
     * @param {string} key
     * @desc this will clear all the things in session
     * @return {Void}
     */
    removeAllItemsFromSessionStorage = key => {
        return this.sessionStorage.clear();
    };

    /**
     * @name validatePassword
     * @param {string} password as string
     * @desc this function will return the true if provided password is valid
     * @return {Boolean}
     */
    validatePassword = password => {
        // eslint-disable-next-line no-use-before-define, no-useless-escape
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/;
        return re.test(password);
    };

    /**
     * @name isUserPermissionGranted
     * @param {object} user logged in user
     * @param {array} availableUser available user in organization, board, room
     * @param {string} role authorized user as per provided role
     * @desc this function will return the true if user is authorized otherwise false
     * @return {Boolean}
     */
    isUserPermissionGranted = (user, availableUsers, role) => {
        for (let i = 0; i < availableUsers.length; i++) {
            if (user.id === availableUsers[i].id && availableUsers[i].role === role) {
                return true;
            }
        }
        return false;
    };

    /**
     * @name debounce
     * @param {object} function that needs to be debounced
     * @param {delay} time in ms to debounce function
     * @desc this function will throttle the http calls and will only call passed function after specified delay.
     * @return {Function}
     */

    debounce = (func, delay) => {
        let debounceTimer;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    /**
     * @name replaceHyperLinkWithAnchor
     * @param {string} description
     * @desc this function will replace the hyperlinks with the anchor tag and return the value
     * @return {String}
     */

    replaceHyperLinkWithAnchor = (description = '') => {
        if (!description) {
            return '';
        }
        return description.replace(
            // eslint-disable-next-line no-use-before-define, no-useless-escape
            /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/gim,
            '<a href="$1" target="_blank">$1</a>',
        );
    };

    /**
     * @name poll
     * @param {object} function that needs to be polled
     * @param {delay} function that needs to validate resolve
     * @param {var} integer that needs to set polling intervals
     * @param {var} integer that needs to stop after max attempts
     * @desc this function will throttle the http calls and will only call passed function after specified delay.
     * @return {Function}
     */

    poll = async ({ fn, validate, interval, maxAttempts }) => {
        let attempts = 0;
        const executePoll = async (resolve, reject) => {
            const result = await fn();
            attempts++;

            if (validate(result)) {
                return resolve(result);
            } else if (maxAttempts && attempts === maxAttempts) {
                return reject(new Error('Exceeded max attempts'));
            } else {
                setTimeout(executePoll, interval, resolve, reject);
            }
        };

        return new Promise(executePoll);
    };

    /**
     * @name removeImgExtension
     * @param {path} for removing image extension (.png, .jpg)
     * @param {file} file string
     * @param {fileTyoe} file type string specifying the url for cloudinary access
     * @return {routes array[]}
     */
    removeImgExtension = storageKey => {
        //   storageKey = storageKey.replace(/\.[^/.]+$/, '');
        return storageKey;
    };

    /**
     * @name encryptObjectToBase64
     * @param {object} Object
     * @desc to convert object into base64 and return the base64 string
     * @return {base64string}
     */
    encryptObjectToBase64 = object => {
        try {
            return btoa(JSON.stringify(object));
        } catch (error) {
            return null;
        }
    };

    /**
     * @name decryptObjectFromBase64
     * @param {string} base64String
     * @desc decrypt base64 into object
     * @return {Object}
     */
    decryptObjectFromBase64 = base64String => {
        try {
            return JSON.parse(atob(base64String));
        } catch (error) {
            return null;
        }
    };

    /**
     * @name isPlatformIos
     * @param {void}
     * @desc return true if device is iOS
     * @return {Boolean}
     */
    isPlatformIos = () => {
        const currentPlatform = navigator.platform;
        if (navigator.userAgent.includes('Mac') && 'ontouchend' in document) {
            return true;
        }
        for (let i = 0; i < Constant.IOS_PLATFORMS.length; i++) {
            if (Constant.IOS_PLATFORMS[i].includes(currentPlatform)) {
                return true;
            }
        }
        return false;
    };
}
