import React from 'react';
import { connect } from 'react-redux';
import './guest-dashboard-container.style.scss';
import { Media } from 'react-bootstrap';
import { Constant, ImageComponent, ButtonComponent } from '../../../../shared';
import tagLive from '../../../../assets/images/tag-live-transparent.png';
import tagRSVP from '../../../../assets/images/tag-rsv-pd.png';
import tagInvite from '../../../../assets/images/tag-invite.png';
import iconClock from '../../../../assets/images/ic-clock.svg';
import iconArrowRight from '../../../../assets/images/ic-arrow-right.svg';
import icon_placeHolder from '../../../../assets/images/icon-placeholder.png';
import iconRecording from '../../../../assets/images/ic-recording.png';
import iconPrivateRoom from '../../../../assets/images/private-room.png';
import iconLounge from '../../../../assets/images/group.png';
import { GuestDashboardService } from '../../services';
import { startLoading, UserService, PubnubService } from '../../../../core';
import Emitter from '../../../../core/services/emitter/emitter';
import { DateTime } from 'luxon';
import tagGreenRoom from '../../../../assets/images/green-room-ic.png';

export class GuestDashboardContainer extends React.Component {
    _dashboardService = new GuestDashboardService();
    _userService = new UserService();
    _pubnubService = new PubnubService();
    pubsubCreds = null;
    roomInviteList = [];
    isPolling = false;
    pollingId = null;
    emitterOn = false;
    constructor(props) {
        super(props);
        this.state = {
            upcomingOrLiveRooms: [],
            declinedRooms: [],
            pastRooms: [],
            hasRooms: true,
        };
    }

    getUser = async () => {
        if (this.props.user && this.props.user.id) {
            return this.props.user;
        }
        return await this._userService.getUser();
    };

    getParticipantDetails = (room, id) => {
        return room.participants.find(participant => participant.id === id);
    };

    userIsSpeakerPlus = participant => {
        return (
            participant &&
            (participant.role === Constant.ROOM_MEMBERS.MODERATOR || participant.role === Constant.ROOM_MEMBERS.SPEAKER)
        );
    };

    isGreenRoom = room => {
        return !!room.greenRoomStartTime;
    };

    componentDidMount = () => {
        this.initializeDashboard();
    };

    componentWillUnmount = () => {
        if (this.pollingId) {
            clearInterval(this.pollingId);
        }
        if (this.emitterOn) {
            this.emitterOn = false;
            Emitter.off('message_received');
        }
    };

    initializeDashboard = async () => {
        try {
            this.props.startLoading(true);
            await this.fetchRoomInvites();
            this.user = await this.getUser();
            this.separateDeclinedAndPastRooms();
            this.startPolling();
            this.props.startLoading(false);
        } catch (e) {
            this.props.startLoading(false);
        }
    };

    fetchRoomInvites = async () => {
        try {
            this.roomInviteList = await this._dashboardService.getRoomInvites();
            this.roomInviteList = this.roomInviteList.map(this.isConferenceExists);
        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    pubnubSubscription = async () => {
        const roomIds = this.state.upcomingOrLiveRooms
            .concat(this.state.declinedRooms)
            .map(room => `status.${room.roomId}`);
        if (roomIds.length) {
            this.pubsubCreds = await this._dashboardService.getStatusPubsubKey();
            this._pubnubService.initialize(this.pubsubCreds.pubnubAuthKey, this.user.id);
            this._pubnubService.addChannelGroup(roomIds, this.pubsubCreds.channelGroupId);
            this._pubnubService.addEventListener();
            this._pubnubService.subscribe(['status.*'], true);
            this.listenConferenceEvents();
        }
    };

    listenConferenceEvents = () => {
        this.emitterOn = true;
        Emitter.on('message_received', ({ m }) => {
            const { message } = m;
            if (message) {
                switch (message.action) {
                    case Constant.ROOM_CONFERENCE_STATUS.CONFERENCE_CREATED:
                        this.roomInviteList.forEach(room => {
                            if (room.roomId === message.roomId) {
                                room.conferenceExists = true;
                                room.conferenceEnded = false;
                                room.ended = false;
                            }
                        });
                        break;
                    case Constant.ROOM_CONFERENCE_STATUS.CONFERENCE_ENDED:
                        this.roomInviteList.forEach(room => {
                            if (room.roomId === message.roomId) {
                                room.conferenceExists = true;
                                room.conferenceEnded = true;
                                room.ended = true;
                            }
                        });
                        break;
                }
            }
        });
    };

    isConferenceExists = room => {
        if (room.ended === undefined) {
            room.conferenceExists = false;
            room.conferenceEnded = false;
            return room;
        }
        room.conferenceExists = true;
        room.conferenceEnded = false;
        if (room.ended) {
            room.conferenceEnded = true;
        }
        return room;
    };

    updateUpcomingRoomStatus = activeRoom => {
        const currentTime = this.getCurrentTime();
        const startTime = this.getLocalTimeFromUtcTime(activeRoom.startTime);
        const timeDiff = startTime.diff(currentTime);
        activeRoom.isGreenRoom = this.isGreenRoom(activeRoom);
        this.handleGreenRoom(activeRoom);
        if (Number(timeDiff.toFormat('mm')) > 14 || activeRoom.isLive) {
            activeRoom.timeDiff = null;
            return activeRoom;
        }
        if (
            Number(timeDiff.toFormat('mm')) <= 14 &&
            Number(timeDiff.toFormat('mm')) >= 0 &&
            !(activeRoom.greenRoomStatus === Constant.GREEN_ROOM_STATUS.STARTED)
        ) {
            activeRoom.timeDiff = timeDiff.toFormat('mm:ss');
        } else {
            activeRoom.timeDiff = null;
            activeRoom.isLive = true;
        }
        return activeRoom;
    };

    separateDeclinedAndPastRooms = () => {
        const upcomingOrLiveRooms = this.getUpcomingOrLiveRooms().map(this.updateUpcomingRoomStatus);
        const declinedRooms = this.getDeclinedRooms();
        const pastRooms = this.getPastRooms();
        this.setState(
            {
                ...this.state,
                upcomingOrLiveRooms,
                declinedRooms,
                pastRooms,
            },
            async () => {
                if (
                    !this.state.declinedRooms.length &&
                    !this.state.pastRooms.length &&
                    !this.state.upcomingOrLiveRooms.length
                ) {
                    this.setState({
                        ...this.state,
                        hasRooms: false,
                    });
                    this.props.startLoading(false);
                    return;
                }
                await this.pubnubSubscription();
            },
        );
    };

    handleGreenRoom = room => {
        try {
            if (!room.isGreenRoom || room.greenRoomStatus === Constant.GREEN_ROOM_STATUS.LIVE) {
                return;
            }
            const participant = this.getParticipantDetails(room, this.user.id);
            if (this.userIsSpeakerPlus(participant)) {
                const currentTime = this.getCurrentTime();
                const startTime = this.getLocalTimeFromUtcTime(room.startTime);
                const greenRoomStartTime = this.getLocalTimeFromUtcTime(room.greenRoomStartTime);
                if (currentTime < greenRoomStartTime) {
                    room.greenRoomStatus = Constant.GREEN_ROOM_STATUS.UPCOMING;
                    return;
                }
                if (currentTime >= greenRoomStartTime && currentTime < startTime) {
                    room.greenRoomStatus = Constant.GREEN_ROOM_STATUS.STARTED;
                    return;
                }
                room.greenRoomStatus = Constant.GREEN_ROOM_STATUS.LIVE;
            }
        } catch (e) {
            console.error({ e });
        }
    };

    isLiveRoom = room => {
        const currentTime = this.getCurrentTime();
        const startTime = this.getLocalTimeFromUtcTime(room.startTime);
        const endTime = DateTime.fromISO(room.endTime, { zone: 'utc' })
            .toLocal()
            .plus({ minutes: 1 });

        return (
            (room.conferenceExists && !room.conferenceEnded && startTime <= currentTime) ||
            (!room.conferenceExists && startTime <= currentTime && endTime >= currentTime)
        );
    };

    getUpcomingOrLiveRooms = () => {
        const rooms = this.roomInviteList.filter(room => {
            if (room.conferenceEnded) {
                return;
            }
            if (room.status === Constant.ROOM_INVITE_STATUS.REJECTED) {
                return;
            }
            const currentTime = this.getCurrentTime();
            const startTime = this.getLocalTimeFromUtcTime(room.startTime);
            const isLive = this.isLiveRoom(room);
            if (isLive) {
                room.isLive = true;
                return room;
            }
            if (currentTime < startTime) {
                room.isLive = false;
                return room;
            }
        });
        this.sortRoomByDateAndTimeASC(rooms);
        return rooms;
    };

    getDeclinedRooms = () => {
        const declinedRooms = this.roomInviteList.filter(room => {
            if (room.ended) {
                return;
            }
            if (room.status === Constant.ROOM_INVITE_STATUS.REJECTED) {
                return room;
            }
        });
        this.sortRoomByDateAndTimeASC(declinedRooms);
        return declinedRooms;
    };

    getPastRooms = () => {
        const pastRooms = this.roomInviteList.filter(room => {
            if (room.conferenceEnded) {
                room.isPast = true;
                return room;
            }
            const currentTime = this.getCurrentTime();
            const endTime = DateTime.fromISO(room.endTime, { zone: 'utc' })
                .toLocal()
                .plus({ minutes: 1 });
            if (!room.conferenceExists && currentTime >= endTime) {
                room.isPast = true;
                return room;
            }
        });
        this.sortRoomByDateAndTimeDESC(pastRooms);
        return pastRooms.length > 5 ? pastRooms.slice(0, 5) : pastRooms;
    };

    sortRoomByDateAndTimeDESC = rooms => {
        rooms.sort((r1, r2) => {
            const r1StartTime = this.getLocalTimeFromUtcTime(r1.startTime);
            const r2StartTime = this.getLocalTimeFromUtcTime(r2.startTime);
            if (r1StartTime > r2StartTime) {
                return -1;
            }
            if (r1StartTime < r2StartTime) {
                return 1;
            }
            return 0;
        });
    };

    sortRoomByDateAndTimeASC = rooms => {
        rooms.sort((r1, r2) => {
            const r1StartTime = this.getLocalTimeFromUtcTime(r1.startTime);
            const r2StartTime = this.getLocalTimeFromUtcTime(r2.startTime);
            if (r1StartTime < r2StartTime) {
                return -1;
            }
            if (r1StartTime > r2StartTime) {
                return 1;
            }
            return 0;
        });
    };

    getLocalTimeFromUtcTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' }).toLocal();
    };

    getCurrentTime = () => {
        return DateTime.local();
    };

    getTimezone = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
        });
        return zone[0] ? zone[0].abbr : 'EST';
    };

    getFormattedDay = date => {
        const roomStartTime = DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toFormat('yyyy-MM-dd');
        const localTime = DateTime.local();
        const today = localTime.toISODate();
        const tomorrow = localTime.plus({ days: 1 }).toISODate();
        const yesterday = localTime.minus({ days: 1 }).toISODate();
        if (today === roomStartTime) {
            return Constant.DATE_OBJ.TODAY;
        }
        if (tomorrow === roomStartTime) {
            return Constant.DATE_OBJ.TOMORROW;
        }
        if (yesterday === roomStartTime) {
            return Constant.DATE_OBJ.YESTERDAY;
        }
        return;
    };

    getFormattedDate = date => {
        return (
            this.getFormattedDay(date) ||
            DateTime.fromISO(date, { zone: 'utc' })
                .toLocal()
                .toFormat('LLL dd, yyyy')
        );
    };

    getFormattedTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' })
            .toLocal()
            .toFormat('h:mma');
    };

    getFormattedRoomDateTime = room => {
        return `${this.getFormattedDate(room.startTime)} | ${this.getFormattedTime(
            room.startTime,
        )} - ${this.getFormattedTime(room.endTime)} ${this.getTimezone()}`;
    };

    getRoomParticipantsAsPerRole = (room, roles) => {
        return room.participants.filter(participant => roles.includes(participant.role));
    };

    getTop3SpeakersFromRoom = room => {
        const speakers = this.getRoomParticipantsAsPerRole(room, [
            Constant.ROOM_MEMBERS.SPEAKER,
            Constant.ROOM_MEMBERS.MODERATOR,
        ]);
        return speakers.slice(0, 3);
    };

    getSpeakersFromPrivateRoomAsAttendee = room => {
        return this.getRoomParticipantsAsPerRole(room, [
            Constant.ROOM_MEMBERS.SPEAKER,
            Constant.ROOM_MEMBERS.MODERATOR,
        ]);
    };

    getAttendeesFromRoom = room => {
        return this.getRoomParticipantsAsPerRole(room, [Constant.ROOM_MEMBERS.GUEST, Constant.ROOM_MEMBERS.ATTENDEE]);
    };

    getAcceptedStatusAttendee = room => {
        const attendees = this.getAttendeesFromRoom(room);
        return attendees.filter(attendee => attendee.inviteStatus === Constant.ROOM_INVITE_STATUS.ACCEPTED);
    };

    startPolling = () => {
        const pastRooms = this.state.pastRooms;
        let declinedRooms = this.state.declinedRooms;
        let upcomingOrLiveRooms = this.state.upcomingOrLiveRooms;

        this.pollingId = setInterval(() => {
            if (!this.roomInviteList.length || this.isPolling) {
                return;
            }
            this.isPolling = true;
            upcomingOrLiveRooms = this.getUpcomingOrLiveRooms().map(this.updateUpcomingRoomStatus);
            this.roomInviteList.forEach(room => {
                if (room.isPast) {
                    return;
                }
                const currentTime = this.getCurrentTime();
                const endTime = this.getLocalTimeFromUtcTime(room.endTime).plus({ minute: 1 });
                const alreadyInPastList = pastRooms.find(pastRoom => pastRoom.roomId === room.roomId);
                if (
                    (room.conferenceEnded || (!room.conferenceExists && currentTime >= endTime)) &&
                    !alreadyInPastList
                ) {
                    declinedRooms = declinedRooms.filter(declinedRoom => declinedRoom.roomId !== room.roomId);
                    upcomingOrLiveRooms = upcomingOrLiveRooms.filter(
                        upcomingAndLiveRoom => upcomingAndLiveRoom.roomId !== room.roomId,
                    );
                    room.isLive = false;
                    room.isPast = true;
                    if (pastRooms.length === 5) {
                        pastRooms.pop();
                    }
                    pastRooms.push(room);
                    this.sortRoomByDateAndTimeDESC(pastRooms);
                }
            });
            this.setState({
                ...this.state,
                upcomingOrLiveRooms,
                pastRooms,
                declinedRooms,
            });

            this.isPolling = false;
        }, 1000);
    };

    getActiveRoomStatus = room => {
        if (room.roomType === Constant.ROOM_TYPE.LOUNGE) {
            return iconLounge;
        }
        if (room.greenRoomStatus === Constant.GREEN_ROOM_STATUS.STARTED) {
            return tagGreenRoom;
        }
        if (room.isLive) {
            return tagLive;
        }
        const statusDictionary = {
            pending: tagInvite,
            accepted: tagRSVP,
        };
        return statusDictionary[room.status];
    };

    navigateToRoomDetail = roomId => {
        this.props.history.push({
            pathname: `/guest-room-detail/${roomId}`,
        });
    };

    navigateToRoom = roomId => {
        this.props.history.push({
            pathname: `/room-join/${roomId}`,
            state: { prevPath: this.props.location.pathname },
        });
    };

    navigateToLounge = roomId => {
        this.props.history.push({
            pathname: `/lounge-join/${roomId}`,
            state: { prevPath: this.props.location.pathname },
        });
    };

    navigateToPastRooms = () => {
        this.props.history.push({
            pathname: `/guest-past-rooms`,
            state: { prevPath: this.props.location.pathname },
        });
    };

    navigateToPrivateRoomMessage = roomId => {
        this.props.history.replace({
            pathname: `/private-room-experience`,
        });
    };

    showGreenRoomStatus = room => {
        return [Constant.GREEN_ROOM_MSG, this.getFormattedTime(room.greenRoomStartTime)].join(' ');
    };

    render() {
        return (
            <div className="guest-profile-body">
                {!this.state.hasRooms ? (
                    <div className="login_wrapper">
                        <div className="login-block">
                            <p className="unauthorized_msg-alignment">{Constant.NO_ROOM_AVAILABLE_MESSAGE}</p>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        {this.state.upcomingOrLiveRooms.map(room => {
                            const isPrivate = room.roomType === Constant.ROOM_TYPES.PRIVATE;
                            const attendees = isPrivate
                                ? this.getSpeakersFromPrivateRoomAsAttendee(room)
                                : this.getAcceptedStatusAttendee(room);
                            let createdBy = isPrivate
                                ? room.participants.filter(participant => participant.id === room.createdBy)
                                : null;
                            createdBy = createdBy && createdBy.length ? createdBy[0] : null;
                            return (
                                <div key={`live-room-${room.roomId}`} className="guest-room-row">
                                    <div className="guest-room-row__left">
                                        {!isPrivate ? (
                                            <ImageComponent
                                                src={room.image}
                                                placeholder={icon_placeHolder}
                                                alt=""
                                                className="guest-room-row__img-hero cursor-pointer"
                                                onClick={() => {
                                                    this.navigateToRoomDetail(room.roomId);
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}

                                        <div className="guest-room-row__status">
                                            {room.timeDiff ? (
                                                <div className="room-start-timer">
                                                    <img src={iconClock} alt="icon" className="room-start-timer-img" />
                                                    <span className="room-start-timer-text">{room.timeDiff}</span>
                                                </div>
                                            ) : (
                                                <img
                                                    className="guest-room-row__status-tag"
                                                    src={this.getActiveRoomStatus(room)}
                                                    alt="icon"
                                                />
                                            )}
                                            <div className="guest-room-row__time-wrap">
                                                <p className="guest-room-row__time">
                                                    {this.getFormattedRoomDateTime(room)}
                                                </p>
                                            </div>
                                        </div>
                                        {room.greenRoomStatus === Constant.GREEN_ROOM_STATUS.UPCOMING && (
                                            <p className="guest-room-row__greenroom-time">
                                                {this.showGreenRoomStatus(room)}
                                            </p>
                                        )}
                                        <div className="guest-room-row__room-info">
                                            {!isPrivate ? (
                                                <ImageComponent
                                                    src={room.organization.logo}
                                                    placeholder={icon_placeHolder}
                                                    alt=""
                                                    className="guest-room-row__org-img"
                                                />
                                            ) : (
                                                ''
                                            )}
                                            <div>
                                                <h2
                                                    className="guest-room-row__room-name"
                                                    onClick={() => {
                                                        this.navigateToRoomDetail(room.roomId);
                                                    }}
                                                >
                                                    {room.name}
                                                </h2>
                                                <h5 className="guest-room-row__board-name">
                                                    By {isPrivate ? createdBy && createdBy.name : room.board.name}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="guest-room-row__mid">
                                        {!isPrivate ? (
                                            <ul className="guest-room-row__speaker-list">
                                                {this.getTop3SpeakersFromRoom(room).map(speaker => (
                                                    <li
                                                        key={`speaker-${speaker.id}`}
                                                        className="guest-room-row__speaker-item"
                                                    >
                                                        <Media className="guest-room-row__speaker-info">
                                                            {speaker.fullAvatarUrl ? (
                                                                <img
                                                                    src={speaker.fullAvatarUrl}
                                                                    alt="avatar"
                                                                    className="guest-room-row__speaker-img"
                                                                />
                                                            ) : (
                                                                <ImageComponent
                                                                    isAvatar={true}
                                                                    src={speaker.image}
                                                                    placeholder={icon_placeHolder}
                                                                    alt=""
                                                                    className="guest-room-row__speaker-img"
                                                                />
                                                            )}

                                                            <Media.Body>
                                                                <div className="guest-room-row__speaker-name-wrap">
                                                                    <h5
                                                                        className="guest-room-row__speaker-name"
                                                                        title={speaker.name}
                                                                    >
                                                                        {speaker.name}
                                                                    </h5>
                                                                    <p className="guest-room-row__speaker-role">
                                                                        {speaker.role}
                                                                    </p>
                                                                </div>
                                                                <p
                                                                    className="guest-room-row__speaker-blurb"
                                                                    title={speaker.blurb}
                                                                >
                                                                    {speaker.blurb}
                                                                </p>
                                                            </Media.Body>
                                                        </Media>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            ''
                                        )}

                                        <div className="guest-room-row__attendee-wrap">
                                            <h6 className="guest-room-row__attendee-heading">Attendees</h6>
                                            {attendees.length ? (
                                                <ul className="guest-room-row__attendee-list">
                                                    {attendees.slice(0, 6).map(attendee => (
                                                        <li
                                                            key={`attendee-${attendee.id}`}
                                                            className="guest-room-row__attendee-item"
                                                        >
                                                            {attendee.fullAvatarUrl ? (
                                                                <img
                                                                    src={attendee.fullAvatarUrl}
                                                                    alt="avatar"
                                                                    className="guest-room-row__attendee-img"
                                                                />
                                                            ) : (
                                                                <ImageComponent
                                                                    isAvatar={true}
                                                                    src={attendee.image}
                                                                    placeholder={icon_placeHolder}
                                                                    alt=""
                                                                    className="guest-room-row__attendee-img"
                                                                />
                                                            )}
                                                        </li>
                                                    ))}
                                                    {this.getAcceptedStatusAttendee(room).length > 6 ? (
                                                        <li className="guest-room-row__attendee-item--count">
                                                            <p className="guest-room-row__attendee-count-text">
                                                                +{this.getAcceptedStatusAttendee(room).length - 6}
                                                            </p>
                                                        </li>
                                                    ) : (
                                                        ''
                                                    )}
                                                </ul>
                                            ) : (
                                                'No Attendees Available'
                                            )}
                                        </div>
                                    </div>
                                    <div className="guest-room-row__right">
                                        {room.isLive ? (
                                            <ButtonComponent
                                                onclick={() => {
                                                    if (room.roomType === Constant.ROOM_TYPE.LOUNGE) {
                                                        this.navigateToLounge(room.roomId);
                                                        return;
                                                    }
                                                    this.navigateToRoom(room.roomId);
                                                }}
                                                className={'br__btn-theme-filled guest-room-row__btn-filled'}
                                                name="Join Now"
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            );
                        })}

                        {/* declined rooms */}
                        {this.state.declinedRooms.length ? (
                            <div className="guest-old-rooms">
                                <h5 className="guest-old-rooms__heading">Declined Rooms</h5>
                                <ul className="guest-old-rooms__list">
                                    {this.state.declinedRooms.map(room => {
                                        const isPrivate = room.roomType === Constant.ROOM_TYPES.PRIVATE;
                                        let createdBy = isPrivate
                                            ? room.participants.filter(participant => participant.id === room.createdBy)
                                            : null;
                                        createdBy = createdBy && createdBy.length ? createdBy[0] : null;
                                        return (
                                            <li key={`declined-${room.roomId}`} className="guest-old-rooms__item">
                                                <div>
                                                    {isPrivate ? (
                                                        <img
                                                            src={iconPrivateRoom}
                                                            alt="room"
                                                            className="guest-old-rooms__room-type-icon"
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <p className="guest-old-rooms__time">
                                                            {this.getFormattedRoomDateTime(room)}
                                                        </p>
                                                        {room.recordingTotalDurationInSeconds ? (
                                                            <img
                                                                src={iconRecording}
                                                                alt="img"
                                                                className="guest-old-rooms__recording-img"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="guest-old-rooms__room-info">
                                                        {!isPrivate ? (
                                                            <ImageComponent
                                                                src={room.organization.logo}
                                                                placeholder={icon_placeHolder}
                                                                alt=""
                                                                className="guest-old-rooms__org-img cursor-pointer"
                                                                onClick={() => {
                                                                    this.navigateToRoomDetail(room.roomId);
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        <div>
                                                            <h2
                                                                className="guest-old-rooms__room-name"
                                                                onClick={() => {
                                                                    this.navigateToRoomDetail(room.roomId);
                                                                }}
                                                            >
                                                                {room.name}
                                                            </h2>
                                                            <h5 className="guest-old-rooms__board-name">
                                                                By{' '}
                                                                {isPrivate
                                                                    ? createdBy && createdBy.name
                                                                    : room.board.name}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* past rooms */}
                        {this.state.pastRooms && this.state.pastRooms.length ? (
                            <div className="guest-old-rooms">
                                <h5 className="guest-old-rooms__heading">Past 5 Rooms</h5>
                                <ul className="guest-old-rooms__list">
                                    {this.state.pastRooms.map(room => {
                                        const roomType = room.roomType;
                                        const isPrivate = roomType === Constant.ROOM_TYPES.PRIVATE;
                                        return (
                                            <li key={`past-${room.roomId}`} className="guest-old-rooms__item">
                                                <div>
                                                    {isPrivate ? (
                                                        <img
                                                            src={iconPrivateRoom}
                                                            alt="room"
                                                            className="guest-old-rooms__room-type-icon"
                                                        />
                                                    ) : roomType === Constant.ROOM_TYPE.LOUNGE ? (
                                                        <img
                                                            src={iconLounge}
                                                            alt="room"
                                                            className="guest-old-rooms__room-type-icon"
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <p className="guest-old-rooms__time">
                                                            {this.getFormattedRoomDateTime(room)}
                                                        </p>
                                                        {room.recordingTotalDurationInSeconds ? (
                                                            <img
                                                                src={iconRecording}
                                                                alt="img"
                                                                className="guest-old-rooms__recording-img"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="guest-old-rooms__room-info">
                                                        {!isPrivate ? (
                                                            <ImageComponent
                                                                src={room.organization.logo}
                                                                placeholder={icon_placeHolder}
                                                                alt=""
                                                                className="guest-old-rooms__org-img cursor-pointer"
                                                                onClick={() => {
                                                                    this.navigateToRoomDetail(room.roomId);
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        <div>
                                                            <h2
                                                                className="guest-old-rooms__room-name"
                                                                onClick={() => {
                                                                    this.navigateToRoomDetail(room.roomId);
                                                                }}
                                                            >
                                                                {room.name}
                                                            </h2>
                                                            {!isPrivate ? (
                                                                <h5 className="guest-old-rooms__board-name">
                                                                    By {room.board.name}
                                                                </h5>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className="text-right">
                                    <button
                                        className="guest-room-row__pastroom-btn-outline"
                                        onClick={() => {
                                            this.navigateToPastRooms();
                                        }}
                                    >
                                        View Past Rooms <img src={iconArrowRight} alt="icon" />{' '}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: (isLoading, isMessage, message) => dispatch(startLoading(isLoading, isMessage, message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestDashboardContainer);
