import React from 'react';
import logo from '../../../assets/images/logo.png';
import './error-container.style.scss';

const ErrorContainer = props => {
    return (
        <div className="login_wrapper" data-test="error_container">
            <div className="login-block">
                <img src={logo} alt="logo" className="login__logo img-fluid mb-4" />
                <p className="content-styling">This link has expired. Please contact the administrator</p>
            </div>
        </div>
    );
};

export default ErrorContainer;
