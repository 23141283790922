import { Http } from '../../../core';
import axios from 'axios';

export class CommonService {
    /**
     * @name joinRoom
     * @param {string} roomId required for joining room
     * @desc Will join a room
     * @return {Promise}
     */
    joinRoom = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`room/join/${roomId}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
    /**
     * @name updateUserProfile
     * @param {object} requestPayload required for updating user profile
     * @desc Will update a user profile
     * @return {Promise}
     */
    updateUserProfile = requestPayload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put('user', requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     *
     * @name getRoomInvites
     * @desc this will be return the list of rooms
     * @return {Promise}
     */
    getRoomInvites = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get('room/invites')
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     *
     * @name isRoomShareable
     * @desc this will return if room is shareable
     * @return {Promise<Boolean>}
     */
    isRoomShareable = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`room/isShareAble/${roomId}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     *
     * @name joinShareableRoom
     * @desc this will make logged in user part of shareable room
     * @return {Promise<Boolean>}
     */
    joinRoomShareableRoom = roomId => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.put(`/room/shareable/join/${roomId}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     *
     * POC
     * @name getSplashimages
     * @param {pageNo: Number, searchedImage: string} param
     * @desc
     * @return {Promise}
     */
    getSplashimages = queryParams => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_UNSPLASH_API}?page=${queryParams.pageNo}&client_id=${process.env.REACT_APP_UNSPLASH_CLIENT_ID}&query=${queryParams.searchedImage}&utm_source=BR_ONE&utm_medium=referral`,
                )
                .then(async response => {
                    return resolve({
                        results: response.data.results,
                        totalPages: response.data.totalPages,
                        totatItems: response.data.total,
                    });
                });
        });
    };

    /**
     *
     * @name downloadSplashImage
     * @param {imagedownloadUrl: string} param
     * @desc
     * @return {Promise}
     */
    downloadSplashImage = imagedownloadUrl => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${imagedownloadUrl}&client_id=${process.env.REACT_APP_UNSPLASH_CLIENT_ID}`)
                .then(async response => {
                    return resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    getSettings = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/settings`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
