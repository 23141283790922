import React from 'react';
import logo from '../../../assets/images/logo.png';
import { Constant } from '../../../shared';
import './private-room-message.style.scss';

const PrivateRoomContainer = props => {
    return (
        <div className="login_wrapper" data-test="private_room_main">
            <div className="login-block">
                <img src={logo} alt="logo" className="login__logo img-fluid mb-4" data-test="private_room_msg_logo" />
                <p className="unauthorized_msg-alignment">{Constant.PRIVATE_ROOM_EXP_MESSAGE}</p>
            </div>
        </div>
    );
};

export default PrivateRoomContainer;
