import React from 'react';
import iconLinkedin from '../../../assets/images/ic-linkedin.svg';
import iconMsg from '../../../assets/images/ic-message.svg';
import iconMail from '../../../assets/images/ic-mail.svg';
import iconLocation from '../../../assets/images/ic-location.svg';
import iconArrowRight from '../../../assets/images/ic-arrow-right.svg';
import icon_placeHolder from '../../../assets/images/icon-placeholder.png';
import iconLock from '../../../assets/images/icon-lock.png';
import iconUsers from '../../../assets/images/ic-users.png';
import iconGlobe from '../../../assets/images/icon-globe.png';
import iconPencil from '../../../assets/images/ic-edit-android.png';
import iconSetting from '../../../assets/images//ic-settings.png';
import iconCheckmark from '../../../assets/images/ic-checkmark.png';
import org from '../../../assets/images/org-1.png';
import { Constant, ImageComponent, IconButton, ButtonComponent } from '../../../shared';
import './guest-profile-view.style.scss';
import { connect } from 'react-redux';
import { startLoading, showHideErrorToaster } from '../..';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { GuestDashboardService } from '../../../content/guest-dashboard/services';
import { UserRelations, UserService } from '../../services';
import { Col, Row } from 'react-bootstrap';
import { GuestRoomDetailService } from '../../../content/guest-room-detail/services';
import iconPrivateRoom from '../../../assets/images/private-room.png';
export class GuestProfileViewContainer extends React.Component {
    _dashboardService = new GuestDashboardService();
    _userService = new UserService();
    _guestRelationService = new UserRelations();
    _guestRoomDetailService = new GuestRoomDetailService();
    roomInviteList = [];
    isPolling = false;
    pollingId = null;

    constructor(props) {
        super(props);
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        this.state = {
            pastRooms: [],
            summary: '',
            userBoards: [],
            profileId: params.get('profileId'),
            user: this.props.user,
        };
    }

    componentDidMount = () => {
        if (!this.state.profileId) {
            this.initializeDashboard();
            return;
        }
        this.setState({
            ...this.state,
            user: this.props.user,
        });
        this.getOthersProfile();
    };

    componentDidUpdate = prevProps => {
        if (
            (this.props.user !== this.state.user && !this.state.profileId) ||
            this.props.location !== prevProps.location
        ) {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            if (params.get('profileId')) {
                this.setState({
                    ...this.state,
                    profileId: params.get('profileId'),
                });
                this.getOthersProfile(params.get('profileId'));
            } else {
                this.setState({
                    ...this.state,
                    user: this.props.user,
                    profileId: '',
                });
                this.initializeDashboard();
            }
        }
    };

    getOthersProfile = async (profileId = this.state.profileId) => {
        try {
            this.props.startLoading(true);
            const user = await this._guestRelationService.getOtherUserProfile(profileId);
            this.setState({
                ...this.state,
                user,
                profileId: profileId,
            });
            await Promise.all([this.getUserBoards(), this.getUserMutualRooms()]);
            this.props.startLoading(false);
        } catch (error) {
            this.props.startLoading(false);
        }
    };

    initializeDashboard = async () => {
        try {
            this.props.startLoading(true);
            await this.fetchRoomInvites();
            this.getPastRooms();
            this.props.startLoading(false);
            await this.getUserBoards();
            await this.getRelationSummary();
        } catch (e) {
            this.props.startLoading(false);
        }
    };

    fetchRoomInvites = async () => {
        const roomInviteList = await this._dashboardService.getRoomInvites();
        this.roomInviteList = roomInviteList.filter(room => room.roomType !== Constant.ROOM_TYPES.PRIVATE);
    };

    getPastRooms = () => {
        const pastRooms = this.roomInviteList.filter(room => {
            if (room.ended) {
                return room;
            }
            const currentTime = this.getCurrentTime();
            const endTime = DateTime.fromISO(room.endTime, { zone: 'utc' })
                .toLocal()
                .plus({ minutes: 1 });
            if (currentTime > endTime) {
                return room;
            }
            return false;
        });
        this.sortRoomByDateAndTimeDESC(pastRooms);
        this.setState({
            ...this.state,
            pastRooms: pastRooms.length > 5 ? pastRooms.slice(0, 5) : pastRooms,
        });
    };

    getCurrentTime = () => {
        return DateTime.local();
    };

    sortRoomByDateAndTimeASC = rooms => {
        rooms.sort((r1, r2) => {
            const r1StartTime = this.getLocalTimeFromUtcTime(r1.startTime);
            const r2StartTime = this.getLocalTimeFromUtcTime(r2.startTime);
            if (r1StartTime < r2StartTime) {
                return -1;
            }
            if (r1StartTime > r2StartTime) {
                return 1;
            }
            return 0;
        });
    };

    sortRoomByDateAndTimeDESC = rooms => {
        rooms.sort((r1, r2) => {
            const r1StartTime = this.getLocalTimeFromUtcTime(r1.startTime);
            const r2StartTime = this.getLocalTimeFromUtcTime(r2.startTime);
            if (r1StartTime > r2StartTime) {
                return -1;
            }
            if (r1StartTime < r2StartTime) {
                return 1;
            }
            return 0;
        });
    };

    getLocalTimeFromUtcTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' }).toLocal();
    };

    navigateToRoomDetail = roomId => {
        this.props.history.push({
            pathname: `/guest-room-detail/${roomId}`,
        });
    };

    setProfileTimeStamp = date => {
        return DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toFormat('LLLL d, yyyy');
    };

    getTimezone = () => {
        const machineTime = DateTime.local();
        const zoneName = machineTime.zoneName;
        const zone = Constant.TIME_ZONES.filter(timezone => {
            if (timezone.utc.includes(zoneName)) {
                return timezone;
            }
            return false;
        });
        return zone[0] ? zone[0].abbr : 'EST';
    };

    getFormattedDay = date => {
        const roomStartTime = DateTime.fromISO(date, { zone: 'utc' }).toISODate();
        const localTime = DateTime.local();
        const today = localTime.toISODate();
        const tomorrow = localTime.plus({ days: 1 }).toISODate();
        const yesterday = localTime.minus({ days: 1 }).toISODate();
        if (today === roomStartTime) {
            return Constant.DATE_OBJ.TODAY;
        }
        if (tomorrow === roomStartTime) {
            return Constant.DATE_OBJ.TOMORROW;
        }
        if (yesterday === roomStartTime) {
            return Constant.DATE_OBJ.YESTERDAY;
        }
        return;
    };

    getFormattedDate = date => {
        return (
            this.getFormattedDay(date) ||
            DateTime.fromISO(date, { zone: 'utc' })
                .toLocal()
                .toFormat('LLL dd, yyyy')
        );
    };

    getFormattedTime = time => {
        return DateTime.fromISO(time, { zone: 'utc' })
            .toLocal()
            .toFormat('h:mma');
    };

    getFormattedRoomDateTime = room => {
        return `${this.getFormattedDate(room.startTime)} | ${this.getFormattedTime(
            room.startTime,
        )} - ${this.getFormattedTime(room.endTime)} ${this.getTimezone()}`;
    };

    goBackFunction = () => {
        this.props.history.goBack();
    };

    numFormatter = num => {
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
        } else if (num < 900) {
            return num; // if value < 1000, nothing to do
        }
    };

    gotoEditProfile = () => {
        this.props.history.push({
            pathname: `/guest-profile`,
        });
    };

    getUserBoards = async () => {
        let userBoards = await this._dashboardService.getUserBoards(this.state.user?.id);
        this.setState({
            ...this.state,
            userBoards: userBoards.results,
        });
    };

    getUserMutualRooms = async () => {
        let mutualRooms = await this._guestRelationService.getUserMutualRooms(this.state.user?.id);
        this.setState({
            ...this.state,
            pastRooms: mutualRooms.results,
        });
    };

    getRelationSummary = async () => {
        const summary = await this._dashboardService.getRelationSummary();
        this.setState({
            ...this.state,
            summary: summary,
        });
    };

    followUnfollowUser = async (userId, payload) => {
        try {
            const response = await this._guestRoomDetailService.followUnFollowUser(userId, payload);
            if (response.isSuccess) {
                this.getOthersProfile();
                return;
            }
            this.props.showHideErrorToaster({
                show: true,
                message: `Failed to ${payload.follow ? 'follow' : 'unfollow'} user`,
                bgColor: '#ff3547',
            });
        } catch (error) {
            this.props.showHideErrorToaster({
                show: true,
                message: `Failed to ${payload.follow ? 'follow' : 'unfollow'} user`,
                bgColor: '#ff3547',
            });
        }
    };

    updateInnerCircle = async (userId, payload) => {
        try {
            const response = await this._guestRoomDetailService.innerCircle(userId, payload);
            if (response.isSuccess) {
                this.getOthersProfile();
                return;
            }
            this.props.showHideErrorToaster({
                show: true,
                message: 'Something went wrong updating inner circle',
                bgColor: '#ff3547',
            });
        } catch (error) {
            this.props.showHideErrorToaster({
                show: true,
                message: 'Something went wrong while updating inner circle',
                bgColor: '#ff3547',
            });
        }
    };

    render() {
        return (
            <div className="guest-profile-view">
                <Row>
                    <Col sm="6" xs="12" className="guest-profile-view__left-col">
                        <div className="guest-profile-view__info">
                            <IconButton
                                click={() => {
                                    this.goBackFunction();
                                }}
                                image={iconArrowRight}
                                text="Back"
                                cssClass="guest-room-detail__icon-btn mb-4"
                                iconClass="rotate-icon"
                            />
                            <img
                                src={this.state.user?.fullAvatarUrl}
                                alt=""
                                className="guest-profile-view__img img-fluid"
                            />
                            <h4 className="guest-profile-view__name">{this.state.user?.name}</h4>
                            <p className="guest-profile-view__blurb">{this.state.user?.blurb}</p>
                            {this.state.user?.memberSince ? (
                                <p className="guest-profile-view__timestamp">
                                    Member since {this.setProfileTimeStamp(this.state.user?.memberSince)}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <ul className="guest-profile-view__social-wrap">
                            {this.state.user?.linkedin ? (
                                <li className="guest-profile-view__social-item">
                                    <div>
                                        <img src={iconLinkedin} alt="" className="guest-profile-view__social-img" />
                                        {this.state.user?.linkedin}
                                    </div>
                                    <div className="guest-profile-view__visibility-img-wrap">
                                        {this.state.user?.visibility &&
                                        this.state.user?.visibility?.linkedin === 'only_me' ? (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        ) : this.state.user?.visibility?.linkedin === 'inner_circle' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconUsers}
                                                alt=""
                                            />
                                        ) : this.state.user?.visibility?.linkedin === 'public' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconGlobe}
                                                alt=""
                                            />
                                        ) : (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        )}
                                    </div>
                                </li>
                            ) : (
                                ''
                            )}
                            {this.state.user?.email ? (
                                <li className="guest-profile-view__social-item">
                                    <div>
                                        <img src={iconMail} alt="" className="guest-profile-view__social-img" />
                                        {this.state.user?.email}
                                    </div>
                                    <div className="guest-profile-view__visibility-img-wrap">
                                        {this.state.user?.visibility &&
                                        this.state.user?.visibility?.email === 'only_me' ? (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        ) : this.state.user?.visibility?.email === 'inner_circle' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconUsers}
                                                alt=""
                                            />
                                        ) : this.state.user?.visibility?.email === 'public' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconGlobe}
                                                alt=""
                                            />
                                        ) : (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        )}
                                    </div>
                                </li>
                            ) : (
                                ''
                            )}
                            {this.state.user?.phone ? (
                                <li className="guest-profile-view__social-item">
                                    <div>
                                        <img src={iconMsg} alt="" className="guest-profile-view__social-img" />
                                        {this.state.user?.phone}
                                    </div>
                                    <div className="guest-profile-view__visibility-img-wrap">
                                        {this.state.user?.visibility &&
                                        this.state.user?.visibility.phone === 'only_me' ? (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        ) : this.state.user?.visibility?.phone === 'inner_circle' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconUsers}
                                                alt=""
                                            />
                                        ) : this.state.user?.visibility?.phone === 'public' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconGlobe}
                                                alt=""
                                            />
                                        ) : (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        )}
                                    </div>
                                </li>
                            ) : (
                                ''
                            )}
                            {this.state.user?.location ? (
                                <li className="guest-profile-view__social-item text-decoration-none">
                                    <div>
                                        <img src={iconLocation} alt="" className="guest-profile-view__social-img" />
                                        {this.state.user?.location}
                                    </div>
                                    <div className="guest-profile-view__visibility-img-wrap">
                                        {this.state.user?.visibility &&
                                        this.state.user?.visibility?.location === 'only_me' ? (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        ) : this.state.user?.visibility?.location === 'inner_circle' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconUsers}
                                                alt=""
                                            />
                                        ) : this.state.user?.visibility?.location === 'public' ? (
                                            <img
                                                className="guest-profile-view__visibility-img"
                                                src={iconGlobe}
                                                alt=""
                                            />
                                        ) : (
                                            <img className="guest-profile-view__visibility-img" src={iconLock} alt="" />
                                        )}
                                    </div>
                                </li>
                            ) : (
                                ''
                            )}
                        </ul>

                        {!this.state.profileId && (
                            <div className="guest-profile-view__followers-wrap">
                                <Link to="/relation/innerCircle" className="guest-profile-view__followers-link">
                                    <span>{this.numFormatter(this.state.summary?.totalInnerCircle)}</span>
                                    <span>Inner Circle</span>
                                </Link>
                                <Link to="/relation/following" className="guest-profile-view__followers-link">
                                    <span>{this.numFormatter(this.state.summary?.totalFollowings)}</span>
                                    <span>Following</span>
                                </Link>
                                <Link to="/relation/followers" className="guest-profile-view__followers-link">
                                    <span>{this.numFormatter(this.state.summary?.totalFollowers)}</span>
                                    <span>Followers</span>
                                </Link>
                            </div>
                        )}

                        <p className="guest-profile-view__bio">{this.state.user?.bio}</p>

                        {!this.state.profileId && (
                            <div className="guest-profile-view__btns-wrap">
                                <IconButton
                                    click={() => {
                                        this.gotoEditProfile();
                                    }}
                                    image={iconPencil}
                                    text="Edit"
                                    cssClass="guest-profile-view__icon-btn-edit guest-profile-view__icon-btn"
                                    iconClass=""
                                />
                                <IconButton
                                    image={iconSetting}
                                    text="Settings"
                                    cssClass="guest-profile-view__icon-btn"
                                    iconClass=""
                                />
                            </div>
                        )}
                        {this.state.profileId && (
                            <div className="d-flex align-items-center flex-wrap">
                                {!this.state.user?.relationship?.isFollowing && (
                                    <ButtonComponent
                                        className={'guest-room-detail__btn-follow'}
                                        name="Follow"
                                        onclick={() => this.followUnfollowUser(this.state.profileId, { follow: true })}
                                    />
                                )}
                                {this.state.user?.relationship?.isFollowing && (
                                    <IconButton
                                        data-test="past-room-home-btn"
                                        image={iconCheckmark}
                                        text="Following"
                                        cssClass="guest-room-detail__icon-btn-profile"
                                        iconClass="guest-room-detail__icon-btn-profile-icon"
                                        click={() => this.followUnfollowUser(this.state.profileId, { follow: false })}
                                    />
                                )}
                                {this.state.user?.relationship?.isFollower &&
                                    !this.state.user?.relationship?.isInnerCircle && (
                                        <ButtonComponent
                                            className={'guest-room-detail__btn-follow ml-3'}
                                            name="Add to Inner Circle"
                                            onclick={() =>
                                                this.updateInnerCircle(this.state.profileId, { innerCircle: true })
                                            }
                                        />
                                    )}
                                {this.state.user?.relationship?.isInnerCircle && (
                                    <IconButton
                                        data-test="past-room-home-btn"
                                        image={iconCheckmark}
                                        text="Inner Circle"
                                        cssClass="guest-room-detail__icon-btn-profile ml-3"
                                        iconClass="guest-room-detail__icon-btn-profile-icon"
                                        click={() =>
                                            this.updateInnerCircle(this.state.profileId, { innerCircle: false })
                                        }
                                    />
                                )}
                            </div>
                        )}
                    </Col>

                    <Col sm="6" xs="12" className="guest-profile-view__right-col">
                        {/* Rooms  */}
                        <h4 className="guest-profile-view__subheading">
                            {!this.state.profileId ? 'Last 5 Past Rooms' : 'Mutual Rooms'}
                        </h4>
                        {this.state.pastRooms && this.state.pastRooms.length ? (
                            <ul className="guest-profile-view__list">
                                {this.state.pastRooms.map(room => {
                                    const isPrivate = room.roomType === Constant.ROOM_TYPES.PRIVATE;
                                    return (
                                        <li key={room.roomId} className="guest-profile-view__item">
                                            <div>
                                                {isPrivate ? (
                                                    <img
                                                        src={iconPrivateRoom}
                                                        alt=""
                                                        className="guest-past-rooms__room-type-icon"
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                                <p className="guest-profile-view__room-time">
                                                    {this.getFormattedRoomDateTime(room)}
                                                </p>
                                                <div className="guest-profile-view__room-info">
                                                    {!isPrivate ? (
                                                        <ImageComponent
                                                            src={room.organization.logo}
                                                            placeholder={icon_placeHolder}
                                                            alt=""
                                                            className="guest-profile-view__org-img"
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                    <div>
                                                        <h2
                                                            className="guest-profile-view__room-name"
                                                            onClick={() => {
                                                                this.navigateToRoomDetail(room?.roomId);
                                                            }}
                                                        >
                                                            {room?.name}
                                                        </h2>
                                                        {!isPrivate ? (
                                                            <h5 className="guest-profile-view__board-name">
                                                                By {room.board.name}
                                                            </h5>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            'No rooms to display'
                        )}

                        {this.state.pastRooms.length > 0 && (
                            <Link
                                to={
                                    this.state.profileId
                                        ? `/guest-mutual-rooms/${this.state.user?.id}`
                                        : `/guest-past-rooms`
                                }
                                className="guest-profile-view__view-all"
                            >
                                View All
                            </Link>
                        )}

                        {/* boards */}
                        <h4 className="guest-profile-view__subheading">
                            {this.state.profileId ? 'Boards' : 'Recent Boards'}
                        </h4>

                        {this.state.userBoards && this.state.userBoards.length ? (
                            <ul className="guest-profile-view__list">
                                {this.state.userBoards &&
                                    this.state.userBoards.map(board => {
                                        return (
                                            <li className="guest-profile-view__item" key={board.boardId}>
                                                <div className="guest-profile-view__room-info">
                                                    <ImageComponent
                                                        src={board.organization.logo}
                                                        placeholder={org}
                                                        alt=""
                                                        className="guest-profile-view__org-img"
                                                    />
                                                    <div>
                                                        <h2 className="guest-profile-view__board-name-head">
                                                            {board.name}
                                                        </h2>
                                                        <h5 className="guest-profile-view__org-name">
                                                            {board.organization.name}
                                                        </h5>
                                                        <h6 className="guest-profile-view__board-members">
                                                            {board.memberCount} Members
                                                        </h6>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>
                        ) : (
                            'This user is not associated with any boards yet.'
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestProfileViewContainer);
