import React from 'react';
import './members-detail-container.style.scss';
import BackComponent from '../../../../shared/components/back-component/back-component';
import { AuthService, AWSStorageService, CloudinaryService, startLoading } from '../../../../core';
import { MembersDetailDashboardService } from '../../services';
import {
    BoardHeaderComponent,
    Constant,
    DeleteModalComponent,
    DeleteModalSuccessComponent,
    ImageComponent,
    Utility,
} from '../../../../shared';
import { connect } from 'react-redux';
import { Popover } from 'react-bootstrap';
import { MemberDataTableComponent, ModalComponent } from '../../components';
import placeholder from '../../../../assets/images/icon-placeholder.png';
import { v4 as uuidv4 } from 'uuid';
export class MembersDetailContainer extends React.Component {
    _awsStorageService = new AWSStorageService();
    _memberDetailService = new MembersDetailDashboardService();
    _authService = new AuthService();
    _utilityService = new Utility();
    _cloudinaryService = new CloudinaryService();
    constructor(props) {
        super(props);
        this.state = {
            memberDetails: true,
            showDeleteModal: false,
            showSuccessDeleteModal: false,
            modal: false,
            label: Constant.MEMBER_DETAILS_MODAL_LOGO.LABEL,
            ratio: Constant.MEMBER_DETAILS_MODAL_LOGO.RATIO,
            menuItems: Constant.MEMBER_DETAILS_MENU_ITEMS,
            paginationQueryParams: {
                pageNo: 0,
                totalRecords: 0,
            },
            formDetails: {
                userId: null,
                profilePicture: null,
                localImagePath: '',
                fullName: '',
                role: '',
                email: '',
                blurb: '',
                existingProfilePicturePath: '',
            },
            validationErrorMessage: {
                logoValidation: '',
                nameValidation: '',
                emailValidation: '',
                blurbValidation: '',
                roleValidation: '',
            },
            accessibleRoles: null,
            disabledSubmitButton: false,
            roleFieldDisabled: false,
            orgId: '',
            boardDetails: {
                boardLogo: null,
                id: null,
                name: null,
                orgLogo: null,
                orgName: null,
                subtitles: { description: null, directors: [null], producers: [null] },
            },
            members: [],
            deletionModalInfo: {
                modalHeader: Constant.MEMBER_DETAILS_DELETE_MODAL_INFO.MODAL_HEADER,
                modalParagraph: Constant.MEMBER_DETAILS_DELETE_MODAL_INFO.MODAL_PARAGRAPH,
            },
            isProducerPlus: false,
            selectedMember: null,
            deletionSuccessModalInfo: {
                modalMessage: Constant.MEMBER_DETAILS_MODAL_DELETION_MESSAGE,
            },
        };
    }

    setParams = () => {
        const param = {
            boardId: this.props.match.params.id,
        };
        return param;
    };

    getMemberBySpecifiedRole = (membersArray, role) => {
        const totalMembers = [];
        membersArray.forEach(member => {
            if (member.role === role) {
                totalMembers.push(member.name);
            }
        });
        return totalMembers;
    };

    handleBoardDetails = board => {
        const id = board.boardId;
        const name = board.name;
        const orgName = this._utilityService.getStorageItems('orgName');
        const producers = this.getMemberBySpecifiedRole(board.member, Constant.BOARD_MEMBERS.PRODUCER);
        const directors = this.getMemberBySpecifiedRole(board.member, Constant.BOARD_MEMBERS.DIRECTOR);
        const description = board.description;
        const boardLogo = board.logo;
        const orgLogo = this._utilityService.getStorageItems('orgLogo');
        return { id, name, orgName, boardLogo, orgLogo, subtitles: { producers, directors, description } };
    };

    handleBoardMembers = async member => {
        const id = member.id;
        const name = member.name ? member.name.slice(0, Constant.MEMBER_NAME_LIMIT) : Constant.MEMBER_FALLBACK.name;
        const fallBackImage = member.image && (await this._cloudinaryService.getCloudinaryURL(member.image));
        const memberColumn = (
            <div className="member-name">
                <img
                    src={member.fullAvatarUrl || fallBackImage || placeholder}
                    alt=""
                    placeholder={placeholder}
                    width="40"
                    height="40"
                    className="img-fluid member-name__img"
                />

                <p>{name}</p>
            </div>
        );
        const blurb = member.blurb || Constant.MEMBER_FALLBACK.blurb;
        const email = member.email;
        const role = Constant.MEMBER_ROLES[member.role];
        return { id, memberColumn, role, blurb, email };
    };

    setBoardDetails = (boardInfo, membersInfo) => {
        this.setState({
            boardDetails: boardInfo,
            members: membersInfo,
        });
    };

    fetchBoardDetails = async () => {
        const params = this.setParams();
        const boardDetails = await this._memberDetailService.getBoardDetails(params);
        const boardInfo = this.handleBoardDetails(boardDetails);
        boardDetails.member.forEach(mem => {
            if (!mem.name) {
                mem.name = Constant.MEMBER_FALLBACK.name;
            }
        });
        let membersInfo = boardDetails.member.sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1));
        membersInfo = await Promise.all(
            boardDetails.member.map(async member => {
                const singleMemberInfo = await this.handleBoardMembers(member);
                return singleMemberInfo;
            }),
        );
        this.setBoardDetails(boardInfo, membersInfo);
    };

    setUserInfo = async () => {
        const userInfo = await this._memberDetailService.getUser();
        this.setState({ userInfo });
    };

    fetchCompleteDetails = async () => {
        this.props.startLoading(true);
        await this.setUserInfo();
        await this.handleRoleAccess();
        await this.fetchBoardDetails();
        this.props.startLoading(false);
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', this.scrollEndHandler);
        this.setState({ orgId: this.props.match.params.orgId });
        await this.fetchCompleteDetails();
    };

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollEndHandler);
    }

    userIsRoot = async () => {
        return this.state.userInfo.isRoot;
    };

    findUserRole = async () => {
        if (await this.userIsRoot()) {
            return Constant.ROOT_USER;
        }
        const param = {
            boardId: this.props.match.params.id,
            id: this.props.match.params.orgId,
        };
        const orgMemberDetails = await this._memberDetailService.getOrganizationDetails(param);
        const boardMemberDetails = await this._memberDetailService.getBoardDetails(param);
        return this._memberDetailService.getUser().then(_successLog => {
            let memberFound = orgMemberDetails.member.find(member => member.id === _successLog.id);
            memberFound = memberFound || boardMemberDetails.member.find(member => member.id === _successLog.id);
            return memberFound ? memberFound.role : Constant.UNKNOWN_USER;
        });
    };

    handleRoleAccess = async () => {
        const role = await this.findUserRole();
        switch (role) {
            case Constant.ROOT_USER:
            case Constant.ORGANIZATION_MEMBERS.EXECUTIVE_PRODUCER:
                this.setState({
                    accessibleRoles: Constant.MEMBER_DETAILS_EP_ROOT_ACCESSIBLE_ROLES,
                    isProducerPlus: true,
                });
                break;
            case Constant.BOARD_MEMBERS.PRODUCER:
                this.setState({
                    accessibleRoles: Constant.MEMBER_DETAILS_PRODUCER_ACCESSIBLE_ROLES,
                    isProducerPlus: true,
                });
                break;
            default:
                this.props.history.replace({
                    pathname: `/unauthorized-error/${true}`,
                });

                break;
        }
    };

    initializeFormFields = () => {
        this.setState({
            ...this.state,
            modal: false,
            formDetails: {
                userId: null,
                profilePicture: null,
                localImagePath: '',
                fullName: '',
                role: '',
                defaultRole: '',
                email: '',
                blurb: '',
                existingProfilePicturePath: '',
                selectedLogo: false,
            },
            roleFieldDisabled: false,
            disabledSubmitButton: false,
            validationErrorMessage: {
                logoValidation: '',
                nameValidation: '',
                emailValidation: '',
                blurbValidation: '',
                roleValidation: '',
            },
        });
    };

    handleClose = () => {
        this.initializeFormFields();
    };

    isCurrentUser = id => {
        return this.state.userInfo.id === id;
    };

    disableRoleUpdate = () => {
        this.setState({ roleFieldDisabled: true });
    };

    setSameUserState = userId => {
        return this.isCurrentUser(userId) && this.disableRoleUpdate();
    };

    getValueForRole = role => {
        if (this.isCurrentUser(this.state.formDetails.userId)) {
            this.setDefaultRole(role);
            return '';
        }
        const accessibleRoleValues = Object.keys(this.state.accessibleRoles);
        const roleValue = accessibleRoleValues.find(accessibleRole => accessibleRole === role);
        return roleValue || accessibleRoleValues[0];
    };

    openBoardCreationModal = async id => {
        this.props.startLoading(true);
        await this.setState({ formDetails: { ...this.state.formDetails, userId: id } });
        const userId = this.state.formDetails.userId;
        const boardId = this.props.match.params.id;
        this.setSameUserState(userId);
        try {
            let { name, fullAvatarUrl, image, email, blurb, role } = await this._memberDetailService.getUserById(
                userId,
                boardId,
            );
            role = this.getValueForRole(role);

            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    fullName: name || '',
                    localImagePath: fullAvatarUrl || (image && (await this._cloudinaryService.getCloudinaryURL(image))),
                    blurb: blurb || '',
                    email,
                    role,
                    image,
                },
            });
            this.setState({ modal: true });
            this.props.startLoading(false);
        } catch (error) {
            this.props.startLoading(false);
        }
    };

    openDeletionModal = memberId => {
        this.props.startLoading(true);
        const selectedMember = this.state.members.find(member => member.id === memberId);
        const name = selectedMember.memberColumn.props.children[1].props.children;
        this.setState({
            selectedMember,
            deletionModalInfo: {
                ...this.state.deletionModalInfo,
                modalParagraph: `Are you sure you want to remove ${name} from ${this.state.boardDetails.name} board? You will need to invite them again to add them back to the board.`,
            },
            showDeleteModal: true,
        });
        this.props.startLoading(false);
    };

    removeFileFromStorage = async () => {
        if (this.state.formDetails.existingProfilePicturePath) {
            await this._awsStorageService.removeFile(this.state.formDetails.existingProfilePicturePath);
        }
    };

    setSelectedLogo = async profilePicture => {
        if (this._utilityService.validateImageFile(profilePicture.type)) {
            this.props.startLoading(true);
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    profilePicture,
                },
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            await this.removeFileFromStorage();
            const storageMetaData = await this.getStorageMetaData(this.state.formDetails.userId);
            const cloudinaryImageUrl = await this._cloudinaryService.getCloudinaryURL(storageMetaData);
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    localImagePath: cloudinaryImageUrl,
                    existingProfilePicturePath: storageMetaData,
                    cloudinaryImageKey: this._utilityService.removeImgExtension(storageMetaData),
                },
            });
            return;
        }
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, profilePicture: null, localImagePath: '' },
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: Constant.EDIT_MEMBER_MODAL_VALIDATION.logoFileValidation,
            },
        });
    };

    setCloudinaryImageUrl = src => {
        this.setState({
            ...this.state,
            formDetails: {
                ...this.state.formDetails,
                localImagePath: this._utilityService.removeImgExtension(src),
            },
        });
    };

    setFullName = fullName => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, fullName },
            validationErrorMessage: { ...this.state.validationErrorMessage, nameValidation: '' },
        });
    };

    setBlurb = blurb => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, blurb },
            validationErrorMessage: { ...this.state.validationErrorMessage, blurbValidation: '' },
        });
    };

    setDefaultRole = defaultRole => {
        this.setState({ ...this.state, formDetails: { ...this.state.formDetails, defaultRole } });
    };

    setRole = role => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, role },
            validationErrorMessage: { ...this.state.validationErrorMessage, roleValidation: '' },
        });
    };

    selectRole = async e => {
        this.setRole(e.target.value);
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    validateForm = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };

        if (!this.state.formDetails.profilePicture && !this.state.formDetails.localImagePath) {
            validationErrorMessage.logoValidation = Constant.EDIT_MEMBER_MODAL_VALIDATION.logoValidation;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.fullName.trim()) {
            validationErrorMessage.nameValidation = Constant.EDIT_MEMBER_MODAL_VALIDATION.nameExistValidation;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const re = /[`!@#$%^&*()_=\[\]{};\\|<>\/?~]/;

        if (re.test(this.state.formDetails.fullName.trim())) {
            validationErrorMessage.nameValidation =
                Constant.EDIT_MEMBER_MODAL_VALIDATION.nameSpecialCharacterValidation;
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.email) {
            return false;
        }

        return true;
    };

    submit = async (e, userId) => {
        if (!this.validateForm()) {
            return;
        }
        this.props.startLoading(true);
        try {
            const payload = {
                name: this.state.formDetails.fullName,
                blurb: this.state.formDetails.blurb,
                image: this.state.formDetails.existingProfilePicturePath || this.state.formDetails.image,
                fullAvatarUrl: this.state.formDetails.localImagePath,
                role: this.state.formDetails.role,
            };

            const boardId = this.props.match.params.id;
            await this._memberDetailService.updateProfile(payload, userId, boardId);
            this.handleClose();
            await this.fetchCompleteDetails();
        } catch (error) {
            this.props.startLoading(false);
        }
    };

    uploadFileToStorage = async () => {
        const credentials = await this._authService.getAuthCredentials();
        const cognitoId = credentials.identityId;
        const storageMetaData = await this._awsStorageService.uploadFile(
            `${cognitoId}/${uuidv4()}`,
            this.state.formDetails.profilePicture,
            this.state.formDetails.profilePicture.type,
        );
        return storageMetaData;
    };

    getStorageMetaData = async userId => {
        let storageMetaData = '';
        if (userId) {
            if (this.state.formDetails.profilePicture) {
                storageMetaData = await this.uploadFileToStorage();
            } else {
                storageMetaData = this.state.formDetails.existingProfilePicturePath;
            }
        } else {
            storageMetaData = await this.uploadFileToStorage();
        }
        return storageMetaData.key || storageMetaData;
    };

    onSelectMenu = (menuItem, memberId) => {
        switch (menuItem) {
            case 'Edit Profile':
                this.openBoardCreationModal(memberId);
                break;
            case 'Remove Member':
                this.openDeletionModal(memberId);
                break;
        }
    };

    getBoardHeading = heading => {
        return Constant.BOARD_HEADER[heading];
    };

    getDescription = (subtitles, subtitle) => {
        let boardListClass, popover, boardListClassParent;
        if (subtitle === Constant.BOARD_DETAILS_SUBTITLE_CONSTANT) {
            if (subtitles[subtitle] && subtitles[subtitle].length > 80) {
                popover = (
                    <Popover
                        id="popover-basic"
                        className="executiveproducer__popover executiveproducer__popover--width-400"
                    >
                        <Popover.Content className="board-detail__popover-content">
                            <h4 className="board-detail__popover-head">Board Description</h4>
                            <p className="board-detail__popover-desc">{subtitles[subtitle]}</p>
                        </Popover.Content>
                    </Popover>
                );
            }
            boardListClass = 'board-detail__board-description board-detail__truncate';
            boardListClassParent = 'col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-30';
        }
        return { popover, boardListClass, boardListClassParent };
    };

    onListClick = roomInfo => {};

    redirect = () => {};

    isConfirmDelete = async isConfirm => {
        if (isConfirm) {
            this.props.startLoading(true);
            const param = {
                boardId: this.props.match.params.id,
                userId: this.state.selectedMember.id,
            };
            this._memberDetailService
                .deleteMember(param)
                .then(async _successLog => {
                    if (_successLog.isSuccess) {
                        const members = this.state.members.filter(member => member.id !== this.state.selectedMember.id);
                        await this.fetchBoardDetails();
                        this.setState({
                            ...this.state,
                            members,
                            showDeleteModal: false,
                            showSuccessDeleteModal: true,
                            selectedMember: null,
                        });
                    }

                    this.props.startLoading(false);
                })
                .catch(() => {
                    this.props.startLoading(false);
                });
        } else {
            this.closeDeletionModal();
        }
    };

    closeDeletionModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            selectedMember: null,
        });
    };

    closeSuccessDeleteModal = () => {
        this.setState({
            ...this.state,
            showSuccessDeleteModal: false,
        });
    };

    render() {
        return (
            <div className="organization-detail-wrpper">
                <BackComponent id="back-link-org-details" link={`/organization/${this.state.orgId}`}></BackComponent>

                <BoardHeaderComponent
                    boardDetails={this.state.boardDetails}
                    getHeadings={this.getBoardHeading}
                    getDescription={this.getDescription}
                    boardId={this.state.boardDetails.id}
                    buttonName={Constant.MEMBER_DETAILS_BUTTON_NAME}
                    redirect={this.redirect}
                />

                <div className="organization-detail__table-wrapper board-detail__table-wrapper manage-member-table-wrapper">
                    {
                        <MemberDataTableComponent
                            isProducerPlus={this.state.isProducerPlus}
                            menuItems={this.state.menuItems}
                            data={this.state.members}
                            onClick={this.onListClick}
                            onSelectMenu={this.onSelectMenu}
                            menuItems={this.state.menuItems}
                        />
                    }
                </div>
                <ModalComponent
                    {...this.state}
                    fileHandling={{
                        setSelectedLogo: this.setSelectedLogo,
                        localImagePath: this.state.formDetails.localImagePath,
                        cloudinaryImageKey: this.state.formDetails.cloudinaryImageKey,
                        setCloudinaryImageUrl: this.setCloudinaryImageUrl,
                    }}
                    setFullName={this.setFullName}
                    setBlurb={this.setBlurb}
                    setRole={this.setRole}
                    selectRole={this.selectRole}
                    submit={this.submit}
                    handleClose={this.handleClose}
                    validateImageFileMessage={this.validateImageFileMessage}
                />
                <DeleteModalComponent
                    modalInfo={this.state.deletionModalInfo}
                    showDeleteModal={this.state.showDeleteModal}
                    isConfirmDelete={this.isConfirmDelete}
                />
                <DeleteModalSuccessComponent
                    deletionSuccessModalInfo={this.state.deletionSuccessModalInfo}
                    showSuccessDeleteModal={this.state.showSuccessDeleteModal}
                    closeSuccessDeleteModal={this.closeSuccessDeleteModal}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(null, mapDispatchToProps)(MembersDetailContainer);
