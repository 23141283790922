import { Http } from '../../../../core';

export class RoomInviteDashboardService {
    /**
     * @name getBoards
     * @desc Will fetch all boards
     * @return {Promise}
     */
    getBoards = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`invite/boards`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    console.log('failed');
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getBoardMembers
     * @param {object} params contains boardIds required for getting their members, pageIndex and pageSize required for pagination and searchQuery for searching.
     * @desc Will fetch members of a board according to params
     * @return {Promise}
     */
    getBoardMembers = params => {
        const { boardIds, pageIndex, pageSize, searchQuery } = params;
        let endPoint = boardIds
            ? `invite/users?boardIds=${boardIds}&pageIndex=${pageIndex}&pageSize=${pageSize}`
            : `invite/users?pageIndex=${pageIndex}&pageSize=${pageSize}`;
        endPoint = searchQuery ? `${endPoint}&name=${searchQuery}` : endPoint;
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(endPoint)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    console.log('failed');
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getSelectedMembersCount
     * @param {object} requestPayload contains payload getting selected members count
     * @desc Will fetch selected members count
     * @return {Promise}
     */
    getSelectedMembersCount = requestPayload => {
        let endPoint = `invite/users-count?`;
        for (const key in requestPayload) {
            if (requestPayload[key] !== null) {
                endPoint += `${key}=${requestPayload[key]}&`;
            }
        }
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(endPoint)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name sendInvite
     * @param {object} requestPayload contains payload for sending invite
     * @desc Will send invite
     * @return {Promise}
     */
    sendInvite = requestPayload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post('invite/send', requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
