import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './room-invite-table-list-component.style.scss';
import { v4 as uuidv4 } from 'uuid';
import { Constant } from '../../../../shared';

const roomInviteTableListComponent = props => {
    const { id, selectedRole, isSelected, ...info } = props.memberInfo;
    return (
        <React.Fragment>
            <td
                id="table-meatball-menu"
                data-test="member-table-body-row-data-render"
                className="room-invite__table-cel-body room-invite__table-cel-body--checkbox"
            >
                <label className="custom-checkbox">
                    <input
                        className="custom-checkbox__input"
                        type="checkbox"
                        checked={isSelected}
                        onChange={e => props.onCheckboxChange(e, id)}
                    />
                    <span className="custom-checkbox__checkmark"></span>
                </label>
            </td>
            <td
                id={`member-table-body-row-data-member-column`}
                className="room-invite__table-cel-body room-invite__table-cel-body--member"
                data-test="member-table-body-row-data-render"
            >
                {info.memberColumn}
            </td>
            <td
                id={`member-table-body-row-data-blurb`}
                className="room-invite__table-cel-body room-invite__table-cel-body--blurb"
                data-test="member-table-body-row-data-render"
            >
                {info.blurb}
            </td>
            <td
                id={`member-table-body-row-data-blurb`}
                className="room-invite__table-cel-body room-invite__table-cel-body--email"
                data-test="member-table-body-row-data-render"
            >
                {info.email}
            </td>
            <td
                id="table-meatball-menu"
                data-test="member-table-body-row-data-render"
                className="room-invite__table-cel-body room-invite__table-cel-body--role"
            >
                <Dropdown className="room-invite__dropdown">
                    <Dropdown.Toggle as="div" className="room-invite__dropdown-toggle" id="roleDropdown">
                        {Constant.ROOM_INVITE_ROLES[selectedRole]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="room-invite__dropdown-menu">
                        {props.roleOptions.map(role => {
                            return (
                                <label key={uuidv4()} className="custom-radio">
                                    <span className="custom-radio__text">{Constant.ROOM_INVITE_ROLES[role]}</span>
                                    <input
                                        type="radio"
                                        className="custom-radio__input"
                                        value={role}
                                        onChange={e => props.selectRole(e, id)}
                                        checked={selectedRole === role}
                                        name="role"
                                    />
                                    <span className="custom-radio__checkmark"></span>
                                </label>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </React.Fragment>
    );
};

export default roomInviteTableListComponent;
