import React, { Component } from 'react';
import './registration-redirection-style.scss';
import viewOff from '../../../assets/images/ic-view-off.svg';
import viewOn from '../../../assets/images/ic-view-on.svg';
import iconInfo from '../../../assets/images/icon-info.svg';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FileSelectComponent, InputComponent, Utility, Constant } from '../../../shared';
import { DateTime } from 'luxon';
import { AuthService, UserRegister, AWSStorageService, startLoading, CloudinaryService } from '../..';
import { connect } from 'react-redux';
import { showHideErrorToaster } from '../../';

export class RegistrationRedirectionContainer extends Component {
    _authService = new AuthService();
    _utilityService = new Utility();
    _userRegister = new UserRegister();
    _awsStorageService = new AWSStorageService();
    _cloudinaryService = new CloudinaryService();

    constructor(props) {
        super(props);

        this.state = {
            formDetails: {
                avatar: null,
                avatarPath: '',
                fullName: '',
                email: '',
                blurb: '',
                password: '',
                confirmPassword: '',
                showPassword: false,
                showConfirmPassword: false,
                existingProfilePicturePath: '',
                token: null,
            },
            validationErrorMessage: {
                logoValidation: '',
                nameValidation: '',
                emailValidation: '',
                blurbValidation: '',
                passwordValidation: '',
                confirmPasswordValidation: '',
            },
            buttonDisabled: false,
            isSuccessfullySubmitted: false,
            isAlreadyRegistered: false,
            yearNow: DateTime.now()
                .toFormat('yyyy')
                .toString(),
        };
    }

    showHideLoader = show => {
        this.props.startLoading(show);
    };

    componentDidMount = async () => {
        try {
            this.showHideLoader(true);
            const verificationToken = new URLSearchParams(this.props.location.search).get('token');
            if (!verificationToken) {
                throw { message: 'Invalid email verification link.' };
            }
            const verificationResponse = await this._userRegister.userEmailVerify({ jwtToken: verificationToken });

            this.setState({
                ...this.state,
                formDetails: { ...this.state.formDetails, email: verificationResponse.email, token: verificationToken },
            });
            this.showHideLoader(false);
        } catch (error) {
            let errMsg = 'Something went wrong.';
            if (error.response.status === 409) {
                errMsg = 'The email has already been used for registered member.';
            } else if (error.response.status === 400) {
                errMsg = 'Your registration link has expired. Please generate a new link and try again.';
            }
            this.props.showHideErrorToaster({
                show: true,
                message: errMsg,
                bgColor: '#ff3547',
            });
            this.redirectRegisteredUser();
            this.showHideLoader(false);
        }
    };

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    setSelectedAvatar = async avatar => {
        const image = this._utilityService.validateImageFile(avatar, Constant.FILE_UPLOAD_LIMIT);
        if (image.isValid) {
            this.props.startLoading(true);
            await this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    avatar,
                },
                validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
            });
            //  const module = this.props.match.params.module;
            const signedObject = await this._userRegister.getSignedUrlForUser(this.state.formDetails.avatar.type);

            await this._awsStorageService.uploadFileWithoutAuthentication(
                signedObject.signedUrl,
                this.state.formDetails.avatar,
            );
            const cloudinaryImageUrl = await this._cloudinaryService.getCloudinaryURL(signedObject.key);
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    avatarPath: cloudinaryImageUrl,
                    existingProfilePicturePath: signedObject.key,
                    cloudinaryImageKey: this._utilityService.removeImgExtension(signedObject.key),
                },
            });
            return;
        }

        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, avatar: null, avatarPath: '' },
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: image.message,
            },
        });
    };

    setCloudinaryImageUrl = src => {
        setTimeout(() => {
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    avatarPath: this._utilityService.removeImgExtension(src),
                },
            });
        }, 2000);
    };

    setFullName = fullName => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, fullName },
            validationErrorMessage: { ...this.state.validationErrorMessage, nameValidation: '' },
        });
    };

    setBlurb = blurb => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, blurb },
            validationErrorMessage: { ...this.state.validationErrorMessage, blurbValidation: '' },
        });
    };

    setPassword = password => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, password },
            validationErrorMessage: { ...this.state.validationErrorMessage, passwordValidation: '' },
        });
    };

    setConfirmPassword = confirmPassword => {
        this.setState({
            ...this.state,
            formDetails: { ...this.state.formDetails, confirmPassword },
            validationErrorMessage: { ...this.state.validationErrorMessage, confirmPasswordValidation: '' },
        });
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    validateForm = () => {
        const validationErrorMessage = { ...this.state.validationErrorMessage };

        if (!this.state.formDetails.avatar) {
            validationErrorMessage.logoValidation = 'Please select the profile picture.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.fullName.trim()) {
            validationErrorMessage.nameValidation = 'Please write your full name.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        // eslint-disable-next-line no-useless-escape
        const re = /[`!@#$%^&*()_=\[\]{};\\|<>\/?~]/;

        if (re.test(this.state.formDetails.fullName.trim())) {
            validationErrorMessage.nameValidation = 'Special characters are not allowed in Full Name.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.email) {
            return false;
        }

        if (!this.state.formDetails.password) {
            validationErrorMessage.passwordValidation = 'Please type the password.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this._utilityService.validatePassword(this.state.formDetails.password)) {
            validationErrorMessage.passwordValidation =
                'Please make sure password should be of 8 characters with at least 1 digit and 1 uppercase letter.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.confirmPassword) {
            validationErrorMessage.confirmPasswordValidation = 'Please type the confirm password.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (this.state.formDetails.password !== this.state.formDetails.confirmPassword) {
            validationErrorMessage.confirmPasswordValidation =
                'Please make sure the password and confirm password fields are same.';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        return true;
    };

    redirectRegisteredUser = (isAlreadyRegistered = false) => {
        this.setState({ ...this.state, isSuccessfullySubmitted: true, isAlreadyRegistered });
        this._authService.logout();
        setTimeout(() => {
            this.props.history.replace('/guest-login');
        }, 1000);
    };

    submit = async () => {
        if (!this.validateForm()) {
            return;
        }

        this.showHideLoader(true);
        try {
            const payload = {
                token: this.state.formDetails.token,
                name: this.state.formDetails.fullName,
                password: this.state.formDetails.password,
                blurb: this.state.formDetails.blurb,
                image: this.state.formDetails.existingProfilePicturePath,
                fullAvatarUrl: this.state.formDetails.avatarPath,
            };
            await this._userRegister.registerUser(payload);

            this.showHideLoader(false);
            this.redirectRegisteredUser();
        } catch (error) {
            if (error.response.status === 400) {
                const isAlreadyRegistered = true;
                this.redirectRegisteredUser(isAlreadyRegistered);
            } else {
                console.error({ error });
            }
            this.showHideLoader(false);
        }
    };

    popover = () => {
        return (
            <Popover id="popover-basic" className="executiveproducer__popover">
                <Popover.Content className="ep__popovercontent">
                    <ul className="role__list">
                        <li className="role__list__item">
                            <b>Minimum length,</b> which must be at least 8 characters
                        </li>
                        <li className="role__list__item">
                            <b>Require numbers</b>
                        </li>
                        <li className="role__list__item">
                            <b>Require uppercase letters</b>
                        </li>
                        <li className="role__list__item">
                            <b>Require lowercase letters</b>
                        </li>
                    </ul>
                </Popover.Content>
            </Popover>
        );
    };

    render() {
        const popover = this.popover();
        return (
            <>
                <Form>
                    <div className="guest-register">
                        <div className="guest-register__block">
                            <div className="br__register-form-wrapper" data-test="register_form">
                                <FileSelectComponent
                                    id={'user-register'}
                                    ratio="1:1"
                                    isProfilePicture={true}
                                    validateImageFileMessage={this.validateImageFileMessage}
                                    fileHandling={{
                                        setSelectedLogo: this.setSelectedAvatar,
                                        localImagePath: this.state.formDetails.avatarPath,
                                        cloudinaryImageKey: this.state.formDetails.cloudinaryImageKey,
                                        setCloudinaryImageUrl: this.setCloudinaryImageUrl,
                                    }}
                                    validationErrorMessage={{ ...this.state.validationErrorMessage }}
                                />

                                <Form.Group
                                    data-test="full_name_group"
                                    className="br__form-group form-group--badge"
                                    controlId="formFullName"
                                >
                                    <Form.Label className="br__form-label" data-test="full_name_label">
                                        Full Name
                                    </Form.Label>
                                    <InputComponent
                                        data-test="full_name"
                                        onChange={this.setFullName}
                                        errorMessage={this.state.validationErrorMessage.nameValidation}
                                        classes={'br__form-control'}
                                        value={this.state.formDetails.fullName}
                                        type={'text'}
                                        charLimit={Constant.USER_FULL_NAME_LIMIT}
                                    />
                                </Form.Group>

                                <Form.Group className="br__form-group" data-test="email_group" controlId="formEmail">
                                    <Form.Label className="br__form-label" data-test="email_label">
                                        Email Address
                                    </Form.Label>
                                    <InputComponent
                                        data-test="email"
                                        disable={true}
                                        value={this.state.formDetails.email}
                                        classes={'br__form-control'}
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="br__form-group form-group--badge"
                                    data-test="blurb_form_group"
                                    controlId="formBlurb"
                                >
                                    <Form.Label className="br__form-label" data-test="blurb_form_label">
                                        Blurb
                                    </Form.Label>
                                    <InputComponent
                                        data-test="blurb"
                                        type="description_box"
                                        value={this.state.formDetails.blurb}
                                        charLimit={100}
                                        onChange={this.setBlurb}
                                        errorMessage={this.state.validationErrorMessage.blurbValidation}
                                        classes={'br__form-control'}
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="br__form-group"
                                    data-test="pass_form_group"
                                    controlId="formPassword"
                                >
                                    <Form.Label className="br__form-label" data-test="pass_form_label">
                                        Password
                                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
                                            <img id="" className="label__popover" src={iconInfo} alt="info" />
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <div className="br__input-badge-wrapper">
                                        <InputComponent
                                            data-test="password"
                                            fieldType="password"
                                            type={this.state.formDetails.showPassword ? 'text' : 'password'}
                                            value={this.state.formDetails.password}
                                            onChange={this.setPassword}
                                            errorMessage={this.state.validationErrorMessage.passwordValidation}
                                            classes={'br__form-control br__form-control--badge'}
                                        />
                                        <img
                                            onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    formDetails: {
                                                        ...this.state.formDetails,
                                                        showPassword: !this.state.formDetails.showPassword,
                                                    },
                                                });
                                            }}
                                            src={this.state.formDetails.showPassword ? viewOff : viewOn}
                                            alt=""
                                            className="br__input-badge"
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group
                                    className="br__form-group"
                                    data-test="conf_pass_form_group"
                                    controlId="formFullName"
                                >
                                    <Form.Label className="br__form-label" data-test="conf_pass_form_label">
                                        Confirm Password
                                    </Form.Label>
                                    <div className="br__input-badge-wrapper">
                                        <InputComponent
                                            data-test="confirm_password"
                                            fieldType={'password'}
                                            value={this.state.formDetails.confirmPassword}
                                            type={this.state.formDetails.showConfirmPassword ? 'text' : 'password'}
                                            onChange={this.setConfirmPassword}
                                            errorMessage={this.state.validationErrorMessage.confirmPasswordValidation}
                                            classes={'br__form-control br__form-control--badge'}
                                        />
                                        <img
                                            onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    formDetails: {
                                                        ...this.state.formDetails,
                                                        showConfirmPassword: !this.state.formDetails
                                                            .showConfirmPassword,
                                                    },
                                                });
                                            }}
                                            src={this.state.formDetails.showConfirmPassword ? viewOff : viewOn}
                                            alt=""
                                            className="br__input-badge"
                                        />
                                    </div>
                                </Form.Group>

                                <Button
                                    data-test="save_btn"
                                    className="br__btn-theme-filled guest-register__btn-register d-block"
                                    onClick={e => {
                                        this.submit();
                                    }}
                                >
                                    Register
                                </Button>
                                <div className="text-center">
                                    <div className="guest-register__need-help">
                                        {' '}
                                        <a
                                            data-test="need_help"
                                            href="https://boardroomone.notion.site/boardroomone/Organization-Management-2bca263b20f34f16b66d5ec6d9d2a186"
                                            target="_blank"
                                            onMouseDown={e => {
                                                e.preventDefault();
                                            }}
                                        >
                                            Need help?
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(null, mapDispatchToProps)(RegistrationRedirectionContainer);
