import { Http } from '../http/http';
import { store } from '../../';
import { setUser } from '../../store';
import { Utility, Constant } from '../../../shared';
export class UserService {
    _utilityService = new Utility();
    /**
     * @name getUser
     * @desc will fetch loggedIn user
     * @return {Promise}
     */
    getUser = () => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`user/me`)
                .then(async _successLog => {
                    resolve(_successLog.data);
                    store.dispatch(setUser(_successLog.data));
                })
                .catch(e => reject(e.toString));
        });
    };

    /**
     * @name createAnonymousUser
     * @desc will return token for recent created anonymous user
     * @return {Promise<String>}
     */
    createAnonymousUser = requestPayload => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.post('/user/anonymous', requestPayload)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name isAnonymous
     * @desc will return true is user is anonymous
     * @return {Boolean}
     */
    isAnonymous = requestPayload => {
        return !!this._utilityService.getSessionStorageItems(Constant.SESSION_STORAGE_KEYS.IS_ANONYMOUS);
    };
}
