import React from 'react';
import { Modal } from 'react-bootstrap';
import ButtonComponent from '../../../../shared/components/button-component/button-component';
import './screen-reshare-confirmation-modal.style.scss';

const ScreenReshareConfirmationModal = props => {
    return (
        <>
            <Modal
                className="reshare-confirmation-modal"
                data-test="createOrganization__modal"
                centered
                show={props.show}
                onHide={props.handleClose}
            >
                <Modal.Body className="reshare-confirmation-modal__body">
                    <p className="reshare-confirmation-modal__heading co__heading">
                        {props.name} is currently sharing their screen. Are you sure you want to share your screen?
                    </p>
                </Modal.Body>
                <Modal.Footer className="comodal__footer">
                    <ButtonComponent
                        id={'reshare-confirmation-modal-component-button-close'}
                        className={'btn__link'}
                        name="Cancel"
                        onclick={props.handleClose}
                    />
                    <ButtonComponent
                        id={'reshare-confirmation-modal-component-button-submit'}
                        className={'br__btn-theme-filled br__btn-theme-filled--width-93'}
                        name="Yes"
                        onclick={props.reshareScreen}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ScreenReshareConfirmationModal;
