import { Http } from '..';
import { Utility } from '../../../shared';
import { AWSStorageService } from '..';
export class CloudinaryService {
    _utilityService = new Utility();
    _awsStorageServicee = new AWSStorageService();

    /**
     * @name getCloudinaryURL
     * @param {path} for generating cloudinary url
     * @param {file} file string
     * @param {fileTyoe} file type string specifying the url for cloudinary access
     * @return {routes array[]}
     */

    getCloudinaryURL = storageMetaData => {
        var imageId = storageMetaData;
        console.log('image url', imageId);
        console.log(storageMetaData);
        const imageUrl = `https://boardroom1-dev-bucket.s3.us-east-1.amazonaws.com/public/${imageId}`;
        console.log('final iamge url', imageUrl);
        return imageUrl;
    };

    /**
     * @name getCloudinaryPublicURL
     * @param {path} for generating cloudinary url for public dir
     * @param {image_path} file string
     * @param {string} image_path type string specifying the url for cloudinary access
     * @return {Promise<string>}
     */

    getCloudinaryPublicURL = storageMetaData => {
        var imageId = storageMetaData;
        console.log('image url', imageId);
        console.log(storageMetaData);
        const imageUrl = `https://boardroom1-dev-bucket.s3.us-east-1.amazonaws.com/public/${imageId}`;
        console.log('final iamge url', imageUrl);
        return imageUrl;
        //    const image = await this._awsStorageService.downloadFile(imageId);
        //       console.log("image id from aws",imageId)
        //       if (image && image.Body) {
        //             return URL.createObjectURL(image.Body);
    };

    // return this._utilityService.getSessionStorageItems('cloudinaryBaseUrl')
    //     ? [
    //           JSON.parse(this._utilityService.getSessionStorageItems('cloudinaryBaseUrl')).cloudinaryPublicBaseUrl,
    //           '/',
    //           storageMetaData,
    //       ].join('')
    //     : null;

    /**
     * @name fetchCloudinaryWebhook
     * @param {path} for removing image extension (.png, .jpg)
     * @param {file} file string
     * @param {fileTyoe} file type string specifying the url for cloudinary access
     * @return {routes array[]}
     */

    fetchCloudinaryWebhook = key => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`/user/getImageStatus?key=${key}`)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
