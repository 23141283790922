import React from 'react';
import './error-message-component.style.scss';

const errorMessageComponent = props => {
    const { message, id } = { ...props };

    return (
        <span id={id || 'error-message'} className="danger-error-message">
            {message}
        </span>
    );
};

export default errorMessageComponent;
