import { Http } from '../../../../core';

export class GuestPastRoomService {
    /**
     *
     * @name getRoomInvites
     * @desc this will be return the list of rooms
     * @return {Promise}
     */
    getPastRooms = (pageNo, pageSize, optionalQueryParams = {}) => {
        let URL = `/room/past-invites?pageIndex=${pageNo}&pageSize=${pageSize}`;
        for (let q in optionalQueryParams) {
            const value = optionalQueryParams[q];
            if (value) {
                URL = URL.concat('&', `${q}=${value}`);
            }
        }
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(URL)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(null);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };

    /**
     * @name getUserMutualRooms
     * @desc Returns the list of mutual rooms of a given user to the current user.
     * @return {Promise}
     */
    getUserMutualRooms = (userId, pageNo, pageSize, optionalQueryParams = {}) => {
        let URL = `/user/mutual-rooms/${userId}?pageIndex=${pageNo}&pageSize=${pageSize}`;
        for (let q in optionalQueryParams) {
            const value = optionalQueryParams[q];
            if (value) {
                URL = URL.concat('&', `${q}=${value}`);
            }
        }
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(URL)
                .then(_successLog => {
                    _successLog ? resolve(_successLog.data) : reject(false);
                })
                .catch(errorLog => {
                    reject(errorLog);
                });
        });
    };
}
