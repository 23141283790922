import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs, Tab } from 'react-bootstrap';
import './image-library-component.style.scss';
import { Form } from 'react-bootstrap';
import iconCancel from '../../../assets/images/ic-cancel.svg';
import { InputComponent, ButtonComponent } from '..';
import { Constant } from '../../services';
import { CommonService } from '../../../core';

const ImageLibraryComponent = props => {
    const _commonService = new CommonService();
    const [selectedImage, setSelectedImage] = useState(null);
    const [isRecentlyUsed, setRecentlyUsed] = useState(false);
    const [key, setKey] = useState(Constant.LIBRARY_OPTIONS.LIBRARY);

    const onScrollDown = e => {
        let element = e.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            props.onImageScrollDown();
        }
    };

    useEffect(() => {
        if (key === Constant.LIBRARY_OPTIONS.RECENT) {
            props.getRecentImages();
        }
    }, [key]);

    const onHide = () => {
        setSelectedImage(null);
        props.closeLibraryModal();
    };

    const getSplashImageUrl = async imageDownloadUrl => {
        try {
            props.startLoading(true);
            setRecentlyUsed(false);
            const resp = await _commonService.downloadSplashImage(imageDownloadUrl);
            props.startLoading(false);
            if (resp.url) {
                props.selectImage(resp.url);
            }
        } catch (error) {
            props.startLoading(false);
            props.showHideErrorToaster({
                show: true,
                message: 'Network Error',
                bgColor: '#ff3547',
            });
        }
    };

    return (
        <>
            <Modal
                className="theme-modal image-library-modal"
                data-test="delete_success_modal"
                centered
                show={props.isLibraryModalOpen}
                onHide={onHide}
            >
                <div
                    onClick={() => {
                        onHide();
                    }}
                    data-test="close_modal_div"
                >
                    <img
                        src={iconCancel}
                        className="br__create-organization-icon-close"
                        alt=""
                        data-test="cancel_icon"
                    />
                </div>
                <Modal.Body className="theme-modal__body">
                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={k => setKey(k)} className="mb-3">
                        <Tab eventKey="lib" title="Unsplash">
                            <Form.Group controlId="formDescription" data-test="form_group_description">
                                <Form.Label className="image-label">Search</Form.Label>
                                <InputComponent
                                    value={props.searchedImage}
                                    onChange={props.onImageSearchChange}
                                    textId={'room-container-input-description'}
                                    placeholder={'Search for business, art, digital, & more.'}
                                    classes={'image-search'}
                                />
                            </Form.Group>
                            {props.searchedImage && !props.libraryImages.length && !props.isSearching ? (
                                <p className="image-lib__not-found">No image found</p>
                            ) : (
                                <Row className="image-row" id="libraryImages" onScroll={onScrollDown}>
                                    {props.libraryImages.map((image, index) => {
                                        return (
                                            <Col
                                                md="6"
                                                className="mb-3"
                                                key={index}
                                                onClick={() => {
                                                    setSelectedImage(image.links.download_location);
                                                }}
                                            >
                                                <img
                                                    className={`img-fluid library-img ${
                                                        selectedImage === image.links.download_location
                                                            ? 'library-img--active'
                                                            : ''
                                                    }`}
                                                    src={image.urls.small}
                                                />
                                                <p className="library-credits">
                                                    Photo by{' '}
                                                    <a
                                                        className="library-link"
                                                        href={`https://unsplash.com/@${image.user.username}?utm_source=BoardroomOne&utm_medium=referral`}
                                                        target="_blank"
                                                    >
                                                        {image.user.first_name}&nbsp;{image.user.last_name}
                                                    </a>
                                                </p>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            )}
                        </Tab>
                        <Tab eventKey="recently" title="Recently Used">
                            <Row className="image-row" id="recentImages">
                                {props.recentImages.map((image, index) => {
                                    return (
                                        <Col
                                            md="6"
                                            className="text-center"
                                            key={index}
                                            onClick={() => {
                                                setRecentlyUsed(true);
                                                setSelectedImage(image);
                                            }}
                                        >
                                            <img
                                                className={`img-fluid library-img ${
                                                    selectedImage === image ? 'library-img--active' : ''
                                                }`}
                                                src={image}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer className="comodal__footer">
                    <ButtonComponent
                        className={'btn__link'}
                        name="Cancel"
                        onclick={() => {
                            props.closeLibraryModal();
                        }}
                    />
                    <ButtonComponent
                        disabledSubmitButton={!selectedImage}
                        className={'br__btn-theme-filled br__btn-theme-filled--width-93'}
                        name="Select"
                        onclick={() => {
                            !isRecentlyUsed ? getSplashImageUrl(selectedImage) : props.selectImage(selectedImage);
                            setRecentlyUsed(false);
                        }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ImageLibraryComponent;
