import React from 'react';
import { Table } from 'react-bootstrap';
import { MemberTableListComponent } from '..';
import './member-data-table-component.style.scss';

const memberDataTableComponent = props => {
    return (
        <div
            className="board-label"
            style={{
                color: 'black',
            }}
        >
            <Table
                id="member-data-table"
                className="organization__board-listing-table manage-member-table"
                data-test="member-table_render"
            >
                <thead id="member-table-header" className="board-listing__head" data-test="member-table_header_render">
                    <tr
                        id="member-table-header-row"
                        className="board-listing__row--head"
                        data-test="member-table_header_row_render"
                    >
                        <td
                            id={`member-table-header-row-data-01`}
                            data-test="member-table_header_row_data_render"
                            className="board-listing__cel--head"
                        >
                            MEMBERS
                        </td>
                        <td
                            id={`member-table-header-row-data-02`}
                            data-test="member-table_header_row_data_render"
                            className="board-listing__cel--head"
                        >
                            ROLE
                        </td>
                        <td
                            id={`member-table-header-row-data-03`}
                            data-test="member-table_header_row_data_render"
                            className="board-listing__cel--head"
                        ></td>
                        <td
                            id={`member-table-header-row-data-04`}
                            data-test="member-table_header_row_data_render"
                            className="board-listing__cel--head"
                        >
                            EMAIL ADDRESS
                        </td>
                        <td
                            id={`member-table-header-row-data-05`}
                            data-test="member-table_header_row_data_render"
                            className="board-listing__cel--head"
                        ></td>
                    </tr>
                </thead>
                <tbody
                    id="table-body"
                    className="board-listing__body manage-member-table__body"
                    data-test="member_table_body_render"
                >
                    {props.data &&
                        props.data.map((singleMember, index) => {
                            const menuItems = [...props.menuItems];
                            if (!props.isProducerPlus) {
                                const index = menuItems.findIndex(item => item.name === 'Remove Member');
                                if (index >= 0) {
                                    menuItems.splice(index, 1);
                                }
                            }
                            return (
                                <tr
                                    key={singleMember.id}
                                    id={`member-table-body-row-${index + 1}`}
                                    className="board-listing__row manage-member-table__row"
                                    data-test="member_table_body_row_render"
                                >
                                    <MemberTableListComponent
                                        memberInfo={singleMember}
                                        menuItems={menuItems}
                                        onSelectMenu={props.onSelectMenu}
                                        onClick={props.onClick}
                                        index={index}
                                    />
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};

export default memberDataTableComponent;
