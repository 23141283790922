import { Auth } from 'aws-amplify';
import { store } from '../../';
import { setAccessToken, setUser } from '../../store';
import { Exception, Constant, Utility } from '../../../shared';

export class AuthService {
    _utilityService = new Utility();
    constructor() {
        Auth.configure({
            authenticationFlowType:
                Constant.AUTHENTICATION_CONSTANT.AUTHENTICATION_FLOW_TYPE.USER_SRP_AUTH |
                Constant.AUTHENTICATION_CONSTANT.AUTHENTICATION_FLOW_TYPE.CUSTOM_AUTH,
        });
    }

    async login(user) {
        try {
            await Auth.signIn(user.email, user.password);
            store.dispatch(setAccessToken(await this.getAuthToken()));
        } catch (e) {
            throw new Exception(Constant.AUTH_EXCEPTION.TYPE, Constant.AUTH_EXCEPTION.MESSAGE);
        }
    }

    async doPasswordLessLogin(email, challengeResponse) {
        try {
            const signinResponse = await Auth.signIn(email);
            if (
                signinResponse.authenticationFlowType ===
                Constant.AUTHENTICATION_CONSTANT.AUTHENTICATION_FLOW_TYPE.CUSTOM_AUTH
            ) {
                await Auth.sendCustomChallengeAnswer(signinResponse, challengeResponse);
                store.dispatch(setAccessToken(await this.getAuthToken()));
            }
        } catch (e) {
            throw new Exception(Constant.AUTH_EXCEPTION.TYPE, Constant.AUTH_EXCEPTION.MESSAGE);
        }
    }

    clearSessionStorage = () => {
        this._utilityService.removeAllItemsFromSessionStorage();
    };

    async logout() {
        this.clearSessionStorage();
        await Auth.signOut();
        store.dispatch(setAccessToken(''));
        store.dispatch(setUser(''));
    }

    async logoutWithoutClearingStore() {
        this.clearSessionStorage();
        await Auth.signOut();
    }

    async getAuthToken() {
        const session = await Auth.currentSession();
        if (session && session.accessToken && session.accessToken.jwtToken) {
            return session.accessToken.jwtToken;
        }
        return null;
    }

    async getAuthCredentials() {
        return await Auth.currentUserCredentials();
    }

    async answerCustomChallenge(cognitoUser, answer) {
        await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
        try {
            await Auth.currentSession();
        } catch {
            console.log('Apparently the user did not enter the right code');
        }
    }

    async forgotPassword(email) {
        try {
            await Auth.forgotPassword(email);
        } catch (error) {
            return error;
        }
    }

    async forgotPasswordSubmit(email, code, new_password) {
        try {
            return await Auth.forgotPasswordSubmit(email, code, new_password);
        } catch (error) {
            return error;
        }
    }

    async getCurrentAuthenticatedUser() {
        try {
            return await Auth.currentAuthenticatedUser();
        } catch (error) {
            return null;
        }
    }
}
