import React from 'react';
import { startLoading } from '../..';
import { AuthService, AWSStorageService, showHideErrorToaster, CloudinaryService } from '../..';
import { Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png';
import iconArrowRight from '../../../assets/images/ic-arrow-right.svg';
import iconLock from '../../../assets/images/icon-lock.png';
import iconUsers from '../../../assets/images/ic-users.png';
import iconGlobe from '../../../assets/images/icon-globe.png';
import './guest-profile-edit.style.scss';
import { Constant, ErrorMessageComponent, FileSelectComponent, Utility, IconButton } from '../../../shared';
import { DateTime } from 'luxon';
import { Auth } from 'aws-amplify';
import { CommonService } from '../../services/common/common';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { GuestRoomDetailService } from '../../../content/guest-room-detail/services';
import { UserService } from '../../services';
import { Link } from 'react-router-dom';
import GuestHeader from '../../components/guest-header/guest-header';

export class GuestProfileEditContainer extends React.Component {
    _authService = new AuthService();
    _commonService = new CommonService();
    _awsStorageService = new AWSStorageService();
    _utilityService = new Utility();
    _cloudinaryService = new CloudinaryService();
    _guestRoomDetailService = new GuestRoomDetailService();
    _userService = new UserService();

    roomId = null;
    userId = null;
    isComponentUpdated = false;

    constructor(props) {
        super(props);
        this.state = {
            formDetails: {
                name: '',
                email: '',
                blurb: '',
                fullAvatarUrl: null,
                localImagePath: null,
                existingLogoPath: null,
                linkedin: '',
                location: '',
                phone: '',
                bio: '',
                innerCircleOnlyEmail: false,
                innerCircleOnlyLinkedin: false,
                innerCircleOnlyLocation: false,
                innerCircleOnlyPhone: false,
                visibility: {
                    email: 'public',
                    phone: 'public',
                    linkedin: 'public',
                    location: 'public',
                    bio: 'public',
                },
            },
            buttonDisabled: false,
            existingLogoPath: null,
            userImage: null,
            localImagePath: null,
            validationErrorMessage: {
                logoValidation: '',
                memberNameValidation: '',
                phoneValidation: '',
            },

            visibilityOptions: [
                { icon: iconLock, value: 'only_me', text: 'Visible to me only' },
                { icon: iconUsers, value: 'inner_circle', text: 'Visible to inner circle' },
                { icon: iconGlobe, value: 'public', text: 'Visible to everyone' },
            ],
        };
    }

    setLogo = async imageId => {
        if (imageId !== '') {
            const logo = await this.getImageSrc(imageId);
            return logo;
        }
        return null;
    };

    getImageSrc = async imageId => {
        const image = await this._awsStorageService.downloadFile(imageId);

        if (image && image.Body) {
            return URL.createObjectURL(image.Body);
        }
        return null;
    };

    componentDidMount = () => {
        this.getTokenFromQuery();
        this.setUserEmail();
    };

    getTokenFromQuery = () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        this.roomId = queryParams.get('roomId') || null;
    };

    componentDidUpdate() {
        if (this.isComponentUpdated) {
            return;
        }
        this.setUserEmail();
    }

    setUserEmail = async () => {
        if (!this.props.user.email) {
            return;
        }
        this.props.startLoading(true);
        this.userId = this.props.user.id;

        this.setState({
            ...this.state,
            formDetails: {
                ...this.formDetails,
                email: this.props.user.email,
                name: this.props.user.name,
                blurb: this.props.user.blurb,
                linkedin: this.props.user.linkedin,
                location: this.props.user.location,
                phone: this.props.user.phone,
                bio: this.props.user.bio,
                innerCircleOnlyEmail: this.props.user.innerCircleOnlyEmail,
                innerCircleOnlyLinkedin: this.props.user.innerCircleOnlyLinkedin,
                innerCircleOnlyLocation: this.props.user.innerCircleOnlyLocation,
                innerCircleOnlyPhone: this.props.user.innerCircleOnlyPhone,
                localImagePath: this.props.user.fullAvatarUrl,
                existingLogoPath: this.props.user.image,
                visibility: {
                    email: (this.props.user.visibility && this.props.user.visibility.email) || 'public',
                    phone: (this.props.user.visibility && this.props.user.visibility.phone) || 'public',
                    linkedin: (this.props.user.visibility && this.props.user.visibility.linkedin) || 'public',
                    location: (this.props.user.visibility && this.props.user.visibility.location) || 'public',
                    bio: (this.props.user.visibility && this.props.user.visibility.bio) || 'public',
                },
            },
        });
        this.isComponentUpdated = true;
        this.props.startLoading(false);
    };

    setErrorMessages = validationErrorMessage => {
        this.setState({ ...this.state, validationErrorMessage });
    };

    validateForm = () => {
        const validationErrorMessage = {
            ...this.state.validationErrorMessage,
            logoValidation: '',
            memberNameValidation: '',
            phoneValidation: '',
        };

        if (!this.state.formDetails.localImagePath || !this.state.formDetails.existingLogoPath) {
            validationErrorMessage.logoValidation = 'Please select the profile picture';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        if (!this.state.formDetails.name || !this.state.formDetails.name.trim()) {
            validationErrorMessage.memberNameValidation = 'Name is required';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        const re = /^[0-9.+]*$/;

        if (!re.test(this.state.formDetails.phone)) {
            validationErrorMessage.phoneValidation = 'Please select numbers only';
            this.setErrorMessages(validationErrorMessage);
            return false;
        }

        return true;
    };

    handleInputChange(e) {
        const { name, value } = e.target;

        this.setState({
            ...this.state,
            formDetails: {
                ...this.state.formDetails,
                [name]: value,
            },
        });
    }

    async signIn(email) {
        return await Auth.signIn(email);
    }

    setSelectedLogo = async image => {
        try {
            if (this._utilityService.validateImageFile(image.type)) {
                this.props.startLoading(true);
                await this.setState({
                    ...this.state,
                    userImage: image,
                    validationErrorMessage: { ...this.state.validationErrorMessage, logoValidation: '' },
                });
                const storageMetaData = await this.getStorageMetaData(this.userId);
                const cloudinaryImageUrl = await this._cloudinaryService.getCloudinaryURL(storageMetaData);
                this.setState({
                    ...this.state,
                    formDetails: {
                        ...this.state.formDetails,
                        localImagePath: cloudinaryImageUrl,
                        existingLogoPath: storageMetaData,
                        cloudinaryImageKey: this._utilityService.removeImgExtension(storageMetaData),
                    },
                });
                this.props.startLoading(false);
                return;
            }

            this.setState({
                ...this.state,
                userImage: null,
                localImagePath: '',
                validationErrorMessage: {
                    ...this.state.validationErrorMessage,
                    logoValidation: 'Selected File is not an image',
                },
            });
        } catch (e) {
            this.props.startLoading(false);
        }
    };

    uploadFileToStorage = async () => {
        const credentials = await this._authService.getAuthCredentials();
        const cognitoId = credentials.identityId;
        const storageMetaData = await this._awsStorageService.uploadFile(
            `${cognitoId}/${uuidv4()}`,
            this.state.userImage,
            this.state.userImage.type,
        );
        return storageMetaData;
    };

    getStorageMetaData = async userId => {
        let storageMetaData = '';
        if (userId) {
            if (this.state.userImage) {
                storageMetaData = await this.uploadFileToStorage();
            } else {
                storageMetaData = this.state.existingLogoPath;
            }
        } else {
            storageMetaData = await this.uploadFileToStorage();
        }
        return storageMetaData.key || storageMetaData;
    };

    setCloudinaryImageUrl = src => {
        setTimeout(() => {
            this.setState({
                ...this.state,
                formDetails: {
                    ...this.state.formDetails,
                    localImagePath: this._utilityService.removeImgExtension(src),
                },
            });
        }, 2000);
    };

    isRoomLive = async () => {
        const invitedRoom = await this.fetchRoomDetails();
        if (invitedRoom) {
            const currentTime = DateTime.local();
            const startTime = DateTime.fromISO(invitedRoom.startTime, { zone: 'utc' }).toLocal();
            const endTime = DateTime.fromISO(invitedRoom.endTime, { zone: 'utc' }).toLocal();
            return !invitedRoom.ended && startTime <= currentTime && endTime >= currentTime;
        }
        return false;
    };

    fetchRoomDetails = async (noFilter = true) => {
        const roomDetails = await this._guestRoomDetailService.getRoomDetail(this.roomId, noFilter);
        return roomDetails;
    };

    /**
     *
     * These functions should be remove in future as per now these are the part of the scope
     *
     * checkIfLiveRoomExistThenNavigateOtherwiseLogout
     * navigateToWebLiveRoom
     * displayToasterMessage
     * getLiveRoom
     * logoutAndRedirectToMagicLinkSender
     */

    checkIfLiveRoomExistThenNavigateOtherwiseLogout = async () => {
        const liveRoom = await this.getLiveRoom();
        if (liveRoom) {
            this.navigateToWebLiveRoom(liveRoom.roomId);
            return;
        }
        this.displayToasterMessage(Constant.ERROR_MESSAGES.NO_LIVE_ROOM, true);
        setTimeout(() => {
            this.logoutAndRedirectToMagicLinkSender();
        }, 2000);
    };

    navigateToWebLiveRoom = roomId => {
        this.props.history.replace(`/room-join/${roomId}`);
    };

    displayToasterMessage = (message, isError) => {
        this.props.showHideErrorToaster({
            show: true,
            message: message,
            bgColor: isError ? '#ff3547' : 'green',
        });
    };

    getLiveRoom = async () => {
        const invitedRooms = await this.getRoomInvites();
        if (invitedRooms && invitedRooms.length) {
            const index = invitedRooms.findIndex(room => {
                const currentTime = DateTime.local();
                const startTime = DateTime.fromISO(room.startTime, { zone: 'utc' }).toLocal();
                const endTime = DateTime.fromISO(room.endTime, { zone: 'utc' }).toLocal();
                return !room.ended && startTime <= currentTime && endTime >= currentTime;
            });

            return index !== -1 ? invitedRooms[index] : null;
        }
        return null;
    };

    logoutAndRedirectToMagicLinkSender = async (error = null) => {
        let pathname = `/guest`;
        if (error) {
            pathname += `/${this._utilityService.encryptObjectToBase64(error)}`;
        }
        await this._authService.logout();
        this.props.history.replace({
            pathname,
        });
    };

    getUpdatedUser = async () => {
        await this._userService.getUser();
    };

    async saveUserDetail(e) {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        this.props.startLoading(true);

        const requestPayload = {
            name: this.state.formDetails.name,
            email: this.state.formDetails.email,
            blurb: this.state.formDetails.blurb,
            image: this.state.formDetails.existingLogoPath,
            fullAvatarUrl: this.state.formDetails.localImagePath,
            linkedin: this.state.formDetails.linkedin,
            location: this.state.formDetails.location,
            phone: this.state.formDetails.phone,
            bio: this.state.formDetails.bio,
            innerCircleOnlyEmail: this.state.formDetails.innerCircleOnlyEmail,
            innerCircleOnlyLinkedin: this.state.formDetails.innerCircleOnlyLinkedin,
            innerCircleOnlyLocation: this.state.formDetails.innerCircleOnlyLocation,
            innerCircleOnlyPhone: this.state.formDetails.innerCircleOnlyPhone,
            visibility: {
                email: this.state.formDetails.visibility.email,
                phone: this.state.formDetails.visibility.phone,
                linkedin: this.state.formDetails.visibility.linkedin,
                location: this.state.formDetails.visibility.location,
                bio: this.state.formDetails.visibility.bio,
            },
        };
        await this._commonService.updateUserProfile(requestPayload);
        await this.getUpdatedUser();
        if (this.roomId) {
            const isRoomLive = await this.isRoomLive();
            if (isRoomLive) {
                this.props.history.replace(`/room-join/${this.roomId}`);
                return;
            }
            this.props.history.replace(`/guest-room-detail/${this.roomId}`);
        } else {
            this.props.history.replace(`/guest-dashboard`);
        }
        this.props.startLoading(false);
    }

    validateImageFileMessage = errMessage => {
        this.setState({
            ...this.state,
            validationErrorMessage: {
                ...this.state.validationErrorMessage,
                logoValidation: errMessage,
            },
        });
    };

    navigateToHomePage = () => {
        if (this.props.isGuest) {
            const isAnonymous = this._userService.isAnonymous();
            if (isAnonymous) {
                this.props.history.push(
                    `/guest-room-detail/${this._utilityService.getSessionStorageItems(
                        Constant.SESSION_STORAGE_KEYS.ROOM_ID,
                    )}`,
                );
                return;
            }
            this.props.history.push('/guest-dashboard');
            return;
        }
    };

    goBackFunction = () => {
        this.props.history.goBack();
    };

    selectVisibility = e => {
        this.setState({
            // ...this.state,
            formDetails: {
                ...this.state.formDetails,
                visibility: {
                    ...this.state.formDetails.visibility,
                    email: e.target.value,
                },
            },
        });
    };

    selectVisibilityPhone = e => {
        this.setState({
            // ...this.state,
            formDetails: {
                ...this.state.formDetails,
                visibility: {
                    ...this.state.formDetails.visibility,
                    phone: e.target.value,
                },
            },
        });
    };

    selectVisibilityLinkedIn = e => {
        this.setState({
            // ...this.state,
            formDetails: {
                ...this.state.formDetails,
                visibility: {
                    ...this.state.formDetails.visibility,
                    linkedin: e.target.value,
                },
            },
        });
    };

    selectVisibilityLocation = e => {
        this.setState({
            // ...this.state,
            formDetails: {
                ...this.state.formDetails,
                visibility: {
                    ...this.state.formDetails.visibility,
                    location: e.target.value,
                },
            },
        });
    };

    selectVisibilityBio = e => {
        this.setState({
            // ...this.state,
            formDetails: {
                ...this.state.formDetails,
                visibility: {
                    ...this.state.formDetails.visibility,
                    bio: e.target.value,
                },
            },
        });
    };

    render() {
        return (
            <>
                {this.props.user.name ? (
                    <GuestHeader {...this.props} navigateToHomePage={this.navigateToHomePage} user={this.props.user} />
                ) : (
                    ''
                )}
                <div className="guest-profile-edit">
                    {this.props.user.name ? (
                        ''
                    ) : (
                        <img
                            id="b1-logo"
                            src={logo}
                            alt="logo"
                            className="guest-profile-edit__logo img-fluid"
                            data-test="boardroom-logo"
                        />
                    )}

                    {this.props.user.name ? (
                        <IconButton
                            click={() => {
                                this.goBackFunction();
                            }}
                            image={iconArrowRight}
                            text="Back"
                            cssClass="guest-room-detail__icon-btn"
                            iconClass="rotate-icon"
                        />
                    ) : (
                        ''
                    )}

                    {this.props.user.name ? <h4 className="guest-profile-edit__main-heading">Edit Profile</h4> : ''}

                    <Row>
                        <Col xs="12" md="12">
                            <Form onSubmit={e => this.saveUserDetail(e)}>
                                <div className="guest-profile-edit__block">
                                    <h2 className="create-room__heading mb-0">Profile</h2>
                                    <FileSelectComponent
                                        id="file-upload-create-room"
                                        isProfilePicture={true}
                                        fileHandling={{
                                            setSelectedLogo: this.setSelectedLogo,
                                            localImagePath: this.state.formDetails.localImagePath,
                                            cloudinaryImageKey: this.state.formDetails.cloudinaryImageKey,
                                            setCloudinaryImageUrl: this.setCloudinaryImageUrl,
                                        }}
                                        validationErrorMessage={this.state.validationErrorMessage}
                                        validateImageFileMessage={this.validateImageFileMessage}
                                        memberDetails="true"
                                        cameraIcon="true"
                                    />

                                    <Form.Group
                                        id="form-full-name"
                                        className="guest-profile-edit__form-group"
                                        controlId="formFullName"
                                    >
                                        <Form.Label className="guest-profile-edit__form-label">Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            className="br__form-control truncate-input"
                                            placeholder="Name"
                                            onChange={e => this.handleInputChange(e)}
                                            maxLength={155}
                                            value={this.state.formDetails.name}
                                        />
                                        <ErrorMessageComponent
                                            message={this.state.validationErrorMessage.memberNameValidation}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        id="form-blurb"
                                        className="guest-profile-edit__form-group"
                                        controlId="formFullName"
                                    >
                                        <Form.Label className="guest-profile-edit__form-label">Blurb</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="blurb"
                                            rows={2}
                                            className="br__form-control"
                                            placeholder="Blurb (Optional)"
                                            maxLength={75}
                                            onChange={e => this.handleInputChange(e)}
                                            value={this.state.formDetails.blurb}
                                        />
                                    </Form.Group>

                                    <h2 className="create-room__heading mb-0">Contact Information</h2>

                                    <p className="guest-head-tagline">By default everyone can see these information.</p>

                                    <Form.Group
                                        id="form-email-name"
                                        className="guest-profile-edit__form-group"
                                        controlId="formEmailAddress"
                                    >
                                        <Form.Label className="guest-profile-edit__form-label">
                                            Email Address
                                        </Form.Label>
                                        <div className="guest-profile-edit__input-dropdown-wrap">
                                            <Form.Control
                                                name="email"
                                                className="br__form-control truncate-input"
                                                placeholder="Email"
                                                value={this.state.formDetails.email}
                                                disabled
                                            />
                                            <Dropdown className="guest-profile-edit__dropdown">
                                                <Dropdown.Toggle
                                                    as="div"
                                                    className="guest-profile-edit__dropdown-toggle"
                                                    id="roleDropdown"
                                                >
                                                    {this.state.formDetails.visibility.email === 'only_me' ? (
                                                        <img src={iconLock} alt="" />
                                                    ) : this.state.formDetails.visibility.email === 'inner_circle' ? (
                                                        <img src={iconUsers} alt="" />
                                                    ) : this.state.formDetails.visibility.email === 'public' ? (
                                                        <img src={iconGlobe} alt="" />
                                                    ) : (
                                                        <img src={iconLock} alt="" />
                                                    )}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="guest-profile-edit__dropdown-menu"
                                                    align="right"
                                                >
                                                    {this.state.visibilityOptions.map((visible, index) => (
                                                        <Dropdown.Item
                                                            as="div"
                                                            className="guest-profile-edit__dropdown-item"
                                                        >
                                                            <label key={index} className="custom-radio">
                                                                <span className="custom-radio__text">
                                                                    <img
                                                                        className="guest-profile-edit__visibility-icon"
                                                                        src={visible.icon}
                                                                        alt=""
                                                                    />
                                                                    {visible.text}
                                                                </span>
                                                                <input
                                                                    type="radio"
                                                                    className="custom-radio__input"
                                                                    name="visibilityOption"
                                                                    value={visible.value}
                                                                    onChange={e => this.selectVisibility(e)}
                                                                    checked={
                                                                        this.state.formDetails.visibility.email ===
                                                                        visible.value
                                                                    }
                                                                />
                                                                <span className="custom-radio__checkmark"></span>
                                                            </label>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <ErrorMessageComponent message={''} />
                                    </Form.Group>

                                    <Form.Group
                                        id="form-phone"
                                        className="guest-profile-edit__form-group"
                                        controlId="formPhone"
                                    >
                                        <Form.Label className="guest-profile-edit__form-label">Phone Number</Form.Label>
                                        <div className="guest-profile-edit__input-dropdown-wrap">
                                            <Form.Control
                                                name="phone"
                                                className="br__form-control truncate-input"
                                                placeholder="Phone"
                                                onChange={e => this.handleInputChange(e)}
                                                maxLength={355}
                                                value={this.state.formDetails.phone}
                                            />
                                            <Dropdown className="guest-profile-edit__dropdown">
                                                <Dropdown.Toggle
                                                    as="div"
                                                    className="guest-profile-edit__dropdown-toggle"
                                                    id="roleDropdown"
                                                >
                                                    {this.state.formDetails.visibility.phone === 'only_me' ? (
                                                        <img src={iconLock} alt="" />
                                                    ) : this.state.formDetails.visibility.phone === 'inner_circle' ? (
                                                        <img src={iconUsers} alt="" />
                                                    ) : this.state.formDetails.visibility.phone === 'public' ? (
                                                        <img src={iconGlobe} alt="" />
                                                    ) : (
                                                        <img src={iconLock} alt="" />
                                                    )}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="guest-profile-edit__dropdown-menu"
                                                    align="right"
                                                >
                                                    {this.state.visibilityOptions.map((visible, index) => (
                                                        <Dropdown.Item
                                                            as="div"
                                                            className="guest-profile-edit__dropdown-item"
                                                        >
                                                            <label key={index} className="custom-radio">
                                                                <span className="custom-radio__text">
                                                                    <img
                                                                        className="guest-profile-edit__visibility-icon"
                                                                        src={visible.icon}
                                                                        alt=""
                                                                    />
                                                                    {visible.text}
                                                                </span>
                                                                <input
                                                                    type="radio"
                                                                    className="custom-radio__input"
                                                                    name="visibilityOptionPhone"
                                                                    value={visible.value}
                                                                    onChange={e => this.selectVisibilityPhone(e)}
                                                                    checked={
                                                                        this.state.formDetails.visibility.phone ===
                                                                        visible.value
                                                                    }
                                                                />
                                                                <span className="custom-radio__checkmark"></span>
                                                            </label>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <ErrorMessageComponent
                                            message={this.state.validationErrorMessage.phoneValidation}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        id="form-linkedin"
                                        className="guest-profile-edit__form-group"
                                        controlId="formLinkedin"
                                    >
                                        <Form.Label className="guest-profile-edit__form-label">LInkedIn</Form.Label>
                                        <div className="guest-profile-edit__input-dropdown-wrap">
                                            <Form.Control
                                                name="linkedin"
                                                className="br__form-control truncate-input"
                                                placeholder="Linkedin"
                                                onChange={e => this.handleInputChange(e)}
                                                maxLength={200}
                                                value={this.state.formDetails.linkedin}
                                            />
                                            <Dropdown className="guest-profile-edit__dropdown">
                                                <Dropdown.Toggle
                                                    as="div"
                                                    className="guest-profile-edit__dropdown-toggle"
                                                    id="roleDropdown"
                                                >
                                                    {this.state.formDetails.visibility.linkedin === 'only_me' ? (
                                                        <img src={iconLock} alt="" />
                                                    ) : this.state.formDetails.visibility.linkedin ===
                                                      'inner_circle' ? (
                                                        <img src={iconUsers} alt="" />
                                                    ) : this.state.formDetails.visibility.linkedin === 'public' ? (
                                                        <img src={iconGlobe} alt="" />
                                                    ) : (
                                                        <img src={iconLock} alt="" />
                                                    )}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="guest-profile-edit__dropdown-menu"
                                                    align="right"
                                                >
                                                    {this.state.visibilityOptions.map((visible, index) => (
                                                        <Dropdown.Item
                                                            as="div"
                                                            className="guest-profile-edit__dropdown-item"
                                                        >
                                                            <label key={index} className="custom-radio">
                                                                <span className="custom-radio__text">
                                                                    <img
                                                                        className="guest-profile-edit__visibility-icon"
                                                                        src={visible.icon}
                                                                        alt=""
                                                                    />
                                                                    {visible.text}
                                                                </span>
                                                                <input
                                                                    type="radio"
                                                                    className="custom-radio__input"
                                                                    name="visibilityOptionLinkedIn"
                                                                    value={visible.value}
                                                                    onChange={e => this.selectVisibilityLinkedIn(e)}
                                                                    checked={
                                                                        this.state.formDetails.visibility.linkedin ===
                                                                        visible.value
                                                                    }
                                                                />
                                                                <span className="custom-radio__checkmark"></span>
                                                            </label>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <ErrorMessageComponent message={''} />
                                    </Form.Group>

                                    <Form.Group
                                        id="form-location"
                                        className="guest-profile-edit__form-group"
                                        controlId="formLocation"
                                    >
                                        <Form.Label className="guest-profile-edit__form-label">Location</Form.Label>
                                        <div className="guest-profile-edit__input-dropdown-wrap">
                                            <Form.Control
                                                name="location"
                                                className="br__form-control truncate-input"
                                                placeholder="Location"
                                                onChange={e => this.handleInputChange(e)}
                                                maxLength={200}
                                                value={this.state.formDetails.location}
                                            />
                                            <Dropdown className="guest-profile-edit__dropdown">
                                                <Dropdown.Toggle
                                                    as="div"
                                                    className="guest-profile-edit__dropdown-toggle"
                                                    id="roleDropdown"
                                                >
                                                    {this.state.formDetails.visibility.location === 'only_me' ? (
                                                        <img src={iconLock} alt="" />
                                                    ) : this.state.formDetails.visibility.location ===
                                                      'inner_circle' ? (
                                                        <img src={iconUsers} alt="" />
                                                    ) : this.state.formDetails.visibility.location === 'public' ? (
                                                        <img src={iconGlobe} alt="" />
                                                    ) : (
                                                        <img src={iconLock} alt="" />
                                                    )}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="guest-profile-edit__dropdown-menu"
                                                    align="right"
                                                >
                                                    {this.state.visibilityOptions.map((visible, index) => (
                                                        <Dropdown.Item
                                                            as="div"
                                                            className="guest-profile-edit__dropdown-item"
                                                        >
                                                            <label key={index} className="custom-radio">
                                                                <span className="custom-radio__text">
                                                                    <img
                                                                        className="guest-profile-edit__visibility-icon"
                                                                        src={visible.icon}
                                                                        alt=""
                                                                    />
                                                                    {visible.text}
                                                                </span>
                                                                <input
                                                                    type="radio"
                                                                    className="custom-radio__input"
                                                                    name="visibilityOptionLocation"
                                                                    value={visible.value}
                                                                    onChange={e => this.selectVisibilityLocation(e)}
                                                                    checked={
                                                                        this.state.formDetails.visibility.location ===
                                                                        visible.value
                                                                    }
                                                                />
                                                                <span className="custom-radio__checkmark"></span>
                                                            </label>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <ErrorMessageComponent message={''} />
                                    </Form.Group>

                                    <Form.Group
                                        id="form-bio"
                                        className="guest-profile-edit__form-group"
                                        controlId="formBio"
                                    >
                                        <Form.Label className="guest-profile-edit__form-label">Bio</Form.Label>
                                        <div className="guest-profile-edit__input-dropdown-wrap">
                                            <Form.Control
                                                as="textarea"
                                                name="bio"
                                                rows={4}
                                                className="br__form-control"
                                                placeholder="Bio"
                                                maxLength={300}
                                                onChange={e => this.handleInputChange(e)}
                                                value={this.state.formDetails.bio}
                                            />
                                            <Dropdown className="guest-profile-edit__dropdown guest-profile-edit__dropdown--textarea">
                                                <Dropdown.Toggle
                                                    as="div"
                                                    className="guest-profile-edit__dropdown-toggle"
                                                    id="roleDropdown"
                                                >
                                                    {this.state.formDetails.visibility.bio === 'only_me' ? (
                                                        <img src={iconLock} alt="" />
                                                    ) : this.state.formDetails.visibility.bio === 'inner_circle' ? (
                                                        <img src={iconUsers} alt="" />
                                                    ) : this.state.formDetails.visibility.bio === 'public' ? (
                                                        <img src={iconGlobe} alt="" />
                                                    ) : (
                                                        <img src={iconLock} alt="" />
                                                    )}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="guest-profile-edit__dropdown-menu"
                                                    align="right"
                                                >
                                                    {this.state.visibilityOptions.map((visible, index) => (
                                                        <Dropdown.Item
                                                            as="div"
                                                            className="guest-profile-edit__dropdown-item"
                                                        >
                                                            <label key={index} className="custom-radio">
                                                                <span className="custom-radio__text">
                                                                    <img
                                                                        className="guest-profile-edit__visibility-icon"
                                                                        src={visible.icon}
                                                                        alt=""
                                                                    />
                                                                    {visible.text}
                                                                </span>
                                                                <input
                                                                    type="radio"
                                                                    className="custom-radio__input"
                                                                    name="visibilityOptionBio"
                                                                    value={visible.value}
                                                                    onChange={e => this.selectVisibilityBio(e)}
                                                                    checked={
                                                                        this.state.formDetails.visibility.bio ===
                                                                        visible.value
                                                                    }
                                                                />
                                                                <span className="custom-radio__checkmark"></span>
                                                            </label>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Form.Group>

                                    <div className="guest-profile-edit__footer">
                                        {this.state.formDetails.name ? (
                                            <Link to="/guest-profile-view" className={'btn__link'}>
                                                Cancel
                                            </Link>
                                        ) : (
                                            ''
                                        )}
                                        <Button
                                            id="join-room-btn"
                                            className="btn__themefilled guest-profile-edit__btn"
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestProfileEditContainer);
