// This dashboard will be add in future
import React from 'react';
import { connect } from 'react-redux';
import './guest-header.style.scss';
import { Media, Dropdown } from 'react-bootstrap';
import arrow from '../../../assets/images/arrow.svg';
import logo from '../../../assets/images/logo.png';
import betaLogo from '../../../assets/images/beta-logo-new.png';
import iconAdd from '../../../assets/images/ic-add.svg';
import anonymousPlaceholder from '../../../assets/images/ic-anomyous-user.png';
import { startLoading, showHideErrorToaster } from '../../../core';
import { Link } from 'react-router-dom';
import { CloudinaryService, AuthService } from '../../../core';
import { ImageComponent } from '../../../shared';
import { selectUser } from '../../store';
import { UserService } from '../../services';
import { BroadcastChannel } from 'broadcast-channel';
import { GuestPrivateRoomService } from '../../../content/guest-create-private-room/services';
export class GuestHeader extends React.Component {
    _cloudinaryService = new CloudinaryService();
    _guestPrivateRoomService = new GuestPrivateRoomService();
    _authService = new AuthService();
    _userService = new UserService();
    _channel = null;
    constructor(props) {
        super(props);
        try {
            this._channel = new BroadcastChannel('auth');
        } catch (error) {
            this._channel = null;
        }
        this.state = {
            name: this.props.user.name,
            image: 'https://via.placeholder.com/40',
            privateRoomAuthorized: null,
        };
    }

    onLogout = async () => {
        await this._authService.logout();
        if (this._channel) {
            this._channel.postMessage({ case: 'signOut', pathname: '/' });
        }
    };

    onSelectUser = () => {
        if (!this._userService.isAnonymous()) {
            this.props.history.push({
                pathname: `/guest-profile-view`,
            });
            return;
        }
        this.props.selectUser(true);
    };

    componentDidMount = () => {
        const isAnonymous = this._userService.isAnonymous();
        if (!isAnonymous) {
            this.canCreatePrivateRooms();
        }
    };

    canCreatePrivateRooms = async () => {
        try {
            const privateRoomAuthorized = await this._guestPrivateRoomService.canCreatePrivateRoom();
            this.setState({
                ...this.state,
                privateRoomAuthorized,
            });
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        return (
            <header className="guest-header">
                <div>
                    <img
                        src={betaLogo}
                        className="guest-header__beta-logo img-fluid"
                        alt="beta-logo"
                        onClick={() => {
                            this.props.navigateToHomePage();
                        }}
                    />
                    <img
                        onClick={() => {
                            this.props.navigateToHomePage();
                        }}
                        src={logo}
                        className="guest-header__logo"
                        alt="logo"
                    />
                </div>
                <div className="guest-header__right">
                    {this.state.privateRoomAuthorized && this.state.privateRoomAuthorized.authorized ? (
                        <Link to="/guest-create-private-room" className="guest-header__create-link">
                            <img src={iconAdd} alt="iconAdd" title="Create Private Room" />
                        </Link>
                    ) : (
                        ''
                    )}
                    <Dropdown className="guest-header__menu">
                        <Dropdown.Toggle as="div" className="guest-header__dropdown-toggle" id="dropdownMenuButton">
                            <Media className="guest-header__media">
                                {this.props.user.fullAvatarUrl ? (
                                    <img
                                        width={40}
                                        src={this.props.user.fullAvatarUrl}
                                        alt="User"
                                        className="guest-header__user-img"
                                    />
                                ) : (
                                    <ImageComponent
                                        isAvatar={true}
                                        src={this.props.user.image}
                                        placeholder={
                                            this._userService.isAnonymous()
                                                ? anonymousPlaceholder
                                                : 'https://via.placeholder.com/40'
                                        }
                                        alt=""
                                        className="guest-header__user-avatar-img"
                                    />
                                )}
                                <Media.Body>
                                    <h5 className="guest-header__user-name" title={this.props.user.name}>
                                        {this.props.user.name || ''}
                                    </h5>
                                </Media.Body>
                                <img src={arrow} className="guest-header__arrow" alt="" />
                            </Media>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="guest-header__dropdown-menu" align="right">
                            {this._userService.isAnonymous() ? (
                                ''
                            ) : (
                                <Dropdown.Item
                                    onClick={e => {
                                        e.preventDefault();
                                        this.onSelectUser();
                                    }}
                                    className="guest-header__dropdown-item"
                                >
                                    My Profile
                                </Dropdown.Item>
                            )}

                            <Link to="/guest" onClick={() => this.onLogout()} className="guest-header__dropdown-item">
                                Log Out
                            </Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </header>
        );
    }
}

const mapStateToProp = state => {
    /**
     * This will return all the state declare inside the app directory in store
     */
    return {
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
        showHideErrorToaster: error => dispatch(showHideErrorToaster(error)),
        selectUser: isUserSelected => dispatch(selectUser(isUserSelected)),
    };
};

export default connect(mapStateToProp, mapDispatchToProps)(GuestHeader);
