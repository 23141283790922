export class Exception {
    constructor(exceptionType, message) {
        this.exceptionType = exceptionType;
        this.message = message;
    }

    getExceptionType = () => {
        return this.exceptionType;
    };

    getMessage = () => {
        return this.message;
    };
}
