/**
 * import constants here
 */
import { SET_SOMETHING, START_LOADER, SHOW_HIDE_ERROR_TOASTER, SET_USER, SELECT_USER } from './constants';

/**
 * this is the initial state we can define more state inside this initialState for example
 *
 * naming convention should be follow inside the initial state will be camelCase
 *
 * const initialState = {
 *   someNumber: 0,
 *   someArray: [],
 *   someObject: {}
 * };
 */
const initialState = {
    someThing: { key_1: 'value 1', key_2: 'value 2' },
    isLoading: false,
    isMessage: false,
    message: '',
    errorToaster: {
        show: false,
        message: '',
    },
    user: {
        blurb: '',
        id: '',
        image: '',
        isRoot: false,
        name: '',
    },
    isUserSelected: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        /**
         * reducers should be like
         * and add more cases if required
         *
         * case <TYPE_ACTION_HERE>:
         *      return Object.assign({}, state, { someObject: action.someObject });
         */

        //
        //
        case START_LOADER:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
                isMessage: action.isMessage,
                message: action.message,
            });
        case SHOW_HIDE_ERROR_TOASTER:
            return Object.assign({}, state, { errorToaster: action.errorToaster });
        case SET_SOMETHING:
            return Object.assign({}, state, { someThing: action.someThing });
        case SET_USER:
            return Object.assign({}, state, { user: action.user });
        case SELECT_USER:
            return Object.assign({}, state, { isUserSelected: action.isUserSelected });
        default:
            return state;
    }
}
