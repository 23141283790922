import React, { useState } from 'react';
import { Media, Dropdown } from 'react-bootstrap';
import './header.style.scss';
import arrow from '../../../assets/images/arrow.svg';
import logo from '../../../assets/images/logo.png';
import { AuthService } from '../../services';
import { Link } from 'react-router-dom';
import { CloudinaryService } from '../..';
import { BroadcastChannel } from 'broadcast-channel';

function Header(props) {
    const _authService = new AuthService();
    const _cloudinaryService = new CloudinaryService();
    let _channel = null;
    try {
        _channel = new BroadcastChannel('auth');
    } catch (error) {
        _channel = null;
    }
    const [name, setName] = useState('');
    const [image, setImage] = useState('https://via.placeholder.com/40');
    React.useEffect(() => {
        (function getUser() {
            setName(props.user.name ? props.user.name : '');
            setImage(
                props.user.fullAvatarUrl ||
                    (image && _cloudinaryService.getCloudinaryURL(props.user.image)) ||
                    'https://via.placeholder.com/40',
            );
        })();
    }, [props.user]);

    const onLogout = async () => {
        await _authService.logout();
        if (_channel) {
            _channel.postMessage({ case: 'signOut', pathname: '/' });
        }
    };

    return (
        <header data-test="header_component" className="header">
            <img
                data-test="app_logo"
                onClick={() => {
                    props.navigateToHomePage();
                }}
                src={logo}
                className="header__logo"
                alt="logo"
            />
            <Dropdown className="header__menu">
                <Dropdown.Toggle as="div" className="header__dropdown-toggle" id="dropdownMenuButton">
                    <Media className="header__media">
                        <img
                            data-test="user_image"
                            width={40}
                            height={40}
                            src={image}
                            alt="User"
                            className="header__user-img"
                        />
                        <Media.Body>
                            <h5 data-test="account_name" className="header__user-name">
                                {name}
                            </h5>
                        </Media.Body>
                        <img data-test="dropdown_arrow" src={arrow} className="header__arrow" alt="" />
                    </Media>
                </Dropdown.Toggle>
                <Dropdown.Menu className="header__dropdown-menu" align="right">
                    <Link to="/" onClick={onLogout} className="header__dropdown-item">
                        Log Out
                    </Link>
                </Dropdown.Menu>
            </Dropdown>
        </header>
    );
}

export default Header;
