import React from 'react';
import './icon-button-component.style.scss';

const IconButton = props => {
    return (
        <button className={'icon-button ' + props.cssClass} onClick={props.click}>
            <img src={props.image} alt="icon" className={'icon-button__img ' + props.iconClass} />
            <span className="icon-button__text">{props.text}</span>
            {props.title && <span className="tool-msg">{props.title}</span>}
        </button>
    );
};

export default IconButton;
