import React from 'react';
import './room-invite-header-component.style.scss';
import { ButtonComponent, IconButton } from '../../../../shared/components';
import iconCSV from '../../../../assets/images/ic-csv-file.svg';
import iconUpload from '../../../../assets/images/ic-upload.svg';

const RoomInviteHeader = props => {
    return (
        <div className="room-invite__header">
            <div className="room-invite__header-top">
                <h4 data-test="invite-header-title" className="room-invite__heading">
                    Invite Attendees to {props.roomName}
                </h4>
                <div className="room-invite__btn-wrap">
                    <ButtonComponent
                        onclick={props.sendInvite}
                        id="btn-send-invite"
                        className={'br__btn-theme-filled br__btn-theme-filled--width-138'}
                        name="Send Invites"
                    />
                </div>
            </div>
            <div className="room-invite__header-bottom">
                <div className="room-invite__header-bottom-left">
                    <IconButton image={iconUpload} text="Import CSV file" cssClass="mr-15" />
                    <IconButton image={iconCSV} text="Sample" />
                </div>
                <div className="room-invite__header-bottom-right">
                    <label className="room-invite__selected">
                        {props.selectedMembers} selected |{' '}
                        <span onClick={() => props.clearAll()} className="room-invite__clear-all">
                            Clear all
                        </span>{' '}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default RoomInviteHeader;
