import { Http } from '../../../../core';

export class BoardDashboardService {
    /**
     * @name getSomething
     * @param {any} param Data required for dummy manipulation
     * @desc Describe what function will do like API calling or doing some other stuff
     * @return {Promise}
     */
    getSomething = (param /** */) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`` /** api endpoint should go here */).then(
                _successLog => {
                    resolve(_successLog.data);
                },
                _errorLog => {
                    /** if we want to resolve something when error occurs */
                    resolve(/** here you can pass log where you call getSomething method and want something in case of error */);
                },
            );
        });
    };

    /**
     * @name doSomething
     * @param {any} param Data required for dummy manipulation
     * @desc Describe what function will do like API calling or doing some other stuff
     * this function will throw error where we call it we have to handle it via try catch
     * @return {Promise}
     */
    doSomething = (param /** */) => {
        return new Promise((resolve, reject) => {
            Http.REQUEST.get(`` /** api endpoint should go here */)
                .then(_successLog => {
                    resolve(_successLog.data);
                })
                .catch(e => reject(e.toString));
        });
    };
}
