import React from 'react';
import { Layout } from '../..';
import LoginContainer from '../login/login';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { setAccessToken } from '../../';
import { UserService } from '../../services';
import { MagicLinkSenderContainer } from '..';
import { Utility, Constant } from '../../../shared';
export class AuthContainer extends React.Component {
    _userService = new UserService();
    _utilityService = new Utility();
    constructor(props) {
        super(props);

        /**
         * initiate state here
         */
    }

    componentDidUpdate = async (prevState, prevProps) => {
        await this.setAccessTokenToStore();
        if ((prevState.accessToken && !this.props.user) || !this.props.user.id) {
            await this._userService.getUser();
        }
    };

    setAccessTokenToStore = async () => {
        if (!this.props.accessToken) {
            const accessToken = this._utilityService.getSessionStorageItems(Constant.SESSION_STORAGE_KEYS.ACCESS_TOKEN);
            if (accessToken) {
                this.props.setAccessToken(accessToken);
                return;
            }
            const session = await Auth.currentSession();
            if (session && session.accessToken && session.accessToken.jwtToken) {
                this.props.setAccessToken(session.accessToken.jwtToken);
            }
        }
    };

    renderLayout = () => {
        return <Layout {...this.props}>{this.props.children}</Layout>;
    };

    authorizedRenderer = () => {
        return this.renderLayout();
    };

    render() {
        if (this.props.isPublicRoute) {
            return '';
        }

        return (
            <div>
                {/**
                 *
                 * Check condition if user is authenticated otherwise redirect to login page
                 */}
                {this.props.accessToken ? (
                    this.authorizedRenderer()
                ) : this.props.isGuest ? (
                    <MagicLinkSenderContainer />
                ) : (
                    <LoginContainer accessToken={this.props.accessToken} />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAccessToken: accessToken => dispatch(setAccessToken(accessToken)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
