import { combineReducers } from 'redux';
import { appReducer, authReducer } from './app';

export default combineReducers({
    app: appReducer,
    /**
     * can add more reducers specific to module and add here
     */
    authReducer: authReducer,
});
