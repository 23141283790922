import React from 'react';
import { Table } from 'react-bootstrap';
import { RoomInviteTableListComponent } from '..';
import './room-invite-data-table-component.style.scss';
import { v4 as uuidv4 } from 'uuid';

const roomInviteDataTableComponent = props => {
    return (
        <div>
            <Table id="member-data-table" className="room-invite__table" data-test="member-table_render">
                <thead
                    id="member-table-header"
                    className="room-invite__table-head"
                    data-test="member-table_header_render"
                >
                    <tr
                        id="member-table-header-row"
                        className="room-invite__table-head-row"
                        data-test="member-table_header_row_render"
                    >
                        <td
                            id={`member-table-header-row-data-01`}
                            data-test="member-table_header_row_data_render"
                            className="room-invite__table-cel-head room-invite__table-cel-head--checkbox"
                        ></td>
                        <td
                            id={`member-table-header-row-data-01`}
                            data-test="member-table_header_row_data_render"
                            className="room-invite__table-cel-head room-invite__table-cel-head--members"
                        >
                            MEMBERS
                        </td>
                        <td
                            id={`member-table-header-row-data-02`}
                            data-test="member-table_header_row_data_render"
                            className="room-invite__table-cel-head room-invite__table-cel-head--blurb"
                        ></td>
                        <td
                            id={`member-table-header-row-data-03`}
                            data-test="member-table_header_row_data_render"
                            className="room-invite__table-cel-head room-invite__table-cel-head--email"
                        >
                            EMAIL ADDRESS
                        </td>
                        <td
                            id={`member-table-header-row-data-04`}
                            data-test="member-table_header_row_data_render"
                            className="room-invite__table-cel-head room-invite__table-cel-head--role"
                        >
                            ROLE
                        </td>
                    </tr>
                </thead>
                <tbody id="table-body" className="room-invite__table-body" data-test="member_table_body_render">
                    {props.data &&
                        props.data.map((singleMember, index) => {
                            return (
                                <tr
                                    key={uuidv4()}
                                    id={`member-table-body-row-${index + 1}`}
                                    className="room-invite__table-body-row"
                                    data-test="member_table_body_row_render"
                                >
                                    <RoomInviteTableListComponent
                                        onCheckboxChange={props.onCheckboxChange}
                                        selectRole={props.selectRole}
                                        memberInfo={singleMember}
                                        roleOptions={props.roleOptions}
                                        index={index}
                                    />
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};

export default roomInviteDataTableComponent;
