import React from 'react';
import './drop-down-button.style.scss';
import { Dropdown } from 'react-bootstrap';

const btnDropDownComponent = props => {
    const { onSelect, id, dotClass, hoverClass, src } = { ...props };
    let menuItems = props.menuItems;
    if (props.isGreenRoom) {
        menuItems = menuItems.filter(item => !item.hideOnGreenRoom);
    }

    return (
        <>
            <Dropdown id={`${id}-container`} className="dropdown-btn theme__dropdown">
                <Dropdown.Toggle as="div" id={`${id}-dropdown-basic`} className="dropdown-btn__toggle">
                    <img src={src} className={`dropdown-btn__dots ${dotClass}`} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu id={`${id}-menu`} className="dropdown-btn__menu theme__dropdownmenu">
                    {menuItems.map((item, index) => (
                        <Dropdown.Item
                            id={`${id}-item-${index}`}
                            key={`${id}-item-${index}`}
                            onClick={() => {
                                onSelect(item.name);
                            }}
                            className={`dropdown-btn__menu-item ${
                                item.isDanger ? 'dropdown-btn__menu-item--error' : ''
                            } ${hoverClass}`}
                        >
                            {item.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default btnDropDownComponent;
