import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { startLoading } from '../..';
import { RoomJoinService } from '../../../content/room-join/services';
import { Utility } from '../../../shared';
import './room-wait.style.scss';
import logo from '../../../assets/images/logo.png';

const RoomWaitContainer = props => {
    const _roomJoinService = new RoomJoinService();
    const _utilityService = new Utility();
    const validateRoomStart = result => {
        return result;
    };

    const fetchCredentials = () => {
        return _roomJoinService
            .getStreamToken(props.user.id, props.match.params.roomId)
            .then(_successLog => {
                return true;
            })
            .catch(e => {
                return false;
            });
    };

    useEffect(() => {
        (async function isRoomStarted() {
            const result = await _utilityService.poll({
                fn: fetchCredentials,
                validate: validateRoomStart,
                interval: 10000,
            });
            if (result) {
                props.history.push(`/room-join/${props.match.params.roomId}`);
            }
        })();
    }, []);

    return (
        <div className="login_wrapper">
            <div className="login-block">
                <img src={logo} alt="logo" className="login__logo img-fluid mb-4" />
                <p className="text-center">
                    Room hasn't been started yet. Please wait for the moderator to start the room.
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        ...state.authReducer,
        ...state.app,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: isLoading => dispatch(startLoading(isLoading)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomWaitContainer);
