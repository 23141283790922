import React, { useEffect, useLayoutEffect, useState } from 'react';
import arrowRight from '../../../assets/images/ic-arrow-blue.svg';
import arrowLeft from '../../../assets/images/ic-arrow-blue.svg';
import './carousel.style.scss';

const CarouselRoomInvite = props => {
    const { children } = props;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(0);
    const [touchPosition, setTouchPosition] = useState(null);
    const [itemToShow, setItemToShow] = useState(4);
    const [allChildren, setAllChildren] = useState();
    let buttonStyle = { background: 'transparent', border: 'none' };

    const [transformVariable, settransformVariable] = useState(0);

    // Set the length to match current children from props

    useEffect(() => {
        const total = [];
        children.forEach(child => {
            Array.isArray(child) ? total.push(...child) : total.push(child);
        });
        setAllChildren(total);
        allChildren && setLength(allChildren.length);
    }, [children]);

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth < 600) {
                setItemToShow(1);
                setCurrentIndex(0);
                settransformVariable(90);
                return;
            }
            setItemToShow(4);
            setCurrentIndex(0);
            settransformVariable(100);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const next = () => {
        if (currentIndex < length - itemToShow) {
            setCurrentIndex(prevState => prevState + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1);
        }
    };

    const handleTouchStart = e => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleTouchMove = e => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
            next();
        }

        if (diff < -5) {
            prev();
        }

        setTouchPosition(null);
    };

    return (
        <div className="room-invite__carousel" data-test="carousel_component">
            <div
                className="carousel-content-wrapper"
                data-test="carousel_content"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
            >
                <div
                    className={`carousel-content show-${itemToShow}`}
                    style={{ transform: `translateX(-${currentIndex * (transformVariable / itemToShow)}%)` }}
                >
                    {allChildren}
                </div>
            </div>
            <div className="carousel__nav" data-test="carousel_nav">
                <button
                    data-test="left_arrow"
                    onClick={() => prev()}
                    style={currentIndex <= 0 ? { ...buttonStyle, display: 'none' } : buttonStyle}
                    className="carousel__arrow carousel__arrow--left"
                >
                    <img src={arrowLeft} alt="" />
                </button>
                <button
                    data-test="right_arrow"
                    onClick={() => next()}
                    style={currentIndex >= length - itemToShow ? { ...buttonStyle, display: 'none' } : buttonStyle}
                    className="carousel__arrow carousel__arrow--right"
                >
                    <img src={arrowRight} alt="" />
                </button>
            </div>
        </div>
    );
};

export default CarouselRoomInvite;
