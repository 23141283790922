import React from 'react';

const buttonComponent = props => {
    const { id, param } = { ...props };
    return param ? (
        <button
            disabled={props.disabledSubmitButton}
            className={props.className}
            onClick={e => {
                props.onclick(e, param);
            }}
            id={id}
        >
            {props.name}
        </button>
    ) : (
        <button
            disabled={props.disabledSubmitButton}
            className={props.className}
            onClick={e => {
                props.onclick(e);
            }}
            id={id}
        >
            {props.name}
        </button>
    );
};

export default buttonComponent;
