import React from 'react';
import { Form } from 'react-bootstrap';
import './input-component.style.scss';
import { ErrorMessageComponent } from '..';

const InputComponent = props => {
    const [character, setCharacter] = React.useState('');
    const {
        placeholder,
        onChange,
        errorMessage,
        charLimit,
        type,
        textId,
        classes,
        value,
        fieldType,
        disable,
        onKeyDown,
        hasIcon,
        icon,
        iconClass,
        iconWrapperClass,
    } = { ...props };

    if (disable) {
        return (
            <>
                <Form.Control
                    disabled={disable}
                    className={`${classes} br__input-disabled`}
                    type={'text'}
                    value={value || ''}
                    onKeyDown={onKeyDown || null}
                />
            </>
        );
    }

    if (fieldType && fieldType === 'password') {
        return (
            <>
                <Form.Control
                    autoComplete="new-password"
                    className={classes}
                    type={type}
                    placeholder={placeholder || ''}
                    value={value || ''}
                    onChange={e => {
                        if (charLimit) {
                            e.target.value =
                                e.target.value.length > charLimit
                                    ? e.target.value.substring(0, charLimit)
                                    : e.target.value;
                            setCharacter(e.target.value.length);
                        }
                        onChange(e.target.value);
                    }}
                    onKeyDown={onKeyDown || null}
                />
                <ErrorMessageComponent id={`${textId}-text-validation-message`} message={errorMessage} />
            </>
        );
    }

    if (type === 'textarea') {
        return (
            <>
                <Form.Control
                    as="textarea"
                    rows={2}
                    className={classes}
                    placeholder={placeholder || ''}
                    value={value || ''}
                    onChange={e => {
                        if (charLimit) {
                            e.target.value =
                                e.target.value.length > charLimit
                                    ? e.target.value.substring(0, charLimit)
                                    : e.target.value;
                            setCharacter(e.target.value.length);
                        }
                        onChange(e.target.value);
                    }}
                    onKeyDown={onKeyDown || null}
                />
                <ErrorMessageComponent id={`${textId}-text-validation-message`} message={errorMessage} />
            </>
        );
    }

    if (type === 'email') {
        return (
            <>
                <Form.Control
                    autoComplete="new-password"
                    className={classes}
                    type={type}
                    placeholder={placeholder || ''}
                    value={value || ''}
                    onChange={e => {
                        onChange(e.target.value);
                    }}
                    onKeyDown={onKeyDown || null}
                />
                <ErrorMessageComponent id={`${textId}-text-validation-message`} message={errorMessage} />
            </>
        );
    }

    if (hasIcon) {
        return (
            <>
                <div className={iconWrapperClass}>
                    <Form.Control
                        autoComplete="new-password"
                        className={classes}
                        type={type}
                        placeholder={placeholder || ''}
                        value={value || ''}
                        onChange={e => {
                            onChange(e.target.value);
                        }}
                        onKeyDown={onKeyDown || null}
                    />
                    <img src={icon} alt="" className={iconClass} />
                </div>
                <ErrorMessageComponent id={`${textId}-text-validation-message`} message={errorMessage} />
            </>
        );
    }

    return type === 'description_box' ? (
        <>
            <Form.Control
                className={classes}
                as="textarea"
                placeholder={placeholder || ''}
                value={value || ''}
                onChange={e => {
                    if (charLimit) {
                        e.target.value =
                            e.target.value.length > charLimit ? e.target.value.substring(0, charLimit) : e.target.value;
                        setCharacter(e.target.value.length);
                    }
                    onChange(e.target.value);
                }}
                onKeyDown={onKeyDown || null}
            />

            {type ? (
                <span className="form__badge">
                    {character || value.length}/{charLimit}
                </span>
            ) : (
                ''
            )}
            <ErrorMessageComponent id={`${textId}-desc-validation-message`} message={errorMessage} />
        </>
    ) : (
        <>
            <Form.Control
                className={classes}
                type="text"
                placeholder={placeholder || ''}
                value={value || ''}
                onChange={e => {
                    if (charLimit) {
                        e.target.value =
                            e.target.value.length > charLimit ? e.target.value.substring(0, charLimit) : e.target.value;
                        setCharacter(e.target.value.length);
                    }
                    onChange(e.target.value);
                }}
                onKeyDown={onKeyDown || null}
            />

            {type ? <span className="form__badge">{character || value.length}/50&nbsp;</span> : ''}
            <ErrorMessageComponent id={`${textId}-text-validation-message`} message={errorMessage} />
        </>
    );
};

export default InputComponent;
