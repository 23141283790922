import Storage from '@aws-amplify/storage';

export class AWSStorageService {
    /**
     * @name uploadFile
     * @param {path} for specifying path of the file in storage
     * @param {file} file object
     * @param {fileTyoe} file type object specifying the type of a file
     * @return {routes array[]}
     */
    uploadFile = async (path, file, fileType) => {
        return await Storage.put(path, file, fileType);
    };

    /**
     * @name downloadFile
     * @param {fileKey} aws uploaded identity id
     * @return {string | null} it will return the src for display we can use it to download also
     */
    downloadFile = async (fileKey, customePrefix = false) => {
        /*  IOS Amplify couldn't add custom prefix, to make it consistent we have handled this
           through backend code. hence no need to pass custom prefix here
        */
        try {
            let config = { download: true };
            return await Storage.get(fileKey, config);
        } catch (e) {
            return null;
        }
    };

    /**
     * @name uploadFileWithoutAuthentication
     * @param {string} url for specifying path of the file in storage
     * @param {file} file object
     */
    uploadFileWithoutAuthentication = async (url, file) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url, true);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    return resolve();
                }
            };
            xhr.onerror = err => {
                reject({ message: 'Image Uploading Failed' });
            };
            xhr.send(file);
        });
    };

    /**
     * @name removeFile
     * @param {string} url for specifying path of the file in storage
     * @param {file} file object
     */
    removeFile = async key => {
        return await Storage.remove(key);
    };
}
