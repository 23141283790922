import React from 'react';
import '../room-sidebar-component/room-sidebar-component.style.scss';
import { Collapse, Form } from 'react-bootstrap';
import { Constant, ImageComponent, IconButton } from '../../../../shared';
import { RoomSpeakerComponent } from '..';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import iconShare from '../../../../assets/images/ic-share.svg';
import iconLounge from '../../../../assets/images/ic-peoples.svg';

const RoomSideBar = props => {
    let menuItems = Constant.ROOM_DETAILS_SPEAKERS_MENU_ITEMS;
    let members = props.details.members;
    members.forEach(mem => {
        if (mem.role !== Constant.ROOM_MEMBERS.MODERATOR) {
            mem.menuItems = [{ name: 'Promote to Moderator', isDanger: false }];
            mem.menuItems = [...mem.menuItems, ...menuItems];
        } else if (mem.role !== Constant.ROOM_MEMBERS.SPEAKER) {
            mem.menuItems = [{ name: 'Promote to Speaker', isDanger: false }];
            mem.menuItems = [...mem.menuItems, ...menuItems];
        } else {
            mem.menuItems = menuItems;
        }
    });
    return (
        <div className="room-detail__meta" data-test="room_sidebar">
            <div className="room-detail__img-wrap">
                <ImageComponent
                    className="room-detail__img-hero"
                    data-test="hero_img"
                    src={props.details.heroImage}
                    placeholder={null}
                    alt=""
                />
                <ImageComponent
                    className="room-detail__img-org"
                    data-test="org_logo"
                    src={props.details.orgLogo}
                    placeholder={null}
                    alt=""
                />
            </div>
            <div className="room-detail__info">
                <div className="room-detail__info-inner">
                    <ImageComponent
                        className="room-detail__img-org--mob"
                        src={props.details.orgLogo}
                        placeholder={null}
                        alt=""
                    />
                    <div>
                        <h2 className="room-detail__name" data-test="room_name">
                            {props.details.name}
                        </h2>
                        <h5 className="room-detail__board-name" data-test="board_name">
                            By {props.details.boardName} Board
                        </h5>
                    </div>
                </div>
                <p className="room-detail__time" data-test="timestamp">
                    {props.details.timeStamp}
                </p>

                {props.details.isLoungeEnabled && (
                    <div className="room-detail__lounge-icon-wrap">
                        <img src={iconLounge} className="room-detail__lounge-icon" alt="icon" />
                        <span className="room-detail__lounge-text">Lounge is enabled for this room.</span>
                    </div>
                )}

                {props.details.isShareAble && (
                    <div className="room-detail__link-wrapper">
                        <label className="room-detail__link-label">Link</label>
                        <div className="room-detail__link-inner">
                            <Form.Control
                                className="room-detail__link-input"
                                type="text"
                                value={props.roomLink}
                                placeholder="Some link here"
                                readOnly
                                disabled
                            />
                            <IconButton
                                image={iconShare}
                                text="Copy Link"
                                title={props.copyMessage}
                                click={props.copyToClipBoard}
                                cssClass="room-detail__icon-btn"
                            />
                        </div>
                        <p className="room-detail__link-tagline">{Constant.ROOM_DETAILS_LINK_COPIED_MESSAGE}</p>
                    </div>
                )}

                {!props.collapseOpen && (
                    <p
                        data-test="description_slice"
                        className={
                            'room-detail__desc room-detail__truncate-4 ' +
                            (props.classForTruncate ? 'room-detail__negate-truncate' : '')
                        }
                        dangerouslySetInnerHTML={{
                            __html: props.details.description.slice(0, Constant.DESCRIPTION_LIMIT),
                        }}
                    ></p>
                )}
                {props.collapseOpen && (
                    <Collapse in={props.collapseOpen}>
                        <div
                            id="readmore-collapse-text"
                            className="room-detail__desc"
                            dangerouslySetInnerHTML={{ __html: props.details.description }}
                        ></div>
                    </Collapse>
                )}
                {props.details.description.length > Constant.DESCRIPTION_LIMIT && (
                    <label
                        data-test="desription_label"
                        className="room-detail__read-more"
                        onClick={props.onLabelClick}
                        aria-controls="readmore-collapse-text"
                        aria-expanded={props.collapseOpen}
                    >
                        {!props.collapseOpen
                            ? Constant.ROOM_DETAILS_DESCRIPTION_CLICK_MESSAGE.READ_MORE
                            : Constant.ROOM_DETAILS_DESCRIPTION_CLICK_MESSAGE.COLLAPSE}
                    </label>
                )}
            </div>

            <div className="room-detail__speakers">
                <h4 className="room-detail__speaker-heading">Speakers | {props.details.members.length}</h4>
                <DragDropContext onDragEnd={props.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {provided => (
                            <ul className={props.classForListing} {...provided.droppableProps} ref={provided.innerRef}>
                                {members &&
                                    members.map((member, index) => (
                                        <Draggable key={member.id} draggableId={member.id} index={index}>
                                            {provided => (
                                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                                    <RoomSpeakerComponent
                                                        dragHandleProps={{ ...provided.dragHandleProps }}
                                                        member={member}
                                                        boardId={props.details.boardId}
                                                        onSelectMenu={props.onSelectMenu}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default RoomSideBar;
